#rio-ad-footer {
    background-color: black;
    .container-row{
      padding: 100px 17%;
      background: url(~img/books/leszek-book/bottom-bg-small.jpg) no-repeat center top;
      background-size: 100% 340px;
      position: relative;
      @media (max-width: 1300px) {
        background-size: 1300px 340px;
      }

      .main-text{
        text-align: left;
        width: 40%;
        font-size: 36px;
        font-family: @fontNowayMedium;
        color: white;
        line-height: 1.3;
        font-weight: 150;
        @media (max-width: 1000px) {
          font-size: ~"calc(20px + 30 * ((100vw - 400px) / 1040))";
          width: 55%;
        }
        @media (max-width: 700px) {
          width: 100%;
          text-align: center;

        }
      }

      a {
        background: transparent url(~img/video/pro-vs-pro/cta-link.png);
        background-size: 100% auto;
        position: absolute;
        width: 292px;
        height: 65px;
        left: 65%;
        top: 40%;
        line-height: 65px;
        font-size: 9px;
        color: #4f575a;
        text-align: center;
        font-weight: bold;
        letter-spacing: 1.5px;
        font-family: @fontHeading;
        text-transform: uppercase;
        @media (max-width: 1000px) {
          left: 60%;
        }
        @media (max-width: 700px) {
          top: 75%;
          left: 20%;
        }
        @media (max-width: 700px) {
          left: 15%;
        }
      }
    }
  }
