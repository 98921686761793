.rio-modal {
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 8000;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bg {
    .transition(all, 0.45s, ease-out);
    background: rgba(119, 119, 119, 0.45);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 8010;
  }

  &.active {
    visibility: visible;

    .bg {
      opacity: 1;
    }
  }

  .content {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    visibility: hidden;
    z-index: 8020;
    max-height: 80vh;
    overflow-y: auto;

    &.active {
      visibility: visible;
    }

    // do not show close by default
    .close-message {
      display: none;
    }
  }

  iframe {
    display: block;
  }

  .close {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 30px;
    height: 30px;
    background: transparent url('~img/rio/form_error.png') no-repeat center center;
    cursor: pointer;
    z-index: 1103;
    display: inline;
  }
}
