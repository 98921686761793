// section of the site where users can comment on a thread type
section.comments {
  background: #f7f8f8;
  padding: 30px 0;

  > * {
    margin: 0 15%;
  }

  .comment {
    max-width: 1040px;
    color: #444;
  }

  > header {
      padding-bottom: 5px;

    > h2 {
      font-family: @fontDefault;
      color: #1f2122;
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      margin-right: 6px;
    }

    .comments-filters {
      display: inline-block;
      margin-top: 6px;
      vertical-align: top;

      h4 {
        .transition(color, 0.1s, ease-in-out);
        background-position: right 7px;
        background-size: 6px 6px;
        color: #8f9598;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        padding-right: 16px;

        &:hover {
          color: #4d5254;
        }
      }

      // TODO built out filter menu
      ul {
        display: none;
      }
    }
  }


  .loading-comments,
  .no-comments {
    padding: 0 0 20px 0;

    h4 {
      font-size: 20px;
      font-weight: 500;
      color: #8D969B;
    }
  }


  .upgrade_to_comment {
    margin-top: 80px;
    position: relative;

    h3, .author {
      display: inline-block;

      .avatar img {
        height: 44px;
        width: 44px;
      }
    }

    h3 {
      .border-radius(6px);
      background: #4d5254;
      color: #b5bbbe;
      font-family: @fontDefault;
      font-size: 14px;
      left: 0;
      margin-left: 56px;
      padding: 12px 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }



  .comment {
    border-top: 1px solid @colorBorder;
    padding: 5px 0 0;
    margin: 15px 0 0;
    position: relative;
    overflow: visible;
  }


  // all comments, including nested, styles
  .comment,
  .comment-form .preview{
    // variables for comment
    @widthIndentation: 56px;
    word-wrap: break-word;

    .edit-controls {
      display: none;
      position: absolute;
      right: 40px;
      bottom: 0px;
    }

    // Only show delete/edit controls on hover.
    &:hover {
      .edit-controls {
        display: inline-block;
      }
    }


    // Delete animations
    & > div {
      // This padding needs to be all on the top so inline-anchors work.
      // padding: 20px 0 0;
      position: relative;
    }

    &.ng-leave {
      .transition(all, 2s);
    }

    &.ng-leave.ng-leave-active {
     & > div {
        .transition(all, 2s);
        opacity:0;
        margin-top: -100%;
      }
    }


    // element that is positioned the same height as the sticky header such that animating to it will work
    .scrolltop-pointer {
      visibility: hidden;
      position: absolute;
      left: 0;
    }


    // header area where the avatar, name of author and created_at are displayed
    padding-bottom: 8px;

    .avatar, .author-display_name {
      display: inline-block;
    }

    // Note that the avatar element width must match the padding-left on the subsequent comment area and replies
    .avatar {
      position: relative;
      top: 15px;
      margin-right: 15px;
    }

    .author-display_name {
      font-family: @fontDefault;
      font-size: 15px;
      font-weight: 700;
      margin: 0 6px 8px -4px;
      white-space: nowrap;

      &.pro {
        background: #b7a475;
        color: #fff;
        padding: 0 4px;
        display: inline-block;
        font-weight: 500;
        border-radius: 2px;
        font-size: 14px;
      }
    }

    .time {
      color: #8f9598;
      font-size: 13px;
      font-weight: 300;
    }


    // Align replies and comment content below the name of the commenter, rather than the avatar to the left
    .comment-text, .comment-actions, .replies {
      padding-left: @widthIndentation;
    }

    // Hide the markdown editing buttons and preview if the content_type is html.
    .format-0 {
      .wmd-button-row {
        display: none;
      }
    }


    // Comment and preview element styles.
    .wmd-preview {
      padding-top: 0;
      padding-bottom: 0;
    }

    .comment-text,
    .wmd-preview {
      border: none;
      font-size: 14px;
      line-height: 1.6;
      font-weight: 400;
      overflow: hidden;
      margin: 0;

      img {
        max-width: 100%;
        height: auto;
      }

      p {
        padding-bottom: 10px;
      }

      a {
        color: @colorLink;
        text-decoration: underline;

        &:hover {
          color: @colorLink;
        }
      }

      a.mention-href {
        background-color: #a5aeb2;
        color: #fff;
        font-weight: 500;
        text-decoration: none;
        padding: 3px;
        .border-radius(2px);

        &.pro {
          background-color: #b7a475;
        }
      }

      a.mention-href:hover {
        text-decoration: none;
      }

      ul {
        margin-left: 30px;
        list-style-type: circle;
      }

      ol {
        margin-left: 30px;
        list-style-type: decimal;
      }

      blockquote {

      }
    }

    .footer-and-forms {
      min-height: 24px;
    }

    .comment-actions {
      position: relative;

      .flag-control {
        display: none;
        position: absolute;
        right: 0;
        top: 0px;
      }
    }

    &:hover .comment-actions .flag-control {
      display: block;
    }


    // actions such as like, reply, etc.
    .comment-actions .actions {
      list-style: none;
      padding: 5px 0 0;

      li {
        .transition(color, 0.1s, ease-in-out);
        color: #8f9598;
        cursor: pointer;
        display: inline;
        font-size: 12px;
        margin-right: 24px;
        vertical-align: top;

        &:hover {
          color: #4d5254;
        }

        > div, > span, > svg {
          // background is needed to hide the animation
          background-color: #f7f8f8;
          display: inline-block;
          vertical-align: top;
        }

        > div.like {
          > div, > span, > svg {
            display: inline-block;
            vertical-align: top;
          }
        }
      }

      // icons
      .icon {
        height: 13px;
        margin-right: 2px;
        position: relative;
        width: 13px;

        path {
          fill: #666B6B;
        }
      }

      li > div.like {
        // how it should look if a user liked the comment
        &.user-liked {
          // run this animation for the svg icon
          .icon-like {
            -webkit-animation: like-out 0.3s linear;

            path {
              fill: #be141a;
            }
          }
        }

        .icon {
          top: 2px;
        }

        path {
          .transition(fill, 0.15s, ease-in-out);
        }

        .like-count {
          position: relative;
        }

        .like-count-info {
          .transition(width, 0.15s, ease-in-out);
          height: 15px;
          overflow: hidden;
          position: relative;
          width: 0;

          &:hover {
            text-decoration: underline;
          }
        }

        &:hover .like-count-info {
          width: 125px;
          overflow: hidden;
        }
      }

      .icon-reply {
        height: 15px;
        width: 15px;
        left: -2px;
        position: relative;
      }
    }
  }


  // Replies
  .replies {
    padding-left: 65px;

    .comment-reply {
      position: relative;
    }
  }



  // New comment form wrapping div
  .new-comment {
    margin-top: 25px;
    border-top: 1px solid @colorBorder;

    .comment-form {
      padding-left:0;
    }

    .comment {
      border: none;
    }

    .preview .comment {
      padding: 0;
      margin-top: 10px;
    }

    .page-down {
      .wmd-button-row,
      textarea {
        .transition(all, 0.3s, ease-out);
      }
    }

    .page-down.collapsed {
      .wmd-button-row {
        height: 1px;
        top: -12px;
      }

      .wmd-button-row {
        overflow: hidden;
      }

      textarea {
        overflow: hidden !important;
        min-height: 38px;
        height: 38px;
      }
    }
  }


  // List of errors
  .error {
    ul {
      list-style: none;
      color: red;
      text-align: right;
      margin: 0;
      padding: 0;
    }
  }


  // form used to make a new comment/reply
  .comment-form {
    padding: 10px 0 20px 60px;

    .controls {
      margin: 0;
      text-align: right;
    }

    &.active .controls {
      display: block;
    }

    // Note that the avatar element width must match the padding-left on the subsequent comment area and replies
    .author {
      display: inline-block;
      position: relative;
      top: 30px;
      width: 9%;
    }


    .cancel {
      color: @colorLink;
      cursor: pointer;
      margin-right: 20px;
      position: relative;
      top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .btn {
      color: #fff;
      background: #272a2b;
      border: none;
      float: right;

      &:hover {
        background: #323536;
      }

      &:disabled {
        opacity: 0.3;
        cursor: auto;
      }
    }
  }
}

body.feeds-following .scrolltop-pointer {
  top: -45px;
}

body.thread-detail .scrolltop-pointer {
  top: -100px;
}


.page-down {
  display: inline-block;
  width: 90%;

  .preview {
    max-width: 644px;
    word-wrap: break-word;
  }

  .preview h4 {
    display: none;
    color: #272a2b;
    font-size: 13px;
    font-weight: 500;
  }
}


@media (max-width:@widthMobileMax) {
  section.comments {
    margin: 0;
    padding: 10% 5%;

    > header {
      border-width: 1px;
      padding-bottom: 10px;

      h2 {
        font-size: 18px;
      }
    }

    .comment > a > .avatar {
      top: 8px;
      width: 32px;
    }

    .comment .edit-controls {
      display: inline-block;
    }

    .comment .comment-actions .flag-control {
      display: block;
    }

    .comment .comment-text, .comment .wmd-preview {
      font-size: 13.5px;
      line-height: 1.5;
      font-weight: 400;
    }

    .comment .comment-actions .actions {
      padding: 10px 0 0;
    }

    .comment .comment-text, .comment .comment-actions {
      padding-left: 2px;
    }

    .comment .replies {
      padding-left: 25px;
    }

    .comment-form {
      padding: 0 0 0 4px;

      > h3 {
        font-weight: 300;
        margin: 15px 0 10px;
      }

      .avatar {
        display: none;
      }

      .page-down {
        display: inline-block;
        width: 100%;
      }

      .wmd-wrap .wmd-panel {
        margin-bottom: 0;

        .preview {
          display: none;
        }
      }

      .wmd-wrap .editor-wrap {
        margin-bottom: 0;
      }

      .wmd-wrap textarea {
        font-weight: 400;
        line-height: 1.5;
        font-size: 13.5px;
      }

      .btn {
        float: right;
        font-size: 11px;
        margin-bottom: 10px;
        padding: 6px 16px;
      }
    }

    .new-comment {
      border-top: none;
    }

  }
}

@media (max-width: @widthMobileMax) {
  section.comments {
    > * {
      margin: 0 5%;
    }

    .comment {
      .avatar {
        top: 13px;

        svg {
          width: 18px;
          height: 18px;
        }

        img {
          border-radius: 50%;
          height: 36px;
          width: 36px;
        }
      }

      .comment-text, .comment-actions {
        padding-left: 6px;
      }

      &.comment-reply {
        .comment-text, .comment-actions {
          padding-left: 20px;
        }
      }
    }


    .replies {
      padding-left: 0;
    }

    .comment-form {
      padding: 10px 0 20px;
      width: 96%;
      margin: 0 1% 0 3%;

      .controls {
        margin: 10px 0 0 0;
      }

      .cancel {
        top: 3px;
      }
    }
  }
}

.signup-or-login {
  padding: 2%;
  background-color: #454c4e;
  text-align: center;
  margin-top: 5%;

  p {
    color: #fff;
    padding: 0;
  }
}


