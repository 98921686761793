// Reusable layouts for sections of the site
.layout {
  .flex_column(9);
  .flex_container(row);
  .transition(all, 0.20s, ease-in-out);
}

#torso {
  .box-shadow(0px 0px 8px 0px rgba(0,0,0,0.1));
  background: #fff;
  border-right: 1px solid #ccd1d3;
  margin: 50px auto 0;
  // sizing of the layout depending on the window size
  //  Default to the standard desktop max.
  width: @widthDesktopMax;

  // If the browser size is smaller, make the torso full width.
  @media (max-width: @widthDesktopMax) {
    & {
      width: 100%;
    }
  }

  .container {
    margin: 0 5%;
    position: relative;

    > .pinned-threads-table {
      margin-bottom: 2%;

      h3 {
        font-size: 22px;
        padding-left: 30px;
        background-image: url(~img/icons/pin.png);
        background-size: auto 65%;
        background-position: 0;
        background-repeat: no-repeat;
        font-weight: 500;
        color: #a8aeb0;
        margin: 1% 0;
      }
    }
  }
}




// One-column layout.
.layout-one_column {
  position: relative;
  z-index: 1000;

  > .col_1 {
    .flex_column(1);
    // No need to specify width - it's 100%!
  }
}




// Standard small left column two column layout. Currently used in the user following feed.
// This uses nested flex-box containers. The menu + rest of the site are column flex boxes. Then the two columns in the
// feed are row based flex boxes, and within each of those are column based flex boxes that are scrollable-y. The reason
// for the last one is we're then able to use a trick where we set transform3D on both the containers and sticky headers
// to then make them fixed relative to the container rather than the entire document.
//
// Here's an example of this: http://codepen.io/anon/pen/Cavox
.layout-two_column-left_small {
  .transition(left, 0.22s, ease-out);
  margin: 0 ;

  // column defaults
  > .col_1,
  > .col_2 {
    max-height: 100%;
    position: relative;
    overflow: hidden;
  }

  // left column
  > .col_1 {
    .flex_column(1);
    border-right: #c6cacc solid 1px;
    min-width: 334px;
  }

  // right column
  > .col_2 {
    // make it overwhelmingly larger than the other column, then set mid/max widths on the smaller one
    .flex_column(30);
    background: #fff url(~img/partials/thread/watermark.jpg) no-repeat 49% center;
    background-size: 140px 201px;
    position: relative;
    .thread-close {
      background-image: url(~img/feeds/Close.png);
      width: 16px;
      height: 16px;
      position: fixed;
      left: 92%;
      top: 0px;
      &:hover {
        cursor: pointer;
      }
      @media (min-width: 1340px) {
        left: 1005px;
      }
    }
  }

  // toggle on mobile/desktop when col2 slides over col1
  .back-to-col_1 {
    cursor: pointer;
    display: none;
  }

  // loaders for each column
  .loading-col_1 {
    .transform(translateY(-50%));
    text-align: left;
    position: absolute;
    top: 50%;
    left: 2%;
    z-index: 4200;

    h3 {
      font-weight: 300;
    }

    .loader {
      display: inline-block;
      margin: 0 auto;
      font-size: 4px;
      position: relative;
      text-indent: -9999em;
      -webkit-animation-delay: 0.16s;
      animation-delay: 0.16s;
      top: -11px;
      left: 20px;

      @-webkit-keyframes loader {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em #aaa;
        }
        40% {
          box-shadow: 0 2.5em 0 0 #fff;
        }
      }
      @keyframes loader {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em #aaa;
        }
        40% {
          box-shadow: 0 2.5em 0 0 #fff;
        }
      }

      &:before,
      &:after,
      & {
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        .animation(loader, 1.4s, 0, ease-in-out, infinite);
        .border-radius(50%);
        height: 2.5em;
        width: 2.5em;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
      }

      &:before {
        left: -3.5em;
      }
      &:after {
        left: 3.5em;
        -webkit-animation-delay: 0.32s;
        animation-delay: 0.32s;
      }
    }
  }
  .loading-col_2 {
    background: #fff url(~img/partials/thread/watermark.jpg) no-repeat 49% center;
    background-size: 140px 201px;
    display: none;
    height: 100%;
    overflow: scroll;
    position: absolute;
    top: 0;
    z-index: 4200;
    width: 100%;

    .progress {
      background: #a81313;
      height: 4px;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
    }
  }


  //  Default to the standard desktop max.
  // width: @widthDesktopMax;

  // If the browser size is smaller, make the torso full width.
  @media (max-width: @widthDesktopMax) {
    & {
      width: 100%;
    }
  }
}


@media (min-width: @widthTabletMin) and (max-width: 960px) {
  .layout-two_column-left_small {
    .back-to-col_1 {
      &.mobile {
        display: none !important;
      }
      cursor: pointer;
      display: none;
      height: 45px;
      left: 41%;
      top: 60px;
      position: fixed;
      width: 45px;
      z-index: 4030;

      // * {
      //   -webkit-transform: translate3d(0,0,0);
      // }

      .icon {
        .transform(rotate(90deg));
        width: 20px;
        height: 20px;
        left: 12px;
        top: 3px;
        z-index: 4200;
        position: relative;
        margin-top: 4px;

        path {
          fill: #aaa;
        }
      }

      // added the word 'menu' because of http://jxnblk.tumblr.com/post/82486816704/an-update-on-the-hamburger-menu
      // since we have the have the menu we have to toggle it somehow, so we need to be more explicit for users that
      // haven't used the hamburger
      h2 {
        font-size: 9px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        color: #aaa;
      }
    }

    .col_1-cover {
      background: #000;
      display: none;
      left: 0;
      opacity: 0.2;
      top: 0;
      z-index: 4011;
      position: fixed;
      width: 40%;
      height: 100%;
    }

    .col_2-cover {
      background: #000;
      display: block;
      left: 40%;
      opacity: 0.2;
      top: 0;
      z-index: 4021;
      position: fixed;
      width: 60%;
      height: 100%;
    }

    > .col_1 {
      max-width: 40%;
    }

    > .col_2 {
      -webkit-overflow-scrolling: touch;
      height: 100%;
      left: 40%;
      position: absolute;
      top: 0;
      width: 92%;
      z-index: 4020;
    }
  }

  body.show-col_1 .layout-two_column-left_small {
    .back-to-col_1 {
      .icon {
        .transform(rotate(90deg));
      }
    }
  }

  body.show-col_2 .layout-two_column-left_small {
    .back-to-col_1 {
      left: 9%;

      .icon {
        .transform(rotate(-90deg));
      }
    }

    .col_1-cover {
      display: block;
    }

    .col_2-cover {
      display: none;
    }

    > .col_2 {
      left: 8%;
    }
  }
}

@media (max-width: @widthMobileMax) {
  .layout-two_column-left_small {
    // weird iOS viewport issue, needs restraints
    overflow-x: hidden;
    // overflow-y: hidden;

    > .col_1, > .col_2 {
      .transition(all, .2s, ease-out);
      height: auto;
      width: 100%;

      // override the default
      min-width: 200px;
    }

    > .col_1.event-list {
      padding-top: 0;
      margin-top: 0;

      .state-selector {
        top: 0;
        .desktop-header {
          display: none;
        }
        .mobile-header {
          display: block;
          .header-btn {
            font-size: 14px;
            font-weight: 400;
            font-family: @fontNowayMedium;
            &:hover {
              cursor: pointer;
            }
          }
          .dashboard-btn {
            float: left;
            color: #828282;
            text-decoration: none;
            padding-left: 30%;
          }
          .feed-btn {
            float: right;
            color:black;
            text-decoration: underline;
            padding-right: 30%;
          }
          &.mobile-show-dashboard {
            .dashboard-btn {
              color:black;
              text-decoration: underline;
            }
            .feed-btn {
              color: #828282;
              text-decoration: none;
            }
          }
        }
      }

      .filter {
        top: 45px;
        &.mobile-show-dashboard {
          display:none;
        }
      }
    }
  }

  body.show-col_1 {
    .back-to-col_1 {
      &.not-mobile {
        display: none !important;
      }
    }

    .layout-two_column-left_small {
      // position: relative;
      margin-top: 50px;
      // -webkit-transform: translate3d(0,0,0);

      > .col_1 {
        border-right: none;
        .list-inner {
          &.mobile-show-dashboard{
            display: none;
          }
        }
      }

      > .col_2 {
        right: -100%;
        top: 0;
        position: absolute;
        &.mobile-show-dashboard{
          right: 0px;
          top: 45px;
          height: 100%;
          overflow: scroll;
          .thread-detail-ui-view {
            // display: none;
          }
        }
      }
    }
  }

  body.show-col_2 {
    // move the logo over and show the back arrow
    .nav-main > .user_menu-logo_name {
      left: 48px;
    }

    // move over the back arrow
    .back-to-col_1 {
      &.not-mobile {
        display: none !important;
      }
      &.mobile {
        display: block;
        background-image: url(~img/feeds/arrow.svg);
        width: 20px;
        height: 12px;
        background-repeat: no-repeat;
        top:18px;
        margin-right: 3px;
      }
      // transition for feed when adding the back button
      .transform(translate3d(0,0,15px));
      .transition(left, 0.4s, linear);
      left: 3px;
      position: fixed;
      z-index: 5200;

      .icon {
        -webkit-transform: rotate(90deg);
        width: 20px;
        height: 20px;
        left: 12px;
        top: 3px;
        z-index: 4200;
        position: relative;
        margin-top: 4px;

        path {
          fill: #fff;
        }
      }

      // added the word 'menu' because of http://jxnblk.tumblr.com/post/82486816704/an-update-on-the-hamburger-menu
      // since we have the have the menu we have to toggle it somehow, so we need to be more explicit for users that
      // haven't used the hamburger
      h2 {
        font-size: 9px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        color: #aaa;
      }
    }

    // hide the events
    .layout-two_column-left_small {
      margin-top: 50px;
      > .col_1 {
        right: -100%;
        top: 0;
        position: absolute;
      }
    }
  }
}





// Standard small right column two column layout. Currently used on thread type detail views.
.layout-two_column-right_small {
  .flex_container(row);
  .transition(left, 0.22s, ease-out);
  position: relative;
  z-index: 1000;

  // left column
  > .col_1 {
    .box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.15));
    .flex_column(3);
    border-right: 1px solid #aaafb1;
    position: relative;
  }

  &.provideo > .col_1 {
    border-right: none;
    .box-shadow(none);
  }

  // right column
  > .col_2 {
    .flex_column(1);
    max-width: 298px;
    min-width: 298px;
    .thread-close {
      top: 60px;
    }
  }


  //  Default to the standard desktop max.
  width: @widthDesktopMax;

  // If the browser size is smaller, make the torso full width.
  @media (max-width: @widthDesktopMax) {
    & {
      width: 100%;
    }
  }

  // Mobile Size
  @media (max-width: @widthMobileMax) {
    .col_1 {
      width: 100%;
      .flex_column(none);
    }

    .col_2 {
      display: none;
    }
  }
}
