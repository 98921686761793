.rio--accordion {
  > li {
    .expand-trigger {
      cursor: pointer;
    }

    .section {
      display: none;
    }

    &.expanded {
      .section {
        display: block;
      }
    }
  }
}