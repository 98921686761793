body.raf-affiliates-landing {
  .row {
    width: 100%;
    text-align: center;
    margin-left: 0;
    padding-top: 50px;
    padding-bottom: 50px;

    &.first {
      margin-top: @navHeight; // because of the top nav
    }

    &.row-white {
      background-color: #fff;
    }

    &.row-grey {
      background-color: #f2f2f2;
    }

    &.extra-padding {
      padding-top: 125px;
      padding-bottom: 125px;
    }

    > .container-row {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      text-align: center;

      > .left-right-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .left {
          text-align: left;
          align-self: center;
          margin-right: 10px;

          > img.sulsky-image {
            width:630px;
            height:370px;
          }
        }

        .right {
          text-align: left;
          align-self: center;
          margin-left: 10px;

          span.twitter,
          span.facebook,
          span.email,
          span.link {
            float: left;
            margin-left: 60px;
            text-align: center;
            text-transform: uppercase;
            width: 80px;

            > img {
              position: relative;
              margin: 0 auto;
              width: 80px;
              height: 80px;
            }
          }

          span.facebook,
          span.link {
            > img {
              top: 11px;
              width: 60px;
              height: 60px;
            }

          }
        }
      }
    }
  }
}

// Mobile support
@media (max-width: @widthMobileMax) {
  body.raf-affiliates-landing {
    .row {
      &.extra-padding {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      > .container-row {
        > .left-right-container {
          flex-direction: column;

          .left,
          .right {
            text-align: center;

            span.twitter,
            span.facebook,
            span.email,
            span.link {
              width: 60px;
              margin-left: 20px;
              text-align: center;
              text-transform: none;
              font-size: 12px;

              > img {
                width: 60px;
                height: 60px;
              }
            }

            span.twitter {
              margin-left: 0;
            }

            span.facebook,
            span.link {
              > img {
                top: 8px;
                width: 40px;
                height: 40px
              }
            }
          }

          .left {
            > img.sulsky-image {
              width: 100%;
              height: auto;
            }
          }

          .right {
            padding-top: 50px;
          }
        }
      }
    }
  }
}
