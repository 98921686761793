// Forms.less
// Base styles for various input types, form layouts, and states
// -------------------------------------------------------------

// variables
@inputBackground:           #fff;
@inputBorder:               #ccc;
@inputBorderRadius:         3px;
@baseLineHeight:            18px;


// Make all forms have space below them
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

// Groups of fields with labels on top (legends)
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: @baseLineHeight * 1.5;
  font-size: 13px * 1.5;
  line-height: @baseLineHeight * 2;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

input,
button,
select,
textarea {
  font-family: @fontDefault;
  outline: none !important;
}

// Identify controls by their labels
label {
  display: block;
  margin-bottom: 5px;
}

// Shared size and type resets
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  display: inline-block;
  padding: 6px 10px;
  margin-bottom: 9px;
  font-size: 13px;
  color: #555;
}

// Reset appearance properties for textual inputs and textarea
// Declare width for legacy (can't be on input[type=*] selectors or it's too specific)
input,
textarea {
  width: 210px;
}
// Reset height since textareas have rows
textarea {
  height: auto;
}
// Everything else
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  border: 1px solid @inputBorder;
  .border-radius(@inputBorderRadius);
  .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));

  // Focus state
  &:focus {
    border-color: rgba(82,168,236,.8);
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6)");
  }
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 3px 0;
  *margin-top: 0; /* IE7 */
  line-height: normal;
  cursor: pointer;
}

// Reset width of input buttons, radios, checkboxes
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto; // Override of generic input selector
}

// Make uneditable textareas behave like a textarea
.uneditable-textarea {
  width: auto;
  height: auto;
}

// Set the height of select and file controls to match text inputs
select,
input[type="file"] {
  height: auto; /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px; /* For IE7, add top margin to align select with labels */
}

// Make select elements obey height by applying a border
select {
  width: 220px; // default input width + 10px of padding that doesn't get applied
  // border: 1px solid #bbb;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
}

// Indent the labels to position radios/checkboxes as hanging
.radio,
.checkbox {
  min-height: 18px; // clear the floating input if there is no label text
  padding-left: 18px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}

// Move the options list down to align with labels
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px; // has to be padding because margin collaspes
}

// space out consecutive inline controls
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}


// General classes for quick sizes
.input-mini       { width: 60px; }
.input-small      { width: 90px; }
.input-medium     { width: 150px; }
.input-large      { width: 210px; }
.input-xlarge     { width: 270px; }
.input-xxlarge    { width: 530px; }

// Grid style input sizes
input[class*="span"],
select[class*="span"],
textarea[class*="span"] {
  float: none;
  margin-left: 0;
}


// DISABLED STATE
// --------------

// Disabled and read-only inputs
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eee;
  border-color: #ddd;
}

// Explicitly reset the colors here
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}


// Margin to space out fieldsets
.control-group {
  margin-bottom: @baseLineHeight / 2;
}

// Legend collapses margin, so next element is responsible for spacing
legend + .control-group {
  margin-top: @baseLineHeight;
  -webkit-margin-top-collapse: separate;
}


form {

  label {
    font-family: @fontDefault;
    font-size: 14px;
    color: #878282;
  }

  textarea {
    font-family: @fontDefault;
    font-size: 13px;
    color: #878282;
    border: 1px solid #cccccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 20px;
    width: 473px;
    height: 200px;
  }
}

select {
  cursor: pointer;
}
