// The notifications dropdown in the main menu.
.menu-notifications {

  .icon-notifications {
    position: relative;
    top: 1px;

    svg {
      height: 15px;
    }
  }

  .menu-dropdown--dropdown {
    .tab {
      background: #fff;
    }

    .list {
      height: 275px;
      margin-bottom: 49px;

      .notification {
        h3 .action-text {
          font-weight: normal;
        }
      }

      a:hover {
        color: inherit;
      }
    }

    .footer {
      a {
        float: right;
      }

      .btn {
        float: left
      }

      .link-settings {
        font-size: 12px;
        position: relative;
        top: 5px;

        &:hover {
          color: #a70106;
        }
      }
    }
  }

}
