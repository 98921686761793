
// A list of users displayed small.
ul.list-users {
  border-bottom: 1px solid #ccd1d3;
  clear: both;
  .clearfix();
  list-style: none;
  padding: 0;

  li {
    padding: 0;
    margin: 0;
    position: relative;

    a {
      .clearfix();
      border-top: 1px solid #dee2e2;
      display: block;
      padding: 10px 0;

      &.is-pro {
        padding: 15px 0 5px;

        .user-info {
          .name {
            margin-top: 6px;
            display: inline-block;
          }

          .level {
            margin-top: -2px;
          }
        }
      }

      .avatar {
        float: left;
        margin: 0px 0 0 17px;
        overflow: hidden;
        padding: 0 3px;

        img {
          display: block;
          height: 44px;
          overflow: hidden;
          width: 44px;
        }

        &.is_pro {
          background: url("~img/rio/sidebar/crown.jpg") no-repeat scroll center 2px #212121;
          .border-radius(3px);
          margin-top: -4px;
          overflow: hidden;
          padding: 12px 3px 4px;
        }
      }

      .user-info {
        float: left;
        padding-left: 11px;
        position: relative;
        top: 7px;

        .name {
          color: #3f3f3f;
          font-size: 14px;
          line-height: 1;
          font-family: @fontHeading;
          font-weight: 500;
        }

        .level,
        .points {
          color: #878282;
          display: block;
          font-size: 11px;
          margin-top: -3px;

          &.is-pro {
            color: @colorLink;
          }
        }
      }
    }


    form {
      .btn {
        font-size: 11px;
        padding: 4px 14px;
        .border-radius(3px);
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }


  }


  &.sidebar {
    .avatar img {
      display: block;
      height: 40px;
      overflow: hidden;
      width: 40px;
    }

    li a {
      border-top: 1px solid #ccd1d3;

      .user-info {
        top: 4px;

        .name {
          font-size: 12px;
        }
      }

      form .btn {
        display: none;
        top: 17px;
      }
    }

    li:hover {
      background: #fff;

      form .btn {
        display: block;
      }
    }
  }
}
