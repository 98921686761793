body.feeds-following {
    .user-tour, .user-tour-mobile {
        .description-box {
            color: black;
            background: transparent;
            position: absolute;
            display: none;

            .text-box {
                background: white;
                padding: 10px;
                border-radius: 10px;
                font-family: @fontOpenSans;
                h2 {
                    font-weight: bolder;
                }
            }

            a {
                color: black;
                text-decoration: underline;
            }

            .button-container {
                height: 20px;
            }

            .next-box, .skip {
                &:hover {
                    cursor: pointer;
                }
            }

            .next-box {
                float: left;
                font-weight: normal;
            }

            .skip {
                float: right;
                font-weight: normal;
                color: #a5a6a6;
            }

            .dot {
                height: 10px;
                width: 10px;
                background: white;
                border-radius: 50%;
                &.verticle {
                    margin-left: -5px;
                }
                &.horizontal {
                    margin-top: -5px;
                }
            }

            .verticle-line {
                height: 20px;
                width: 1px;
                background: white;
            }

            .horizontal-line {
                width: 20px;
                height: 1px;
                background: white;
            }
        }

    }
    .user-tour {
        // This is all the tour stuff for non mobile (greater than 767px)
        @media (max-width: @widthMobileMax) {
            display: none;
        }

        .description-box {
            &.active {
                display: block;
            }

            .text-box {
                width: 400px;
                font-size: 14px;
            }

            &.description-box-0 {
                top: 60px;
                left: 35px;
                .up-pointer {
                    margin-left: 40px;
                }
            }

            &.description-box-1 {
                &.active {
                    display: flex;
                }
                flex-direction: row;
                .left-pointer {
                    display: flex;
                    flex-direction: row;
                    margin-top: 20px;
                }
            }

            .up-pointer {
                margin-left: 50px;
            }
        }
    }

    // ALL the tour stuff for mobile is below

    .user-tour-mobile {
        @media (min-width: @widthMobileMax) {
            display: none;
        }

        .description-box {
            z-index: 10000;
            &.active {
                display: block;
            }

            .text-box {
                width: 200px;
                font-size: 12px;
            }

            .right-pointer, .left-pointer {
                display: flex;
                flex-direction: row;
                margin-top: 20px;
            }

            &.description-box-0 {
                top: 65px;
                left: ~"calc(50vw - 110px)";
                .up-pointer {
                    margin-left: 100px;
                }
            }

            &.description-box-1 {
                &.active {
                    display: flex;
                }
                flex-direction: row;
                color: white;
                .text-box, .dot, .horizontal-line {
                    background: grey;
                }
                a {
                    color: white;
                    text-decoration: underline;
                }
            }

            &.description-box-2,
            &.description-box-3,
            &.description-box-4,
            &.description-box-5 {
                &.active {
                    display: flex;
                }
                flex-direction: row;
                right: 5%;
            }
        }

    }
}
