// if site message is showing, push down the site

@media (max-width: @widthMobileMax) {
  body.site_message_showing,
  body.site_message_showing_feed {
    #unsupported-browser,
    #site_message,
    #upgrade_notice {
      top: auto;
      bottom: 0;
    }
  }
}

@media (min-width: @widthTabletMin) {
  body.site_message_showing {
    .nav-main {
      top: 50px;
    }

    .nav-drawer {
      top: 85px;

      .nav-main-user {
        padding-bottom: 85px;
      }
    }

    #torso {
      margin-top: 100px;
    }

    @media (max-width: @widthMobileMax) {

    }
  }

  body.site_message_showing_feed {
    #unsupported-browser,
    #site_message,
    #upgrade_notice {
      top: auto;
      bottom: 0;
    }
  }
}

@media (max-width: @widthMobileMax) {
  #unsupported-browser,
  #site_message,
  #upgrade_notice {
    .span12 {
      // leave a space for the close button
      margin-right: 8%;
    }

    i.close-message {
      // make close slightly bigger so it can be pressed
      z-index: 3000;
      background-repeat: no-repeat;
      right: 0;
      height: 50px;
      width: 50px;
    }
  }
}

#site_message.discount-available {
  .cta {
    .transition(all, 0.20s, ease-in-out);
    background-color: #333;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: @fontHeading;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    margin-left: 4px;
    padding: 11px 14px;
    text-decoration: none;

    &:hover {
      background: #333;
    }
  }
}

#unsupported-browser,
#site_message,
#upgrade_notice {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  *, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  background: #f6f6f6 url(~img/partials/site-message/banner-bg.jpg) repeat-x left top;
  background-size: auto 100%;
  left: 0;
  position: fixed !important;
  text-align: center;
  top: 0;
  transition: background-color 400ms ease;
  width: 100%;
  z-index: 8000 !important;
  min-height: 50px;

  .container,
  .site-message__content {
    color: #858a90;
    display: inline-block;
    font-family: @fontHeading;
    font-size: 15px;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    padding: 1em;

    strong {
      color: #1a1e1f;
    }
  }

  a {
    color: #FFF;
    text-decoration: underline;
  }

  &.order_by {
    display: block;
  }

  &.show {
    display: block;
  }

  .close-message {
    cursor: pointer;
    position: absolute;
    right: 19px;
    top: 17px;

    .icon {
      height: 16px;
      width: 16px;
    }

    path {
      fill: #1a1e1f;
    }
  }
}

@media (max-width: 1439px) {
  #unsupported-browser,
  #site_message,
  #upgrade_notice {
    .site-message__content {
      font-size: ~"calc(10px + 5 * ((100vw - 400px) / 1040))";
    }
  }
}

@media (max-width: @widthMobileMax) {
  #unsupported-browser,
  #site_message,
  #upgrade_notice {
    text-align: left;

    .annual-banner-close {
      z-index: 90;
      right: 0px;
      top: 10px;
      padding: 5px;
    }

    .site-message__content {
      line-height: 1.4;
      padding-left: 108px;
      position: fixed;
      margin: 5px;

      .cta {
        font-size: ~"calc(11px + 2 * ((100vw - 400px) / 1040))";
        position: absolute;
        left: 10px;
        top: 0;
      }
    }

    strong {
      display: block
    }
  }
}

// by default don't show
#site_message {
  display: none;
}

.message_overlay {
  background: transparent;
  z-index: 1100;
  position: relative;
  width: 100%;

  &.hide_message_container_right {
    .message_container_right {
      display: none;
    }
  }

  .message_signup_error {
    background-color: #982d28;
    text-align: center;
    margin-bottom: -50px; // this is as the torso has margin because of the top nav

    .message_container {
      position: relative;
      margin-top: 52px;
      padding-top: 25px;
      padding-bottom: 25px;

      .message {
        padding-left: 50px;
        padding-right: 50px;

        .paypal,
        .credit-card,
        .skrill {
          color: #fff;
          font-size: 15px;
          letter-spacing: 0.2px;
          display: none;
          padding: 0;
        }

        &.paypal {
          .paypal {
            display: block;
          }
        }

        &.skrill {
          .skrill {
            display: block;
          }
        }

        &.credit-card {
          .credit-card {
            display: block;
          }
        }
      }

      .close-message {
        color: #fff;
        cursor: pointer;
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 30px;
        font-size: calc(35px + 6 * ((100vw - 320px) / 1330));
        padding: 1%;
      }
    }
  }

  .message_container_right {
    position: absolute;
    left: 50%;
    top: 105px;

    .message_container {
      position: relative;
      left: -50%;
      background: #fff;

      .message {
        border: 1px solid #cecece;
        .border-radius(3px);
        padding: 25px 50px 18px 50px;
        overflow: hidden;
        position: relative;
        min-width: 500px;
        max-width: 700px;

        .message_list {
          list-style: none;
          padding: 0;

          li {
            color: #808080;
            display: block;
            padding: 0 0 8px 0;
            text-align: center;
          }
        }

        .close-message {
          background: transparent url(~img/rio/message_close.png) no-repeat left top;
          cursor: pointer;
          height: 25px;
          position: absolute;
          right: 0;
          text-indent: -9999px;
          top: 0;
          width: 24px;
        }
      }
    }
  }
}

#neck .message {
  color: #fff;
  font-family: @fontHeading;
  font-weight: 400;
  font-size: 14px;
  padding: 20px 0;
  position: relative;
  text-align: center;

  .message_container {
    margin: 0 auto;
    width: 80%;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .close-message {
    background: transparent url(~img/rio/neck_message_close.png) no-repeat left top;
    cursor: pointer;
    display: block;
    height: 11px;
    position: absolute;
    right: 15px;
    text-indent: -9999px;
    top: 14px;
    width: 11px;
  }

  &.upgrade {
    .box-shadow(inset 0 1px 0 1px rgba(100, 100, 100, 0.4));
    .border-radius(4px);
    border: 1px solid #000;
    color: #f5da5e;
    background: #202020;
    font-family: @fontHeading;
    font-weight: 500;
    font-size: 15px;

    &:after {
      .box-shadow(0 4px 6px 0 rgba(0, 0, 0, 0.5));
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 49px;
      width: 100%;
      z-index: 0;
    }

    strong {
      font-family: @fontHeading;
      font-weight: normal;
    }

    .btn-elite {
      color: #756601;
      margin-left: 11px;
      margin-right: 11px;
      text-decoration: none;
      width: 86px;

      &:hover {
        background-color: transparent;
      }
    }

    .close-message {
      background: transparent url(~img/video/upgrade_close_btn.png) no-repeat left top;
      height: 12px;
      right: 9px;
      top: 9px;
      width: 12px;
    }
  }
}

// Style upgrade notice separately since it's slightly different in markup and design.
#neck #upgrade_notice {

  &::before {
    content: None;
  }

  .message_container {
    color: #000;

    a {
      color: @colorLink;
    }
  }

  .message {
    position: static;

    .close {
      background: transparent url(~img/rio/message_close.png) no-repeat left top;
      cursor: pointer;
      height: 25px;
      position: absolute;
      right: 0;
      text-indent: -9999px;
      top: 0;
      width: 24px;
    }
  }
}
