body.about-us {
  background: #fff;
  padding-top: @navHeight;

  div.about-us {
    display: flex;
    justify-content: center;
    flex-direction: column;

    section {
      display: flex;
      justify-content: center;
    }

    section.header {
      background: url(~img/partials/about-us/about-us-compressed.png) no-repeat top;
      background-color: #000;
      background-size: 100% 100%;
      padding-top: 150px;
      padding-bottom: 150px;
      position: relative;

      h1 {
        color: #fff;
        font-size: calc(46px + 6 * ((100vw - 320px)/ 1330));
        font-weight: 600;

        b {
          color: #b7a475;
        }

        &:before,
        &:after {
          color: #b7a475;
          display: block;
          text-align: center;
          font-weight: 100;
          content: "“";
          height: 40px;
        }

        &:after {
          transform: rotate(180deg);
        }
      }

      &:after {
        background: url(~img/partials/about-us/phils_signature.png) no-repeat top;
        background-size: cover;
        content: '';
        position: absolute;
        height: 30px;
        width: 135px;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    section.body {
      flex-direction: column;

      .phil-pic {
        .border-radius(2px);
        //background: url(..) no repeat top;
        background-size: cover;
        top: -40px;
        position: relative;
        margin: 0 auto;

        .icon-pro {
          height: 35px;
          width: 35px;
          margin: 0 4px 0 0px;
          top: 45px;
          left: 45px;
          position: absolute;

          path {
            .transition(fill, .2s, ease-in-out);
          }

        }

        img {
          .border-radius(2px);
          display: block;
          width: 70px;
          height: 70px;
        }
      }

      h2 {
        color: #000;
        font-size: calc(30px + 6 * ((100vw - 320px)/ 1330));
        font-weight: 500;
        text-align: center;

        &:after {
          content: '';
          width: 110px;
          height: 2px;
          background-color: #cdcfd3;
          margin: 0 auto;
          display: block;
        }
      }

      .body-content {
        max-width: 650px;
        text-align: center;
        margin: 0 auto;
        padding: 50px 10px;

        p {
          font-size: calc(18px + 6 * ((100vw - 320px)/ 1330));
        }
      }

    }

    section.get-started {
      background: #2e3537;
      padding: 50px;
      align-items: center;
      justify-content: space-evenly;

      p.join {
        font-family: @fontHeading;
        color: #fff;
        font-size: calc(24px + 6 * ((100vw - 320px)/ 1330));
      }

      a.cta {
        .transition(all, 0.2s, ease-out);
        .border-radius(4px);
        background: #fff;
        color: #1b1e1f;
        cursor: pointer;
        display: inline-block;
        font-family: @fontHeading;
        font-size: 16px;
        font-weight: 500;
        padding: 12px 30px;

        &:hover {
          background: #eaeaea;
          text-decoration: none;
          .border-radius(8px);
        }
      }
    }
  }
}


@media (max-width: @widthTabletMin) {
  body.about-us {
      div.about-us {
        section.header {
          background-size: cover !important;
        }

        section.get-started {
          flex-direction: column;

          p.join {
            text-align: center;
          }
        }
      }
  }
}

@media (max-width: @widthMobileMax) {
  body.about-us {
    padding-top: 52px;

    div.about-us {
      section.header {
        padding-top: 75px;

        h1 {
          text-align: center;
          font-size: calc(34px + 6 * ((100vw - 320px)/ 1330));

          &:after,
          &:before {
            display: inline-block;
            position: relative;
          }

          &:before {
            left: 5px;
          }

          &:after {
            right: 5px;
            transform: scale(-1, 1);
          }
        }

        // center phils signature
        // &:after {
        //   left: 50%;
        //   transform: translateX(-50%);
        // }
      }
    }
  }
}
