section.get-started {
  background: black;

  .inner-wrapper {
    height: 425px;
    display: flex;
    background-image: url('~img/components/sign-up-page-footer/sign-up-footer-bg.jpg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    max-width: 1667px;
    margin: 0 auto;
  }

  img {
    position: absolute;
    top:40%;
    z-index: 10;
    left: 65%;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  .container {
    height: 100%;
  }

  .left {
    background: black;
    width: 80%;
    clip-path: polygon(0% 0%, 100% 0%, 72% 100%, 0% 100%);
    background-image: url('~img/components/sign-up-page-footer/sign-up-footer-left-bg.jpg');

    h1 {
      font-size: 40px;
      font-family: @fontNowayMedium;
      color: white;
      margin-left: 20%;
      margin-top: 120px;
    }
  }

  .right {
    width: 20%;
  }

  @media (max-width: 1340px) {
    img {
      left: 62%;
    }

    .left {
      clip-path: polygon(0% 0%, 100% 0%, 66% 100%, 0% 100%);
    }
  }

  @media (max-width: 990px) {

    height: 350px;

    img {
      left: 62%;
      width: 250px;
    }

    .left {
      clip-path: polygon(0% 0%, 100% 0%, 65% 100%, 0% 100%);

      h1 {
        margin-top: 85px;
        margin-left: 5%;
      }
    }
  }

  @media (max-width: 750px) {

    height: 300px;

    img {
      left: 60%;
      width: 200px;
    }

    .left {
      clip-path: polygon(0% 0%, 100% 0%, 60% 100%, 0% 100%);
      margin-top: 60px;
      h1 {
        font-size: 30px;
      }

    }
  }

  @media (max-width: 550px) {

    height: 300px;

    img {
      left: 55%;
      width: 200px;
    }

    .left {
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%);
      h1 {
        font-size: 24px;
      }
    }
  }

}

