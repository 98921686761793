// base level table element. add to any table for site wide defaults
.table {
  background: #fff;
  border-collapse: collapse;
  width: 100%;
}


// stripes rows based on no class and the 'alt' class
.table-striped {
  tr.alt {
    background: #f9fafb;
  }
}


// stylizes elements particular to a user table, such as avatar and the follow button
.table-users {
  td {
    border-bottom: 1px solid #eee;
    padding: 5px 0;
  }

  .avatar {
    padding-left: 15px;

    img {
      .border-radius(2px);
      border: none;
      display: block;
      height: 60px;
      width: 60px;
    }
  }

  .user-info {
    padding: 8px 0 0 22px;
    width: 100%;

    .name {
      font-size: 16px;
      line-height: 1;
    }

    .level,
    .points {
      color: #878282;
      display: block;

      &.is-pro {
        color: #222;
      }
    }
  }

  .follow-trigger {
    padding-right: 30px;
    text-align: center;
    word-break: normal;

    .unfollow {
      background: #f2f2f2;

      &:hover {
        background: #fbfbfb;
      }
    }

    form {
      width: 70px;
    }
  }

  .last_active {
    color: #979797;
    font-family: @fontHeading;
    font-weight: 400;
    font-size: 12px;
    padding: 0 32px 0 23px;
    white-space: nowrap;
  }
}

// rounded corners and drop shadows for table-posts's.
.table-posts_wrap {
  .box-shadow(0px 3px 16px 0px rgba(0, 0, 0, 0.09));
  border: 1px solid #e7e7ea;
  .border-radius(6px);
  // overflow: hidden;
}

// stylizes elements for the forum list view and pro training videos list view
.table-posts {
  font-size: 12px;
  border: none;

  tr.page {
    display: none;
  }

  td {
    border: 1px solid #eee;
    color: #798387;
    padding: 12px 10px 10px;
    vertical-align: middle;

    // make sure left and right hand side are 20px, line up with neck
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  td.view,
  td.download {
    border-left: none;
    text-align: center;
    position: relative;

    span {
      color: @colorLink;
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }

      &.synced {
        cursor: default;
        color: #798387;

      &:hover {
        text-decoration: none;
      }
      }
    }

    .divider {
      float: left;
      position: absolute;
      left: 0px;
      top: 42%;
      height: 10px;
      width: 1px;
      background: #d1d8dc;
    }
  }


  // prevent wrapping of text for some columns
  .content_type,
  .game_type,
  .post_kind {
    white-space: nowrap;
  }

  .title_data {
    width: 100%;
    padding-left: 10px;

    a {
      font-family: @fontDefault;
      font-weight: 500;
      font-style: normal;
    }

    .badge-user {
      .border-radius(2px);
      white-space: nowrap;
      padding: 3px 5px;
      font-size: 11px;
      color: #fff;
    }

    .is_pro {
      background: #b7a475;
    }

    .is_staff {
      background: #90171C;
    }

    .is_mod {
      background: #666666;
    }

    .title {
      color: @colorLink;
      font-size: 15px;
      font-weight: 600;

      &.old {
        .transition(color, 0.5s, ease-out);
        color: #5e5e5e;
      }
    }

    .angular_data {
      display: none;
    }

    .user_level {
      margin-top: -4px;
    }

    .pinned {
      background-image: url(~img/icons/pin.png);
      background-size: auto 80%;
      background-repeat: no-repeat;
      background-position: center 0;
      width: 19px;
      height: 19px;
      margin-left: 8px;
      color: transparent;
      user-select: none;
      padding: 3px;
    }

    .pinned.tooltip {
      .tooltip();
    }
  }

  td.pro_level {
        text-align: center;
  }

  .interactions {
    width: 42px;
    padding-right: 10px;

    .icon-comment,
    .icon-like,
    .icon-pro_like {
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 13px auto;
        display: inline-block;
        padding-left: 18px;
    }

    .icon-like.tooltip,
    .icon-comment.tooltip {
      .tooltip();
    }

    .icon-comment {
      background-image: url(~img/components/icon/comment.svg);
    }

    .icon-like {
      background-image: url(~img/components/icon/like_hover.svg);

      &.liked {
        background-image: url(~img/components/icon/like_active.svg);
      }
    }
  }

  .post_kind span.type {
    background-repeat: no-repeat;
    background-position: left center;
    display: inline-block;
    padding-left: 20px;

    &.post {
      background-image: url(~img/components/icon/post.svg);
      background-size: 14px auto;
    }

    &.hh {
      background-image: url(~img/components/icon/handhistory.svg);
      background-size: 14px auto;
    }

    &.post.tooltip,
    &.hh.tooltip {
      .tooltip();
    }
  }

  .pros_game_type {
    text-align: center;
  }

  .game_type {
    text-align: center;
    width: 25px;

    &.tooltip {
      .tooltip();
    }
  }

  .last_modified {
    white-space: nowrap;
    min-width: 100px;

    .tooltip {
      .tooltip();
    }
  }
}


.table-pro_training {

  tr.country {
    background-size: cover;

    &.argentina {
      background-image: url('~img/international-week/argentina_background.png');
    }

    &.brazil {
      background-image: url('~img/international-week/brazil_background.png');
    }

    &.china {
      background-image: url('~img/international-week/china_background.png');
    }

    &.france {
      background-image: url('~img/international-week/france_background.png');
    }

    &.germany {
      background-image: url('~img/international-week/germany_background.png');
    }

    &.italy {
      background-image: url('~img/international-week/italy_background.png');
    }

    &.poland {
      background-image: url('~img/international-week/poland_background.png');
    }

    &.spain {
      background-image: url('~img/international-week/spain_background.png');
    }

    > td.date {
      background: #fff;
    }

    > td.title_data,
    > td.view,
    > td.pro_level,
    > td.download,
    > td.pros_game_type,
    > td.comment_count,
    > td.like_count {
      border: none;
      color: #eee;
      font-weight: 500;

      .video-progress-small {
        display: none;
      }

      > span {
        color: #fff;
        font-weight: 500;
      }
    }

    > td.title_data {
      > a.title,
      > a.author {
        color: #fff;
        font-weight: 700;
      }
    }
  }

  tr.agpm {
    background-size: cover ;
    background-image: url('~img/video-row-bgs/agpm-row-bg.jpg');
    background-position: right;

    > td.date {
      background: #fff;
      min-width: 118px;
    }

    > td.title_data,
    > td.view,
    > td.pro_level,
    > td.download,
    > td.pros_game_type,
    > td.comment_count,
    > td.like_count {
      border: none;
      color: #eee;
      font-weight: 500;

      .video-progress-small {
        display: none;
      }

      > span {
        color: #fff;
        font-weight: 500;
      }
    }

    > td.title_data {
      > a.title,
      > a.author {
        color: #fff;
        font-weight: 700;
      }
    }
  }

  h4 {
    color: #798387;
    font-family: @fontOpenSans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
  }

  time {
    font-family: @fontOpenSans;
    font-weight: 400;
    font-size: 11px;
    color: #798387;
    text-transform: capitalize;
  }

  td.title_data {
    border-right: none;
  }

  td.download {
    border-left: none;
    border-right: none;
  }

  td.view {
    border-left: none;
    border-right: none;
  }
}

@media (max-width: @widthTabletMax) {
  .table-posts .last_modified a {
    display: block;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// Phones
@media (max-width: @widthMobileMax) {

  .table-posts {
    td {
      padding: 7px;
    }

    // Hide these columns when viewed via phone.
    td.date,
    td.game_type,
    td.post_kind,
    td.like_count,
    td.view,
    td.pro_level,
    td.last_modified,
    td.download {
      display: none;
    }

    td.title_data {
      padding-left: 10px;
      word-break: break-word;
    }
  }
}
