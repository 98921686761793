.sidebar {
  .ad-upgrade {
    background: #1b1e1f url('~img/components/sidebar/ad-upgrade-bg.jpg') no-repeat;
    background-size: 100% auto;
    color: #9da0a1;
    padding: 190px 25px 35px;
    text-align: center;

    h4 {
      background: url('~img/components/sidebar/ornament.png') bottom center no-repeat;
      background-size: 15px auto;
      color: #9da0a1;
      font-size: 13px;
      text-transform: uppercase;
      padding-bottom: 25px;
      margin-bottom: 15px;

      span {
        color: #fff;
        display: block;
        font-size: 20px;
      }
    }

    .btn {
      .border-radius(5px);
      .transition(background-color, .2s);
      border: 1px solid #606263;
      display: inline-block;
      color: #fff;
      background: #000;

      &:hover {
        color: #FFF;
        text-decoration: none;
        background: #333;
      }
    }
  }

  .ad-annual  {
    background: #820412 url('~img/components/sidebar/annual-ad-min.jpg') no-repeat;
    background-size: 130% auto;
    background-position: -40px -45px;
    height: 297px;
    display: block;
  }

  .from-the-ground-up-mtt-wsop-sale {
    background: #1b1e1f url('~img/components/sidebar/from-the-ground-up-mtt-wsop-sale.jpg') no-repeat;
    background-size: 100% auto;
    height: 297px;
    display: block;
  }

  .ad-agpm {
    background: #1b1e1f url('~img/components/sidebar/agpm-ad.jpg') no-repeat;
    background-size: 100% auto;
    height: 227px;
    display: block;
  }

  .ad-ftgu-plo {
    background: #1b1e1f url('~img/courses/ftgu-plo-forum-ad.jpg') no-repeat;
    background-size: 100% auto;
    height: 227px;
    display: block;
  }

  .pads-on-pads {
    background: #1b1e1f url('~img/courses/pads-on-pads/pads-forum-ad.jpg') no-repeat;
    background-size: 100% auto;
    height: 297px;
    display: block;
  }

  .plo-puzzle {
    background: #1b1e1f url('~img/components/sidebar/plo-puzzle-sale-forum-sidebar-min.jpg') no-repeat;
    background-size: 100% auto;
    height: 225px;
    display: block;
  }

  .ad-ftgu-sng {
    background: #1b1e1f url('~img/courses/ftgu-sng-forum-ad.jpg') no-repeat;
    background-size: 100% auto;
    height: 227px;
    display: block;
  }

  .ad-visions {
    background: #1b1e1f url("~img/components/sidebar/vision_sidebar_ad.jpg") no-repeat;
    background-size: 100% auto;
    height: 297px;
    display: block;
  }

  .rio--carousel {
    position: relative;
    padding-bottom: 2px;
    background: #000;

    .rio--carousel--prev,
    .rio--carousel--next {
      display: none;
    }

    .rio--carousel--dots {
      position: absolute;
      bottom: 5px;

      li {
        padding: 0.25em;

        &.active {
          padding: 0.25em 1.3em;
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;
      li {
        transition: all 1.5s;

        &.active {
          transition: all 1.5s;
        }
      }
    }
  }
}
