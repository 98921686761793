/*
  Basic default styles for a sidebar.

  Should probably be overridden in either _layouts.less or your specific page/app less file.
 */

.sidebar {
    background: #f3f5f6;
    border-right: 1px solid #ccd1d3;
    .box-shadow(0px 0px 8px 0px rgba(0,0,0,0.1));
    overflow: hidden;
    width: 298px;
}




// Hide the sidebar on a tablet or smaller
@media (max-width: @widthTabletMax) {
  .sidebar {
    display: none;
  }
}
