#post_subscribe {
  float: left;
  margin-top: 12px;

  .subscribed_text {
    display: none;
  }

  .not_subscribed_text {
    display: block;
  }

  &.subscribed {
    .subscribed_text {
      display: block;
    }

    .not_subscribed_text {
      display: none;
    }
  }
}
