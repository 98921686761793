body.blocked-forum {
  &.plo-puzzle {
    .blocked-inner {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 40%), url("~img/courses/plo-puzzle/page-bg.jpg");
      background-size: 100% auto;

      .container {
        .button-purchase {
          color: #000;

          span {
            border: none;
            box-shadow: none;
            background: #ffffff;

            &:hover {
              opacity: 0.85;
            }
          }
        }
      }
    }
  }

  .blocked-inner {
    background-image: url("~img/courses/masterclass/brain_bg_1600x800-min.jpg");
    background-color: #07080c;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width:100%;
    min-height: 700px;
    height: 100vh;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      max-width: 550px;
      margin: 0 auto;
      width: 90%;

      .lock-icon {
        height: 30px;
        width: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      h1 {
        color: white;
        text-align: center;
        padding-bottom: 20px;
      }

      h2 {
        color: #9fa5ad;
        text-align: center;
        padding-bottom: 30px;
        b {
          color: white;
        }
      }

      .button-purchase {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        font-family: @fontOpenSans;

        span {
          background: none;
          border: 1px solid #1cb419;
          vertical-align: middle;
          .box-shadow(~"0px 0px 20px rgba(28, 180, 25, .5)");
          padding: 5px 50px;
          display: inline-block;
          cursor: pointer;
          border-radius: 30px;
          overflow: hidden;

          &:hover {
            opacity: .75;
          }

          &:active {
            opacity: 1;
          }
      }
      }
    }
    footer {
      padding-bottom: 10px !important;
    }
  }
}
