.learning {

  .paths-holder, .paths-info-holder {
    width: 100%;
    height: 100%;
    top: 0px;
    -webkit-transition: all 0.5s cubic-bezier(.86,0,.14,.99);
    transition: all 0.5s cubic-bezier(.86,0,.14,.99);
  }

  .paths-holder {
    &__show {
      position: relative;
      left: 0px;
      -webkit-filter: blur(0px); /* Chrome, Safari, Opera */
      filter: blur(0px);
    }

    &__hide {
      position: absolute;
      left: 120%;
      -webkit-filter: blur(20px); /* Chrome, Safari, Opera */
      filter: blur(20px);

      ul.paths li.no-touch:hover {
        box-shadow: none !important;

        .path-triangle, .path-arrow {
          display: none !important;
        }
      }
    }
  }

  .paths-info-holder {
    &__show {
      position: relative;
      left: 0px;
      -webkit-filter: blur(0px); /* Chrome, Safari, Opera */
      filter: blur(0px);
    }

    &__hide {
      position: absolute;
      left: 120%;
      -webkit-filter: blur(20px); /* Chrome, Safari, Opera */
      filter: blur(20px);
    }
  }

  &__common {
    min-height: 720px;
    padding: 0 50px 60px 50px;
    overflow: hidden;

    &__left {
      width: 400px;
      overflow: hidden;
      position: relative;
      float: left;
      top: 115px;
      box-shadow: 0px 0px 87px -12px rgba(99,99,99,1);
      background: transparent url('~img/partials/learning-paths/left-side-background.png') no-repeat;
    }

    &__right {
      padding-top: 60px;
      min-height: inherit;
      margin-left: 400px;
      padding-left: 70px;
      overflow: hidden;
      position: relative;

      &__wrapper {
        position: relative;
      }
    }
  }

  // ===========================================================================
  // Path sections

  &__paths {
    &__left {
      hr {
        display: inline-block;
        width: 40px;
        height: 5px;
        border: none;
        margin-left: 50px;
        background: #5F6672;

        &.top {
          margin-top: 60px;
          margin-bottom: 30px;
        }

        &.bottom {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }

      h2 {
        padding: 10px 20px;
        color: white;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        padding: 0 20px 20px;
        text-align: left;
        color: #94979D;
        font-size: 13px;
        font-family: @fontDefault;
      }
    }

    &__right {
      ul.paths {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          overflow: hidden;
          position: relative;
          border-bottom: 1px solid #eee;
          line-height: 1.2;
          text-align: left;
          vertical-align: middle;
          font-family: @fontHeading;

          a {
            padding: 25px 15px;
            display: block;

            &.no-touch:hover {
              text-decoration: none;
            }
          }

          .path-title {
            color: #12171E;
            font-size: 30px;
            font-weight: bold;
          }

          .path-videos {
            color: #AAA;
            font-size: 11px;
            font-weight: 400;
          }

          .path-arrow {
            display: inline-block;
            width: 150px;
            height: 100%;
            line-height: 90px;
            text-align: center;
            color: #C9261E;
            font-size: 19px;
            font-weight: bold;
            position: absolute;
            top: 0px;
            right: 0px;

            span {
              display: inline-block;
              width: 22px;
              height: 8px;
              position: absolute;
              top: calc(50% - 4px);
              left: 75px;
              background: url('~img/partials/learning-paths/section-arrow.png') no-repeat center center;
              background-size: 21.5px 8px;
            }
          }

          .path-triangle {
            display: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 113px 70px 0 0;
            border-color: #ffffff transparent transparent transparent;
            position: absolute;
            top: 0px;
            left: 0px;
            background: transparent;
          }

          &.no-touch:hover {
            cursor: pointer;
            background: #fff;
            box-shadow: 0px 0px 87px -12px rgba(99,99,99,1);

            .path-triangle {
              display: block;
            }

            .path-arrow {
              background: linear-gradient(to right, rgba(175,15,74,1) 0%, rgba(201,39,30,1) 100%);

              span {
                background: url('~img/partials/learning-paths/section-arrow-hover.png') no-repeat center center;
                background-size: 21.5px 8px;
              }
            }
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }

  // ===========================================================================
  // Path categories

  &__path-info {
    &__left {
      a.back-arrow-red {
        display: block;
        width: 30px;
        height: 20px;
        margin: 30px 0px 10px 50px;
        background: url('~img/partials/learning-paths/back-arrow.png') no-repeat;
        background-size: 100% auto;
      }

      a.back-arrow-red.no-touch:hover {
        opacity: 0.8;
      }

      hr {
        display: inline-block;
        width: 40px;
        height: 5px;
        margin: 30px 0 30px 50px;
        border: none;
        background: #5F6672;
      }

      h1 {
        padding: 0 50px;
        color: white;
        text-align: left;
        font-size: 44px;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        padding: 0 20px 15px 50px;
        color: white;
        text-align: left;
        font-size: 11px;
        font-weight: bold;
      }

      p.path-description {
        font-family: @fontDefault;
        font-size: 12px;
        color: #94979D;
        padding-top: 15px;
        font-weight: 400;
      }

      ul {
        padding: 0 50px;
        margin-bottom: 50px;
        font-family: @fontDefault;
        list-style-type: none;

        li {
          display: block;

          a {
            margin: 5px 0;
            padding: 10px 35px 10px 20px;
            position: relative;
            color: #7D828D;
            text-align: left;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: #181B20;
            display: block;

            &:hover {
              text-decoration: none;
              background: #101216;

              .arrow {
                color: #d8414c;
              }
            }
          }

          .pro-name {
            color: white;
          }

          .pro-account {
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
          }

          .arrow {
            position: absolute;
            right: 20px;
            color: #a81313;
            font-size: 8px;
            font-weight: 100;
            letter-spacing: -1px;

            // HACK: Fixes some rendering glitches in Chrome for OSX by
            //       enforcing GPU rendering of the container.
            transform: translateZ(0);

            .l1 { opacity: 0.3; }
            .l2 { opacity: 0.7; }
            .l3 { opacity: 1.0; }
          }
        }
      }
    }

    &__right {
      min-width: 500px;

      // HACK: Fixes some rendering glitches in Chrome for OSX by
      //       enforcing GPU rendering of the container.
      transform: translateZ(0);

      ul.sections {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .section-title-arrow {
          display: inline-block;
          margin-left: 4px;
          color: #ddd;
          font-size: 16px;
          position: absolute;
          transform: scaleX(0.5);
        }

        .section-expanded {
          padding-right: 0px;

          .section-title-arrow {
            transform: scaleY(0.5) rotate(90deg);
            color: #B0104B;
          }

          .section-title {
            font-family: @fontHeading;
            margin-right: 40px;
          }

          ul.section-videos {
            display: inline-block;
            width: 450px;

            li {
              position: relative;
              border-bottom: 1px solid #eee;
              cursor: pointer;
              list-style-type: none;
              color: #717C7F;
              font-size: 12px;
              line-height: 18px;

              &:last-child {
                border-bottom: none;
              }

              &.no-touch:hover {
                background: #fff;
                box-shadow: 0px 0px 87px -12px rgba(99,99,99,1);

                .video-progress-bg {
                  display: block;
                }

                .video-progress-small {
                  background: url('~img/components/video-progress/progress-small@2x-red.png') no-repeat;
                  background-size: 150px 15px;
                }
              }

              a {
                display: block;
                min-width: 200px;
                padding: 8px 0 8px 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #979FA1;
                white-space: nowrap;
              }

              .video-progress-small {
                position: absolute;
                top: calc(50% - 7px);
                left: 15px;
              }

              .video-progress-bg {
                display: none;
                height: 55px;
                width: 50px;
                position: absolute;
                left: 0px;
                top: 0px;
                background: linear-gradient(to right, #af0f4a 0, #c9271e 100%);

                .triangle {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 10px 54px 0;
                  border-color: transparent #ffffff transparent transparent;
                  position: absolute;
                  right: 0px;
                  top: 0px;
                }
              }

              .video-title {
                display: inline-block;
                overflow: hidden;
                max-width: 300px;
                color: #9D0000;
                font-weight: bold;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
                font-size: 12px;
              }

              @media (max-width: @widthMobileMax) {
                .video-title {
                  max-width: 60vw;
                }
              }

              .video-author {
                color: #171C23;
                font-weight: bold;
                font-size: 11px;
              }

              .video-is-featured {
                height: 8px;
                width: 8px;
                border-radius: 4px;
                position: absolute;
                right: 15px;
                top: calc(50% - 4px);
                background: #9D0000;
              }

              .video-duration {
                color: #a81313;
              }
            }
          }
        }

        & > li {
          position: relative;
          border-bottom: 1px solid #eee;
          text-align: left;
          vertical-align: middle;
          line-height: 35px;

          &.no-touch:hover .section-title-arrow {
            transform: scaleY(0.5) rotate(90deg);
            color: #B0104B;
          }

          .section-expanded, .section-shrinked {
            padding: 25px 0 25px 15px;
          }

          .section-shrinked {
            &:hover {
              cursor: pointer;
            }
          }

          a.no-touch:hover {
            text-decoration: none;
          }

          .section-title {
            display: inline-block;
            width: 215px;
            overflow: hidden;
            cursor: pointer;
            color: #12171E;
            vertical-align: top;
            text-overflow: ellipsis;
            font-size: 22px;
            font-weight: bold;
            font-family: @fontHeading;
          }

          .section-videos-count {
            display: inline-block;
            min-width: 110px;
            margin-left: 40px;
            color: #ddd;
            font-size: 22px;
            font-weight: bold;
            font-family: @fontHeading;
            text-align: center;
          }

          .section-new-videos-count {
            display: inline-block;
            width: 70px;
            position: relative;
            color: white;
            font-size: 11px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            background: linear-gradient(to right, rgba(175,15,74,1) 0%, rgba(201,39,30,1) 100%);

            .triangle {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 50px 0 0 10px;
              border-color: transparent transparent transparent #ffffff;
              position: absolute;
              top: 0px;
              left: 0px;
              background: transparent;
            }
          }

          .score-circle {
            position: absolute;
            right: 0px;
            top: calc(50% - 30px);
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}


@-webkit-keyframes lload
{ 0% {
    stroke-dashoffset: 170
  }
}
@-moz-keyframes lload
{ 0% {
    stroke-dashoffset: 170
  }
}
@keyframes lload
{ 0% {
    stroke-dashoffset: 170
  }
}


.section-progress-bar {
  width: 115px;
  height: 115px;
  margin: 0 auto 20px;
  position: relative;
  cursor: pointer;

  svg.circles {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: rotate(-91deg);
    -moz-transform: rotate(-91deg);
    -ms-transform: rotate(-91deg);
    transform: rotate(-91deg);

    /** change color of this for the main circle part */
    circle {
      fill: none;
      stroke-width: 5px;
      stroke: #ddd;
    }

    /** change color of this for the fillage */
    circle:nth-child(2) {
      fill: none;
      stroke: #c9271e;

      -webkit-animation: lload 0.5s ease-out;
      -moz-animation: lload 0.5s ease-out;
      -o-animation: lload 0.5s ease-out;
      animation: lload 0.5s ease-out;
    }
  }

  .percentage {
    width: 65px;
    height: 50px;
    position: absolute;
    top: 14px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
}


.title__mobile {
  &__paths-list {
    display: none;
  }

  &__path-info {
    display: none;
  }
}


// Tablet support
@media (max-width: @widthTabletMax) {

  .section-progress-bar {
    display: none;
  }

  .learning__paths {
    display: block;
  }

  // make section go full widt
  .learning__path-info__right {
    min-width: 100%;
  }

  .learning__common {

    padding: 0 0px 60px 0px;

    &__left {
      display: none;
    }

    &__right {
      width: 100%;
      margin-left: 0px;
      padding-left: 0px;
      padding-top: 0px;

      ul.sections .section-expanded ul.section-videos {
        display: block;
        margin: 0;
        width: 100%;
      }

      .path-arrow {
        right: 0px;
      }
    }
  }

  // show titles in the sections when on mobile / tablet
  .title__mobile {
    background: #f7f8f8;

    &__paths-list {
      background: #f7f8f8;
      display: block;
      padding-top: 30px;
      padding-bottom: 20px;

      h1 {
        display: inline-block;
        margin-left: 15px;
        font-weight: 700;
        color: #000;
        text-overflow: ellipsis;
      }
    }

    &__path-info {
      background: #f7f8f8;
      display: block;
      padding-top: 30px;
      padding-bottom: 20px;

      h1 {
        display: inline-block;
        margin-left: 15px;
        font-weight: 700;
        color: #000;
        text-overflow: ellipsis;
      }

      a.back-arrow-red {
        display: inline-block;
        width: 30px;
        height: 18px;
        margin-left: 20px;
        background: url('~img/partials/learning-paths/back-arrow.png') no-repeat;
        background-size: 100% auto;
      }

      a.back-arrow-red:hover {
        opacity: 0.8;
      }
    }
  }
}

// Mobile support
@media (max-width: @widthMobileMax) {
  .section-progress-bar {
    display: none;
  }

  .learning__paths {
    display: block;
  }

  // make videos count block so to go on a new line
  .learning__path-info__right > ul.sections > li {
    .section-videos-count {
      min-width: 100%;
      margin-left: 0px;
      display: block;
      text-align: left;
    }
  }

  .learning__path-info__right > ul.sections .section-expanded > ul.section-videos > li {
    a {
      display: inline-block;
    }
  }

  .learning__common {
    padding: 0 0px 60px 0px;

    &__left {
      display: none;
    }

    &__right {
      width: 100%;
      margin-left: 0px;
      padding-left: 0px;

      ul.sections .section-expanded ul.section-videos {
        display: block;
        margin: 0;
        width: 100%;
      }

      .path-arrow {
        right: 0px;
      }
    }
  }
}
