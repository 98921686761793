// Styles for modal found in newsletter-signup-modal.js
.rio-modal.rio-signup-survey-popup {
  overflow: auto;

  .content {
    width: 720px !important;
    height: auto !important;
  }

  .close-message {
    display: block !important;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .signup-survey-popup {
    background: #12171f url('~img/partials/newsletter-signup-modal/newsletter-signup-modal-bg.jpg') no-repeat;
    background-size: 100%;
    border-radius: 30px;

    .content-wrap {
      color: #fff;
      min-height: 440px;
      padding: 175px 0 5px;
      margin: 0 auto;

      .modal-title {
        color: #fff;
        font-size: 64px;
        font-weight: 700;
        line-height: 1em;
      }

      p {
        //color: #fff;
        margin: 10px auto 10px;
        padding: 0;
        width: 430px;
        color: #9d9faa;
        font-size: 18px;
        line-height: 1.7em;
      }

      .actions {
        width: auto;
        margin-bottom: 25px;

        a {
          color: #fff;
          font-size: 25px;
          line-height: 2.5em;
          padding: 30px;
        }
      }
    }
  }
}

// Start shrinking down the modal here.
@media (max-width: 915px) {
  .rio-signup-survey-popup.active {

    .content {
      overflow: hidden;
      width: 90% !important;
      max-width: 480px;
      height: auto !important;
    }

    .signup-survey-popup {
      .content-wrap {
        padding-top: 85px;
        min-height: auto;
        width: auto;

        .modal-title {
          font-size: 42px;
        }

        p {
          //color: #fff;
          padding-left: 40px;
          padding-right: 40px;
          font-size: 14px;
          width: auto;
        }

        .actions {
          width: auto;
          margin-bottom: 25px;

          a {
            color: #fff;
            font-size: 20px;
            padding: 10px 10px;
          }
        }
      }
    }
  }
}
