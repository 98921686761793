//
// Modals - modified version of what's used in Bootstrap 3
// -------------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4020;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // // When fading in the modal, animate it to slide down
  // &.fade .modal-dialog {
  //   .translate(0, -25%);
  //   .transition-transform(~"0.3s ease-out");
  // }
  // &.in .modal-dialog { .translate(0, 0)}

  &.fade .modal-dialog { opacity: 0; }
  
  &.in .modal-dialog, 
  &.show .modal-dialog { opacity: 1; }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  .transition(opacity, 0.10s, ease-out);
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  background-clip: padding-box;
  background-color: #f3f5f6;
  border-radius: 5px;
  border: 1px solid #6a6f71;
  outline: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

// Modal background
.modal-backdrop {
  .transition(opacity, 0.10s, ease-out);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4010;
  background-color: #1b1e1f;
  // Fade for backdrop
  &.fade { opacity: 0; }
  &.in { opacity: 0.4; }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: @widthMobileMax) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}
