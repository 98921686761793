body.three-free-videos {
  background: #05070F;

  // Hide the global nav signup button.
  .global-nav .global-nav__section .signup {
    display: none;
  }

  #body_container {
    overflow-x: hidden;
  }

  .bg-container {
    position: absolute;
    background-image: url(~img/three_free_videos/free-vids-bg.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 800px;
    z-index: -10;
    background-position:bottom;
    min-width: 1200px;
    @media (max-width: 1200px) {
      background-position: inherit;
    }
  }

  .content-wrapper {
    max-width: 1340px;
    margin: 50px auto;
    padding: 0 100px;
  }

  h1 {
    font-family: @fontNowayMedium;
    font-size: 60px;
    color: white;
    line-height: 1.15;
    padding-top: 100px;
    margin-bottom: 60px;
  }

  .upgrade-cta {
    color: white;
    background: #c92024;
    padding: 16px 26px;
    font-size: 18px;
    font-family: @fontOpenSans;
    font-weight: 500;
    border-radius: 40px;
    box-shadow: 3px 4px 39px 0 #cf0f1b;
    span {
      height: 12px;
      width: 22px;
      display: inline-block;
      margin-left: 40px;
      margin-bottom: 3px;
      vertical-align: middle;
      background-size: contain;
      background-image: url(~img/three_free_videos/right-arrow-cta.png);
    }
    &:hover {
      text-decoration: none;
      background: #B91D1D;
    }
  }

  .video-wrapper {
    margin-top: 100px;
    .video {
      text-align: center;
    }

    .text-container {
      width: 50%;
      margin-top: 30px;
      h2 {
        font-family: @fontNowayMedium;
        font-size: 32px;
        color: white;
      }
      h3 {
        color: #8e9aa5;
        font-size: 16px;
        font-family: @fontNowayMedium;
        margin-top: -15px;
      }
      p{
        color: #878f9a;
        font-family: @fontOpenSans;
        font-size: 16px;
        line-height: 1.75;
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 800px) {
    .content-wrapper {
      padding: 0 5%;
      @media (max-width: 350px) {
        padding: 0% 2%;
      }

      h1 {
        padding-top: 40px;
        font-size: ~"calc(26px + 22 * ((100vw - 320px) / 480))";
        margin-bottom: 30px;
      }

      .upgrade-cta {
        font-size: 14px;
        padding: 14px 24px;
        border-radius: 24px;
      }

      .video-wrapper {
        margin-top: 50px;
        .text-container {
          margin-top: 5px;
          min-width: 270px;
          h2 {
            font-size: 24px;
          }
          h3 {
            font-size: 14px;
          }

          p {
            margin-top: 10px;
            font-size: 14px;
          }

          @media (max-width: 400px) {
            h2 {
              font-size: 18px;
            }
            p {
              font-size: 12px;
              line-height: 1.45;
            }
          }

        }
      }

    }

  }

}



#three-free-videos-cta-above-footer {
  background-color: black;
  max-width: 1340px;
  margin: 0 auto;

  .container-row {
    position: relative;
    padding: 5% 10%;
    z-index: 2;
    @media (max-width: 350px) {
      padding: 5% 2%;
    }

    // in after element so the background can be with slight opacity.
    &:after {
      background: url(~img/books/leszek-book/bottom-bg-small.jpg) no-repeat center top;
      opacity: 0.5;
      background-size: 100% 340px;
      position: absolute;
      display: block;
      content: "";
      top: 0;
      left:0 ;
      z-index: -1;
      width: 100%;
      height: 100%;

      @media (max-width: @widthTabletMax) {
        display: none;
      }
    }

    .main-text{
      text-align: left;
      width: 55%;
      font-size: 32px;
      font-family: @fontNowayMedium;
      color: white;
      line-height: 1.3;
      font-weight: 300;

      @media (max-width: @widthTabletMax) {
        font-size: ~"calc(12px + 38 * ((100vw - 280px) / 1160))";
        width: 100%;
        text-align: center;
      }
    }

    a {
      background: transparent url(~img/video/pro-vs-pro/skewed-from-one-side-red.jpg);
      background-size: 100% auto;
      position: absolute;
      width: 292px;
      height: 65px;
      left: 65%;
      top: 40%;
      line-height: 65px;
      color: #fff;
      text-align: center;
      font-family: @fontNowayMedium;
      font-size: 24px;
      text-transform: uppercase;
      transition: 0.2s;

      &:after {
        background-image: url('~img/misc/arrow_white.png');
        content: "";
        position: absolute;
        right: 5px;
        top: 25px;
        width: 45px;
        height: 20px;
        background-repeat: no-repeat;
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        transform: scale(1.05);

        &:after {
          right: 2px;
        }
      }

      @media (max-width: @widthTabletMax) {
        position: relative;
        font-size: ~"calc(22px + 30 * ((100vw - 400px) / 1040))";
        margin: 0 auto;
        top: 0;
        left: 0;
        display: block;
      }
    }
  }
}
