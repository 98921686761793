body.activated#plans_pricing {
  #neck {
    background-color: #f7f8f8;
    text-align: center;

    h1 {
      color: #272a2b;
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px
    }
  }

  // some plans display modifications as here there are only 2 plans shown
  .plans {
    max-width: 66%;
    margin: 0 auto;

    .plan.essential,
    .plan.elite {
      width: 33%;
    }

    // always hide the essential plus sign on hover and keep border color
    &.hover-essential {
      .plan.essential {
        .icon-plus {
          visibility: hidden;
        }

        .information {
          border-left-color: #c0c7c9;
        }
      }
    }

    &.hover-elite {
      .plan.essential {
        .icon-plus {
          visibility: hidden;
        }

        .information {
          border-left-color: #c0c7c9;
        }
      }
    }

    // fix the sliding cta size and positions on hover
    .sliding-cta {
     width: 50%;

       &.essential {
        left: 0%;
       }

      &.elite {
        left: 50%;
      }
    }
  }

  .col_1 {
    .header-icon {
      height: 30px;
      width: 23px;
      margin: 0 auto;
      display: block;
    }

    h2 {
      color: #1d1d1d;
      font-size: 38px;
      font-family: @fontHeading;
      font-weight: 400;
      text-align: center;

      &:after {
        position: relative;
        width: 134px;
        height: 1px;
        background: #cdcfd3;
        content: '';
        opacity: 1;
        display: block;
        margin: 0 auto;
        top: 10px;
      }
    }

  }

  .container.plans-titles {
    border-top: 1px solid #e5eaeb;
    margin-top: 40px;
    padding: 60px 0;

    .plans-titles-container {
      width: 66%;
      margin: 0 auto;
      padding-bottom: 25px;

      > div {
        display: inline-block;
        width: 50%;
        float: left;
        text-align: center;
        font-size: 18px;
        font-family: @fontHeading;
        font-weight: 500;
      }
    }

  }

  .container.features {
    margin-top: 50px;
    height: 260px;

    .three-free-videos,
    .forums,
    .feed {
      position: relative;
      height: 260px;
      display: inline-block;
      width: 33%;
      float: left;
      .transition(all, 0.5s);

      p {
        font-family: @fontHeading;
        padding-bottom: 50px;
        font-size: 18px;
        font-weight: 400;
        width: 274px;
        text-align: center;
        height: 115px;
        overflow: hidden;
        margin: 0 auto;
      }

      img {
        width: 267px;
        height: 168px;
        position: absolute;
        bottom: 0;
        left: 60px;
      }

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;


        &:hover {
          img {
            .transform(scale(1.2))
          }
        }
      }

    }
  }

  // add media queries
  @media (max-width: @widthMobileMax) {

    #torso .container.plans {
      .plan.elite,
      .plan.essential {
        width: 100%;
      }
    }

    .container.plans-titles {
      display: none;
    }

    .container.features {
      .three-free-videos,
      .forums,
      .feed {
        width: auto;
        margin-top: 50px;

        img {
          left: 10px;
        }
      }
    }
  }

}
