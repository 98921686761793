@breakpoint1270: 1270px; /* we make cards and odds a bit smaller */
@breakpoint960: 960px;  /* tablets and smaller screens we move cardPool below table) */
@breakpoint680: 680px;  /* phones (all kinds? in portray mode) we switch to card picker mode */


body.poker-odds-calculator {
  background: #060710;

  #torso {
    background: #060710;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
      max-width: 1360px;
      padding: 50px 2.5vw;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;

      @media (max-width: @widthTabletMin) {
        font-size: 14px;
      }

      h1, h2, h3, p, li, header {
        font-weight: 400;
        color: #fff;
        font-family: @fontPoppins;
      }

      h1 {
        font-size: 2em;
        padding: 0.5em 0;
      }

      h2 {
        font-size: 1.5em;
        padding: 0.8em 0;
        line-height: 1.3;
      }

      h3 {
        font-size: 1.25em;
      }

      p, li {
        font-weight: 200;
        max-width: 975px;
        font-size: 1em;
        line-height: 150%;

        b {
          font-weight: 500;
        }

        padding: 0.5em 0;
      }

      &.intro {
        text-align: center;
      }

      &.content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .slider {
          width: 30%;

          .sticky {
            padding: 0 10px;
            width: 100%;
            max-width: 430px;
            position: sticky;
            top: 120px; // accommodate for top nav (50px) + potential banners we run
            background:
              url("~img/poker-odds-calculator/sticky-bg.jpg") no-repeat padding-box,
              linear-gradient(to left, #fff 0%, #FF5656 100%) border-box;
            background-size: cover;
            border-radius: 4px;
            border: 1.2px solid transparent;

            &__header {
              padding: 16px 6px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);

              h3 {
                text-transform: uppercase;
                line-height: 1;
                letter-spacing: 0.1em;
              }
            }

            &__content {
              ul {
                padding: 0 6px;
                margin-top: 15px;
                margin-bottom: 35px;
                list-style: none;

                li {
                  padding: 10px 0;

                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    text-underline-offset: 0.1em;
                  }

                  &.active {
                    font-weight: 600;
                    text-decoration: underline;
                    text-underline-offset: 0.1em;
                  }
                }
              }
            }
          }
        }

        .content {
          width: 65%;

          table.equity {
            max-width: 700px;
            width: 100%;
            border-collapse: collapse;
            color: #000;
            font-weight: 200;
            font-family: @fontPoppins;

            th,
            td {
              width: 50%;
              padding: 10px 0px 10px 16px;
              border: 1px solid #28161f;
            }

            thead tr,
            tbody tr:nth-child(even) {
              background-color: #eaeaea;
            }

            tbody tr:nth-child(odd) {
              background-color: #fff;
            }

            thead {
              font-weight: 500;
            }
          }

          .vision-mention,
          .foundations-mention {
            a {
              margin: 3em 0;
              display: block;

              img {
                border-radius: 5px;
                box-sizing: border-box; // so border to be "inside"
              }

              &:hover {
                > img {
                  border: 3px solid #117d7c;
                }
              }
            }
          }

          .vision-mention {
            a {
              img {
                aspect-ratio: 848/350;
              }
            }
          }

          .foundations-mention {
            a {
              img {
                aspect-ratio: 800/418;
              }
            }
          }

          ol, ul {
            list-style-position: inside;
          }

          ol {
            li::marker {
              font-family: @fontPoppins;
              font-weight: 500;
            }
          }
        }

        @media (max-width: @breakpoint960) {
          .slider {
            display: none;
          }

          .content {
            width: 100%;
          }
        }
      }

      &.faq {
        width: 100%;
        min-width: 100%;
        background: #1a1e1f;

        header {
          font-size: 2.5em;
          text-align: center;
        }

        ul.rio--accordion {
          list-style: none;
          text-align: left;
          width: min(1000px, 90vw);

          li {
            border-bottom: 1px solid #a8b0b9;

            a {
              color: #fff;
              font-weight: 400;
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }

            &:last-child {
              border-bottom: none;
            }

            width: 100%;

            .expand-trigger {
              font-weight: 400;
              font-family: @fontPoppins;
              font-size: 1.5em;
              min-height: 80px;
              line-height: 1.4;
              padding-right: 35px;
              position: relative;

              // New flexbox properties
              display: flex;
              align-items: center;

              .expand-trigger-header-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &:after {
                position: absolute;
                color: #fff;
                font-size: 2em;
                font-weight: 200;
                font-family: @fontPoppins;
                content: "+";
                right: 0;
              }
            }

            &.expanded {
              .expand-trigger {
                .expand-trigger-header-text {
                  white-space: normal;
                  overflow: visible;
                }

                &:after {
                  content: "-";
                }
              }
            }

            .section {
              font-size: 1em;
              line-height: 150%;
              padding-right: 50px;
              padding-bottom: 50px;
            }
          }
        }
      }
    }
  }

  /**
   * The .poker-game element, where everything
   * poker-odds-calculator related should be in
   */
  .poker-game {
     /**
      * Define some --vars, makes implementing mobile design easier
      */
    --card-width: 58px;
    --card-height: 80px;
    --card-aspect-ratio: 58/80;
    --card-gap: 6px;
    --icon-action-height: 24px;
    --table-top-background: url("~img/poker-odds-calculator/table-top-oval-larger-screens.jpg");
    --table-top-max-width: 1100px;
    --table-aspect-ratio: 2000/951;

    @media(max-width: @breakpoint1270) {
      --card-width: 47px;
      --card-height: 65px;
      --card-gap: 4px;
    }

    @media (max-width: @breakpoint680) {
      --card-width: 42px;
      --card-height: 58px;
    }

    /**
     * Layout related stuff.
     * On large screen card pool is on the left-side as this is better UI
     * and easier to see what hand you fill in where.
     *
     * On mobile, as of now, card pool goes below the table which is not ideal.
     * Long term on mobile we'll probably do a card-popup selector, similar
     * to the one here: https://www.tightpoker.com/online-poker-odds-calculator/
     */

    /**
     * LAYOUT stuff
     */
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    gap: calc(6 * var(--card-gap));
    row-gap: 0;
    max-width: 100%;
    margin: calc(0.5 * var(--card-height)) auto;

    // position relative because of the .card-picker element
    position: relative;

    // these 3 are one below another with same width
    // that is at least 70vw
    .game-selector,
    .poker-table,
    .dead-cards {
      grid-column: 1;
      width: 70vw;
      max-width: var(--table-top-max-width);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .game-selector { grid-row: 1; }
    .poker-table { grid-row: 2; }
    .dead-cards { grid-row: 3; }

    // card-pool on the side on large screen
    .card-pool {
      margin-left: calc(5 * var(--card-gap));
      grid-column: 2;
      grid-row: 1 / span 3;
      max-width: calc(4 * var(--card-width));
    }

    @media (max-width: @breakpoint960) {
      gap: 0;

      .poker-table {
        width: min(80vw, var(--table-top-max-width));
      }

      .dead-cards {
        width: 90vw;
        grid-row: 3;
        margin-top: calc(0.7 * var(--card-height));
      }

      .card-pool {
        margin: calc(5 * var(--card-gap)) auto auto;
        grid-column: auto;
        grid-row: 4;
        min-width: 95vw;
      }
    }

    @media (max-width: @breakpoint680) {
      .poker-table {
        width: 90vw;
      }

      .game-selector {
        width: 100vw;
      }

      .card-pool {
        display: none !important;
      }

      .dead-cards {
        max-width: 280px;
        width: 90vw;
      }
    }

    button.icon {
      all: unset;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--icon-action-height);
      height: var(--icon-action-height);
      border-radius: calc(var(--icon-action-height) / 4);
      cursor: pointer;
      background: #fbfcfd;
      transition: all 300ms;

      &:hover {
        background-color: #c6d2e0;
      }

      &.disabled {
        pointer-events: none;

        i {
          background-color: #83929a;
        }
      }

      i {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-color: #243944;
        mask-repeat: no-repeat;
        mask-size: contain;

        &.add-a-seat {
          width: 16px;
          height: 14px;
          mask-image: url("~img/poker-odds-calculator/btn-actions/add-a-seat.svg");
        }

        &.remove-a-seat {
          height: 10px;
          width: 10px;
          mask-image: url("~img/poker-odds-calculator/btn-actions/remove-a-seat.svg");
        }

        &.deal-random-cards {
          width: 18px;
          height: 14px;
          mask-image: url("~img/poker-odds-calculator/btn-actions/deal-random-cards.svg")
        }
      }
    }

    .game-selector {
      text-align: center;

      .button {
        all: unset;
        font-family: @fontPoppins;
        letter-spacing: 1.15px;
        font-size: 16px;
        text-transform: uppercase;
        background: #0d1e26;
        color: #fff;
        margin-right: 2px;
        padding: 15px 30px;

        @media (max-width: @breakpoint960) {
          font-size: 14px;
          padding: 15px 10px;
        }

        &:hover {
          cursor: pointer;
        }

        &.active {
          background: #132935;
        }

        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }

        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          margin-right: 0;
        }
      }
    }

    .poker-table {
      margin: var(--card-height) auto;  // cuz of seats (player positions)
      margin-bottom: 0;
      position: relative;  // cuz of seats (player positions, they are absolute)
      max-width: var(--table-top-max-width);
      aspect-ratio: var(--table-aspect-ratio);
      // safari is finicky with width and max-width otherwise and
      // on zoomed out screen (desktop) or safari mobile (normal)
      // clicking really quick on the board cards zooms in
      box-sizing: border-box;

      @media (max-width: @breakpoint680) {
        aspect-ratio: unset;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
      }

      &__board {
        width: 100%;
        height: 100%;
        aspect-ratio: var(--table-aspect-ratio);
        background: var(--table-top-background);
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // safari is finicky with width and max-width otherwise and
        // on zoomed out screen (desktop) or safari mobile (normal)
        // clicking really quick on the board cards zooms in
        box-sizing: border-box;

        .board {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: calc(var(--card-gap) * 3);

          .controls {
            position: absolute;
            right: 0;
            top: calc(-1 * var(--icon-action-height) - 5px);
            display: flex;
            gap: var(--card-gap);
          }

          .flop {
            position: relative;

            .cards {
              display: flex;
              flex-direction: row;
              gap: var(--card-gap);
            }
          }
        }
      }

      &__seat {
        position: absolute;  // we arrange seats with javascript and transform + translate
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        top: 0;
        left: 0;

        @media (max-width: @breakpoint680) {
          position: relative;
        }

        .controls {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;
          gap: var(--card-gap);
          margin-bottom: var(--card-gap);

          .information {
            flex-grow: 1;
            align-content: center;
            color: #fff;
            font-size: 0.75rem;
            font-family: @fontOpenSans;
            font-weight: 600;
            letter-spacing: 0.1em;
            display: none;

            @media (max-width: @breakpoint680) {
              display: block;
            }
          }
        }

        .hand {
          display: flex;
          flex-direction: row;

          .hole-cards {
            display: flex;
            flex-direction: row;
            gap: var(--card-gap);
          }
        }

        .odds {
          position: absolute;
          top: calc(1.5 * var(--card-height));
          width: calc(3 * var(--card-width));
          color: white;
          font-size: 16px;
          font-family: @fontPoppins;
          background: #22242f;
          border-radius: 8px;
          padding: var(--card-gap) calc(2 * var(--card-gap));
          display: flex;
          flex-direction: column;

          @media(max-width: @breakpoint1270) {
            font-size: 14px;
          }

          @media (max-width: @breakpoint680) {
            position: relative;
            top: 0;
            margin: 5px 0;
          }

          .loading {
            flex-direction: row;
            gap: var(--card-gap);

            .text {
              color: #707488;
            }

            .suits-animated {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: center;
            }

            .suit {
              width: 9px;
              height: 10px;
              margin: 0 3px;
              align-self: center;
              opacity: 0;
              animation-duration: 3s;
              animation-iteration-count: infinite;
              animation-timing-function: steps(5, end);

              &.heart {
                background-image: url("~img/poker-odds-calculator/loading/heart.svg");
                animation-name: heartAnimation;
              }

              &.spade {
                background-image: url("~img/poker-odds-calculator/loading/spade.svg");
                animation-name: spadeAnimation;
              }

              &.diamond {
                background-image: url("~img/poker-odds-calculator/loading/diamond.svg");
                animation-name: diamondAnimation;
              }

              &.clubs {
                background-image: url("~img/poker-odds-calculator/loading/clubs.svg");
                animation-name: clubAnimation;
              }
            }
          }

          .loading {
            display: none;
          }

          &.in_progress {
            .loading {
              display: flex;
            }
          }

          .win,
          .tie {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .win {
            color: #13ab8b;
          }
        }
      }
    }

    .dead-cards {
      flex-direction: column;
      align-items: flex-start;

      .cards {
        display: flex;
        flex-direction: row;
        gap: var(--card-gap);
        justify-content: flex-start;
        flex-flow: wrap;
      }

      .hint-text {
        color: #fff;
        font-family: @fontOpenSans;
        font-size: 1rem;
        padding: calc(2 * var(--card-gap)) 0;

        @media (max-width: @breakpoint680) {
          // make hint-text same size az "Player 1's Hand" type of text
          font-size: 0.75rem;
          letter-spacing: 0.1em;
          font-weight: 600;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .card-pool {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;

      // cards inside the pool to be smaller size
      .card {
        width: calc(0.75 * var(--card-width));
      }

      .s, .h, .c, .d {
        max-width: 25%;
        display: flex;
        flex-flow: wrap;
        gap: var(--card-gap);
        justify-content: center;
      }
    }

    .card-picker {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: none;
      flex-direction: column;
      align-items: flex-start;

      &.opened {
        display: flex;
      }

      &.closed {
        display: none;
      }

      &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
      }

      &__inner {
        position: sticky;
        top: 20vh;
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: center;
        padding: calc(5 * var(--card-gap)) 2vw;
        width: 85vw;
        gap: 3vh;
        border: 1px solid #000;
        border-radius: calc(3 * var(--card-gap));
        background: #fff;
      }

      &__cards-pool {
        display: flex;
        justify-content: center;

        .suit {
          grid-template-areas:
            "A K Q J T"
            "n9 n8 n7 n6 n5"
            ". n4 n3 n2 .";
          column-gap: calc(2 * var(--card-gap));
          row-gap: calc(4 * var(--card-gap));
          display: none;

          .card {
            width: calc(0.85 * var(--card-width));
          }

          &.active {
            display: grid;
          }
        }
      }

      &__suit-selector {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        gap: calc(2 * var(--card-gap));

        .h, .c, .d, .s {
          background-size: auto;
          background-position: center;
          background-repeat: no-repeat;
          padding: 25px;
          width: calc(0.6 * var(--card-width));
          transition: all 300ms;

          &:hover {
            cursor: pointer;
            transform: scale(1.2);
          }
        }

        .h {
          background-image: url("~img/poker-odds-calculator/suits/heart.svg");
          aspect-ratio: 23/22;  // match the svg file ....
        }
        .d {
          background-image: url("~img/poker-odds-calculator/suits/diamond.svg");
          aspect-ratio: 20/24;  // match the svg file ....
        }
        .s {
          background-image: url("~img/poker-odds-calculator/suits/spade.svg");
          aspect-ratio: 22/24;  // match the svg file ....
        }
        .c {
          background-image: url("~img/poker-odds-calculator/suits/club.svg");
          aspect-ratio: 22/24;  // match the svg file ....
        }
      }

      &__close-btn {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        font-size: 1.25rem;
        color: #000;
        text-align: center;
        line-height: 1.6rem;
        background: #d4d9e3;
        transform: rotate(45deg);

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 10px #d4d9e3;
        }
      }

      &__group-we-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: transparent;
        gap: calc(2 * var(--card-gap));

        .description {
          font-size: 0.85rem;
          font-weight: 600;
          font-family: @fontOpenSans;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          padding-bottom: calc(2 * var(--card-gap));
        }

        .cards {
          display: flex;
          flex-direction: row;
          justify-content: center;
          // read as at most 6 cards width (account in for the 5 gaps inbetween)
          max-width: calc(6 * var(--card-width) + 5 * var(--card-gap));
          flex-wrap: wrap;
          gap: var(--card-gap);
        }
      }

    }

    .card {
      width: var(--card-width);
      aspect-ratio: var(--card-aspect-ratio);
      display: flex;
      justify-content: center;
      align-content: center;
      -webkit-user-select: none;  /* Safari */
      -ms-user-select: none;      /* IE 10 and IE 11 */
      user-select: none;

      &:hover {
        cursor: pointer;
        outline: 3px solid #117d7c;
      }

      &.focused {
        outline: 3px solid #117d7c;
      }

      &.face-up {
        &:hover {
          outline: none;
          transform: scale(1.05);
        }
      }

      &.face-down {
        border-radius: 8px;
        background-color: #d4d9e3;

        &:hover {
          .card__plus-sign {
            background-color: #fafcff;
          }
        }

        &.focused {
          .card__plus-sign {
            background-color: #117d7c;
          }
        }
      }

      &.is-from-pool {
        &.is-in-play {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      &__plus-sign {
        border-radius: 50%;
        width: calc(0.35 * var(--card-width));
        aspect-ratio: 21/20;
        color: transparent;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #909cb5;
        mask-image: url("~img/poker-odds-calculator/card-back-plus-sign.svg");
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      &__face {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: linear-gradient(180deg, #FFFFFF 0%, #C0C6D6 100%);
      }
    }
  }
}


@keyframes heartAnimation {
  0%, 100% { opacity: 0; }
  20%, 40%, 60%, 80% { opacity: 1; }
}

@keyframes spadeAnimation {
  0%, 20%, 100% { opacity: 0; }
  40%, 60%, 80% { opacity: 1; }
}

@keyframes diamondAnimation {
  0%, 20%, 40%, 100% { opacity: 0; }
  60%, 80% { opacity: 1; }
}

@keyframes clubAnimation {
  0%, 20%, 40%, 60%, 100% { opacity: 0; }
  80% { opacity: 1; }
}
