/*
/* Styles for a user's event feed.
/* This is also col_1 in the layout-two_column-left_small in _layouts.less
/* -------------------------------------------------------------------------- */

.feed-events {
  // for positioning overlays
  position: relative;

  // necessary for firefox flexbox.
  height: 100%;
  min-height: 0;
  max-height: 100%;
  width: 100vw;

  &.disabled {
    filter: brightness(30%);
    pointer-events: none;
  }

  .event-list {
    .flex_container(column);
    background: #f1f3f4;
    box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,0.1);
    position: relative;

    .state-selector {
      background: #feffff;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1021;
      overflow: hidden;
      .box-shadow(-1px 4px 8px rgba(0,0,0,0.15));

      a, span {
        color: #8f9091;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 13px;
        margin: 0;
        padding: 0;
        padding: 12px 0;
        position: relative;
        text-align: center;
        width: 50%;

        &:first-child {
          border-right: 1px solid #e3e5e5;
        }

        &:hover {
          text-decoration: none;
        }

        &.active {
          color: #272a2b;

          path {
            fill: #666b6b;
          }
        }
      }

      span {
        cursor: default;
        width: 100%;
      }

      svg {
        width: 15px;
        height: 20px;
        position: relative;
        top: 5px;
        left: -3px;

        path {
          fill: #b2b5b5;
        }
      }

      .btn-feed {

      }

      .btn-notifications {

      }

      .unseen_indicator {
        top: 15px;
        left: 25px;
      }

      &.has_notifications {
        .unseen_indicator {
          display: block;
        }
      }
      .mobile-header {
        display: none;
      }
    }

    .filter {
      .box-shadow(-1px 0px 6px rgba(0,0,0,0.5));
      background: #b6bbbd;
      border-bottom: 1px solid rgba(154,162,165,0.6);
      color: #fff;
      font-size: 14px;
      left: 0;
      position: absolute;
      text-align: center;
      top: 49px;
      width: 100%;
      z-index: 1020;
      opacity: 0.96;

      .current {
        cursor: pointer;
        padding: 11px 0;
      }

      h5 {
        font-size: 14px;
        font-weight: 300;
      }

      .menu {
        display: none;
        list-style: none;
        padding: 0;

        li {
          color: #ededed;
          cursor: pointer;
          display: block;
          font-size: 14px;
          padding-bottom: 10px;

          &:hover {
            color: #FFF;
          }
        }
      }

      &.active {
        opacity: 1;

        .menu {
          display: block;
        }
      }
    }

    .list-inner {
      .flex_column(1);
      height: 100%;
      overflow-y: scroll;
      padding-top: 50px;

      &.events {
        padding-top: 95px;
      }
    }



    // Empty notificaton banana.
    .event.empty {
      background: #fff;
      cursor: default;

      .action {
        display: block;
        text-align: center;
        padding: 10px;
      }
    }


    // A single event in the list.
    .event, .notification {
      .clearfix();
      .border-radius(4px);
      background: #f8f9f9;
      border: 1px solid #ccd1d3;
      cursor: pointer;
      margin: 10px;
      overflow: hidden;
      position: relative;
      padding: 10px 0 0 0;

      &.selected {
        .box-shadow(0 0 10px rgba(0, 0, 0, 0.2));

        &:not(.provideo) {
          background: #fff !important;
        }
      }

      header, section {
        padding: 0 15px 0 0;
        position: relative;
      }

      section {
        padding-bottom: 15px;
      }

      &:hover {
        background: #fff;
      }

      header {
        padding-bottom: 7px;
      }

      .avatar {
        float: left;
        margin: 0 10px 0;

        svg {
          height: 15px;
          width: 15px;
        }
      }

      span[rel='author'] {
        font-weight: 700;
        color: @colorLink;

        &.pro {
          background: #b7a475;
          color: #fff;
          padding: 0 4px;
          display: inline-block;
          font-weight: 500;
          border-radius: 2px;
        }
      }

      h3 {
        font-size: 12px;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }


      .action {
        color: #afb3b3;
        font-weight: normal;
      }

      .location {
        color: @colorDefault;
      }

      .event-hook {
        padding-left: 50px;
      }

      h2 {
        font-size: 14px;
        font-weight: 700;
        color: @colorHeavyText;
        font-family: @fontDefault;
      }

      .preview {
        font-size: 12px;
      }

      .stats {
        padding-top: 10px;
        font-size: 11px;
        color: #8f9598;

        span {
          padding-left: 20px;
        }

        .comments {
          background: url('~img/components/icon/comment.svg') no-repeat 2px 0;
          background-size: 12px 12px;
        }

        .likes {
          border-right: 1px solid @colorBorder;
          padding-right: 10px;
          margin-right: 5px;
          background: url('~img/components/icon/like_hover.svg') no-repeat 2px 0px;
          background-size: 11px 11px;
        }
      }


      // Pro Video Event
      &.provideo {
        background: #1f2122;
        border: 1px solid #c9c9c9;
        color: #c0c4c4;

         .avatar{
           img {
             border-color: #2F3233;
           }
         }

        &:hover {
          background: #2f3233;
        }

        // span[rel='author'] {
        //   color: #9b8c66;
        // }

        .action,
        .location {
          font-weight: 300;
          color: #afb3b3;
        }

        .action {
          // color: #787e7e;
        }

        .location {
          // color: #C0C4C4;
        }

        h2 {
          color: #fff;
        }
      }

      // A comment event on a pro video
      &.provideo.comment {
        background: #f8f9f9;
        border: 1px solid #ccd1d3;
        color: inherit;

        .avatar{
          img {
            border-color: #EAEAEA !important;
          }
        }

        .location {
          color: #3e4243;
        }

        section h2 {
          background: #272a2b;
          border: none;
        }
      }

      // A comment event.
      &.comment {

        h2 {
          background: #f1f3f4;
          .border-radius(4px);
          border: 1px solid #ccd1d3;
          font-size: 11px;
          overflow: hidden;
          padding: 5px;
          margin-bottom: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;

          svg {
            width: 16px;
            height: 16px;
            position: relative;
            top: 3px;
          }
        }

        .preview {
          position: relative;

          span:last-child {
            display: inline-block;
            overflow: hidden;
            max-height: 52px;
          }
        }

        .icon-sortbycomments {
          .transform(translateY(-50%));
          width: 12px;
          height: 12px;
          position: absolute;
          left: -26px;
          top: 50%;

          path {
            fill: #77787a;
          }
        }

      }


      /* Expand comments footer */
      .btn-toggle {
        border-top: 1px solid #e7e9ea;
        text-align: center;
        padding: 5px;

        span {
          display: inline-block;
        }

        .icon-downarrow {
          background: url('~img/components/icon/downarrow.svg') no-repeat 0px 0px;
          background-size: auto 9px;
          height: 9px;
          top: 1px;
          width: 9px;
          z-index: 2;
          position: relative;
          opacity: .5;
        }

        .label {
          padding: 0 5px;
          display: inline-block;
          font-size: 12px;
          color: #AFB3B3;
        }
      }

      footer {
        ul {
          .transition();
          border-bottom: 1px solid #e7e9ea;
          list-style: none;
          padding: 0;
          margin: 0 15px 0 50px;
          display: none;
          overflow: hidden;
        }

        .expand {
          display: block;
        }

        .collapse {
          display: none;
        }

        li {
          border-top: 1px solid #e7e9ea;
          font-size: 10px;
          margin: 0;
          overflow: hidden;
          padding: 5px 0 5px 28px;
          position: relative;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span[rel='author'] {
          font-size: 12px;
          font-weight: 700;
          color: @colorHeavyText;
        }

        .avatar {
          left: 0;
          position: absolute;
          top: 4px;
          margin: 0;

          img {
            height: 15px;
            width: auto;
          }
        }

        // Expanded state.
        &.expanded-true {
          .btn-toggle {
            border-top: none;
          }

          ul {
            display: block;
          }

          .expand {
            display: none;
          }

          .collapse {
            display: block;
          }

          .icon-downarrow {
            top: 0px;
            .transform(rotate(180deg));
            transform-origin: center center;
          }
        }
      }
    }


    /* Load more events button. */
    .btn-more {
      .box-shadow(0px -2px 5px rgba(0, 0, 0, 0.1));
      background: #FFF;
      color: #8d9191;
      text-decoration: none;
      border-top: 1px solid #aaafb1;
      font-size: 14px;
      display: block;
      padding: 20px;
      text-align: center;

      &:hover {
        background: #F8F9FA;
      }
    }
  }
}
