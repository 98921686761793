/*

Baseline styles for user avatars. (sized for a 40px image) The display name is optional but the rest needs to stay.

Here is some example markup:

Django:

<a href="/users/{{ object.author.username }}/" rel="author" class="author" title="View user's profile">
  <span class="avatar">
    <img height="36px" width="auto" src="{{ object.author.get_avatar }}" alt="user avatar">
    {% if object.author.is_elite_pro %}<span class="user_level icon-pro is_pro">{{ icons.pro|safe }}</span>
    {% elif object.author.is_pro %}<span class="user_level icon-pro is_pro">{{ icons.pro|safe }}</span>
    {% elif object.author.is_rio_staff %}<span class="user_level icon-pro is_staff">{{ icons.staff|safe }}</span>
    {% elif object.author.is_moderator %}<span class="user_level icon-mod is_mod">{{ icons.mod|safe }}</span>
    {% endif %}
  </span>
  <span class="display_name">{{ object.author.display_name }}</span>
</a>


Angular:

<a href="/users/{{ comment.author.username }}/" rel="author" class="author" title="View user's profile">
  <span class="avatar">
    <img height="36px" width="auto" src="{{ comment.author.avatar }}" alt="user avatar">
    <span ng-if="comment.author.is_elite_pro" class="user_level icon-pro is_pro">{% endverbatim %}{{ icons.pro|safe }}{% verbatim %}</span>
    <span ng-if="comment.author.is_pro" class="user_level icon-pro is_pro">{% endverbatim %}{{ icons.pro|safe }}{% verbatim %}</span>
    <span ng-if="comment.author.is_rio_staff && !comment.author.is_pro" class="user_level icon-pro is_staff">{% endverbatim %}{{ icons.staff|safe }}{% verbatim %}</span>
    <span ng-if="comment.author.is_moderator && !comment.author.is_pro && !comment.author.is_rio_staff" class="user_level icon-mod is_mod">{% endverbatim %}{{ icons.mod|safe }}{% verbatim %}</span>
  </span>
</a>


*/


.avatar {
  position: relative;
  display: inline-block;
  line-height: 0;

  img {
    .border-radius(3px);
    border: 1px solid #EAEAEA;
    background: #C8C8C8;
    display: block;
    line-height: 0;
    z-index: 1;
    // Don't show alt text if image url is broken.
    color: #C8C8C8;
    font-size: 0;
  }

  .user_level {
    width: 50%;
    height: 50%;
    position: absolute;
    bottom: -10%;
    right: -10%;
    z-index: 2;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
