.cookies-bar {
  position: fixed;
  bottom: 15px;
  right: 3vw;
  // z index is so high, to be put ontop of the chatilo add on
  z-index: 15000000;
  display: none;

  &--content {
    background-color: black;
    color: #fff;
    width: 94vw;
    font-size: 13px;
    font-family: @fontHeading;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    .info {
      font-weight: 100;
      text-align: left;

      a {
        color: red;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .action {
      form {
        display: inline-block;
        float:right
      }

      input[type="submit"] {
        background: none;
        color: inherit;
        border: none;
        font: inherit;
        outline: inherit;
        // add padding - to be more easy "clickable" on touch devices
        padding: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

}


@media (max-width: @widthMobileMax) {
  .cookies-bar {
    &--content {
      // lower font so to keep the text on 2 lines on
      font-size: 11px;
    }
  }
}
