// user profile - main page
body#profile.profile {

  .core_info {
    background: #f7f8f8;
    padding: 60px 0 60px;
    border-bottom: 1px solid #bfc3c5;
  }

  .content {
    word-break: break-all;
  }

  .profile_photo {
    .border-radius(5px);
    .box-shadow(0 0 5px 2px rgba(0, 0, 0, 0.1));
    background: #fff;
    padding: 4px;
    border: 1px #ced0d1 solid;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 160px;
      height: auto;
      display: block;
    }
  }

  .profile_info {
    padding-left: 205px;

    h1 {
      font-size: 36px;
      color: #202223;
      font-weight: 700;
      line-height: 1em;
    }

    .title {
      font-size: 12px;
      color: #b5b5b5;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
    }

    .points {
      font-size: 16px;
      color: @colorLink;
      padding: 0;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      list-style: none;
    }

    .clearfix {
      display: inline-block;
    }

    form {
      display: inline-block;
      margin: 15px 15px 5px 0;
    }

    // Follow & Message buttons
    .btn {
      display: inline-block;
      width: auto;
      margin-bottom: 5px;
    }

    .message_user {
      svg {
        height: 14px;
        margin-right: 5px;
        top: 3px;
        width: auto;
      }

      path {
        fill: #fff;
      }
    }
  }

  // Profile section navigation
  .col_1 {
    nav {
      background: #fff;
      border-bottom: 1px solid #bfc3c5;
      z-index: 1;

      // Drop shadow - only downards!
      &:after {
          .box-shadow(0 0 10px 2px rgba(0, 0, 0, 0.15));
          content: "";
          display: block;
          height: 0px;
          left: 50%;
          margin-left: -50%;
          position: absolute;
          width: 100%;
          z-index: -1;
      }

      ul {
        padding: 25px 0 25px 185px;
        margin: 0;
        list-style: none;
      }

      li {
        display: inline-block;

        a {
          font-size: 14px;
          display: block;
          padding: 15px 20px;
        }
      }
    }

    section.content {
      padding: 60px 0;
    }

  }

  .col_2 {
    background: #f3f5f6;
  }
}



// Messaging modal.
body#profile .rio-modal {
  .content .compose_message {
    .border-radius(6px);
    border: none;
    background: #fff;
    padding: 0;
    overflow: hidden;
    text-align: left;

    .msg_form_field {
      padding: 15px 15px 0;
    }

    h2 {
      background: #F8F8F8;
      border-bottom: 1px solid #E0E0E0;
      color: #5e5e5e;
      font-size: 16px;
      padding: 0 0 6px 15px;
      line-height: 18px;

      span {
        font-size: 13px;
        color: #878282;
        display: block;
        background: transparent url(~img/profile/message_user.png) no-repeat 0 8px;
        padding: 6px 0 2px 19px;
      }
    }

    textarea {
      width: 440px;
      outline: none;

      &:focus {
        border: 1px solid #aaa;
        box-shadow: none;
        outline: none;
      }

      @media (max-width: @widthMobileMax) {
        width: 90vw;
      }

    }

    div.required {
      color: @colorLink;
      font-size: 13px;
      margin: 13px 0 8px 15px;
      display: block;
      width: 250px;
    }

    .success {
      color: #878282;
      font-size: 13px;
      height: 160px;
      margin: 17px 0 20px 15px;
    }

    .btn-primary {
      margin-left: 355px;
      margin-bottom: 15px;
      margin-top: 30px;

      @media (max-width: @widthMobileMax) {
        margin-left: 60vw;
      }
    }
  }

}

// Phones
@media (max-width: @widthMobileMax) {

  body#profile.profile {
    .core_info {
      padding-top: 20px;
    }

    .profile_info {
      padding-left: 70px;

      h1 {
        font-size: 24px;
      }
    }

    .profile_photo {
      img {
        width: 40px;
      }
    }

    .col_1 section.content {
      padding-top: 20px;
    }
  }

}
