body.free-poker-tools #torso,
body.free-poker-tools .faq {
  font-size: 16px;

  h1 {
    font-size: 4.25rem;
    padding: 0.5em 0;
    line-height: .8em;
    font-family: @fontNowayMedium;
    font-weight: normal;
  }

  h2 {
    font-size: 2.5em;
    padding: 1em 0;
    line-height: 2;
    text-align: center;
  }

  h3 {
    font-family: @fontNowayMedium;
    font-size: 1.5em;
    line-height: 1.5;
    font-weight: normal;
  }

  h4 {
    font-family: @fontNowayMedium;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 2;
  }

  p, li {
    font-weight: 200;
    max-width: 975px;
    font-size: 1em;
    line-height: 150%;
    padding: 0.5em 0;
    letter-spacing: .02em;

    b {
      font-weight: 500;
    }
  }

  h2, p, li, header {
    font-weight: 500;
    color: #000;
    font-family: @fontDefault;
  }
}


body.free-poker-tools #torso {
  background: #fefefe;
  background-size: 100% auto;
  width: auto;
  max-width: 100vw;
  color: #18191a;
  border: none;

  .content-header {
    height: 400px;
    text-align: center;
    background: #010406 url('~img/views/free-poker-tools/header.jpg') top center no-repeat;
    background-size: cover;
    align-content: center;
    color: #fff;

    h1 {
      span {
        display: block;
        color: #809DAE;
        font-size: 0.2em;
        line-height: 3em;
        letter-spacing: 0.2em;
      }
    }
  }

  .content-wrap {
    max-width: 1275px;

    .intro-paragraph {
      margin: 70px auto;
      text-align: center;
    }
  }

  .tools-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 120px;
    margin-bottom: 120px;
  }

  .tool {
    gap: 100px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.plo-calculator {
      .plaque {
        background: #131F27 url("~img/views/free-poker-tools/odds-calculator-plo-equity.jpg");
        background-size: cover;
      }
    }

    &.nlhe-calculator {
      .plaque {

        background: #131F27 url("~img/views/free-poker-tools/odds-calculator-nlhe-equity.jpg");
        background-size: cover;
      }
    }

    .plaque {
      background: #131F27;
      width: calc(min(700px, 50vw));
      aspect-ratio: 1700/958;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    .description {
      padding-right: 50px;

      .cta {
        margin-top: 25px;
        padding: 12px 35px 8px;
        background: #1D1E25;
        font-family: @fontPoppins;
        font-size: 0.75em;
        line-height: 1.375em;
        display: inline-block;
        color: #fff;
        font-weight: 400;

        &:hover {
          text-decoration: none;
          opacity: .9;
        }
      }
    }
  }
}


body.free-poker-tools {
  .faq {
    width: 100%;
    background: #131F27;

    h2 { color: #fff; }

    ul.rio--accordion {
      max-width: 1275px;
      margin: 0 auto;
      list-style: none;
      text-align: left;
      width: min(1000px, 90vw);

      li {
        max-width: 975px;
        padding: 0.5em 0;
        border-bottom: 1px solid #a8b0b9;
        color: #fff;

        b {
          font-weight: 500;
        }

        a {
          color: #fff;
          font-weight: 400;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        width: 100%;

        .expand-trigger {
          font-weight: 400;
          font-family: @fontPoppins;
          font-size: 1.5em;
          min-height: 80px;
          line-height: 1.4;
          padding-right: 35px;
          position: relative;

          display: flex;
          align-items: center;

          .expand-trigger-header-text {
            font-family: @fontNowayMedium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:after {
            position: absolute;
            color: #fff;
            font-size: 2em;
            font-weight: 200;
            font-family: @fontPoppins;
            content: "+";
            right: 0;
          }
        }

        &.expanded {
          .expand-trigger {
            .expand-trigger-header-text {
              white-space: normal;
              overflow: visible;
            }

            &:after {
              content: "-";
            }
          }
        }

        .section {
          font-size: 1em;
          line-height: 150%;
          padding-right: 50px;
          padding-bottom: 50px;
        }
      }
    }
  }
}


@media (max-width: @widthTabletMax) {
  body.free-poker-tools #torso {
    .intro-paragraph {
      padding: 0 5vw;
    }

    .tool {
      gap: 50px;
      grid-template-columns: repeat(1, 1fr);

      .plaque {
        width: 90vw;
        margin: 0 auto;
      }

      .description {
        padding: 0 5vw;
      }
    }
  }
}
