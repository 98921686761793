body.http-errors {
  background: #000 url(~img/account/bg.jpg) no-repeat top center fixed;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  height: 100%;
  margin: 0;
  padding: 0;


  #body_container {
    height: 100%;

    > section {
      position: relative;
      text-align: center;
      top: 50%;
      .transform(translateY(-50%));

      h1 {
        font-size: 80px;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 40px;
        color: #ddd;
      }

      p {
        font-family: @fontHeading;
        font-size: 20px;
        padding: 0;
      }

      a:hover {
        color: #cf0f00;
      }

      &.http-404 {
        h1 {
          font-size: 50px;
        }

        p {
          color: #ccc;
        }

        ul.popular-pages {
          list-style: none;
          margin: 0 auto;
          width: 60%;
          max-width: 600px;
          min-width: 250px;
          margin-top: 20px;

          li {
            display: inline-block;
            margin-left: 5px;
            margin-top: 10px;

            > a.btn-404 {
              background-color: #656b72;
              border: none;
              .border-radius(4px);
              .transition(all, 0.2s, ease-out);
              min-width: 150px;
              max-width: 150px;
              color: #fff;
              cursor: pointer;
              display: block;

              &:hover {
                color: #fff;
               .border-radius(6px);
                text-decoration: none;
                background: #5b6167;
              }
            }
          }
        }
      }
    }

    > h2 {
      font-size: 20px;
      position: absolute;
      left: 0;
      text-align: center;
      top: 80px;
      width: 100%;
    }
  }

  #video_playlist .toggle {
    display: none;
  }
}


@media (min-width: @widthTabletMin) and (max-width: @widthTabletPortrait) {
  body.http-404 {
    h1 {
      font-size: 60px;
    }

    #body_container > h2 {
      font-size: 18px;
    }
  }
}


@media (max-width: @widthMobileMax) {
  body.http-404 {
    h1 {
      font-size: 40px;
    }

    #body_container > h2 {
      font-size: 16px;
      left: 0;
      top: 60px;
      width: 100%;
    }

    p {
      font-size: 14px;
    }
  }
}
