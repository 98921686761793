.nav-drawer {
  transition: left 0.21s ease-out;
  display: flex !important;

  background-color: #fff;
  cursor: default;
  height: 100%;
  left: -311px;
  padding: 0 !important;
  position: fixed !important;
  top: @navHeight;

  @media (max-width: @widthMobileNavBreakpoint) {
    right: -331px;
    left: auto;

    // on mobile align right, and drawer is from top
    top: 0;
    text-align: right;
  }

  // make this bigger than anything that's on the old site!
  z-index: 2990;

  // required for all global pieces of the site that span both stylesheets
  *, *:before, *:after {
    // use box sizing for everything within this layout
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // User nav
  .nav-main-user {
    z-index: 200;
    width: 310px;
    padding-bottom: 2 * @navHeight;
    position: relative;
    background: #fff;
    border-right: 1px solid #c9c9c9;
    overflow-x: auto;
    .box-shadow(0px 0px 18px 0px rgba(5, 5, 5, 0.14));

    .avatar {
      .border-radius(3px);
      border: 1px solid #d0d8d9;
      margin-right: 6px;
      background-size: 100% auto;
      height: 50px;
      position: relative;
      width: 50px;
    }

    > header,
    > section li {
      padding: 0 12%;
    }

    > header {
      background-color: #f8f8f8;
      padding-bottom: 24px;
      padding-top: 24px;

      > div {
        display: inline-block;
        vertical-align: top;
      }

      h2 {
        color: #535353;
        font-weight: 700;
        font-size: 15px;
        line-height: 1.1;
        margin-top: 10px;
        letter-spacing: normal;
        text-transform: capitalize;

        a.points {
          font-weight: 300;
          display: inline;

          &:hover {
            color: #a70106;
          }
        }

      }

      h4 {
        color: #868d8d;
        font-weight: 300;
        font-size: 12px;
      }
    }

    > .my-account,
    > .browse,
    > .contribute,
    > .staff {
      margin-top: 30px;

      h2 {
        color: #b3b7b7;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 14px;
        padding: 0 12%;
        letter-spacing: normal;
        text-transform: capitalize;

        @media (max-width: @widthMobileNavBreakpoint) {
          text-transform: uppercase;
          letter-spacing: 1.1px;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;
      }

      // give some space to hover over but still have a gap between
      li {
        padding-top: 6px;
        padding-bottom: 6px;
        margin: 6px 0;
      }

      a {
        color: #1e1f1f;
        display: block;
        font-size: 16px;
        font-weight: 300;
        text-decoration: none;
        width: 100%;

        @media (max-width: @widthMobileNavBreakpoint) {
          font-weight: 600;
        }

        // hover states for icons
        &:hover {
          color: #a70106;

          .icon {
            g *,
            .shadow {
              fill: #666B6B;
            }
            .add {
              fill: #fff;
            }

            &.icon-settings {
              .wheel {
                transform: rotate(360deg);
              }
            }
          }
        }
      }

      // icons only
      .icon {
        display: inline-block;
        height: 17px;
        margin-right: 4px;
        position: relative;
        top: 2px;
        width: 17px;

        g * {
          .transition(all, .2s, ease-in-out);
        }

        &-plans_pricing {
          .st0 {
            fill: none !important;
          }
        }

        .wheel {
          transform-origin: 50% 50%;
        }
      }
    }

    > .contribute {
      // as we offset the whole drawer with @navHeight
      // add in extra bottom space so you can scroll all the way down
      margin-bottom: 70px;
    }
  }
}


// .nav-drawer
.nav-drawer {
  // Notification list.
  .nav-notifications {
    .transition(all, 0.21s, ease-out);
    background: #E2E3E5;
    overflow: hidden;
    position: relative;
    right: 0px;
    top: 0;
    width: 0;
    z-index: 100;
    text-align: right;

    &.active {
      width: 300px;
    }

    .notifications-list {
      padding: 10px;
      width: 300px;

      .btn {
        background: #fff;
      }

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        .border-radius(3px);
        border: 1px solid #D7DADE;
        text-align: left;
        background: #fff;
        margin-bottom: 10px;
        padding: 10px;

        &:hover {
          background: #F8F7F9;
        }
      }

      a  {
        color: @colorLinkNav;

        &:hover {
          text-decoration: none;
        }
      }

      h5 {
        font-size: 12px;
        color: #535353;
        padding-bottom: 10px;
      }

      .action_text {
        color: @colorLinkNav;
        font-weight: normal;
      }

      .user,
      .thread_title {
        color: #535353;
        font-weight: 500;
      }

      p {
        font-size: 12px;
        padding-bottom: 10px;
      }

      .pub_date {
        color: #AAACAE;
        font-size: 11px;
      }

      // Seen notifications.
      .seen_true {
        color: #999c9c;
        background: #f0f1f2;

        h5,
        a,
        .action_text,
        .user,
        .thread_title {
          color: #999c9c !important;
        }
      }


    }

  }

  .close {
    position: absolute;
    padding: 20px;

    .icon-close {
      display: block ;
      width: 20px;
      height: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// The user has unseen notifications.
body.has_notifications {
  .nav-drawer .nav-main-user {
    // Make the notification icon red
    .icon-notifications path {
      fill: #B21219;
    }
  }
}

// The user has unread messages
body.has_messages {
  .nav-drawer .nav-main-user {
    // Make the message icon red
    //    .nav-drawer .nav-main-user>.browse a:hover .icon g *,
    .icon-messages path {
      fill: #B21219;
    }
  }
}
