// this animation flips the heart when you like something
// needs to be global for both in the comments and in the thread area
@-webkit-keyframes like-out { .like-out-frames; }
@-moz-keyframes like-out { .like-out-frames; }
@-ms-keyframes like-out { .like-out-frames; }
@-o-keyframes like-out { .like-out-frames; }
@keyframes like-out { .like-out-frames; }
.like-out-frames() {
  0% {.transform(scale(1, 1)); opacity: 1;}
  20% {
    .transform(scale(1.3, 1.3));
    opacity: 0.5;
  }
  50% {
    .transform(scale(0, 1.2));
  }
  75% {
    .transform(scale(1.1, 1.1));
  }
  100% {
    .transform(scale(1, 1));
  }
}


// section of the site where users can like a thread type
// this is for hand histories and topics, videos is below
.thread-detail {
  .handhistory-detail,
  .topic-detail,
  .provideo-detail {
    .thread-likes {
      // adjusts via checkLikePosition() method in header-sticky.js
      .transform(translate3d(0,0,0) translateX(-50%));
      left: 80%;
      position: absolute;
      width: 68px;

      // note this is just higher than the sticky header
      z-index: 1050;

      // when a user likes something, trigger change in look and feel
      &.user-liked {
        // change the background of the button as well as add a drop shadow
        .toggle-logged-in-user-like {
          background: #be141a url(~img/partials/likes/user-liked.png) no-repeat left top;

          // run this animation for the svg icon
          .icon-like {
            -webkit-animation: like-out 0.3s linear;
            opacity: 1;

            path {
              fill: #fff;
            }
          }

          span.total {
            color: #b61e22;
          }

          .action-title {
            color: #fff;
          }
        }
      }

      // what the elements look like normally, if the user has not liked the item
      .toggle-logged-in-user-like {
        .transition(all, 0.3s, ease-in-out);
        // prevent text from getting wonky on transform
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility:    hidden;
        -ms-backface-visibility:     hidden;
        background: #ebeeef;
        border: none;
        color: #a8aeb2;
        cursor: pointer;
        display: block;
        outline: none;
        padding: 44px 10px 9px;
        position: relative;
        width: 100%;
        z-index: 1020;

        svg.icon-like {
          height: 36px;
          left: 18px;
          position: absolute;
          top: 5px;
          width: 32px;
          z-index: 10;

          path {
            .transition(fill, 0.3s, linear);
          }
        }

        svg.icon-pro {
          height: 16px;
          left: 34px;
          position: absolute;
          top: 25px;
          width: 18px;
          z-index: 20;
        }

        span.total {
          .transition(all, 0.3s, ease-in-out);
          left: 0;
          position: absolute;
          text-align: center;
          top: 13px;
          width: 100%;
          z-index: 30;
        }

        span.action-title {
          font-size: 10px;
          font-weight: 500;
          text-align: center;
        }
      }

      .show-likers {
        .transition(all, 0.15s, ease-in-out);
        background-color: #d5d9da;
        border: none;
        color: #8c9397;
        cursor: pointer;
        display: block;
        font-size: 11px;
        font-weight: normal;
        outline: none;
        opacity: 0;
        padding: 4px 0;
        position: relative;
        text-align: center;
        top: -22px;
        width: 100%;
        z-index: 1010;
      }

      // on hover, if users liked it allow someone to click and see who liked the thread
      &:hover .show-likers {
        top: 0;
        opacity: 1;
      }
    }
    @media (min-width: 1340px) {
      .thread-likes {
        left: 900px;
      }
    }
  }
}


// section of the site where users can like a video
.thread-detail .provideo-detail {
  .thread-likes {
    // what the elements look like normally, if the user has not liked the item
    .toggle-logged-in-user-like {
      background: #2b2e2f;

      span.total {
        color: #2b2e2f;
      }
    }
  }
}


// modal window that shows all the likers for a post type
.modal.likers {
  height: 80%;
  max-height: 600px;
  top: 10%;

  .modal-dialog {
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 400px;
  }

  @media (max-width: @widthMobileMax) {
    .modal-dialog {
      width: 300px;
    }
  }

  .modal-content {
    height: 100%;
  }

  section {
    overflow-y: auto;
  }

  header {
    .clearfix();
    background: #fff;
    padding: 18px 25px 18px 30px;
    width: 100%;
  }

  h1 {
    color: #6a6f71;
    float: left;
    font-size: 15px;
    font-weight: normal;
    line-height: 1;
  }

  .icon-close {
    cursor: pointer;
    float: right;
    height: 14px;
    outline: none;
    width: 14px;
  }

  ul {
    background: transparent url(~img/partials/likes/likers-header-bg.png) no-repeat center top;
    background-size: 100% auto;
    list-style: none;
    padding: 0;

    li {
      border-bottom: 1px solid #dde3e5;
      .clearfix();
      display: block;
      padding: 10px 25px;
      width: 100%;
    }

    a {
      .clearfix();
      display: block;
      float: left;
      text-decoration: none;
      width: 76%;
    }

    .avatar {
      float: left;
      margin-right: 10px;

      .user_level {
        width: 45%;
        height: 45%;
      }

      img {
        display: block;
        float: left;
        margin: 0;
      }
    }



    h2 {
      color: #373a3a;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.1;
      padding-top: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        text-decoration: underline;
      }
    }

    h4 {
      color: #757b7b;
      font-weight: normal;
      font-size: 11px;
      line-height: 1;
    }

    .btn {
      display: none;
      margin-top: 10px;
    }

    li:hover .btn {
      display: block;
    }
  }
}


@media (max-width: 960px) {
  .thread-detail .handhistory-detail, .thread-detail .topic-detail {
    .thread-likes {
      right: 0;
      top: @navHeight;
    }
  }
}

@media (max-width: @widthMobileMax) {
  .thread-detail .handhistory-detail, .thread-detail .topic-detail {
    .thread-likes {
      display: none;
    }
  }
}
