// The messages dropdown in the main menu.
.menu-messages {
  margin-right: 20px;

  .icon-messages {
    position: relative;
    top: -2.8px;

    svg {
      width: 18px;
    }
  }

  .dropdown,
  .menu-dropdown--dropdown {

    .list {
      padding-top: 50px;

      a:hover {
        color: inherit;
      }
    }
  }

}
