#hand_form {
  &.col_1 {
    box-shadow: none;
    border-right: none;
  }

  .hand-content .section {
    left: 0;
    margin: 30px 0;
    width: 100%;
  }

  .section {
    // margin: 18px 0;
    // overflow: hidden;
    // border: 1px solid #d6d6d6;
    // .vertical-gradient(#f4f4f4, #ebebeb);
    // .border-radius(7px);
    // font-size: 12px;
    // position: relative;

    span.add_comment {
      background: transparent url(~img/hand/add_comment.png) no-repeat left center;
      bottom: 8px;
      color: #87151a;
      cursor: pointer;
      font-size: 12px;
      padding-left: 18px;
      position: absolute;
      right: 10px;
      text-decoration: underline;
    }
  }

  .edit_street {
    position: relative;

    .actions_wrapper {
      .actions {
        .transition(all, 0.1s, linear);
        border: solid 1px transparent;
        display: inline;

        .hideable {
          cursor: e-resize;
        }

        &:hover {
          .hideable {
            background: white;
            opacity: 1;
            border-right-color: transparent;
            border-left-color: transparent;

            &.first {
              border-left: solid 1px #ccd1d3;
            }

            &.last {
              border-right: solid 1px #ccd1d3;
            }
          }
        }

        .hideable,
        .street-action,
        .separator {
          .transition(all, 0.1s, linear);
          border: solid 1px transparent;
          position: relative;

          &.street-action-hidden,
          &.street-action-hover {
            background: #f5f7f8;
            opacity: .35;
            border: solid 1px transparent;
          }

          &.street-action-shown {
            background: white;
            opacity: 1;
            border-top-color: #ccd1d3;
            border-bottom-color: #ccd1d3;
            border-left-color: transparent;
            border-right-color: transparent;
          }
        }
      }
    }
  }

  .edit_street.is_hidden {
    .section,
    .street_form {
      opacity: .35;
    }

    .street-action-hidden {
      opacity: 1 !important;
    }
  }

  .hide_street_container {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;

    .btn-hide {
      background: #4a4b4d;
      color: white;
    }

    .btn-unhide {
      background: #ccd0d3;
      color: black;
    }
  }

  .street-text {
    word-wrap: break-word;
    margin-left: 15px;
    padding: 10px 0;
  }

  // .game-title {
  //   display: block;
  // }

  // .game-blinds {
  //   font-weight: bold;
  // }

  // .title-block,
  // .street {
  //   border-top: 1px solid #fcfcfc;
  //   border-bottom: 1px solid #d6d6d6;
  //   padding: 12px 114px 12px 15px;
  // }

  // .street.border {
  //   border-top: 1px solid #d4d3d3;
  //   padding-top: 10px;
  // }

  // .street-title {
  //   display: block;
  //   font-size: 20px;
  //   font-weight: 500;
  //   color: #1d2020;
  // }

  // .street-money {
  //   display: inline-block;
  //   font-size: 12px;
  //   margin-right: 10px;
  //   vertical-align: top;
  //   color: #5f5f5f;
  // }

  // .street-players {
  //   display: inline-block;
  //   font-size: 12px;
  //   font-style: italic;
  //   margin-right: 10px;
  //   vertical-align: top;
  //   color: #5f5f5f;
  // }

  // .card-value {
  //   background-position: 12px 5px;
  //   background-repeat: no-repeat;
  //   color: #333;
  //   display: inline-block;
  //   font-size: 16px;
  //   margin: -3px 2px 2px;
  //   padding-right: 15px;
  //   font-family: @fontHeading;
  //   font-weight: 500;
  //   min-width: 12px;

  //   &.diamond {
  //     padding-right: 10px;
  //   }
  // }

  // .street-action {
  //   color: #5f5f5f;
  // }

  // .street-action.primary {
  //   color: @colorLink;

  //   .seat-name {
  //     color: @colorLink;
  //   }
  // }

  // .seat-name {
  //   color: #5f5f5f;
  // }

  // .seat-name.primary {
  //   color: @colorLink;
  // }

  // .game-rake {
  //   color: #5f5f5f;
  //   display: block;
  // }
}

.hand_edit_message_container {
  .hand_edit_message {
    border: 1px solid #c0c7ca;
    margin: 30px 40px 0 -40px;
    .border-radius(4px);

    h5 {
      padding: 0 26px;
      font-weight: bold;
      font-size: 18px;
    }

    p {
      margin: 5px 20px 26px 27px;
      font-size: 14px;
    }

    .how_it_works {
      margin-bottom: 40px;

      h5 {
        background: transparent url(~img/hand/edit_sidebar_header.png) no-repeat center top;
        padding-top: 200px;
        margin-top: -10px;
      }

      p {
        background: transparent url(~img/hand/edit_sidebar_how_works_bg.png) no-repeat bottom center;
        padding-bottom: 40px;
      }
    }

    .supported_hands {
      .list_container {
        .flex_container(row);
        margin: 0 26px 20px;

        ul {
          list-style: none;
          margin-top: 2px;
          .flex_column(1);

          li {
            text-transform: uppercase;
            color: #7c8087;
            font-size: 11px;
            margin: 6px 0;
          }
        }
      }
    }
  }
}
