// Detail view of a pro video
// This is an extension of section.thread-detail
section.thread-detail > div > article.provideo-detail {

  .footer {
    .clearfix();
    background-color: black;
    margin: 0;
    padding: 20px 5%;
    text-align: left;
    color: white;

    .video-information {
      padding-right: 30px;
      float: left;
      width: 60%;

      &.has-downloads {
        border-right: 1px solid #373f46;
      }
    }

    .video-extras {
      text-align: center;
      float: right;
      width: 40%;
      padding-left: 20px;
      padding-top: 10px;

      &.downloads {
        .downloads_container {
          display: flex;
        }
      }

      &.software-tools {
        .software_tools_container {
          display: flex;
          flex-direction: column;
        }
      }

      .toggle-buttons {
        font-family: @fontNowayMedium;
        font-size: 16px;
        text-align: left;
        margin-bottom: 20px;

        a {
          color: #7e868d;
          text-decoration: none;
          text-underline-offset: 7px;
          font-weight: 500;

          &.software-tools {
            margin-right: 20px;
          }

          &.active {
            text-decoration: underline;
            color: #fff;
          }

          &:not(.active):hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .software_tools_container {
        display: none;
        flex-direction: row;
        width: 100%;

        .software_tool {
          display: flex;
          flex-direction: row;
          width: 100%;
          padding: 10px 0;

          &:hover {
            text-decoration: none;
          }

          > div:last-child {
            margin-left: 9px;
          }

          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            text-align: left;
          }

          &__title {
            color: #fff;
            font-family: @fontNowayMedium;
            position: relative;
            font-size: 15px;

            &:after {
              content: '';
              display: block;
              height: 18px;
              width: 18px;
              background-image: url("~img/icons/link-out.svg");
              background-size: cover;
              position: absolute;
              right: -20px;
              top: 0;
            }
          }

          &__description {
            color: #7e868d;
            font-family: @fontOpenSans;
            font-size: 13px;
            font-weight: 400;
          }
        }
      }

      .downloads_container {
        display: none;
        justify-content: flex-start;
        flex-wrap: wrap;

        .outer {
          position: relative;
          //margin: 0 5px;
          padding: 5px 0;
          width: 46%;

          a {
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            white-space: nowrap;

            &:hover {
              text-decoration: none;

              p {
                font-weight: 600;
              }
            }
          }

          .download-icon {
            background-color: #313940;
            width: 23%;
            height: auto;
            padding: 8px;

            &.dashboard {
              background-image: url('~img/courses/masterclass/extra-material/download-button.png');
              background-size: 58%;
              height: 36px;
              background-repeat: no-repeat;
              background-position: center
            }
          }

          .file_links {
            width: 71%;
            text-decoration: none;
            color: white;
            // padding-top: 10px;
            position: relative;
            display: flex;
            align-items: center;

            p {
              //position: absolute;
              // white-space: nowrap;
              //transform: translateX(0);
              //transition: 3s;
              font-size: 13px;
              font-family: @fontOpenSans;
              text-align: left;
              padding: 0;
            }
          }
        }
      }
    }

    .posted-date-dash {
      font-size: 11px;
      color: #7e868d;
      padding-bottom: 5px;
      font-weight: 600;
      letter-spacing: .2ex;
      padding-top: 10px;
    }

    section.video-content {
      // display: none;
      font-size: 14px;
      line-height: 1.6;
      padding: 10px 0 4px;
      font-family: @fontOpenSans;

      &.dashboard {
        padding: 0px 0 4px;
      }

      .posted-date {
        font-size: 11px;
        color: #7e868d;
        padding-bottom: 5px;
        font-weight: 600;
        letter-spacing: .2ex;
      }
    }

    > .thread-content-toggle {
      font-family: @fontHeading;
      background: none;
      border: none;
      color: #666b6b;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      padding: 15px 30px 15px 0;

      > .icon-downarrow {
        display: inline-block;
        height: 10px;
        position: relative;
        top: 1px;
        margin-left: 2px;
        width: 10px;
        z-index: 2;
      }
    }

    .tooltip-menu {
      // border-left: 1px solid #c8d1d1;
      display: inline-block;
      position: relative;
      padding: 5px 0;

      .icon {
        position: relative;
        top: 5px;
        cursor: pointer;
      }

      .add_to_playlist {
        background-image: url('~img/components/icon/add_grey.svg');
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: 5px;
      }

      div {
        display: inline;
        cursor: pointer;
        font-size: 14px;
        padding: 6px 10px 6px 20px;
        color: #7e868d;
        white-space: nowrap;

        a {
          color: #afb3b3;

          &:hover {
            color: #666b6b;
          }
        }

        &:hover {
          color: #666b6b;
        }
      }

      .download_sync_btn {
        background-image: url('~img/components/icon/play_grey.svg');
        background-size: 25%;
        background-repeat: no-repeat;
        background-position: 3px;

        .add_to_downloads {
          display: inline;
        }

        .delete_from_downloads {
          display: none;
        }

        &.synced {
          background-image: url('~img/components/icon/play_white.svg');
          background-size: 15%;
          background-repeat: no-repeat;
          background-position: 0px;
          color: white;

          .add_to_downloads {
            display: none;
          }

          .delete_from_downloads {
            display: inline;
          }
        }

      }

      #post_subscribe {
        float: none;
        margin-top: 0px;
        display: inline-block;
        background-image: url('~img/components/icon/check_grey.svg');
        background-size: 17%;
        background-repeat: no-repeat;
        background-position: 5px;

        &.subscribed {
          background-image: url('~img/components/icon/check_white.svg');
          background-size: 16%;
          background-repeat: no-repeat;
          background-position: 5px;
        }

        .subscribed_text {
          color: white;
        }
      }

    }

    .ellipse-button {
      background: none;
      border: none;

      .ellipse {
        margin-left: 15px;

        &:hover {
          cursor: pointer;
        }

        &.hide {
          display: none;
        }
      }
    }

    .social-container {
      display: inline-block;
    }
  }

  .tab-bar {
    display: flex;
    padding: 0 15%;
    background: #000;

    .tab-bar-inner {
      display: flex;
      gap: 5px;
    }

    .tab {
      background: #d4d6d7;
      margin: 0;
      display: inline-block;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px 5px 0 0;

      &:hover {
        background: #fbfbfb;
      }

      &.active {
        background: #f7f8f8;
      }
    }
  }

  .quiz-section {
    background: #f7f8f8;
    padding: 30px 0 120px;

    header {
      margin-bottom: 50px;
      border-bottom: 1px solid #c0c7ca;
      padding-bottom: 20px;

      h2 {
        color: #1f2122;
        font-size: 20px;
        font-weight: 700;
      }
    }

    .quiz-section-content {
      margin: 0 15%;
    }

    .question {
      padding-bottom: 30px;
      padding-right: 40px;
      position: relative;

      &.hidden {
        display: none;
      }

      &__vote {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 5px;
        right: -40px;
        top: -16px;

        &.vote-submitted {
          pointer-events: none;
          opacity: 0.7;

          &.thumbsup {
            .up {
              border: 1px solid #444;
            }
          }

          &.thumbsdown {
            .down {
              border: 1px solid #444;
            }
          }

          //.up, .down {
          //  display: none;
          //}
        }

        .up, .down {
          font-size: 16px;
          padding: 5px 8px;
          border-radius: 4px;

          &:hover {
            cursor: pointer;
            background: #eee;
          }
        }
      }

      &.open_ended {
        //max-width: 550px;

        .reveal {
          cursor: pointer;
          padding: .75em 1em .7em;
          background: #f7f8f8;
          border: 1px solid #ddd;
          display: inline-block;
          border-radius: 5px;
          font-size: 14px;

          &:hover {
            background: #fff;
          }

          &.hide {
            display: none;
          }
        }

        .answer {
          display: none;
          font-size: 14px;
          line-height: 1.5em;
          color: #333;

          &.revealed {
            display: block;
          }
        }
      }

      &.answered {
        .correct {
          background: #e4eee4 !important;
          border-color: green !important;

          .icon {
            opacity: 1;
          }
        }
      }

      ul {
        list-style: none;
      }

      .question-text {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 1em;
      }

      .answer-choice {
        border: 1px solid #ddd;
        padding: .75em 1em .7em;
        margin-bottom: 5px;
        font-size: 14px;
        border-radius: 5px;
        color: #333;
        display: flex;

        .icon {
          opacity: 0;
          margin-right: 10px;
        }


        &.selected {
          background: #fff3f3 !important;
          border-color: red;

          .icon {
            opacity: 1;
          }
        }

        &.selected.correct {
          background: #e4eee4 !important;
          border-color: green !important;
        }

        &:hover {
          background: #fff;
          cursor: pointer;
        }
      }
    }

    .load-more {
      display: flex;
      justify-content: center;

      &.loading {
        button {
          pointer-events: none;
          opacity: 0.7;
          background-image: url("~img/rio/loading.gif");
          background-repeat: no-repeat;
          background-position-x: 97%;
          background-size: auto 50%;
          background-position-y: 50%;
        }
      }
    }
  }

}


// ProVideo sidebar styles.
.layout.provideo .sidebar {
  section {
    background-color: #1b1e1f;
  }

  .btn {
    .transition(background, 0.2s, linear);
    .border-radius(5px);
    padding: 10px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    margin: 0 auto;
    display: inline-block;

    &:hover {
      .transition(none);
      text-decoration: none;
    }
  }

  // Author section
  .mast {
    text-align: center;
    padding-bottom: 24px;
    position: relative;

    span {
      display: block;
    }

    h3 {
      font-size: 13px;
      font-weight: normal;
      text-align: center;
      overflow: hidden;
      padding-top: 200px;
    }

    &.additional-authors {
      min-height: 280px;

      h3 {
        width: 50%;
        position: absolute;
        left: 0;

        img {
          left: -40%;
          position: relative;
          width: 180%;
        }

        &.author2 {
          left: 50%;
        }

        .author_name {
          font-size: 14px;
          padding-top: 2px;
          background-position: 50% 80%;
          background-size: 15px auto;
        }

        .pro_type {
          font-size: 11px;
        }
      }
    }

    .info {
      position: relative;
    }

    .pro_type {
      color: #85858d;
      font-size: 12px;
      font-weight: 500;
      line-height: 1em;
      text-transform: uppercase;
    }

    .author {
      position: absolute;
      display: block;
      top: 0;
      left: 0;

      img {
        display: block;
        height: 100%;

        &.not-active {
          filter: grayscale(1);
        }
      }
    }

    .author_name {
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      display: block;
      padding-bottom: 25px;
      margin-bottom: 21px;
      background: url('~img/components/icon/crown.svg') no-repeat 50% 100%;
      background-size: 15px auto;
    }

    .btn {
      position: relative;
      border: 1px solid #606263;
      width: 158px;

      &:hover {
        background: #272b2c;
      }
    }

    // edit here for the sidebar
    &.learning-path {
      padding: 30px 0;

      .path {
        color: #B7A475;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
      }

      .section {
        color: white;
        font-size: 20px;
        font-weight: bold;
      }

      .progress {
        color: #68686E;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }

  }

  // related videos section
  .related-videos {

    &.mini-course-videos {
      border-top: 1px solid #373c3e;
    }

    .mini-course-header {
      text-align: center;
      font-size: 14px;
      color: #fff;
      padding: 15px 15px;

      .mini-course-subtitle {
        color: #b7a475;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        padding: 0;
        margin: 0;
      }

      .mini-course-title {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
      }

      .mini-course-video-count {
        color: #68686e;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
        padding: 0;
      }
    }

    ul {
      border-top: 1px solid #373c3e;
      padding: 0;
    }

    li {
      border-bottom: 1px solid #373c3e;
    }

    a {
      display: block;
      padding: 20px 10px 20px 60px;
      position: relative;
      .transition(background, 0.2s, linear);

      &:hover {
        .transition(none);
        background: #272b2c;
        text-decoration: none;
      }
    }

    h3 {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }

    .details {
      color: #687275;
      font-size: 11px;
    }

    .icon-play {
      border: 1px solid #fff;
      .border-radius(10px);
      height: 20px;
      width: 20px;
      padding: 5px 4px 5px 6px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 30px;
    }

    .more {
      padding: 30px 0;
      text-align: center;

      .btn {
        background: #2e3233;
        border: none;
        color: #fff;
        display: inline-block;
        padding: 10px 55px;

        &:hover {
          background: #383d3e;
        }
      }
    }

    &.elliot-roe-card {
      height: 227px;
      position: relative;
      top: -1px;
      background: #1b1e1f;
      background-image: url("~img/courses/related-videos-a-game-filler.jpg");
      background-size: cover;

      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &:hover {
          background: none;
        }
      }
    }

    &.vision-card {
      height: 227px;
      position: relative;
      top: -1px;
      background: #1b1e1f;
      background-image: url("~img/courses/vision_video_banner.jpg");
      background-size: cover;

      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &:hover {
          background: none;
        }
      }
    }
  }

}


// Mobile phones
@media (max-width: @widthMobileMax) {

  section.thread-detail > div > article.provideo-detail {

    .information {
      width: 80%;
      padding: 4%;

      .back-to-col_1-footer {
        margin-bottom: 5px;
        background-image: url(~img/feeds/arrow.svg);
        width: 20px;
        height: 12px;
        background-repeat: no-repeat;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 20px;
          height: auto;
        }
      }

      header {
        p {
          color: #7e8689;
          padding: 0;
        }
      }

      h1 {
        font-size: 18px;
        font-family: @fontHeading;
        font-weight: 500;
        line-height: 1.2em;
        margin-bottom: 10px;
      }
    }

    .thread-likes {
      top: 220px;
      position: absolute;
    }

    .author-information {
      left: 0;
      bottom: 14px;
      display: block;

      > div {
        display: inline-block;
      }

      > .name-level {
        border-radius: 2px;
        margin-left: 2px;
        padding: 2px 8px;
        background: #b7a475;

        h3 {
          font-size: 11px;
          color: #fff;
        }
      }

      > .author-pro_videos-total {
        display: none;
      }

      .avatar {
        position: relative;
        left: 0;
        top: 6px;
      }
    }

     .quiz-section {
        padding-top: 10%;

       header {
         h2 {
           font-size: 18px;
         }
       }

       .quiz-section-content {
         margin: 0 10%;
       }
     }

  }

  section.thread-detail > div > article.provideo-detail .footer {
    padding: 0 0 5em;
    position: relative;
    display: flex;
    flex-direction: column;

    .video-information {
      width: 100%;
      border-right: 0;
    }

    .video-extras {
      width: 100%;
    }

    .downloads_container {
      .outer {
        max-width: 200px;
      }
    }


    .thread-content-toggle {
      padding: 0 0 0 4%;
    }


    .tooltip-menu {
      display: inline-block;
    }

    .video-content {
      clear: both;
      padding: 0 5% !important;
    }

    .posted-date-dash {
      padding: 0 5%;
    }

  }


}
