.hand-content {
  font-size: 15px;

  .section {
    .border-radius(7px);
    background: #f5f7f8;
    left: -2%;
    margin: 1% 0;
    overflow: hidden;
    position: relative;
    width: 104%;
  }

  .street-text {
    font-size: 14px;
    line-height: 1.6;
    font-weight: 300;
    word-wrap: break-word;
    padding: 10px 0;
  }

  .game-title {
    display: block;
  }

  .game-blinds {
    font-weight: bold;
  }

  .title-block,
  .street {
    font-size: 14px;
    padding: 8px 13px;
  }

  .street {
    padding: 12px 114px 12px 15px;
  }

  .street.border {
    border-top: 1px solid #d4d3d3;
    padding-top: 10px;
  }

  .street-title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #1d2020;
  }

  .street-money {
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;
    vertical-align: middle;
    color: #5f5f5f;
  }

  .street-players {
    display: inline-block;
    font-size: 12px;
    font-style: italic;
    margin-right: 10px;
    vertical-align: middle;
    color: #5f5f5f;
  }

  .card-value {
    background-position: 12px 4px;
    background-repeat: no-repeat;
    background-size: auto 58%;
    color: #333;
    display: inline-block;
    font-size: 14px;
    margin: -3px 2px 2px;
    padding-right: 17px;
    font-weight: 500;
    min-width: 12px;

    &.heart {
      background-image: url(~img/partials/hand_history-detail/heart.png);
      padding-right: 18px;
    }

    &.diamond {
      background-image: url(~img/partials/hand_history-detail/diamond.png);
      padding-right: 13px;
    }

    &.spade {
      background-image: url(~img/partials/hand_history-detail/spade.png);
    }

    &.club {
      background-image: url(~img/partials/hand_history-detail/club.png);
    }
  }

  .street-action {
    color: #5f5f5f;
  }

  .street-action.primary {
    color: @colorLink;

    .seat-name {
      color: @colorLink;
      font-weight: bold;
    }
  }

  .seat-name {
    color: #5f5f5f;
  }

  .seat-name.primary {
    color: @colorLink;
    font-weight: bold;
  }

  .game-rake {
    color: #5f5f5f;
    display: block;
  }
}

