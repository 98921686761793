.dashboard-main::-webkit-scrollbar {

}

.dashboard-main {
  background-color: black;
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;

  .feed-main-header {
    margin: 40px 10%;
    font-family: @fontNowayMedium;

    .top-line {
      position: relative;

      .username {
        font-size: 26px;
        color: white;
        font-weight: bold;
      }

      .points {
        font-size: 16px;
        color: white;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .bottom-line {
      position: relative;

      .badge {
        width: 30px;
        height: auto;
        vertical-align: middle;

        &.essential {
          margin-left: -7px;
        }

        &.elite {
          margin-right: 5px;
        }
      }

      .account-type {
        color: #76808a;
        font-size: 14px;
        letter-spacing: 0.1em;
      }

      .point-link {
        float: right;
        color: #76808a;
        text-decoration: none;
      }
    }
  }

  hr {
    margin: 0px 10%;
    color: #76808a;
  }

  .recommended-tag-container {
    position: relative;
    margin-left: 10%;
    margin-top: 30px;
    margin-right: 5%;

    .category {
      color: #76808a;
      font-family: @fontNowayMedium;
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 500;
    }

    .tag-list-tag {
      display: inline-block;
      list-style: none;
      margin: 5px 5px 0 0;
      line-height: 3em;

      &.active {
        a {
          background: #F1F1F1 !important;
          color: #111 !important;
        }
      }

      a {
        padding: 8px 20px;
        border: 1px solid #333333;
        border-radius: 20px;
        background: #1b1e1f;
        color: #fff;

        &:hover {
          background: #313639;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }


  .feed-video-display,
  .video-list-section-selected-tag {
    margin-bottom: 40px;

    .tagged-videos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //justify-content: space-between;
      gap: 20px;
    }

    .vid-tagged-container {
      .vid-container {
        width: 330px;
        padding-right: 0 !important;
      }

      .pagination-infinite {
        width: 100%;
        margin: 40px auto;
      }
    }

    .vid-slider-container,
    .vid-tagged-container {
      position: relative;
      margin-left: 10%;
      margin-top: 30px;
      margin-right: 5%;

      .category {
        margin-bottom: 15px;
        font-family: @fontNowayMedium;
        font-size: 18px;
        color: #76808a;
        display: block;
      }

      .vid-slider::-webkit-scrollbar { /* WebKit */
        /* hide scroll bar */
        width: 0;
        height: 0;
      }

      .vid-slider {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
      }

      #upcoming-videos.vid-slider {
        .corner-banner {
          font-size: 9px;
          line-height: 1.2em;
        }

        .video-main {
          &:hover {
            cursor: default !important;
          }
        }
      }

      .vid-container {
        padding-right: 20px;
        max-width: 350px;

        .video-main {
          width: 330px;
          position: relative;
          height: 200px;
          overflow: hidden;

          &:hover {
            cursor: pointer;
            -webkit-filter: brightness(80%);
            transition: all 1s ease;

            .play-button {
              display: block;
            }
          }

          a {
            display: block;
            height: 200px;
            overflow: hidden;
          }

          .corner-banner {
            color: #fff;
            font-family: @fontDefault;
            font-size: 14px;
            font-weight: 700;
            padding: 5px 0;
            text-align: center;
            text-transform: uppercase;
            background: #e33923;
            position: absolute;
            left: -25px;
            top: 9px;
            transform: rotate(-45deg);
            width: 100px;
            height: 30px;
            letter-spacing: 2px;
          }

          .author-avatar {
            width: 100%;
          }

          .play-button {
            display: none;
            height: 56px;
            width: 56px;
            position: absolute;
            top: 77px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;

            svg.circles {
              height: 56px;
              width: 56px;

              .bg {
                stroke: #cdd0d4;
              }

              .fg {
                stroke: #c40013;
                -webkit-animation: coursesloadmodule 0.5s ease-out;
                -moz-animation: coursesloadmodule 0.5s ease-out;
                -o-animation: coursesloadmodule 0.5s ease-out;
                animation: coursesloadmodule 0.5s ease-out;
              }
            }

            &.seconds-0 {
              svg.circles {
                .bg {
                  stroke: white;
                }
              }
            }

            .icon-play {
              height: 15px !important;
              width: 15px !important;
              top: 13px !important;
              left: 13px !important;


              polygon {
                fill: white;
              }
            }
          }
        }

        .video-footer {
          margin-top: 10px;
          font-family: @fontNowayMedium;

          .author-name {
            color: #76808a;
            font-size: 14px;

            .game-type {
              color: #b5a67a;
              margin-left: 5px;
            }
          }

          .video-title {
            color: white;
            font-size: 18px;
          }

          .release-date {
            font-family: @fontNowayRegular;
            color: #a6a6a6;
            font-size: 11px;
          }
        }
      }

      // Don't show low videos box on recently viewed row.
      #recently-viewed .low-on-videos-item {
        display: none !important;
      }

      .low-on-videos-container {
        width: 330px;
        height: 200px;
        background-color: #1f2021;
        font-family: @fontNowayMedium;
        text-align: center;
        padding: 40px;

        p {
          font-size: 18px;
          color: white;
          padding-bottom: 20px;
          margin-bottom: 15px;
        }

        a {
          font-size: 14px;
          color: white;
          padding: 8px 20px;
          border: 1px solid #515557;
          border-radius: 5px;
        }
      }

      .arrow-container-left, .arrow-container-right {
        height: 200px;
        width: 40px;
        top: 42px;
        position: absolute;

        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
        }
      }

      .arrow-container-left {
        left: -40px;

        .left-button {
          height: 37px;
          width: auto;
          position: absolute;
          left: 7px;
          top: 83px;
        }
      }

      .arrow-container-right {
        right: -40px;

        .right-button {
          height: 37px;
          width: auto;
          position: absolute;
          right: 7px;
          top: 83px;
        }
      }

      .left-button, .right-button {
        &:hover {
          cursor: pointer;
          height: 40px;
          width: auto;
        }
      }
    }
  }

  &.dim-dash {
    filter: brightness(30%);
    z-index: -10;
  }

  hr {
    border-color: #76808a;
  }
}

@media (max-width: @widthMobileMax) {
  .dashboard-main {
    .video-main {
      width: 42vw !important;
      height: 24vw !important;
    }

    .low-on-videos-container {
      width: 42vw !important;
      height: 24vw !important;
      padding: 3vw !important;

      p {
        font-size: ~"calc(10px + 8 * ((100vw - 400px) / 367))" !important;
        color: white !important;
        padding-bottom: 3vw !important;
        margin-bottom: 0 !important;
      }

      a {
        font-size: ~"calc(10px + 4 * ((100vw - 400px) / 367))" !important;
        color: white !important;
        padding: 2px 5px !important;
        border: 1px solid #515557 !important;
        border-radius: 5px !important;
      }
    }

    .vid-slider-container {
      margin-left: 5% !important;
    }

    .arrow-container-left, .arrow-container-right {
      height: 24vw !important;
      width: 30px !important;

      .left-button, .right-button {
        height: 20px !important;
        top: ~"calc(12vw - 10px)" !important;

        &:hover {
          height: 25px !important;
        }
      }
    }

    .arrow-container-left {
      left: -30px !important;
    }

    .arrow-container-right {
      right: -30px !important;
    }

    .play-button {
      top: ~"calc(12vw - 25px)" !important;
    }
  }
}
