@keyframes bonuscode-cta {
  0% { box-shadow: 0 0 0 -25px rgba(0,0,0,0.75) inset; }
  50% { box-shadow: 0 0 100px -25px rgba(0,0,0,0.75) inset; }
  100% { box-shadow: 0 0 0 -25px rgba(0,0,0,0.75) inset; }
}

#bonus-code {
  margin: 10px 0;
  padding: 0 5%;

  .invalid {
    display: none;
    width: 70%;
    margin: 0 auto;
    text-align: left;
    padding-left: 5px;
    color: #a70106;
  }

  form {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    align-items: stretch;
    width: 85%;
    margin: 0 auto;

    input {
      display: inline-block;
      .transition(.15s);
    }

    input.bonus-code__input {
      width: 60%;
      background-color: #f9fafb;
      border: 1px solid #d4d7db;
      border-radius: 5px;
      color: #222;
      height: 45px;
      font-family: @fontOpenSans;
      font-size: 14px;
      font-weight: 400;
      -webkit-appearance: none;
    }

    input.bonus-code__submit {
      width: 35%;
      //background-color: #757779;
      color: #fff;
      height: 45px;
      border: none;
      appearance: none;
      -webkit-appearance: none;
      border-radius: 5px;
      font-family: @fontHeading;
      font-size: 15px;
      font-weight: 500;
      background: #db2a13;
      animation: bonuscode-cta 6s linear infinite;

      &:hover {
        cursor: pointer;
      }

      &.inactive {
        background-color: #d0d1d2;
        pointer-events: none;
      }

    }

  }

  &.unavailable-code {
    .invalid {
      display: block;
    }

    form {
      input.bonus-code__input {
        border-color: #a70106;
      }
    }
  }
}

.signup-bonus-code {
  .receipt-section {
    font-family: @fontHeading;
    padding: 12px 0;
    font-size: 15px;
    color: #6e6e6e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d9d9d9;

    &:first-child {
      border-top: none;
    }

    .code {
      color: #000;
      font-weight: 500;
    }

    .price {
      color: #000;
      font-weight: 500;
    }

    &.total {
      color: #000;
      font-size: 17px;
      font-weight: 500;

      .total-amount {
        font-size: 17px;
        font-weight: 600;

      }
    }
  }
}
