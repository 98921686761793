body.live-seminars-page {

  //#body_container {
  //  overflow: hidden;
  //}

  .rio-modal {
    .content {
      width: 90vw;
      // let's say all our videos will be 16:9 ratio;
      height: calc(9 / 16 * 90vw);
      max-width: 900px;
      max-height: calc(9 / 16 * 900px);
      //top: 42%;
      .close-message {
        &:hover {
        cursor: pointer;
        }
      }
    }
  }

  .blocked-container {
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .blocked-content {
      text-align: center;
      width: 500px;
      color: white;
      position: absolute;
      top: 35vh;
      left: 50vw;
      margin-left: -250px;

      h1 {
        font-family: @fontNowayMedium;
        font-size: 50px;
        color: white;
      }

      p {
        font-family: @fontOpenSans;
        font-size: 22px;
        line-height: 1.6;
        margin-bottom: 20px;
      }

      a {
        color: white;
        background-color: #b7261d;
        font-size: 16px;
        font-family: @fontOpenSans;
        padding: 10px 40px;
        border-radius: 25px;
        letter-spacing: .05em;
        font-weight: 500;
        &:hover {
          text-decoration: none;
        }
      }
    }

  .page-wrapper {
    max-width: 1340px;
    width:100%;
    min-height: calc(100vh - @navHeight - 320px);
    margin: 70px auto;
  }

  .title-container {
    margin-top: 50px;
    font-family: @fontNowayMedium;
    font-size: 62px;
    color: white;
    background-color: black;
    line-height: 1.28;
    text-align: center;
    width: 100%;
    background-image: url(~img/visions/plo/plo_BG.jpg);
    padding: 100px 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .text-container {
    text-align: left;
    color: #54586c;
    font-size: 20px;
    font-family: @fontOpenSans;
    width: 100%;
    line-height: 1.6;
    margin: 0 auto 40px;
    max-width: 900px;

    p {
      padding-bottom: 20px;
      margin: 0 20px;
    }
  }

  .category-header {
    font-size: 32px;
    font-weight: 700;
    margin: 2em 20px 1em;
    color: #000;
  }

  .flex-containter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    margin: 0 10px 70px;

    @media (max-width: @widthTabletMax) {
      flex-direction: column;
      align-items: center;
    }
  }

  .call-module {
    width: 33%;
    padding: 0 15px;
    min-width: 300px;
    margin-bottom: 40px;

    @media (max-width: @widthTabletMax) {
      width: 50%;
    }

    &.blocked {
      z-index: -1;
    }
    .image-wrapper {
      width: 100%;
      padding-top: 70%;
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0 4px 5px rgba(0,0,0,.15);
      text-align: center;
      &:hover {
        cursor: pointer;
        box-shadow: 15px 20px 9px rgba(0, 0, 0, 0.15);
        transform: scale(1.02);
      }
    }
    .author-avatar {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .play-btn {
      position: absolute;
      top: 50%;
      margin-top: -40px;
      margin-left: -40px;
    }

    .bottom-container {
      text-align: left;
      .title {
        font-size: 18px;
        font-family: @fontNowayMedium;
        line-height: 1.3;
        margin-top: 10px;
      }
      .date {
        font-family: @fontOpenSans;
        color: #979ca4;
        font-size: 13px;
      }
    }
  }

  @media (max-width: @widthMobileMax) {
    .blocked-content {
      width: 380px;
      margin-left: -190px;

      h1 {
        font-size: 38px;
      }
      p {
        font-size: 16px;
      }
    }
    .title-container:not(.annual-elite-hangouts) {
      font-size: 40px;
      padding: 40px 0;
    }

    .title-container.annual-elite-hangouts {
      min-height: calc(100vw / 2);
      background-size: cover;
    }

    .text-container {
      font-size: 16px;
      margin: 40px 0;
      @media (max-width: 450px) {
        font-size: 14px;
      }
    }

    .flex-containter{
      margin: 0 10px 30px;
    }
  }

}
