// A generic button that indicates a call to action.
//
// :hover             - Darkened border and icon change out.
// .disabled          - Dims the button to indicate it cannot be used.
//
.btn {
  font-family: @fontDefault;
  .border-radius(20px);
  .transition(all, 0.1s, ease-in-out);
  background-color: transparent;
  border: 1px solid #c0c7ca;
  color: #6a6f71;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 32px;

  &:hover {
    .transition(none);
    text-decoration: none;
    border-color: #aaa;
  }

  [class^="icon-"], [class*=" icon-"] {
    height: 23px;
    margin-right: 4px;
    position: relative;
    top: 6px;
    width: 23px;
  }

  &.contains_icon {
    padding: 8px 39px 14px;
  }

  &.btn-small {
    font-size: 11px;
    padding: 6px 16px;
  }


  // Non-pill, square buttons.
  &.btn-square {
    .border-radius(3px);
    background: #fff;
    border: 1px solid #b2babe;
    padding: 9px 30px;
    text-align: center;

    &:hover {
      border-color: #999;
    }
  }

  &.btn-small {
    font-size: 11px;
    padding: 6px 16px;
  }

  &.btn-large {
    padding: 12px 20px 12px 20px;
  }

  &.btn-gray {
    background: #bac1c4;
    color: #fff;

    &:hover {
      background: #B3BABE;
    }
  }

  &.btn-dark-gray {
    background: #656b72;
    border: none;
    color: #fff;

    &:hover {
      background: #6e757c;
    }
  }

  &.btn-black {
    background: #353535;
    border: none;
    color: #c2c2c2;

    &:hover {
      background: #3f3f3f;
    }
  }

  &.btn-red {
    background: #90171c;
    border: none;
    color: #fff;
    .border-radius(3px);

    &:hover {
      background: #a2403f;
    }
  }

  &.btn-popup {
    font-family: @fontOpenSans;
    font-size: 0.9em;
    font-weight: 400;
    color: @colorDarkText;

    &:hover {
      opacity: 0.9;
    }

    &.btn-contrast {
      color: #ffffff;
      background: @colorDarkText;
    }

    &.btn-disabled {
      color: #a5a5a5;
      background-color: #f1f4f6;
      pointer-events: none;
    }
  }

  &.btn-poppins {
    font-size: 1em;
    line-height: 1.2em;
    font-family: @fontPoppins;
    font-weight: 400;

    border: none;
    .border-radius(6px);
    padding: 8px 40px;

    color: #1B1B1B;
    background: #fff;

    &:hover {
      text-decoration: none;
      opacity: .9;
    }

    &.inverted {
      background: #1B1B1B;
      color: #fff;
    }
  }
}
