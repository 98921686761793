body.search_results {

  // form containers
  .search_form {
    padding: 0 50px;
  }

  // Header mast
  .mast .span12 {
    background: #f3f5f6;

    .search_form {
      padding: 25px 50px;

      input {
        font-family: @fontDefault;
        margin: 0;
        position: relative;
        // top: -3px;
        font-size: 21px;
      }

      .submit {
        .border-radius(0 5px 5px 0);
        background: #9aa1a4 url('~img/components/icon/search_hover.svg') no-repeat 25px 10px;
        background-size: 18px auto;
        border: none;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        padding: 0;
        margin: 0;
        width: 70px;
        height: 38px;
        position: relative;
        left: -10px;
        cursor: pointer;
        @media (max-width: 398px) {
          margin-top: 10px;
          margin-left: 10px;
        }
      }

    }

    h1 {
      font-family: @fontDefault;
      font-size: 22px;
      padding: 0;
      color: #1f2122;
    }
  }

  // Form controls
  .controls {

    .search_form {
      border-bottom: 2px solid #e3e6e7;
      margin: 45px 50px 50px;
      padding: 0;
      padding-bottom: 15px;
    }


    ul {
      list-style: none;
    }

    li {
      display: inline-block;
      font-size: 14px;
      padding: 0 40px 0 0;

      a {
        color: #878e8e;
      }

      &.selected {
        a {
          border-bottom: 1px solid #1b1e1f;
          color: #1b1e1f;
          text-decoration: none;
        }
      }
    }

    select {
      color: @colorDefault;
      font-size: 12px;
      border: 1px solid #c0c7ca;
      background: #fff url('~img/components/form_field/select_bg.png') no-repeat 95% 50%;
      background-size: 8px auto;
      width: 170px;
      margin: 0 15px 10px 0;
      padding: 8px 20px 8px 10px;
      -webkit-appearance: none;
    }
  }


  // result containers
  .search_results {
    .clearfix();
    padding: 0 50px 100px;
    min-height: 400px;

    h3  {
      font-family: @fontDefault;
      font-size: 22px;
      padding: 0;
      color: #1f2122;
    }

    table {
      margin-top: 10px;

      // td {
        // border: none;

        // &:first-child {
        //   padding-left: 0;
        // }

        // &:last-child {
        //   padding-right: 0;
        // }
      // }
    }

    .game_type {
      text-align: left;
    }

    .avatar {
      float: left;
    }

    .avatar img {
      .border-radius(55px);
      margin-right: 20px;
    }

    .info {
      float: left;
      padding-top: 12px;
    }

    .profileprofile {
      td {
        border-left: none;
        border-right: none;
      }
    }

    .profile_data {
      line-height: 1.2em;

      a {
        color: #999;
      }

      .display_name {
        color: #1b1e1f;
        font-size: 14px;
        font-weight: 700;
      }

      .username {
        font-size: 12px;
      }
    }

    .points {
      font-size: 11px;
      padding: 0;
    }

    .follow-cell {
      text-align: right;
    }
  }

  .highlighted {
      background: #fcffa8;
  }

  .pagination > div {
    text-align: center;
    margin: 0 auto;
    padding: 30px 0;
  }

}
