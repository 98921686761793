#site_message .countdown {
  font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0 auto;
  color: #fff;
  line-height: 1.2;
  visibility: hidden;
  display: inline-block;
  position: relative;
  top: -1px;

  span {
    position: relative;
    display: inline-block;
    top: 1px;
  }
}

body.pro-vs-pro {
  #torso > section {

    .container {
      margin: 0 auto;
      max-width: 1660px;
    }

    &.intro {
      text-align: center;

      .container {
        background: #fff url(~img/video/pro-vs-pro/intro-texture.jpg) no-repeat center 430px;
        background-size: 100% auto;

        > h4 {
          border-bottom: 1px solid #d6dde0;
          color: #a5a9ab;
          display: inline-block;
          font-family: @fontHeading;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 6px;
          text-transform: uppercase;
          margin: 0 auto;
          padding: 48px 16px 18px;
        }

        > h1 {
          color: #1f272a;
          font-family: @fontHeading;
          font-size: 75px;
          font-weight: 700;
          text-transform: uppercase;

          span {
            color: #7a8183;
            font-size: 20px;
            position: relative;
            top: -18px;
          }
        }

        > h5 {
          color: #383f41;
          font-family: @fontHeading;
          font-size: 13px;
          font-weight: 500;
          padding-bottom: 60px;
          letter-spacing: 6px;
          text-transform: uppercase;
        }
      }

      .video-controller {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .video-container {
        background: transparent url(~img/video/pro-vs-pro/video-container-overlay.jpg) no-repeat left top;
        margin: 0 auto;
        position: relative;
        max-width: 992px;
        width: 80%;

        section.video_player {
          height: 100%;
        }

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        img {
          display: block;
          width: 100%;
        }

        .timer {
          position: absolute;
          top: 38%;
          left: 0;
          width: 100%;
          z-index: 1000;

          .live_in {
            color: #fff;
            font-family: @fontHeading;
            font-size: 11px;
            font-weight: 500;
            margin: 0 auto;
            text-transform: uppercase;
          }

          .countdown {
            font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
            font-size: 28px;
            font-weight: 400;
            margin: 0 auto;
            color: #fff;
            line-height: 1.2;

            span {
              position: relative;
              display: inline-block;
              top: 2px;
            }

            .seconds {
              color: #a81313;
              font-size: 31px;
              top: 3px;
            }
          }
        }

        .video-trigger-area {
          background: transparent url(~img/video/pro-vs-pro/video-trigger.png) no-repeat left top;
          background-size: 100% 100%;
          width: 220px;
          height: 220px;
          position: absolute;
          top: 28%;
          margin: 0 auto;
          left: 39%;
        }

        .play {
          position: absolute;
          left: 46.25%;
          top: 44%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 0 15px 25px;
          border-color: transparent transparent transparent #ffffff;
        }

        .live {
          color: #bfc4c5;
          font-family: Garamond, Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
          font-style: italic;
          font-size: 18px;
          left: -62px;
          top: -16px;
          text-transform: uppercase;
          position: absolute;

          &:after {
            content: ' ';
            position: absolute;
            width: 1px;
            height: 38px;
            background: #bfc4c5;
            right: -21px;
            .transform(rotate(38deg));
            top: 1px;
          }
        }
      }

      .about {
        background: #101111;
        margin-top: 180px;
        padding-top: 30px;

        h2 {
          color: #ffffff;
          font-family: @fontHeading;
          font-size: 22px;
          font-weight: 400;
          max-width: 230px;
          margin: 0 auto;
        }

        p {
          color: #999;
          font-size: 14px;
          line-height: 1.6;
          margin: 0 auto;
          max-width: 500px;
          padding: 40px 0 10px;
        }

        .transition {
          background: #101111;
          height: 200px;
          display: inline-block;
          width: 100%;

          div {
            background: #282a2a;
            width: 2px;
            margin: 40px auto 0;
            height: 160px;
            top: 0;
          }
        }
      }
    }

    &.pros {
      background: #fff;

      .container {
        background: transparent url(~img/video/pro-vs-pro/pros-bg.jpg) no-repeat center top;
        background-size: auto 100%;
      }

      // http://css-tricks.com/almanac/properties/b/backface-visibility/
      h2 {
        color: #1f272a;
        font-family: @fontHeading;
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        padding: 94px 0;
        text-align: center;
      }

      ul {
        margin: 0 auto;
        position: relative;
        width: 1240px;

        &.top {
          left: 70px;
        }

        &.bottom {
          position: relative;
          top: -70px;
          left: -50px;
          padding-bottom: 68px;
        }
      }

      /* simple */
      li {
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;

        -ms-transform: perspective(1000px);
        -moz-transform: perspective(1000px);
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;

        display: inline-block;
        margin: 0 35px;
        position: relative;
      }

      /* START: Accommodating for IE */
      li:hover .back, li.hover .back {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }

      li:hover .front, li.hover .front {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }

      /* END: Accommodating for IE */

      li, .front, .back {
        width: 170px;
        height: 170px;
      }

      .flipper {
        -webkit-transition: 0.4s;
        -webkit-transform-style: preserve-3d;
        -ms-transition: 0.4s;

        -moz-transition: 0.5s;
        -moz-transform: perspective(1000px);
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;

        transition: 0.4s;
        transform-style: preserve-3d;

        position: relative;

        &.jens_kyllnen {
          .front img {
            left: -75%;
            top: -45%;
          }
        }
      }

      .rotation {
        .transform(rotate(-45deg));
        backface-visibility: hidden;
        border: 1px solid #fff;
        width: 120px;
        height: 120px;
        overflow: hidden;
        top: 50%;
        left: 0;
        position: relative;
      }

      .rotate-back {
        .transform(rotate(45deg));
      }

      .front, .back {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;

        -webkit-transition: 0.6s;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: rotateY(0deg);

        -moz-transition: 0.6s;
        -moz-transform-style: preserve-3d;
        -moz-transform: rotateY(0deg);

        -o-transition: 0.6s;
        -o-transform-style: preserve-3d;
        -o-transform: rotateY(0deg);

        -ms-transition: 0.6s;
        -ms-transform-style: preserve-3d;
        -ms-transform: rotateY(0deg);

        transition: 0.6s;
        transform-style: preserve-3d;
        transform: rotateY(0deg);

        position: absolute;
        top: 0;
        left: 0;
      }

      .front {
        -webkit-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        z-index: 2;

        img {
          height: 248px;
          position: relative;
          left: -60%;
          top: -32%;
          display: block;
        }
      }

      .back {
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
        -o-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        transform: rotateY(-180deg);

        .backside-image {
          .transform(rotate(45deg) scaleX(-1));
          height: 248px;
          position: relative;
          left: 175%;
          top: 75%;
          display: block;
          z-index: 2;
        }

        .rotate-back {
          background: rgba(31, 39, 42, 0.8);
          width: 170px;
          height: 170px;
          position: absolute;
          top: -20%;
          left: -20%;
          z-index: 3;
        }

        h3 {
          color: #ffffff;
          padding-top: 40%;
          font-weight: 500;
          font-size: 13px;
        }
        h4 {
          color: #747f83;
          font-weight: 400;
          font-size: 9px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        h3, h4 {
          font-family: @fontHeading;
          display: block;
          text-align: center;
        }
      }
    }

    &.past_videos {
      background: #ebebeb;

      .container {
        background: #fff url(~img/video/pro-vs-pro/past-videos-bg.jpg) no-repeat center top;
        padding: 180px 0 130px;
      }

      .video-player-image {
        background: transparent url(~img/video/pro-vs-pro/past-video-container.png) no-repeat center top;
        background-size: 100% auto;
        display: block;
        height: 316px;
        margin: 0 auto;
        position: relative;
        text-decoration: none;
        width: 619px;

        h2 {
          color: #fff;
          font-weight: 700;
          font-size: 20px;
          font-family: @fontHeading;
          position: relative;
          top: 118px;
          text-align: center;
          text-decoration: none;
        }

        .number {
          color: #bfc4c5;
          font-family: Garamond, Baskerville, 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
          font-style: italic;
          font-size: 18px;
          opacity: 0.8;
          left: 223px;
          top: 101px;
          text-transform: uppercase;
          position: absolute;
        }
      }

      p {
        color: #7f858a;
        font-size: 14px;
        line-height: 1.6;
        margin: 0 auto;
        max-width: 460px;
        padding: 10px 0 34px;
        text-align: center;
      }

      .divider {
        height: 90px;
        display: inline-block;
        width: 100%;

        div {
          background: #e0e3e6;
          width: 2px;
          margin: 0 auto;
          height: 90px;
          top: 0;
        }
      }
    }

    &.cta {
      background: #fff url(~img/video/pro-vs-pro/cta-bg.jpg) no-repeat center top;
      background-size: auto 100%;
      min-height: 360px;
      position: relative;

      h2 {
        color: #fff;
        font-family: @fontHeading;
        font-weight: 700;
        font-size: 38px;
        max-width: 650px;
        padding: 110px 0 0 180px;
        text-transform: uppercase;
        line-height: 1.3;
      }

      a {
        background: transparent url(~img/video/pro-vs-pro/cta-link.png);
        background-size: 100% auto;
        position: absolute;
        width: 292px;
        height: 65px;
        left: 955px;
        top: 155px;
        line-height: 65px;
        font-size: 10px;
        color: #4f575a;
        text-align: center;
        font-weight: 400;
        letter-spacing: 2px;
        font-family: @fontHeading;
        text-transform: uppercase;
      }
    }
  }
}


@media (min-width: 1520px) {
  body.pro-vs-pro {
    #torso>section.intro .container {
      background-position: center 380px;
    }
  }
}


@media (min-width: 1438px) {
  body.pro-vs-pro {
    #torso>section.cta {
      background-size: 100% auto;

      a {
        left: 68%;
      }
    }
  }
}


@media (max-width: 1420px) {
  body.pro-vs-pro {
    #torso>section.intro .information {
      background: #101111;
    }
  }
}


@media (min-width: 767px) and (max-width: 1380px) {
  body.pro-vs-pro {
    #torso>section.pros {
      margin-bottom: -120px;

      ul {
        width: 768px;
        padding: 0;

        &.top, &.bottom {
          left: 0;
        }

        li:nth-child(5n + 4), li:nth-child(5n) {
          left: 125px;
          top: -58px;
        }

        &.bottom {
          margin: 0 auto;
          position: relative;
          top: -110px;
        }
      }
    }

    #torso > section.cta {

      h2 {
        font-size: 28px;
        max-width: 420px;
        padding: 60px 0 0 50px;
      }

      a {
        left: 65%;
      }
    }
  }
}


@media (max-width: @widthMobileMax) {
  body.pro-vs-pro {
    #torso > section.intro .container {
      background-position: center 340px;

      .video-container .live {
        display: none;
      }

      .information {
        margin-top: 80px;

        p {
          font-size: 12px;
          width: 80%;
        }
      }
    }

    #torso > section.pros {
      margin-bottom: -200px;

      ul {
        padding: 0;
        width: 400px;

        &.top {
          left: auto;
        }

        li {
          margin: 0 10px;
        }

        li:nth-child(3n) {
          margin: 0 27%;
          top: -78px;
        }

        li:nth-child(5n + 4), li:nth-child(5n) {
          top: -157px;
        }

        &.bottom {
          padding-bottom: 0;
          margin: 0 auto;
          position: relative;
          top: -110px;
          left: 0px;
        }
      }
    }

    #torso > section.past_videos {
      .container {
        padding-bottom: 80px;
      }

      .video-player-image {
        background-size: 100% auto;
        height: 223.65px;
        width: 389.55px;

        h2 {
          font-size: 16px;
          top: 82px;
        }

        .number {
          font-size: 14px;
          left: 121px;
          top: 70px;
        }
      }

      p {
        font-size: 12px;
        width: 80%;
      }
    }

    #torso > section.cta {
      background-position: left top;
      background-size: auto auto;

      h2 {
        font-size: 28px;
        max-width: 340px;
        padding: 60px 0 0;
        margin: 0 auto;
      }

      a {
        left: auto;
        right: 0;
        top: 245px;
      }
    }
  }
}
