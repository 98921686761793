#sign-up-cta-footer-banner {
  background: #454c4e;
  bottom: 0;
  color: #fff;
  padding: 30px 0;
  position: fixed;
  width: calc(@widthDesktopMax + 10px);
  z-index: 1100;

  left: 50%;
  transform: translateX(-50%);

  .inner {
    margin: 0 10%;
    position: relative;
  }

  .videos {
    .retina-background-image('~img/components/sign-up-cta-footer-banner/videos','png', no-repeat, 262px, auto, 0, 0);
    height: 108px;
    left: -30px;
    position: absolute;
    top: -40px;
    width: 262px;
  }

  .content {
    padding-left: 260px;
    width: 80%;
  }

  h3 {
    font-size: 21px;
    text-transform: uppercase;
  }

  p {
    color: #bcbdbd;
    font-size: 14px;
    padding: 0;
  }

  .btn {
    background: none;
    border-color: #7d8283;
    position: absolute;
    right: 0;
    top: 10px;

    &:hover {
      background: #50595b;
    }
  }

  &.stuck {
    position: absolute;
    bottom: 352px;
  }
}


// If the browser size is smaller, make the torso full width.
@media (max-width: @widthDesktopMax) {
  #sign-up-cta-footer-banner {
    width: 100%;

    &:after {
      display: none;
    }
  }
}

@media (max-width: @widthTabletPortrait) {
  #sign-up-cta-footer-banner {
    &.stuck {
      bottom: 510px;
    }
  }
}


// Hide on mobile phones
@media (max-width: @widthMobileMax) {
  #sign-up-cta-footer-banner {
    display: none;
  }
}


// If it's larger than a phone...
@media (min-width: @widthMobileMax) {
  // Add some padding to make room for the floating banner thing.
  body#meet_the_pros.not-logged-in #torso,
  body.thread-detail.provideo.not-logged-in #torso {
    padding-bottom: 110px;
  }
}
