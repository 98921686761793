// COMMON less configurations. (lowest common less level on the site)
// These should go before reset-html-elements.less, mixins.less or
// whatever else global that can be imported into our .less bundles

// Colors
@colorBorder: #c0c7ca; // the lighter gray color used in borders
@colorDefault: #616262; // the mid gray color used in default text
@colorLink: #a81313; // the red color used in links
@colorHeavyText: #535353;
@colorDarkText: #1E1F21;

// Fonts
@fontDefault: 'Open Sans', Helvetica, Arial, sans-serif;
// This is for compatibility with legacy styles. Can be removed once legacy styles are removed.
@fontHeading: 'nowaymedium', Verdana, Tahoma, sans-serif;

// Media query widths
@widthMobilePortrait: 384px;
@widthMobileMax: 767px;
@widthTabletMin: 768px;
@widthTabletPortrait: 960px;
@widthTabletMax: 1024px;
@widthLaptopMin: 1025px;
@widthLaptopMax: 1154px;
@widthDesktopMin: 1155px;
@widthDesktopMax: 1340px;
@widthMonitorMin: 1341px;

@widthMobileNavBreakpoint: 870px;


@navHeight: 50px;
@navBackground: #0e1217;
@colorLinkNav: #fff;
@colorLinkNavUnFocused: #7f858c;
@colorLinkHoverNav: #ffffff;

@navDropdownHorizontalHeight: @navHeight + 0.4 * @navHeight;
@navDropdownHorizontalBackground: #191f28;
@navDropdownHrefColor: #a4abae;

@navDropdownSectionHeight: 50px;
@navDropdownVerticalBackground: rgba(44, 50, 58, .95);
@navDropdownLinkColor: @colorLinkNav;


.font-face(@font-family, @filepath, @font-weight, @font-style){
    @font-face {
        font-family: @font-family;
        src: url('~fonts/@{filepath}.eot');
        src: url('~fonts/@{filepath}.eot?#iefix') format('embedded-opentype'),
            url('~fonts/@{filepath}.woff2') format('woff2'),
            url('~fonts/@{filepath}.woff') format('woff'),
            url('~fonts/@{filepath}.ttf')  format('truetype'),
            url('~fonts/@{filepath}.svg#svgFontName') format('svg');
        font-weight: @font-weight;
        font-style: @font-style;
        font-display: swap;
    }
}

.font-face('nowaylight', 'noway-light-webfont', normal, normal);
.font-face('nowayregular', 'noway-regular-webfont', normal, normal);
.font-face('nowaymedium', 'noway-medium-webfont', normal, normal);
.font-face('nowaybold', 'noway-bold-webfont', normal, normal);

@fontOpenSans: 'Open Sans', sans-serif;
@fontPoppins: 'Poppins', sans-serif;
@fontNowayRegular: 'nowayregular', Verdana, Tahoma, sans-serif;
@fontNowayLight: 'nowaylight', Verdana, Tahoma, sans-serif;
@fontNowayMedium: 'nowaymedium', Verdana, Tahoma, sans-serif;
@fontNowayBold: 'nowaybold', Verdana, Tahoma, sans-serif;

@fontWeightRegular: 400;
@fontWeightMedium: 500;
@fontWeightSemiBold: 600;
@fontWeightBold: 700;
