#feed {
  .feed-post {
    .border-radius(3px);
    position: relative;
    background: #fff;
    border: 1px solid #e0e0e0;
    margin: 17px 11px 5px 58px;

    // add the arrow to the left using :after and :before
    &:after, &:before {
      right: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #fff;
      border-width: 10px;
      top: 32px;
      margin-top: -10px;
    }
    &:before {
      border-color: rgba(224, 224, 224, 0);
      border-right-color: #e0e0e0;
      border-width: 11px;
      top: 32px;
      margin-top: -11px;
    }

    &:hover .delete_share_container .delete_share {
      display: block;
    }

    .delete_share_form {
      display: none;
    }

    .delete_share_container {
      display: block;
      position: absolute;
      right: 28px;
      z-index: 1060;
      width: 230px;

      .delete_share {
        background: transparent url(~img/profile/trash.png) no-repeat left top;
        cursor: pointer;
        display: none;
        height: 23px;
        position: absolute;
        text-indent: -9999px;
        right: 0;
        top: -1px;
        width: 25px;
      }

      &:hover .delete-confirm,
      &:hover .delete_share {
        display: block;
      }

      .delete-confirm {
        font-size: 12px;
        padding-right: 5px;
        position: absolute;
        right: 25px;
        top: 3px;
        width: 138px;
        z-index: 2000;

        span {
          cursor: pointer;
          display: inline-block;
          padding: 0 3px;

          &:hover {
            text-decoration: underline;
          }

          &.yes {
            color: @colorLink;
          }
        }
      }
    }

    .component-post-like {
      position: absolute;
      right: 15px;
      top: -4;
      z-index: 1000;
    }

    > form {
      margin: 0;
    }

    span.type_title {
      background: transparent url(~img/rio/rio_icons.png) no-repeat -10px -127px;
      float: left;
      height: 20px;
      margin: -1px 5px 8px 0;
      padding-left: 25px;
      font-size: 14px;
      color: #777;
    }

    &.type-hh .type_title {
      background-position: -10px -88px;
    }
    &.type-share .type_title {
      background-position: -10px -292px;
    }
    &.type-post .type_title {
      background-position: -10px -130px;
    }
    &.type-comment .type_title {
      background-position: -10px -8px;
    }

    .primary_post {
      padding: 15px 0 6px;
      position: relative;

      &:hover .delete_status,
      &:hover .delete-confirm {
        display: block;
      }

      .when {
        color: #7a7474;
        font-size: 11px;
        margin: 10px 0 2px 17px;
        font-family: @fontDefault;
      }

      .video_highlights_no_comments {
        margin-bottom: 10px;
      }

      .author-pic {
        .border-radius(2px);
        position: absolute;
        left: -58px;
        top: 12px;

        .user_level {
          background: transparent url(~img/profile/user_level.png) no-repeat right top;
          .border-radius(3px);
          overflow: hidden;
          position: absolute;
          top: 24px;
          left: 30px;
          display: block;
          height: 25px;
          width: 18px;
          background-size: 18px auto;
        }
        .is_pro {
          background-position: 0 -59px;
        }
        .is_mod {
          background-position: 0 0;
        }
        .is_staff {
          background-position: 0 -30px;
        }

        img {
          .border-radius(2px);
          display: block;
          width: 42px;
          height: 42px;
        }
      }

      .delete_status {
        background: transparent url(~img/profile/trash.png) no-repeat left top;
        cursor: pointer;
        display: none;
        height: 23px;
        position: absolute;
        right: -9px;
        text-indent: -9999px;
        top: -16px;
        width: 25px;
        z-index: 1060;
      }

      .delete-confirm {
        span {
          cursor: pointer;
          display: inline-block;
          padding: 0 3px;

          &:hover {
            text-decoration: underline;
          }

          &.yes {
            color: @colorLink;
          }
        }
      }

      .info_container {
        .clearfix();
        margin: 0 17px;
        position: relative;

        &.status .delete-confirm {
          position: absolute;
          right: 26px;
          top: -13px;
        }

        &.status .status {
          float: left;
          clear: both;
        }

        .title,
        .post_body {
          width: 85%
        }

        .post_body {
          line-height: 1.6;
        }

        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .author_link,
          .link_title {
            font-family: @fontHeading;
            font-weight: 500;
          }

          .link_title {
            font-size: 14px;
          }

          .action {
            font-size: 12px;
            color: #666;
          }

          .status {
            color: #333333;
            margin-left: -7px;
            margin-bottom: 4px;
            padding: 1px 7px 0 7px;
            width: 100%;
            word-wrap: break-word;
          }
        }

        .author_link {
          display: inline-block;

        }

        .post_body {
          overflow: hidden;


          &:last-child br {
            display: none;
          }
        }

        .expand {
          background: url(~img/profile/expand.png) no-repeat scroll center top transparent;
          font-size: 12px;
          height: 38px;
          margin-bottom: 10px;
          padding-top: 10px;
          position: absolute;
          right: 0;
          text-align: center;
          text-decoration: none;
          top: 100px;
          width: 45px;
        }

        .gradient {
          background: transparent url(~img/profile/expand_gradient.png) no-repeat left top;
          bottom: 0;
          height: 33px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }

      .full_video_link {
        .border-radius(3px);
        color: #eef2f5;
        font-size: 12px;
        margin: 0 0 10px 80px;
        padding: 0 10px;
        text-shadow: 0 -1px 0 rgba(0,0,0,.25);
      }

      .video_highlights_container {
        background: none;
        position: relative;

        .video_highlights_list {
          margin: 3px 133px 0 60px;
        }
      }
    }

    .section_comments_container {
      padding: 0 0 8px;
    }
  }
}


// Mobile support
@media (max-width: @widthMobileMax) {
  body#dashboard, body#profile {
    #torso > .row {
      background: none;
    }

    #neck .profile_info {
      margin-top: 20px;
    }

    #feed {
      .feed-post {
        margin: 15px 0;

        .component-post-like {
          right: 1px;
          bottom: 0px;

          .who.active {
            right: -7px;
            top: -7px;
          }
        }

        .section_comments_container {
          display: none;
        }

        span.type_title {
          display: none;
        }

        .primary_post {
          .info_container {
            margin: 0 8px 15px;

            .expand {
              right: 53px;
              bottom: -65px;
              top: auto;
            }
          }

          .info_container h2 {
            width: 100%;

            .link_title {
              clear: both;
              display: block;
              float: left;
              font-size: 12px !important;
            }
          }

          .info_container .post_body {
            font-size: 12px;
            width: 100%;
          }

          .author-pic {
            display: none;
          }

          .when {
            margin-top: 6px;
            margin-left: 8px;
            font-family: @fontDefault;
          }
        }
      }

      .feed-post:before,
      .feed-post:after {
        display: none;
      }

      .load_more {
        width: 100%;
      }
    }

    .share {
      display: none;
    }

    #content_filters {
      display: none;
    }
  }
}
