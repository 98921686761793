section.video_player {
  .transition(background, 0.1s, linear);
  background: #000;
  height: 586px;
  overflow: hidden;
  position: relative;
  width: 100%;

  // Hide videojs control bar when intro is playing.
  &.intro-playing {
    .vjs-control-bar {
      display: none !important;
    }
  }

  .information {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .information > header {
    float: left;
    margin: 4.5% 0 0 5%;
    position: relative;
    text-align: left;
    z-index: 1020;
    width: 60%;

    > p {
      color: #959595;
      font-weight: 500;
      padding: 0;
    }
  }

  .player-container {
    .transition(opacity, 0.1s, linear);
    cursor: pointer;
    display: block;
    height: 0;
    opacity: 0;
    z-index: 1010;
    width: 100%;

    #rioplayer,
    #video {
      height: 100%;
      width: 100%;
    }
  }

  h1 {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.4;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .author-information {
    display: none;
    float: right;
    margin: 4.5% 14% 0 0;
    position: relative;
    text-decoration: none;
    z-index: 1020;

    > div {
      display: inline-block;
    }

    > .name-level {
      margin-left: 8px;
      position: relative;
      top: 2px;
    }

    h3 {
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.2;
    }

    h4 {
      color: #707070;
      font-size: 11px;
      font-weight: 500;
    }

    > .author-pro_videos-total {
      .border-radius(2px);
      background-color: #313232;
      color: #707070;
      border: none;
      font-weight: 700;
      font-size: 8px;
      margin-right: 2px;
      padding: 10px 10px 6px;
      text-align: center;

      div {
        color: #fff;
        font-size: 13px;
        line-height: 1.1;
      }
    }

    .avatar {
      display: inline-block;
      top: 9px;
    }
  }

  .skip-forwards-backwards-animation {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    .transform(translate(-50%, -50%));
    padding: 10px;
    background: #333;
    .border-radius(50%);

    svg.icon {
      height: 40px;
      width: 40px;
      position: relative;

      path {
        fill: #ddd;
      }
    }

    .seconds-count {
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translate(-50%, -50%));
      color: #ddd;
      font-size: 12px;
      font-weight: 700;
    }

    &.forwards {
      svg.icon {
        .transform(translateX(40px) rotateY(-180deg));
      }
    }

    &.forwards,
    &.backwards {
      display: block;
      .animation(glow-grow-in, 0.5s, 0, linear);
    }
  }

  .play-restart,
  .trigger-wrapper {
    height: 100%;
    left: 0;
    opacity: 0;
    display: none;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 1020;
    width: 100%;

    // login/signup notice
    .login-notice {
      color: #fff;
      font-size: 24px;
      width: 100%;

      .btn {
        display: inline-block;
        margin: 10px 5px;
        font-family: @fontDefault;

        h4 {
          font-family: @fontDefault;
        }
      }
    }

    .btn-resume {
      position: relative;
    }

    .btn {
      background: #4b4e4f;
      padding: 10px 0;
      display: block;
      width: 140px;
      border: none;
      margin: 0 auto 10px;
      font-family: @fontDefault;

      h4 {
        font-family: @fontDefault;
      }

      &:hover {
        background: #3F4243;
        color: #eee;
      }
    }

    // Restart icon
    .restart {
      cursor: pointer;
      width: 114px;
      margin: 0 auto;
    }

    > div {
      .transform(translateY(-50%));
      position: relative;
      top: 50%;
      min-height: 130px;
    }


    .circular-progress-bar svg.circles {
      //box-shadow: 0 0 40px #000000e5;
      //border-radius: 60px;

      .bg {
        fill: rgba(47, 50, 51, .86);
      }
    }

    .circular-progress-bar .icon-play-rotate {
      z-index: 10;
    }

    .circle {
      cursor: pointer;
      border-radius: 50%;
      border: 5px solid #fff;
      height: 114px;
      position: relative;
      display: inline-block;
      text-align: center;
      width: 114px;

      &:hover {
        opacity: .8;
      }
    }

    .icon-play {
      height: 36px;
      width: 36px;
      position: relative;
      top: 35px;
      left: 4px;
    }

    .icon-replay {
      height: 50px;
      width: 50px;
      position: relative;
      top: 29px;
      left: 0px;
    }

    .next-vid {
      cursor: pointer;
      position: absolute;
      bottom: -155px;

      .container {
        background-color: #343537;
        display: flex;
        flex-direction: row;
        width: 350px;
        padding: 10px;
        margin-left: 20px;
      }

      .image-container {
        width: 30%;
        height: 60px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        .play-button {
          position: absolute;
          top: 20%;
          left: 30%;

          .circles {
            height: 35px;
            width: 35px;

            .bg {
              stroke: #5c5457;
            }

            .fg {
              stroke: white;
            }
          }

          .icon-play-rotate {
            top: 9px;
            left: 13px;
            margin: 0;
            z-index: 10;

            svg {
              height: 15px !important;
              width: 15px !important;
            }
          }
        }
      }

      .text-container {
        text-align: left;
        padding-left: 10px;
        font-family: @fontNowayMedium;

        p {
          color: white;
          font-size: 14px;
          padding-bottom: 0px;
        }

        .next-video-text {
          color: #aab2ba;
          font-size: 12px;

          .countdown {
            color: #eb5c4e;
          }
        }
      }
    }
  }

  .restart-trigger {
    height: auto;
    top: 90%;
    cursor: pointer;
    margin: 0 auto 0;
    left: 50%;
    width: 200px;
  }

  /*
  Control Bar
   */
  .vjs-control-bar {
    // Settings panel
    .vjs-button.rio-videojs-settings {
      .vjs-control-text {
        cursor: pointer;
        height: 16px;
        width: 16px;
        display: block;
        background: url('~img/components/icon/videosettings.svg') no-repeat;
        background-size: 16px auto;
      }
    }

    .vjs-button.rio-videojs-rio-captions {

      padding: 0 2em;

      .vjs-icon-placeholder {
        &:before {
          color: #96989a;
          content: "\f10c";
          /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
          font-family: VideoJS;
        }
      }

      &.active,
      &:hover {
        .vjs-icon-placeholder {
          &:before {
            color: #fff;
            //opacity: 1;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }

      .vjs-menu-content {
        width: 175px;  // make width larger not to have horizontal scroll
        max-height: 200px;
        white-space: nowrap;  // keep text on one line
        bottom: 28px;

        // if device has no mouse, unfold the whole caption menu option
        // otherwise user will have to "scroll" in the tiny window which
        // is not ideal to say the least.
        @media (hover: none) {
          height: auto;
          max-height: none;
        }

        .vjs-menu-item {
          line-height: 2.4em;  // height larger to be easier to click
          position: relative;

          &.active {
            // TODO: add some more styling on the selected language?
            // thinking if we should make the caption <li> elements
            // using flex css and move the "active" one to the top
            &:before {
              // TODO: change with something better
              content: "✔";
              color: #fff;
              position: absolute;
              left: 15px;
            }
          }

          &.active[data-language="ja"] {
            &:before {
              left: 4px;
            }
          }
        }

        .vjs-menu-item-text {
          text-transform: capitalize;  // default was lowercase, we don't want
        }
      }

      &.vjs-hover {
        .vjs-captions-panel {
          display: block;
        }
      }
    }
  }


  // player states


  // animation for fading OUT an area
  @-webkit-keyframes video-player-fade-out {
    .video-player-fade-out-frames;
  }
  @-moz-keyframes video-player-fade-out {
    .video-player-fade-out-frames;
  }
  @-ms-keyframes video-player-fade-out {
    .video-player-fade-out-frames;
  }
  @-o-keyframes video-player-fade-out {
    .video-player-fade-out-frames;
  }
  @keyframes video-player-fade-out {
    .video-player-fade-out-frames;
  }
  .video-player-fade-out-frames() {
    0% {
      .transform(scale(1, 1));
      opacity: 1;
    }

    100% {
      .transform(scale(0.98, 0.98));
      opacity: 0;
    }
  }

  // animation for fading IN an area
  @-webkit-keyframes video-player-fade-in {
    .video-player-fade-in-frames;
  }
  @-moz-keyframes video-player-fade-in {
    .video-player-fade-in-frames;
  }
  @-ms-keyframes video-player-fade-in {
    .video-player-fade-in-frames;
  }
  @-o-keyframes video-player-fade-in {
    .video-player-fade-in-frames;
  }
  @keyframes video-player-fade-in {
    .video-player-fade-in-frames;
  }
  .video-player-fade-in-frames() {
    0% {
      // .transform(scale(0.98, 0.98));
      opacity: 0;
      display: block;
    }

    100% {
      // .transform(scale(1, 1));
      opacity: 1;
    }
  }

  @-webkit-keyframes glow-grow-in {
    .glow-grow-in-frames;
  }
  @-moz-keyframes glow-grow-in {
    .glow-grow-in-frames;
  }
  @-ms-keyframes glow-grow-in {
    .glow-grow-in-frames;
  }
  @-o-keyframes glow-grow-in {
    .glow-grow-in-frames;
  }
  @keyframes glow-grow-in {
    .glow-grow-in-frames;
  }
  .glow-grow-in-frames() {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      opacity: .5;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1.2);
    }
  }

  // any state that's playing, as well as default
  .information,
  .trigger-wrapper,
  .restart-trigger {
    display: none;
    opacity: 0;
  }

  &.player-playing {
    // raise above the like button, hacky...
    z-index: 1040;
    background-image: none;

    .information, .play-trigger {
      // .animation(video-player-fade-out, 2.0s, 0, linear);
      display: none;
      opacity: 0;
    }

    .player-container {
      height: 100%;
      opacity: 1;
    }
  }


  // when on a touch screen, remove the controls and use the default player's controls
  &.is-touch-screen {
    &.player-playing-intro,
    &.player-paused,
    &.player-loading,
    &.player-broke,
    &.is_hovering {
      .player-container {
        cursor: auto;
      }
    }

    .trigger-wrapper {
      display: none !important;
    }
  }

  &.is-touch-screen.player-onstart {
    .trigger-wrapper {
      display: block !important;
    }
  }


  // when the intro is playing
  &.player-playing-intro {
    // raise above the like button, hacky...
    z-index: 1040;

    // Don't show the control bar when the intro is playing.
    .vjs-control-bar {
      display: none !important;
    }

    .information,
    .trigger-wrapper,
    .restart-trigger {
      display: none;
      opacity: 0;
    }

    .player-container {
      cursor: auto;
      height: 100%;
      opacity: 1;
    }
  }

  // when the video is loading
  &.player-loading {
    @-webkit-keyframes rotate {
      0% {
        -webkit-transform: rotateZ(0deg);
      }
      100% {
        -webkit-transform: rotateZ(360deg);
      }
    }

    @keyframes rotate {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }

    .information {
      display: block;
      opacity: 1;
    }

    .player-container {
      cursor: auto;
      opacity: 0.2;
      height: 100%;
    }

    .video-loading {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      position: absolute;
      left: 50%;
      top: 50%;
      height: 133px;
      width: 133px;

      div {
        background: transparent url(~img/partials/pro_video-detail/player-loader.png) no-repeat left top;
        background-size: 100% auto;
        position: relative;
        left: -50%;
        width: 100%;
        height: 100%;
        -webkit-animation: rotate 1.2s infinite cubic-bezier(0.2, 0.35, 0.2, 0.35);
        animation: rotate 1.2s infinite cubic-bezier(0.2, 0.35, 0.2, 0.35);
      }
    }
  }

  // when the player is paused
  &.player-paused {
    .information,
    .trigger-wrapper {
      //        .animation(video-player-fade-in, 0.15s, 0, linear);
      opacity: 1;
      display: inline-block;
    }

    .restart-trigger {
      display: none;
      opacity: 0;
    }

    circle.fg,
    .play-trigger.btn {
      display: none !important;
    }

    .player-container {
      opacity: 1;
      //opacity: 0.6;
      height: 100%;
    }
  }

  // when the player is loaded but hasn't played yet
  &.player-onstart, &.player-not-loaded, &.player-ended {
    background-image: url(~img/partials/pro_video-detail/player-bg-temp.jpg);

    .player-container {
      opacity: 0;
    }

    .information {
      .animation(video-player-fade-in, 0.15s, 0, linear);
      display: block;
      opacity: 1;
    }
  }

  // when the player is loaded but hasn't played yet
  &.player-onstart {
    .trigger-wrapper,
    .restart-trigger {
      //        .animation(video-player-fade-in, 0.15s, 0, linear);
      display: inline-block;
      opacity: 1;
    }

    .play-restart {
      display: none;
      opacity: 0;
    }
  }

  // when the player is loaded but hasn't played yet
  &.player-not-loaded {
    .trigger-wrapper {
      display: block !important;
      opacity: 1 !important;
    }

    .play-restart,
    .restart-trigger {
      display: none;
      opacity: 0;
    }
  }

  // when the player is fullscreen
  &.is_fullscreen {
    height: 100%;
  }

  // when the video is done
  &.player-ended {
    .player-container {
      opacity: 0;
    }

    .information {
      .animation(video-player-fade-in, 0.15s, 0, linear);
      display: block;
      opacity: 1;
    }

    .restart-trigger,
    .trigger-wrapper {
      //        .animation(video-player-fade-in, 0.15s, 0, linear);
      display: none;
      opacity: 0;
    }

    .play-restart {
      .animation(video-player-fade-in, 0.15s, 0, linear);
      display: block;
      opacity: 1;
    }
  }

  // when the player is broke, don't show anything
  &.player-broke {
    padding: 20% 26%;
    color: #fff;
    font-size: 18px;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}


@media (min-width: @widthTabletMin) and (max-width: 960px) {
  section.video_player .information > header {
    margin-top: 8%;
  }
}


@media (max-width: @widthTabletMax) {
  section.video_player .author-information {
    display: block;
  }
}


@media (max-width: @widthMobileMax) {
  section.video_player {
    height: 220px;

    .video-js .vjs-control-bar {
      padding: 10px 0;
      margin: 0;
      width: 100%;
    }

    h1 {
      font-size: 16px;
      width: 82%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .information {
      height: 100%;

      > header {
        max-width: 100%;
        width: 100%;
      }
    }

    .author-information {
      position: absolute;
      left: 0;
      bottom: 14px;
      margin-left: 5%;

      > .name-level {
        text-align: left;
        margin-left: 46px;
      }

      > .author-pro_videos-total {
        display: none;
      }

      .avatar {
        position: absolute;
        left: 0;
        top: -4px;
      }
    }

    .play-trigger, .play-restart {

      .circle {
        border-width: 3px;
        height: 70px;
        width: 70px;
        top: 20px;
      }

      .icon {
        width: 30px;
        height: 30px;
        top: 16px;
        left: 0px;
      }

      // login/signup notice
      .login-notice {
        margin: 0;
        font-size: 10px;

        .btn {
          display: inline-block;
          margin: 0px 5px;
        }
      }

      .next-vid {
        bottom: 20px;

        .container {
          width: 83%;
          padding: 5px;
          margin-left: 5px;

          .image-container {
            max-width: 150px;

            .play-button {
              display: block;
              left: 35%;
            }
          }
        }
      }
    }

    .play-trigger.btn {
      margin-top: 15%;
    }
  }
}

