body.icm-bootcamp {
  background: #07090B;

  #torso {
    background: #07090B;
    border: none;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(15vh, 150px);
    margin-bottom: 120px;
    // cuz we want header from side to side
    overflow-x: hidden;

    h1 {
      font-family: @fontNowayMedium;
      color: #fff;
      font-size: 110px;
      line-height: 110px;

      @media (max-width: @widthTabletPortrait) {
        font-size: 80px;
        line-height: 70px;
      }
    }

    h2 {
      font-family: @fontNowayMedium;
      color: #fff;
      font-size: 64px;
      line-height: 64px;

      @media (max-width: @widthTabletPortrait) {
        font-size: 45px;
        line-height: 45px;
      }
    }

    header {
      padding-top: min(15vh, 150px);

      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;

      aspect-ratio: 3360/1400;
      background-image: url("~img/views/icm-bootcamp/header.jpg");
      //filter: brightness(120%);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: bottom center;
      width: 100vw;
      max-width: 2560px;

      @media (max-width: @widthTabletPortrait) {
        background-size: 180% auto;
        background-position-x: 55%;
        background-position-y: center;
      }

      .coaches-img-placeholder {
        aspect-ratio: 1680/425;
        width: 100%;
        max-width: 1680px;

        @media (max-width: @widthTabletPortrait) {
          aspect-ratio: 1680/850;
        }
      }

      .information-cards {
        width: 90vw;
        max-width: 1340px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;

        @media (max-width: @widthTabletPortrait) {
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }

        .info {
          padding: 16px 12px;
          border-radius: 6px;
          display: flex;
          flex-grow: 1;
          flex-basis: 0;
          flex-direction: row;
          gap: 25px;
          background: #1b1d2f;
          min-width: 300px;
          width: 80vw;

          .icon {
            width: 28px;
            height: 28px;
          }

          .message {
            color: #fff;
            font-size: 24px;
            line-height: 28px;
            font-family: @fontNowayRegular;

            a {
              color: #fff;
              font-family: @fontNowayRegular;
            }
          }
        }
      }
    }

    section {
      width: 90vw;
      max-width: 1340px;

      .video-overview {
        width: 100%;
        aspect-ratio: 2800/1970;
        background: #1B1D2F url("~img/views/icm-bootcamp/video-thumbnail.jpg");
        background-size: cover;
        background-position-y: -1px;
        border-radius: 10px;
        border: 2px solid #1a213e;
        display: flex;
        justify-content: center;
        align-items: center;

        .play-circle {
          position: relative;
          background: #7681AE;
          width: 90px;
          height: 90px;
          border-radius: 90px;

          .arrow-right {
            position: absolute;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 25px solid #fff;
            top: 30px;
            left: 35px;
          }

          &:hover {
            cursor: pointer;
            .box-shadow(1px 2px 75px rgba(0, 0, 0, 0.55));
            transform: scale(1.1);
          }
        }
      }

      .coaches__wrapper {
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;

        .coach {
          width: calc(33% - 10px);
          min-width: 335px;
          border: 2px solid #1A213E;
          border-radius: 10px;
          display: flex;
          flex-direction: row;

          @media (max-width: @widthTabletPortrait) {
            width: 90vw;
          }

          &__photo {
            width: 165px;
            background: #0c0c14;
            border-radius: 10px 0 0 10px;
            display: flex;

            img {
              align-self: center;
              width: 100%;
              aspect-ratio: 335/670;
              border-radius: 10px 0 0 10px;
            }
          }

          &__bio {
            width: calc(100% - 165px);
            padding: 30px 20px;
            border-radius: 0 10px 10px 0;
            background: linear-gradient(180deg, #131421 0%, #07090B 100%);

            .name {
              font-family: @fontOpenSans;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              line-height: 18px;
              letter-spacing: 0.15em;
              text-transform: uppercase;
              margin-bottom: 30px;
            }

            .bio {
              font-family: @fontOpenSans;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #7681AE;
            }
          }
        }
      }

      .schedule__wrapper {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;

        .schedule__item {
          display: flex;
          flex-direction: row;
          gap: 15px;

          @media (max-width: @widthTabletPortrait) {
            flex-direction: column;
          }

          background: #131421;
          padding: 12px 32px;
          border-radius: 6px;

          &.break {
            font-family: @fontNowayRegular;
            font-size: 18px;
            color: #fff;
            margin: 30px 0;
          }

          &__timeslot {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 120px;
            gap: 2px;

            .icon {
              width: 20px;
              height: 20px;
            }

            .timeslot {
              font-family: @fontNowayRegular;
              font-size: 16px;
              color: #fff;
            }
          }

          &__information {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-left: 1px solid #7681ae;
            padding: 0 40px;

            .title {
              font-family: @fontNowayMedium;
              font-size: 20px;
              color: #fff;
            }

            .description {
              font-family: @fontOpenSans;
              font-size: 16px;
              color: #A6B0D8;
            }

            .coach {
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;
              background: #23253a;
              width: 180px;
              padding: 5px 15px;
              border-radius: 6px;

              .photo {
                img {
                  width: 22px;
                  height: 22px;
                  border-radius: 22px;
                  object-position: 0px -4px;
                  object-fit: cover;

                  &.dara,
                  &.nathan {
                    object-position: 2px -2px;
                  }
                }
              }

              .name {
                padding: 0 10px;
                font-family: @fontOpenSans;
                font-size: 13px;
                color: #A6B0D8;
                font-weight: 100;
                line-height: 22px;
              }
            }
          }
        }
      }

      .join__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        gap: 30px;
        padding: 60px 20px;
        border-radius: 10px;
        border: 2px solid #1A213E;
        background: linear-gradient(180deg, #131421 0%, #07090B 100%);

        .prompt {
          max-width: 640px;
          color: #fff;
          font-family: @fontOpenSans;
          font-size: 22px;
          line-height: 150%;
          font-weight: 400;

          &.muted {
            max-width: 580px;
            font-size: 18px;
            font-family: @fontNowayRegular;
            color: #626B93;
            line-height: 130%;
          }
        }

        .btn-bootcamp {
          width: 320px;
          padding: 10px 25px;
          background: #7681AE;
          //background: linear-gradient(180deg, #131421 0%, #07090B 100%);
          border: 2px solid #454FC7;
          font-family: @fontNowayMedium;
          font-size: 22px;
          color: #fff;

          &:hover {
            background: #8e98c6;
          }
        }
      }

      &.faq-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        ul.rio--accordion {
          list-style: none;
          text-align: left;
          width: 1040px;
          margin-top: 45px;
          margin-bottom: 20px;

          @media (max-width: @widthTabletMax) {
            width: 100%;
          }

          li {
            border-bottom: 1px solid #2b323a;
            width: 100%;

            .expand-trigger {
              font-family: @fontOpenSans;
              font-size: 22px;
              color: #fff;
              margin-top: 30px;
              margin-bottom: 35px;
              padding-right: 30px;
              position: relative;

              @media (max-width: @widthTabletMax) {
                font-size: calc(0.8 * 22px);
              }

              &:after {
                color: #fff;
                content: "+";
                font-family: @fontDefault;
                font-size: 30px;
                position: absolute;
                right: 0;
                font-weight: 700;
                top: -4px;

                @media (max-width: @widthTabletMax) {
                  font-size: calc(0.8 * 30px);
                  top: calc(0.8 * -4px)
                }
              }
            }

            .section {
              font-family: @fontOpenSans;
              color: #b7b7b9;
              margin-bottom: 35px;
              font-size: 18px;

              @media (max-width: @widthTabletMax) {
                font-size: calc(0.8 * 18px);
              }
            }

            &.expanded {
              .expand-trigger {
                &:after {
                  content: "-";
                  transform: scale(1.6, 0.8);
                  right: 4.1px;
                  top: -3.2px;

                  @media (max-width: @widthTabletMax) {
                    font-size: calc(0.8 * -3.2px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .rio-modal {
    .content {
      // that video is with 1920:1080 ratio;
      height: auto;
      width: 95vw;
      max-width: 1340px;
      aspect-ratio: 1920/1080;
      gap: 0;
      margin-bottom: 0;

      .content-inner {
        display: none;
      }

      iframe {
        aspect-ratio: 1920/1080;
      }
    }
  }
}
