.settings-lets-get-started__payment-success {
  background: #f7f8f8;
  border-bottom: 1px solid #dadcdd;
  color: #272a2b;
  display: block;
  font-weight: 700;
  font-size: ~"calc(20px + 20 * ((100vw - 400px) / 940))";
  line-height: 1.6;
  padding: ~"calc(20px + 15 * ((100vw - 400px) / 940))" 0 ~"calc(20px + 20 * ((100vw - 400px) / 940))";
  text-align: center;
  width: 100%;
}

body.settings#lets_get_started {
  .container {
    width: 1340px;
  }

  .settings_content {
    // range of 0-100px from 400px-1340px
    padding: 0 ~"calc(0px + 100 * ((100vw - 400px) / 940))";

    h2, h3 {
      color: #37393d;
    }

    hr {
      background: #cdcfd3;
      border: none;
      display: inline-block;
      height: 1px;
      margin-bottom: 28px;
      width: 136px;
    }

    .thank-you {
      padding-top: 50px;
      text-align: center;

      p {
        color: #6a7072;
        font-size: 18px;
        line-height: 1.3;
        padding: 20px 0;
      }
    }

    .extras {
      display: flex;
      flex-wrap: wrap;
      left: 0;
      list-style: none;
      padding: 0 4%;
      width: 100%;
      justify-content: center;

      &__content {
        width: 100%;
      }

      li {
        .box-shadow(0px 10px 60px 7.5px rgba(0, 0, 0, 0.15));
        background: #fff;
        display: flex;
        margin: 0 2%;
        padding: 20px 2%;
        width: 46%;

        .bonus {
          color: #aab3b8;
          font-family: @fontHeading;
          font-size: 10px;
          font-weight: 700;
        }

        .bonus-description {
          font-size: 28px;

          img {
            height: 35px;
            width: auto;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
            padding-left: 15px;
          }
        }

        h4 {
          color: #101213;
          font-weight: 500;
          line-height: 1.6;
        }

        .bonus-logos {
          margin: 14px 0;
          width: auto;
        }

        p {
          color: #565d61;
          font-weight: 100;
          font-family: @fontHeading;
        }

        .pokergo__directions {
          display: inline-block;
          margin: 0 auto;
          max-width: 75%;
          text-align: left;
        }

        .pokergo__code {
          font-weight: 700;
        }

        .choose-reward {
          .transition(all, 0.20s, ease-in-out);
          background: #89171d;
          border-radius: 8px;
          color: #fff;
          cursor: pointer;
          display: inline-block;
          font-family: @fontHeading;
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          margin: 10px 0;
          min-width: 116px;
          padding: 14px 6% 12px;

          &:hover {
            background: #a71e23;
            text-decoration: none;
          }
        }
      }
    }

    section {
      &.lets-get-started,
      &.gives-you {
        text-align: center;
      }
    }

    h2 {
      font-size: ~"calc(20px + 20 * ((100vw - 400px) / 940))";
      font-weight: 700;
      line-height: 2.6;
    }

    .lets-get-started {
      .header-icon {
        display: inline-block;
        margin-top: ~"calc(24px + 60 * ((100vw - 400px) / 940))";
        width: 24px;
      }
    }

    .gives-you {
      margin-bottom: 40px;

      h3 {
        font-size: ~"calc(16px + 9 * ((100vw - 400px) / 940))";
        line-height: 2.6;
        margin-bottom: 10px;
      }
    }

    h4 {
      color: #37393d;
      // range of 14-20px from 400-1340px res
      font-size: ~"calc(14px + 6 * ((100vw - 400px) / 940))";
      font-weight: 500;
      padding: 0 0 14px;
    }

    .learning-paths,
    .pro-videos-library,
    .full-access {
      p {
        color: #aaa;
        font-family: @fontHeading;
        // range of 11-15px from 400-1340px res
        font-size: ~"calc(11px + 4 * ((100vw - 400px) / 940))";
        line-height: 2;
        padding: 0 12% 30px 0;
      }
    }

    .learning-paths {
      // if .download-redline not launched yet
      clear: both;

      background: transparent url(~img/partials/lets-get-started/learning-path.jpg) no-repeat left top;
      background-attachment: local;
      background-size: auto 82%;
      // range of padding-top is 0-90px from 400-1340px res
      // range of padding-right is 0%-10px from 400-960px res
      // range of padding-bottom is 0-130px from 400-1340px res
      padding:
        ~"calc(0px + 90 * ((100vw - 400px) / 940))"
        ~"calc(0% + 10 * ((100vw - 400px) / 560))"
        ~"calc(0px + 130 * ((100vw - 400px) / 940))"
        56%;
    }

    .pro-videos-library {
      background: transparent url(~img/partials/lets-get-started/pro-videos-library.jpg) no-repeat right top;
      background-size: auto 82%;
      padding:
        ~"calc(0px + 60 * ((100vw - 400px) / 940))"
        56%
        ~"calc(0px + 130 * ((100vw - 400px) / 940))"
        5%;
    }

    .full-access {
      background: transparent url(~img/partials/lets-get-started/full-access.jpg) no-repeat 7% top;
      background-size: auto 76%;
      padding:
        ~"calc(0px + 60 * ((100vw - 400px) / 940))"
        ~"calc(0% + 10 * ((100vw - 400px) / 560))"
        ~"calc(0px + 120 * ((100vw - 400px) / 940))"
        56%;

      &__forum {
        text-transform: uppercase;
      }
    }

    .info {
      .clearfix();
      border-top: 1px solid #e1e5e7;
      list-style: none;
      margin-top: 60px;
      padding: 60px 0 30px;

      h5 {
        color: #272a2b;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 10px;
      }

      li {
        float: left;
        margin: 0;
        padding: 0;
        width: 25%;
        vertical-align: top;
      }

      p {
        color: #5e6367;
        padding: 0 15% 0 0;
      }
    }
  }
}

@media (min-width: @widthMonitorMin) {
  body.settings#lets_get_started {
    .settings_content {
      padding: 0 100px;

      .extras {
        bottom: -150px;

        li {
          .bonus {
            margin-top: 24px;
          }

          h4 {
            font-size: 50px;
          }

          .bonus-logos {
            height: 46px;
          }

          p {
            font-size: 16px;
          }

          .pokergo__directions,
          .pokergo__code {
            font-size: 12px;
          }
        }
      }

      .lets-get-started .header-icon {
        margin-top: 84px;
      }

      h2 {
        font-size: 36px;
      }

      .gives-you h3 {
        font-size: 22px;
      }

      .learning-paths {
        padding: 90px 10% 130px 56%;
      }

      .pro-videos-library {
        padding: 60px 56% 130px 5%;
      }

      .full-access {
        padding: 60px 10% 120px 56%;
      }

      h4 {
        font-size: 20px;
      }

      .learning-paths,
      .pro-videos-library,
      .full-access {
        p {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: @widthDesktopMax) {
  body.settings#lets_get_started {
    #torso {
      border-right: none;
    }

    .container {
      width: 100%;
    }

    .settings_content {
      .extras {
        bottom: ~"calc(-75px - 75 * ((100vw - 400px) / 1040))";

        li {
          .bonus {
            margin-top: ~"calc(11px + 13 * ((100vw - 400px) / 1040))";
          }

          h4 {
            font-size: ~"calc(20px + 30 * ((100vw - 400px) / 1040))";
          }

          .bonus-logos {
            height: ~"calc(24px + 22 * ((100vw - 400px) / 1040))";
          }

          p {
            font-size: ~"calc(12px + 3 * ((100vw - 400px) / 1040))";
            padding: 0 0 ~"calc(0px + 20 * ((100vw - 400px) / 1040))";
          }

          .pokergo__directions,
          .pokergo__code {
            font-size: ~"calc(9px + 3 * ((100vw - 400px) / 1040))";
          }
        }
      }
    }
  }
}

@media (max-width: @widthMobileMax) {
  body.settings#lets_get_started {
    #torso {
      padding: 0;
    }

    .settings_content {
      .extras {
        bottom: 0;
        position: relative;
        padding-bottom: 20px;

        li {
          float: none;
          margin: 20px auto;
          padding: 10px 20px;
          width: 70%;
        }
      }

      .learning-paths,
      .pro-videos-library,
      .full-access {
        background-position: center top;
        background-size: auto 40%;
        padding: 200px 0 40px;
        margin: 0 auto;
        width: 70%;
      }

      .learning-paths,
      .pro-videos-library,
      .full-access {
        p {
          padding-right: 0;
        }
      }

      .info li {
        display: block;
        float: none;
        margin: 20px auto;
        width: 80%;

        p {
          padding-right: 0;
        }
      }
    }
  }
}
