// flags displayin on the site (for language versions)
// as of now not used but later will come in play when we show flags on site
ul.countries {
  // position: absolute;
  // transform: translateY(-50%);
  height: 16px;
  list-style: none;
  display: inline-block;

  li {
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-left: 5px;

    &.argentina,
    &.brazil,
    &.china,
    &.france,
    &.germany,
    &.italy,
    &.poland,
    &.spain {
      height: 18px;
      width: 28px;
      vertical-align: middle;
      margin-bottom: 5px;
    }

    &.russian {
      background: url('~img/flags/russian.png') no-repeat;
      background-size: cover;
    }

    &.portuguese {
      background: url('~img/flags/brazil.png') no-repeat;
      background-size: cover;
    }

    &.argentina {
      background: url('~img/international-week/argentina_flag.png') no-repeat;
      background-size: cover;
    }

    &.brazil {
      background: url('~img/international-week/brazil_flag.png') no-repeat;
      background-size: cover;
    }

    &.china {
      background: url('~img/international-week/china_flag.png') no-repeat;
      background-size: cover;
    }

    &.france {
      background: url('~img/international-week/france_flag.png') no-repeat;
      background-size: cover;
    }

    &.germany {
      background: url('~img/international-week/germany_flag.png') no-repeat;
      background-size: cover;
    }

    &.italy {
      background: url('~img/international-week/italy_flag.png') no-repeat;
      background-size: cover;
    }

    &.poland {
      background: url('~img/international-week/poland_flag.png') no-repeat;
      background-size: cover;
    }

    &.spain {
      background: url('~img/international-week/spain_flag.png') no-repeat;
      background-size: cover;
    }
  }

}
