/**
  The A-Game Poker Masterclass banner that is shown in the Pro Video listing when "Mental Game" is selected.
  The show/hide logic is in `rio-legacy.js`
 */
.banner-agpm-large {
  background: #000;
  display: none;

  // Just hide it once the screen gets small.
  @media (max-width: 895px) {
    display: none !important;
  }

  &.show {
    display: block;
  }

  h3 {
    opacity: 0;
  }

  a {
    background: url('~img/banners/agpm-large.jpg') no-repeat center;
    background-size: auto 135px;
    height: 135px;
    display: block;
  }
}
