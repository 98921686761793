// Video playlist at bottom of page
#video_playlist {
  .transform(translate3d(0,0,3px));
  background: #1b1e1f;
  border-top: 2px solid #a70106;
  bottom: -156px;
  height: 156px;
  left: 0;
  padding-top: 1px;
  position: fixed;
  width: 100%;
  z-index: 5010;

  .toggle {
    background: url(~img/video/playlist/toggle.png) no-repeat left bottom;
    cursor: pointer;
    height: 29px;
    opacity: 0.4;
    position: absolute;
    right: -10px;
    top: -32px;
    width: 34px;

    &:hover {
      opacity: 1;
    }
  }

  &.open .toggle {
    display: block;
    background-position: left top;
  }

  .close {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    .toggle {
      right: 6px;
    }
    .span2, .span10 {
      float: left;
      display: block;
    }
    .span2 {
      padding-left: 1%;
      width: 17%;
    }
    .span10 {
      width: 75%;
    }

    .container .row .videos .browse.prev {
      left: -20px;
    }
  }

  .container {
    margin: 0 auto;
    padding-top: 20px;
    position: relative;
    max-width: 1140px;

    > .info {
      float: left;
      width: 17%;

      h2 {
        color: #fff;
        font-size: 13px;
        font-weight: 300;
        padding: 49px 0 10px 0;
        line-height: 1.5;
      }

      .btn {
        font-size: 15px;
        padding: 9px 20px 7px;
      }
    }

    > .videos {
      float: left;
      position: relative;
      width: 83%;

      .browse {
        background: transparent url(~img/video/playlist/arrows.png) no-repeat left top;
        cursor: pointer;
        display: none;
        height: 18px;
        text-indent: -9999px;
        position: absolute;
        top: 50px;
        width: 12px;

        &.disabled {
          opacity: 0.2;
        }

        &.prev {
          left: -50px;

          &:hover {
            background-position: left bottom;
          }
        }

        &.next {
          background-position: right top;
          right: 0px;

          &:hover {
            background-position: right bottom;
          }
        }
      }

      .scrollable {
        height: 190px;
        margin: 0 30px 0 0;
        position: relative;
        overflow: hidden;

        .full_size {
          .clearfix();
          width: 50000px;
          position: absolute;

          .highlight_group {
            .clearfix();
            float: left;
            list-style: none;
            width: 944px;
          }

          .highlight {
            background: transparent url(~img/video/playlist/video_container_bg.png) no-repeat left 10px;
            .clearfix();
            display: block;
            float: left;
            margin-right: 20px;

            a {
              display: block;
            }

            .click_to_play {
              background: transparent url(~img/video/playlist/video_bg.png) no-repeat left top;
              display: block;
              float: left;
              height: 116px;
              overflow: hidden;
              position: relative;
              text-align: center;
              width: 132px;

              &:hover {
                text-decoration: none;
              }

              ul {
                left: 15px;
                list-style: none;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                top: 13px;
                width: 70%;

                li {
                  background: transparent url(~img/video/playlist/gallery_icons.png) no-repeat left top;
                  color: #fff;
                  display: inline;
                  font-size: 14px;
                  padding-left: 16px;

                  &.comments {
                    background-position: 0px 3px;
                    margin: 0;
                  }

                  &.likes {
                    border-left: 1px solid #fff;
                    background-position: 10px -15px;
                    padding-left: 26px;
                    margin-left: 5px;
                  }

                }
              }
              .description {
                color: #FFFFFF;
                cursor: pointer;
                display: block;
                font-size: 14px;
                left: 20px;
                position: absolute;
                text-align: center;
                top: 42px;
                height: 80px;
                width: 90px;

                .icon {
                  background: transparent url(~img/video/playlist/click_to_play.png) no-repeat left top;
                  display: block;
                  height: 24px;
                  margin-bottom: 8px;
                  margin-left: 25px;
                  width: 39px;
                }
              }
            }

            .info {
              color: #fbfbfb;
              float: left;
              font-size: 12px;
              margin-left: 10px;
              width: 146px;

              .title {
                padding: 8px 0;
              }

              .order {
                clear: both;
                float: left;
                font-weight: bold;
                font-size: 20px;
                padding-top: 6px;
              }

              .remove {
                color: #fbfbfb;
                cursor: pointer;
                float: left;
                padding: 12px 0 0 0;
                text-decoration: underline;
              }
            }
          }
        }

      }
    }
  }
}

@media (max-width: @widthMobileMax) {
  #video_playlist {
    display: none;
  }
}


