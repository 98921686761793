.rio--section-opener {

}


.rio--section-opener--container {
  > div, section {
    display: none;
  }

  > div.open,
  > section.open {
    display: block;
  }
}
