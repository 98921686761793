body.forum {
  #neck {
    background: transparent url(~img/partials/thread/header-stuck-shadow.png) no-repeat center bottom;
    background-size: 100% auto;
    margin-bottom: 36px;

    > .inner {
      background: #f7f8f8;
      padding-bottom: 20px;
    }

    .container {
      .clearfix();
    }

  }

  .game-stakes {
    background: #f7f8f8;
    padding: 20px 0;
  }

  h1 {
    color: #272a2b;
    font-size: 36px;
    font-weight: 700;
    padding: 20px 0 0;
  }

  h2 {
    color: #838990;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
  }

  #content_filters {
    float: left;
    margin: 8px 0 0 0;
  }

  .create_content {
    float: right;
    list-style: none;
    margin-top: 24px;

    li {
      display: inline-block;
      padding: 0 14px;

      &:first-child {
        border-right: 1px solid #e4e5e7;
      }

      a {
        .transition(color, 0.22s, ease-out);
        color: #8e979d;
        font-family: @fontDefault;
        font-size: 13.5px;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          color: @colorLink;
        }
      }
    }
  }

  // Phones
  @media (max-width: @widthMobileMax) {
    h1 {
      font-size: 24px;
    }

    #content_filters {
      float: none;
    }

    #neck {
      .create_content {
        float: none;
      }
    }
  }

  .button-purchase {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      font-family: @fontOpenSans;

      > span {
        display: inline-block;
        background: #e5000b;
        cursor: pointer;
        padding-left: 30px;
        border-radius: 30px;
        overflow: hidden;

        &:hover {
          opacity: .75;
        }

        &:active {
          opacity: 1;
        }
      }

      .price {
        display: inline-block;
        background: #a10000;
        padding: 15px 20px;
        margin-left: 35px;
        font-size: 19px;
      }
    }
}
