// From The Ground Up CTA on the bottom of of course videos.
section.courses-below-video-filler {
  background: #f7f8f8;
  // border-top: 1px solid #d9dddf;

  a.from-the-ground-up {
    display: block;
    width: 100%;
    background: #fff url('~img/courses/courses-below-video-filler.jpg') top center;
    background-size: cover;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    text-decoration: none;
    color: #101213;
    text-align: center;
    height: auto;
    max-height: 190px;
  }

  a.from-the-ground-up-mtt {
    display: block;
    width: 100%;
    background: #111 url("~img/courses/mtt-video-banner@2x-min.jpg") top center;
    background-size: cover;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    height: auto;
    max-height: 190px;
  }

  a.a-game-poker-masterclass {
    display: block;
    width: 100%;
    background: #000 url('~img/courses/masterclass-below-video-filler.jpg') top left;
    background-size: auto 100%;
    background-repeat: no-repeat;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    text-decoration: none;
    color: #fff;
    text-align: center;
    height: auto;
    max-height: 190px;
  }

  a.from-the-ground-up-plo {
    display: block;
    width: 100%;
    background: #000 url('~img/courses/ftgu-plo-bg.jpg') top left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    text-decoration: none;
    color: #fff;
    text-align: center;
    height: auto;
    max-height: 190px;

    .subtitle {
      color: #109abd;
    }
  }

  a.from-the-ground-up-sng {
    display: block;
    width: 100%;
    background: #000 url('~img/courses/ftgu-sng-bg.jpg') top left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    text-decoration: none;
    color: #fff;
    text-align: center;
    height: auto;
    max-height: 190px;

    .subtitle {
      color: #109abd;
    }
  }

  a.pads-on-pads {
    display: block;
    width: 100%;
     background: #000 url('~img/courses/pads-on-pads/background-min.jpg') top left;
    // Black friday below video banner.
    //background: #11050a url('~img/courses/plo-puzzle/below-video-filler-black-friday-sale.jpg') top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    text-decoration: none;
    color: #fff;
    text-align: center;
    height: auto;
    max-height: 190px;

    h4 {
      font-size: 40px;
    }

    p.subtitle {
      color: #07f160;
    }
  }

  a.this-is-plo {
    // 3400 × 2056
    background: #000 url('~img/courses/this-is-plo/phil-galfond-course-hero-no-title.jpg');
    background-size: 100% auto;
    display: block;
    padding-bottom: 18%;
    background-repeat: no-repeat;
    text-align: center;
    text-decoration: none;

    h4 {
      font-size: 40px;
      color: #FFFFFF;
    }

    p.subtitle {
      color: #9e8a8f;
    }
  }

  a.the-game-plan {
    display: block;
    width: 100%;
     background: #000 url('~img/courses/the-game-plan/bg-1.jpg') top left;
    // Black friday below video banner.
    //background: #11050a url('~img/courses/plo-puzzle/below-video-filler-black-friday-sale.jpg') top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    text-decoration: none;
    color: #fff;
    text-align: center;
    height: auto;
    max-height: 190px;

    h4 {
      font-size: 40px;
    }

    p.subtitle {
      color: #818181;
    }
  }

  a.plo-puzzle {
    display: block;
    // This is a banner that site below PLO Puzzle videos. The black friday one is disabled now.
    //background: #11050a url('~img/courses/plo-puzzle/below-video-filler-black-friday-sale.jpg') top center;
     background: #11050a url('~img/courses/plo-puzzle/below-video-filler.jpg') top center;

    background-size: cover;
    padding-bottom: 18%; // keep aspect ratio of the initial image

    height: auto;
    max-height: 190px;

    h4 {
      font-size: 40px;
    }

    p.subtitle {
      color: #07f160;
    }
  }

  a.knockout-tournament-mastery {
    display: block;
    width: 100%;
    background: #000 url('~img/courses/knockout/video-page-banner.png') 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    height: auto;
    max-height: 190px;

    h4 {
      display: none;
    }
  }

  a.foundations {
    display: block;
    width: 100%;
    background: #121212 url("~img/courses/foundations/library/library-header.jpg") top left no-repeat;
    background-size: auto 100%;
    padding-bottom: 18%;  // keep aspect ratio of the initial image
    padding-left: 50%;
    height: auto;
    max-height: 190px;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: @widthMobileMax) {
      background-position: top center;
      padding-left: 0;
      text-align: center;
    }

    h4 {
      padding-top: 1em;
      font-size: 40px;
      color: #FFFFFF;
    }

    p.subtitle {
      color: #9e8a8f;
    }
  }

  a.from-the-ground-up-5card-plo {
    display: block;
    width: 100%;
    background: #121212 url("~img/courses/from-the-ground-up-5card-plo/bg.jpg") top center;
    background-size: 200% auto;
    padding: 50px;
    height: auto;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    h4 {
      font-family: @fontPoppins;
      padding-top: 1em;
      font-size: 40px;
      color: #FCF7F8;
    }

    p.subtitle {
      font-family: @fontNowayMedium;
      color: #B2A8FC;
    }
  }

  a.dominate-with-data {
    display: block;
    width: 100%;
    background: #000 url("~img/courses/dominate-with-data/video-footer.jpg") top center no-repeat;
    background-size: 100% auto;
    color: transparent;
    min-height: 280px;

    @media (max-width: @widthMobileMax) {
      min-height: 230px;
    }
  }


  h4 {
    font-weight: bold;
    font-size: 18px;
    font-family: @fontNowayMedium;
    padding: 1.5em 0 .5em;
    margin: 0;
  }

  p {
    font-family: @fontNowayMedium;
    font-size: 22px;
    padding: 0 0 .5em;
    margin: 0;

  }

  .subtitle {
    font-family: @fontNowayMedium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .25em;
    text-transform: uppercase;
    color: #7e8082;
  }
}

// If a user does not have access to the course, this "Purchase Course" CTA is in place of the video player.
section.provideo-outro.courses {
  background: #000 url('~img/courses/provideo-courses-outro-background.png');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  .course-locked {
    height: 40px;
    width: 40px;
    border: 2px solid #cdd0d4;
    .border-radius(40px);

    background: url(~img/components/icon-non-sprite/locker.svg) no-repeat;
    background-position: center;
    background-size: 25px;
  }

  .course-title {
    font-size: 40px;
    font-weight: 700;
    padding: 20px 0 10px 0;
    color: #fff;
    font-family: @fontHeading;
  }

  p {
    font-family: @fontHeading;
    color: #989ba6;
    font-size: 16px;
    padding: 0;
  }

  .provideo-outro-cta {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    font-family: @fontOpenSans;
    padding: 12px 0 12px 0;
    border: 2px solid #e80527;
    .border-radius(30px);
    overflow: hidden;
    text-decoration: none;
    margin-top: 35px;
    box-shadow: 0px 2px 20px 0px rgba(255, 94, 94, 0.29),inset 2px 1px 20px 0px rgba(255, 94, 94, 0.22);

    > span {
      background: transparent;
      cursor: pointer;
      padding-left: 30px;
      border-radius: 30px;
    }

    .old-price {
      text-decoration: line-through;
      text-decoration-thickness: 2px;
    }

    .price {
      background: #e80527;
      color: #000;
      font-size: 17px;
      padding: 18px 20px 18px 12px;
      margin-left: 30px;
    }

    &:hover {
      box-shadow: 0px 2px 40px 0px rgba(255, 94, 94, 0.29),inset 2px 1px 40px 0px rgba(255, 94, 94, 0.22);
    }

    &.from-the-ground-up-plo {
      border-color: #109abd;
      box-shadow: 0px 2px 20px 0px rgba(16, 154, 189, 0.29),inset 2px 1px 20px 0px rgba(16, 154, 189, 0.22);

      .price {
        background: #109abd;
      }

      &:hover {
        box-shadow: 0px 2px 40px 0px rgba(16, 154, 189, 0.29),inset 2px 1px 40px 0px rgba(16, 154, 189, 0.22);
      }
    }

    &.from-the-ground-up-sng {
      background: #fff;
      color: #000;
      border-color: #fff;

      .price {
        background: #000;
        color: #fff;
      }

      &:hover {
        box-shadow: 0px 2px 40px 0px rgba(99, 99, 99, 0.29),inset 2px 1px 40px 0px rgba(255, 255, 255, 0.22);
      }
    }

    &.pads-on-pads {
      background: linear-gradient(90deg, #bd66e1, #bd66e1);
      border: 2px solid #bd66e1;
      color: white;

      .price {
        background: #142A3D;
        color: white;
      }
    }

    &.from-the-ground-up-5card-plo {
      border-color: #B2A8FC;

      .price {
        background: #B2A8FC;
      }

      &:hover {
        box-shadow: 0 1px 20px 0 rgb(117, 118, 122),inset 2px 1px 20px 0 rgb(78, 70, 143);
      }
    }
  }
}

// If it's larger than a phone...
@media (min-width: @widthMobileMax) {
  section.courses-below-video-filler.logged-out {
    /*
    this is to "counter" the margin we leave on provideo
    pages for our traditional sign up CTA. We won't be showing
    that CTA when we are in courses page
    (search padding-bottom: 110px) in less files for more info
    */
    margin-bottom: -110px;
  }
}


// Phones
@media (max-width: @widthMobileMax) {
  // If a user does not have access to the course, this "Purchase Course" CTA is in place of the video player.
  section.provideo-outro.courses {
    .course-title {
      font-size: 20px;
      padding-top: 10px;
    }

    p {
      font-size: 12px;
    }

    .provideo-outro-cta {
      font-size: 15px;
      padding: 12px 0 12px 0;
      margin-top: 20px;
    }
  }

  // From The Ground Up CTA on the bottom of of course videos.
  section.courses-below-video-filler {
    a.a-game-poker-masterclass {
      background: #000;
    }

    p {
      font-size: 18px
    }
  }
}
