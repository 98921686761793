/**
The Vision sales page styles.
 */
body.plo-visions {
  min-width: 320px;
  background-color: #04070D;

  // PokerGO SHRB version page adjustments.
  &.shrb-vision-page {
    .shrb-banner {
      background: rgb(159,38,181);
      background: linear-gradient(75deg, rgba(4,7,13,1) 3%, rgba(51,15,60,1) 18%, rgba(17,24,32,1) 40%, rgba(17,24,32,1) 60%, rgba(51,15,60,1) 87%, rgba(4,7,13,1) 97%);
      text-align: center;
      color: #fff;
      padding: 20px 5px 10px;
      font-size: 20px;

      h2 {
        font-size: 2em;
        font-weight: 500;
      }

      .pokergo-logo {
        display: inline-block;
        background: url(~img/views/vision-sales/pokergo-logo.png) no-repeat;
        background-size: contain;
        color: transparent;
        width: 5.5em;
        height: 2em;
      }

      p {
        font-size: .9em;
      }
    }

    @media (max-width: 600px) {
      .shrb-banner {
        font-size: 16px;
      }
    }

    @media (max-width: 500px) {
      .shrb-banner {
        font-size: 12px;
      }
    }
  }

  .button-scroll {

  }

  #torso {
    background-color: #04070D;
    background-size: 100% auto;
    width: auto;
    border: none;
    background-image: url(~img/visions/plo/plo_BG.jpg);
    background-repeat: no-repeat;
  }

  .plo-outer {
    .plo-visions-body {
      max-width: 1400px;
      margin: 0 auto;

      .free-trial-available {
        color: #fff;
        font-size: 18px;
        padding: .2em 0;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;

        @media (max-width: @widthMobileMax) {
          align-content: center;
          justify-content: center;
          align-items: center;
          margin: 100px auto !important;
          gap: 0;
        }

        //b {
        //  color: @colorLink;
        //}

        &.get-started-section {
          margin: 100px auto;
          align-content: center;
          justify-content: center;
          align-items: center;
        }

        .go-to-vision-button {
          font-size: 13px !important;
          margin-left: 0;
        }
      }

      .get-started-button {
        font-family: @fontNowayMedium;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        background: #fff;
        color: #000;
        text-transform: uppercase;
        padding: .9em 2.2em;
        border-radius: 22px;
        letter-spacing: .15em;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          background: #ddd;
        }

        &:active {
          background: #bbb;
        }
      }


      .go-to-vision-button {
        font-family: @fontNowayMedium;
        font-weight: 700;
        display: inline-block;
        background: #e42538;
        color: #fff;
        text-transform: uppercase;
        padding: .9em 2.2em;
        border-radius: 20px;
        letter-spacing: .15em;
        cursor: pointer;
        margin-left: 20px;
        animation: change-background 5s ease-in-out infinite;

        &:hover {
          text-decoration: none;
          background: #b01e2d;
        }

        &:active {
          background: #70111b;
        }
      }


      .title-block {
        position: relative;
        margin: 225px 0 410px;

        .button-wrap {
          position: relative;
          z-index: 2;

          .get-started-button {
            display: inline-block;
          }

          p {
            color: #abaebe;
            font-size: 14px;
            margin-left: 30px;
            display: inline-block;
          }
        }

        .vimeo-video {
          cursor: pointer;
          height: 90px;
          width: 90px;
          position: absolute;
          left: calc(50% - 90px);
          top: calc(50% - 120px);
          z-index: 60;

          &:hover {
            opacity: .75;
          }
        }

        .vision-interface {
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          z-index: 0;
          background: url(~img/visions/plo/bg_dots.png) no-repeat 70px 0;
          background-size: 207px 207px;
          width: 750px;

          .vision-interface-inner {
            position: relative;
            top: 20px;
            display: block;
            width: 750px;
          }

          .screenshot {
            width: 100%;
            height: auto;
          }
        }

        .title-description {
          background: url('~img/visions/plo/icon-plus.png') no-repeat 0% 40%;
          background-size: 14px auto;
          font-family: @fontNowayMedium;
          font-size: 15px;
          text-transform: uppercase;
          color: #7c8490;
          letter-spacing: .18em;
          padding-left: 30px;
        }

        .title {
          font-family: @fontNowayMedium;
          line-height: 1.1em;
          position: relative;
          font-size: 138px;
          color: #fff;
          z-index: 1;
          display: inline-block;
          width: 60%;
          margin-bottom: .5em;
        }

        .button-tour {
          position: relative;
          margin-top: 20px;
          display: block;
          text-align: center;
          color: #fff;
          font-size: 18px;
          z-index: 1;

          a {
            color: #fff;
            text-decoration: underline;

            &:hover {
              color: #ED494F;
            }
          }
        }

        img {
          width: 60%;
          height: auto;
        }

        h2 {
          color: white;
          font-size: 50px;
          margin: 30px 0 100px;
          font-family: @fontOpenSans;
          font-weight: bolder;
        }

      }

      .description-block {

        h1 {
          color: white;
          font-size: 70px;
          font-family: @fontOpenSans;
          line-height: 1.25;
        }

        .description-text {
          color: #bcc0cc;
          font-size: 18px;
          font-family: @fontOpenSans;
          text-align: left;
          width: 70%;
          padding: 30px 0;
          min-width: 340px;
          font-weight: 100;
          line-height: 1.75em;
        }

        .description-block-upper {
          display: flex;

          .title-description {
            background: url('~img/visions/plo/icon-plus.png') no-repeat 0% 40%;
            background-size: 12px auto;
            font-family: @fontNowayMedium;
            font-size: 11px;
            text-transform: uppercase;
            color: #7c8490;
            letter-spacing: 0.18em;
            padding-left: 25px;
            margin-bottom: 25px;
          }

          h3 {
            font-size: 60px;
            color: #fff;
            line-height: 1.25em;
          }


          .description-cards {
            display: flex;
            gap: 40px;
            align-items: stretch;
            justify-content: space-evenly;
          }

          .description-card {
            position: relative;
            text-align: center;
            font-size: 20px;
            padding: 87px 50px 45px 50px;
            color: #fff;
            background: #2b3036;
            width: 250px;
            border-radius: 13px;

            &::after {
              content: ' ';
              background-color: #17161d;
              border-radius: 20px;
              position: absolute;
              top: 27px;
              left: calc(50% - 10px);
              width: 20px;
              height: 20px;
            }

            img {
              margin-bottom: 30px;
            }

            p {
              padding-bottom: 0px;
            }
          }

        }

        .description-box-lower {
          .example-list {
            display: none;
          }
        }

        ul {
          text-align: left;
          padding-bottom: 40px;

          li {
            color: white;
            font-size: 18px;
            font-family: @fontOpenSans;
            font-weight: 100;
            list-style: none;
            margin: 10px 0px;
          }

          li:before {
            content: '';
            display: inline-block;
            height: 26px;
            width: 26px;
            background-image: url(~img/visions/plo/PLO-visions-check.png);
            background-size: cover;
            position: absolute;
          }

          .li-content {
            display: inline-block;
            margin-left: 40px; /* approx based on width of :before - YMMV */
          }
        }

        .examples-container,
        .examples-container-lower {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 100px;
          font-weight: 100;
          gap: 14%;

          .example {
            text-align: left;
            width: 30%;

            .title {
              width: 100%;
              height: auto;
              margin-bottom: 30px;
              max-width: 330px;
            }

            h2 {
              color: white;
              font-size: 20px;
              width: 100%;
              font-family: @fontNowayMedium;
              margin: 0;
            }

            .example-text {
              color: #bac1cc;
              font-size: 18px;
              font-family: @fontOpenSans;
              line-height: 1.7;
              width: 100%;
              padding: 0;
            }
          }
        }

        .examples-container-lower {
          margin-top: 140px;

          .second img {
            margin-bottom: 17px;
          }

          .example-lower {
            text-align: center;
          }

          img {
            margin-bottom: 30px;
          }

          p {
            font-size: 21px;
            color: #fff;
          }
        }

      }

      .streak-block {
        margin-top: 80px;
        min-height: 692px;
        background: url('~img/visions/plo/streak-section-bg.jpg') no-repeat top right;
        background-size: auto 692px;

        .description-block {
          max-width: 500px;
        }

        .title-description {
          background: url('~img/visions/plo/icon-plus.png') no-repeat 0% 40%;
          background-size: 12px auto;
          font-family: @fontNowayMedium;
          font-size: 11px;
          text-transform: uppercase;
          color: #7c8490;
          letter-spacing: 0.18em;
          padding-left: 25px;
          margin-bottom: 25px;
        }

        h3 {
          color: white;
          font-size: 60px;
          font-family: @fontOpenSans;
          padding: 0 0 .6em;
          font-weight: 500;
          line-height: 1.3em;
        }

        .description-text {
          color: #bcc0cc;
          font-size: 18px;
          font-family: @fontOpenSans;
          text-align: left;
          width: 70%;
          font-weight: 100;
          line-height: 1.75em;
          padding-top: 0;
          min-width: 420px;

        }
      }

      .footer-block {
        margin: 0 auto;
        text-align: center;
        padding: 60px 0;

        .vision-plans {

          padding: 20px 20px 70px;
          background: #181b21;
          border-radius: 13px;

          &__monthly-yearly-switch {
            position: relative;
            margin: 40px 110px 40px;
          }

          .free-trial-available-plans-selection {
            font-size: 22px;
            color: @colorRed;
            font-family: @fontHeading;
            font-weight: bold;
            padding: 10px;
          }

          .vision-plans-term-form {
            display: block;

            &.blackfriday {
              margin-bottom: 20px;
            }

            .vision-plans-term-form-inner {
              margin: 0;
              padding: 0;
              border: 2px solid #2b2e38;
              background: #1e2128;
              position: relative;
              display: inline-flex;
              border-radius: 25px;
            }

            .plan-option-wrap {
              display: inline-block;
            }

            input[name="vision-plans-term"] {
              display: none;
            }

            label {
              margin: 0;
              padding: 0;
              width: 165px;
              height: 46px;
              display: inline-block;
              position: relative;
              z-index: 2;
              font-size: 16px;
              text-align: center;

              .description {
                font-family: @fontOpenSans;
                display: inline-block;
                padding: 15px 10px 0;
                cursor: pointer;
                line-height: 1em;
                color: #fff;
                font-weight: 700;
                transition: color .25s;
                transition-delay: .0s;
              }

              .savings {
                display: inline-block;
                font-size: 13px;
                color: #f33446;
              }

              // 2 line plans
              &.vision-plans-3-month-label,
              &.vision-plans-12-month-label {
                .description {
                  padding: 5px 10px 0;
                }
              }

              &.vision-plans-3-month-label.blackfriday {
                .description {
                  padding: 15px 10px 0;
                }
              }

              &.vision-plans-12-month-label.blackfriday {
                position: relative;
              }
            }

            #vision-plans-1-month[name="vision-plans-term"]:checked ~ .vision-plans-1-month-label,
            #vision-plans-3-month[name="vision-plans-term"]:checked ~ .vision-plans-3-month-label,
            #vision-plans-12-month[name="vision-plans-term"]:checked ~ .vision-plans-12-month-label {
              .description {
                color: #0e1015;
                transition: color .25s;
                transition-delay: .05s;
              }

            }

            .selected-term-slider {
              position: absolute;
              left: 0;
              top: -2px;
              width: 165px;
              height: 50px;
              padding: 0;
              margin: 0;
              background: #fff;
              z-index: 1;
              border-radius: 25px;
              animation-fill-mode: forwards;
              transition: 0.2s all .05s ease-in-out;
            }

            #vision-plans-1-month:checked ~ .selected-term-slider {
              left: -2px;
            }

            #vision-plans-3-month:checked ~ .selected-term-slider {
              left: 33.3%;

              &.hide-monthly {
                left: -2px;
              }
            }

            #vision-plans-12-month:checked ~ .selected-term-slider {
              left: 67.2%;
              &.hide-monthly {
                left: 50%;
              }
              &.hide-quarterly {
                left: -2px;
                &:not(.hide-monthly) {
                  left: 50%;
                }
              }
            }
          }


          &__products {
            list-style: none;
            display: flex;
            flex-direction: row;
            text-align: center;
            max-width: 900px;
            margin: 0 auto;
            justify-content: space-around;
            //height: 250px;

            li {
              width: 32%;

              &.disabled {
                opacity: 0.8;
                pointer-events: none;

                .price-discount {
                  visibility: hidden;
                }

                .price {
                  span {
                    display: none;
                  }
                }

                &:hover {
                  cursor: none;
                  text-decoration: none;
                }

                .remove-product {
                  display: inline-block;
                }
              }

              .product-description {
                border: 2px solid #262935;
                padding: 20px;
                color: white;
                background-color: #1e2128;
                min-height: 220px;
                font-family: @fontOpenSans;
                font-weight: bold;
                position: relative;

                .black-friday-sale-sticker {
                  height: 90px;
                  width: 90px;
                  position: absolute;
                  top: -55px;
                  left: 0;
                  background: url(~img/subscribe/plans_pricing/black-friday-sale-sticker.png) no-repeat;
                  background-size: 90px auto;

                  @media (max-width: 1285px) {
                    left: 0;

                    @media (max-width: 700px) {
                      top: -15px;
                      height: 75px;
                      width: 75px;
                      background-size: 75px auto;
                    }
                  }
                }

                .price-discount {
                  color: #f33446;
                  text-decoration: line-through;
                  font-size: 16px;

                  // So the spacing doesn't change when there is no discount.
                  .no-discount {
                    text-decoration: none;
                    opacity: 0;
                  }
                }

                .price.blackfriday {
                  font-size: 16px;
                  text-decoration: line-through;
                  color: #d0d8d9;
                }

                .price {
                  font-size: 30px;

                  span {
                    font-size: 12px;
                  }
                }

                .description-plan {
                  font-size: 18px;
                  margin-bottom: 35px;
                }

                a {
                  color: #fff;
                  font-size: 16px;
                  font-weight: 700;
                  text-align: center;
                  font-family: @fontOpenSans;
                  border-radius: 30px;
                  padding: 10px 30px;
                  cursor: pointer;
                  background-color: #515561;
                  display: inline-block;
                  width: 90%;
                  max-width: 300px;

                  &.selected {
                    background: #A70106;

                    &:hover {
                      background: #A70106;
                      cursor: default;
                    }
                  }

                  &:hover {
                    background-color: #787c8e;
                    text-decoration: none;
                  }
                }

                &:hover {
                  transform: scale(1.03);
                  border: 3px solid #f33446;
                  box-shadow: 0 0 10px 10px rgba(255, 56, 78, 0.2);
                }
              }

              margin: 10px;
            }

            &.show-monthly {
              li.monthly {
                display: block;
              }

              li.quarterly {
                display: none;
              }

              li.yearly {
                display: none;
              }
            }

            &.show-quarterly {
              li.monthly {
                display: none;
              }

              li.quarterly {
                display: block;
              }

              li.yearly {
                display: none;
              }
            }


            &.show-yearly {
              li.yearly {
                display: block;
              }

              li.quarterly {
                display: none;
              }


              li.monthly {
                display: none;
              }
            }
          }

          .cart-skip {
            color: black;
          }

        }
      }
    }


    .five-card-plo-block {
      max-width: 560px;
      text-align: center;
      margin: 100px auto 150px;

      .header-subtitle {
        font-family: @fontNowayMedium;
        display: inline-block;
        margin: 0 auto;
        background: #fff;
        color: #000;
        text-transform: uppercase;
        font-size: 11px;
        padding: .5em 1.25em;
        letter-spacing: .25em;
      }

      .header-title {
        font-family: @fontNowayMedium;
        font-size: 60px;
        font-weight: 300;
        color: #fff;
        padding: .25em 0;
      }

      p {
        color: #bcc0cc;
        font-size: 18px;
        font-family: @fontOpenSans;
        font-weight: 100;
        line-height: 1.75em;
        padding-bottom: 1.75em;
      }

      .disclaimer {
        font-size: 15px;
        font-style: italic;
      }
    }

    // testimonial block
    .vision-testimonial-block {
      color: white;
      text-align: center;

      .title-description {
        background: url('~img/visions/plo/icon-plus.png') no-repeat 0 40%;
        background-size: 12px auto;
        font-family: @fontNowayMedium;
        font-size: 11px;
        text-transform: uppercase;
        color: #7c8490;
        letter-spacing: 0.18em;
        padding-left: 25px;
        margin-bottom: 25px;

        display: inline-block;
      }

      h3 {
        font-size: 60px;
        font-family: @fontNowayMedium;
        padding-bottom: 30px;
      }

      .testimonials-wrapper {
        position: relative;
        width: 800px;
        margin: 0 auto;
        min-height: 270px;

        .testimonial {
          width: 100%;
          display: none;

          &.active {
            display: block;
          }

          p {
            font-family: @fontOpenSans;
            font-size: 26px;
            font-style: italic;
            line-height: 1.5;
          }

          .testimonial-text {
            min-height: 210px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .author-container {
            display: flex;
            width: fit-content;
            min-width: 240px;
            margin: 40px auto;

            .info-container {
              text-align: center;
              margin-left: 10px;

              p {
                font-family: @fontOpenSans;
                line-height: 1.4;
                font-size: 16px;
                font-style: normal;
                padding-bottom: 0;
              }

              .author-name {
                color: white;
                font-weight: bolder;
              }

              .author-info {
                color: #8e93a0;
              }
            }
          }

        }
      }

      .arrow {
        position: absolute;
        cursor: pointer;
        top: 20%;
      }

      .right-arrow {
        right: -100px;
      }

      .left-arrow {
        transform: rotate(180deg);
        left: -100px;
      }
    }


    .faqs {
      max-width: 840px;
      margin: 80px auto;
      text-align: left;

      h3 {
        font-family: @fontNowayMedium;
        color: #fff;
        font-size: 60px;
      }

      li {
        border-bottom: 2px solid #2b2f38;
        padding: 3.5em 0 1.5em;
        list-style: none;
      }

      h4 {
        font-weight: 500;
        font-size: 22px;
        color: #fff;
        margin-bottom: 1em;
        padding-right: 15px;
        padding-bottom: 0;
        position: relative;
        cursor: pointer;
      }

      .faq-answer {
        overflow: hidden;
        height: 0;
      }

      .controls {
        display: block;
        position: absolute;
        top: .25em;
        right: 0;
        height: 15px;
        width: 15px;
        background: url('~img/courses/this-is-plo/close-expand.png') bottom left no-repeat;
        background-size: 12px auto;
      }

      li.expanded {
        .faq-answer {
          height: auto;
        }

        .controls {
          background-position: 0 7px;
        }
      }

      p {
        font-size: 18px;
        color: #bcc0cc;
        line-height: 1.5em;
      }
    }


    .get-started-section {
      padding: 0 20px;
      margin-top: 30px;
      text-align: center;
    }


    .next-level {
      margin: 160px auto 0;
      max-width: 680px;
      text-align: center;

      h3 {
        font-size: 48px;
        font-weight: normal;
        padding-bottom: 1em;
      }
    }


    .early-access-info {
      margin: 140px auto;
      text-align: center;

      h6 {
        font-size: 23px;
        padding-bottom: 1em;
      }

      p {
        font-size: 30px;
        line-height: 1.3em;
      }
    }

  }

  // Modal size - for video modals.
  .rio-modal {
    .content {
      width: 80vw;
      height: 80vh;
    }
  }

  @media (max-width: 1400px) {
    .plo-outer {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 1340px) {
    .plo-outer {
      .plo-visions-body {

        .title-block {
          //margin-left: 20px;
          //margin-right: 20px;

          .title {
            font-size: 100px;
            width: 60%;
          }
        }

        .description-block {
          //margin-left: 20px;
          //margin-right: 20px;

          .description-block-upper .description-cards {
            gap: 20px;
          }

          .examples-container {
            margin-left: 0;
          }
        }

        .streak-block {
          //padding-top: 35vw;
          background-size: 60% auto;
          background-position: top right;
          text-align: left;


          .description-block {
            //width: 80%;
            //margin: 0 auto;
          }

          .description-text {
            margin: 0 !important;
          }

          ul {
            margin: 0 auto;
          }
        }
      }
    }

  }

  @media (max-width: 1200px) {
    .plo-outer {
      .plo-visions-body {
        .description-block {
          .description-block-upper {
            flex-direction: column;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .plo-outer {

      .vision-testimonial-block {

        h1 {
          font-size: 58px;
        }

        .testimonials-wrapper {
          width: 600px;

          .testimonial {
            .testimonial-text {
              min-height: 185px;
            }

            p {
              font-size: 22px;
              line-height: 1.3;
            }

            .author-container {
              margin: 15px auto;
            }
          }

          .arrow {
            top: 25%;
          }

          .left-arrow {
            left: -80px;
          }

          .right-arrow {
            right: -80px;
          }
        }

      }

      .footer-block {
        .vision-plans {
          &__products {
            flex-direction: column !important;
            height: auto !important;
            align-items: center;

            li {
              min-width: 280px;
              max-width: 500px;
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .plo-outer {
      .plo-visions-body {
        .title-block {
          margin: 100px 0;

          img {
            width: ~"calc(200px +  160 * ((100vw - 300px) / 600))";
          }

          h2 {
            font-size: ~"calc(14px +  12 * ((100vw - 300px) / 600))";
          }
        }

        .description-block {

          margin-left: 0;
          margin-right: 0px;

          h1 {
            font-size: ~"calc(30px +  40 * ((30vw - 300px) / 600))";
          }

          .description-text {
            margin: 0 auto;
            font-size: 22px;
          }

          .example-list {
            width: 440px;
            margin: 0 auto;
          }

          .examples-container {
            margin-left: 0px;
            flex-direction: column;
            align-items: center;

            .example {
              width: ~"calc(280px +  160 * ((100vw - 300px) / 600))";
              padding-top: 70px;
            }

          }
        }

        .footer-block {
          margin-top: ~"calc(50px +  110 * ((100vw - 400px) / 500))";
          margin-bottom: ~"calc(50px + 110 * ((100vw - 400px) / 500))";

          h1 {
            font-size: ~"calc(34px +  30 * ((100vw - 400px) / 500))";
          }

          .vision-plans {

            &__monthly-yearly-switch {
              margin: 40px auto;

              span {
                &.annual {
                  br {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // 1154px - small laptop or ipad portrait mode
  @media (max-width: @widthLaptopMax) {
    .plo-outer {
      .plo-visions-body {

        .title-block {
          //padding-bottom: 490px;
          margin-bottom: 130px;

          .title {
            width: auto;
            font-size: 90px;
          }

          .vision-interface {
            margin: 100px auto 0;
            position: relative;

            .vision-interface-inner {
              margin: 0 auto;
            }
          }
        }


        // Cards
        .description-block .description-text {
          margin: 0;
        }

        .description-block .description-block-upper {
          .description-card {

          }
        }


      }


    }
  }

  // 767px - phones
  @media (max-width: @widthMobileMax) {

    .plo-outer {

      .plo-visions-body .title-block, .streak-block {

        .title-description {
          display: none;
        }

        .title {
          text-align: center;
          width: 100%;
          line-height: .75em;

          .main-title {
            font-size: 90px;
            text-transform: uppercase;
            display: block;
          }

          .sub-title {
            display: block;
            font-size: 30px;
          }
        }

        .vision-interface {
          width: 100%;

          .vision-interface-inner {
            width: 100%;
          }
        }

        .button-wrap {
          text-align: center;

          .free-trial-available {
            margin: 0 auto;
          }

          .get-started-button {
            margin: 0 auto;
          }

          .go-to-vision-button {
            margin: 15px auto 0;
          }

          p {
            margin: 15px 30px;
            display: inline-block;
          }
        }
      }

      .plo-visions-body .description-block {
        .description-text {
          font-size: 18px;
          min-width: 300px;
          width: 80%;
        }
      }

      .plo-visions-body .description-block .description-block-upper .description-cards {
        display: none;
      }

      .plo-visions-body .description-block .examples-container-lower {
        flex-direction: column;

        .example {
          width: auto;
          margin-bottom: 30px;
        }
      }

      .plo-visions-body .description-block .example-list {

        width: 80%;
        min-width: 300px;

        li {
          font-size: 16px;

          span {
            margin-left: 25px;
          }

          &:before {
            height: 20px;
            width: 20px;
          }
        }

      }

      .plo-visions-body .streak-block {
        background: none;
      }

      .vision-testimonial-block {
        h1 {
          font-size: ~"calc(20px +  38 * ((100vw - 300px) / 430))";
        }

        .testimonials-wrapper {
          width: ~"calc(200px +  400 * ((100vw - 300px) / 430))";

          .testimonial {
            p {
              font-size: ~"calc(14px +  8 * ((100vw - 300px) / 430))";
              line-height: 1.1;
            }

            .author-container {
              margin: 15px auto;

              .info-container {
                p {
                  font-size: 12px;
                }
              }
            }
          }

          .arrow {
            height: 35px;
            width: auto;
          }

          .left-arrow {
            left: -40px;
          }

          .right-arrow {
            right: -40px;
          }
        }
      }

      .plo-visions-body .footer-block {
        .vision-plans {
          .vision-plans-term-form {
            .vision-plans-term-form-inner {
              flex-direction: column;
            }

            label {
              margin: 0 auto;
              width: 300px;
              display: block;
              height: 60px;

              .description {
                padding-top: 21px !important;
              }
            }

            .selected-term-slider {
              left: 0;
              width: 300px;
              height: 60px;
            }

            #vision-plans-1-month:checked ~ .selected-term-slider {
              top: -2px;
              left: 0;
            }

            #vision-plans-3-month:checked ~ .selected-term-slider {
              top: 60px;
              left: 0;

              &.hide-monthly {
                top: -2px;
                left: 0;
              }
            }

            #vision-plans-12-month:checked ~ .selected-term-slider {
              top: 120px;
              left: 0;

              &.hide-monthly {
                top: 60px;
                left: 0;
              }
              &.hide-quarterly {
                top: -2px;
                left: 0;

                &:not(.hide-monthly) {
                  top: 60px;
                  left: 0;
                }
              }
            }
          }
        }
      }

    }

  }

}


@keyframes change-background {
    0% { background: #e42538 }
    50% { background: #b51b2a }
    100% {background: #e42538 }
}
