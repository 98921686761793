//
// Mixins
// Snippets of reusable CSS to develop faster and keep code readable
// ---------------------------------------------------------------------------------------------------------------------

.tooltip() {
  position: relative;

  &:hover {
    cursor: pointer;

    &:after {
      background: #333;
      background: rgba(0,0,0,0.8);
      border-radius: 5px;
      color: #fff;
      content: attr(tooltip-text);
      padding: 8px 15px;
      position: absolute;
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 98;
      font-weight: 500;
      font-size: 11px;
      font-family: @fontDefault;
      width: auto;
      white-space: nowrap;
    }

    &:before {
      border: solid;
      border-color: #333 transparent;
      border-width: 0px 10px 12px 10px;
      content: "";
      position: absolute;
      top: 18px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
    }
  }
}


// Clearfix to clear floated elements. Apply to parent of floated elements - h5bp.com/q
.clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}
// for legacy only
.clearfix {
  .clearfix();
}



// Hide items on mobile only
@media (max-width: @widthMobileMax) {
  // Show
  .visible-phone   { display: inherit !important; } // Use inherit to restore previous behavior

  // Hide
  .hidden-phone    { display: none !important; }

  // Hide everything else
  .hidden-desktop  { display: inherit !important; }
  .visible-desktop   { display: none !important; }
}

@media (min-width: @widthTabletMin) {
  // Show
  .visible-phone   { display: none !important; } // Use inherit to restore previous behavior

  // Hide
  .hidden-phone    { display: inherit !important; }

  // Hide everything else
  .hidden-desktop  { display: none !important; }
  .visible-desktop   { display: inherit !important; }
}



// New way to hide text where you want semantically for there to be text in the DOM but not show because of an image.
// Based on Zeldman's post http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.hide_text() {
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
}



// adds styling that grays out select button, prevents use
.no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



// Module to create an avatar sizing with circular border
.avatar-circle(@size) {
  // the border-radius needs to be half the size of the element to create a circle
  @half-size: @size / 2;

  // generate the border-radius declarations
  .border-radius(50%);

  background-repeat: no-repeat;
  background-size: 100% auto;
  height: unit(@size, px);
  position: relative;
  width: unit(@size, px);
  z-index: 2;
}



// Make a multibrowser compatible transition
// Based on http://caniuse.com/css-animation
.animation (@name, @duration: 0.2s, @delay: 0, @ease: ease-out, @repeat: 1) {
  animation-name: @name;
  animation-duration: @duration;
  animation-timing-function: @ease;
  animation-delay: @delay;
  animation-iteration-count: @repeat;
}



// Fix to deal with whitespace between inline-block elements
// based on http://davidwalsh.name/remove-whitespace-inline-block
.inline-block-fix() {
  margin-left: -4px;
}



// Make a multibrowser compatible transition
// Based on http://caniuse.com/#feat=css-transitions
.transition(@target: all, @duration: 0.2s, @ease: ease-out) {
  transition: @target @duration @ease; // IE10+, FF 26+, Chrome 31+
}


.transition-delay(@delay: 0.2s) {
  transition-delay: @delay;
}



// Make an equal border-radius that's multibrowser compatible
// NOTE this is only a mixin so that the background-clip is always used when doing a border-radius
// Based on http://caniuse.com/#feat=border-radius
//
// @size - How large should the radius be?
// @corner - OPTIONAL - What corner should this be on? Options are top-left, top-right, bottom-left and bottom-right.
.border-radius(@size, @corner: all) {
  & when (@corner = all) {
    border-radius: @size;
  }

  & when not (@corner = all) {
    border-@{corner}-radius: @size;
  }

  // used to make sure anything in the element is held within the boundaries
  background-clip: padding-box;
}

// upper and lower border-radius shortcuts
.upper-border-radius(@radius) {
  border-top-left-radius: @radius;
  border-top-right-radius: @radius;
}
.lower-border-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-bottom-right-radius: @radius;
}



// Multibrowser compatible CSS 2D transforms
// Based on http://caniuse.com/transforms2d
.transform(@arguments, @origin: 0 0) {
  transform: @arguments;
  transform-origin: @origin;
}



.vertical-gradient(@start, @stop) {
  background: -webkit-gradient(linear,
                               left bottom,
                               left top,
                               color-stop(0, @start),
                               color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
                                  @start,
                                  @stop);
  background: -moz-linear-gradient(center bottom,
                                   @start 0%,
                                   @stop 100%);
}



// drop shadow shortcut
.box-shadow(@shadow) {
  box-shadow: @shadow;
}



// Set parent element to be use the flexbox layout.
.flex_container(@direction) {
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: @direction;
}

// Used on columns inside of a .flex_container. Note: You must give columns a width.
.flex_column(@size) {
  flex: @size;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

// To change the order of a .flex_column. @order = integer.
.flex_column_order(@order) {
  order: @order;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
}



/*
  Use separate background images for retina and standard screens.

  Example Usage:
    Regular image path: /images/sprite.png
    Retina image path:  /images/sprite@2x.png

    #element{
      .retina-background-image('/images/sprite','png', no-repeat, 100px, auto, -10px, 0);
    }
*/
.retina-background-image(@file, @type, @repeat, @width, @height, @posx, @posy) {
  background-image: url('@{file}.@{type}');
  background-repeat: @repeat;
  background-position: @posx @posy;
  background-size: @width @height;

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url('@{file}@2x.@{type}');
    background-position: @posx * 2 @posy * 2;
  }
}


.center-vertically-absolute {
  // nudging it up half of it's height after bumping it down halfway
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.center-horizontally-absolute {
  // same logic as above
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-vertically-and-horizontally-absolute {
  // same logic as above
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-horizontally-relative {
  position: relative;
  margin: 0 auto;
}

.box-sizing(@argument) {
  box-sizing: @argument;
}


.filter(@arguments) {
  filter: @arguments;
}

.font(@family, @size, @color, @weight) {
  font-family: @family;
  font-size: @size;
  color: @color;
  font-weight: @weight;
}

.animation-delay(@time) {
  animation-delay: @time;
}

.animation-timing-function(@type) {
  animation-timing-function: @type;
}

.background-clip(@arg) {
  -webkit-background-clip: @arg;
  -moz-background-clip: @arg;
  background-clip: @arg;
}
