// Template Section: Footer
#footer {
  .clearfix();
  // put in a plane above sticky elements
  // .transform(translate3d(0,0,2px));
  background: #090b12;
  padding: 66px 0;
  width: 100%;
  position: relative;
  // just higher than the .body-background
  z-index: 501;

  // required for all global pieces of the site that span both stylesheets
  *, *:before, *:after {
    // use box sizing for everything within this layout
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .footer-content {
    display: flex;
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
  }

  header {
    display: flex;
  }

  .logo {
    // 42 61  0.705
    display: inline-block;
    background: url("~img/pages/elite-landing/logo.webp");
    background-size: cover;
    min-width: calc(0.7 * 30px);
    min-height: 30px;
    margin-right: 16px;
  }

  h6 {
    font-family: @fontOpenSans;
    font-weight: @fontWeightRegular;
    font-size: 12px;
    color: #7c8490;
    align-self: center;
  }


  .links {
    float: right;
    vertical-align: middle;
    text-align: right;
    display: block;
  }


  .links > section {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    min-width: 180px;

    &:nth-child(3) {
      min-width: auto;
    }
  }

  h3 {
    font-family: @fontOpenSans;
    font-weight: @fontWeightRegular;
    color: #fff;
    font-size: 14px;
    padding-bottom: 28px;
  }

  ul {
    list-style: none;
    padding: 0;

    a {
      font-family: @fontOpenSans;
      font-weight: @fontWeightRegular;
      color: #7c8490;
      font-size: 11px;
      text-transform: uppercase;
      text-decoration: none;
      display: inline-block;
      padding: 6px 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


@media (max-width: 960px) {
  #footer {
    .footer-content {
      flex-direction: column-reverse;
    }

    header {
      margin-top: 100px;
      width: 100%;
      justify-content: center;
    }

    .links {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }
}

@media (max-width: @widthMobileMax) {
  #footer {
    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > section {
        text-align: center;
      }

      h3 {
        padding: 20px 0 4px 0;
      }
    }
  }
}
