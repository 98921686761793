body.profile#users {
  #neck {
    .layout-list-with-filters {
      background: #f7f8f8;
      padding: 20px 0;

      h1 {
        color: #272a2b;
        font-size: 36px;
        font-weight: 700;
      }
    }
  }

  #content_filters {
    margin: 20px 0 0 -8px;
  }

  .col_1 {
    .flex_container(row);
  }

  .filters-and-table {
    .flex_column(5);
    margin-bottom: 100px;
    position: relative;

    table .btn {
      font-size: 11px;
      padding: 4px 14px;
      border-radius: 3px;
    }
  }

  aside {
    .box-shadow(none);
    border: none;
    background: none;
    .flex_column(1);
    max-width: 400px;
    min-width: 400px;
  }

  @media (max-width: @widthTabletMax) {
    aside {
      display: none;
    }
  }

}