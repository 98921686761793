/**
  Small video progress images on the pro video listing page.
*/
.video-progress-small {
  background: url('~img/components/video-progress/progress-small@2x.png') no-repeat;
  background-size: 150px 15px;
  display: inline-block;
  height: 15px;
  margin-right: 4px;
  position: relative;
  text-indent: -9999px;
  top: -1px;
  width: 15px;

  &.percent-0 {
    background-position: 0px 0 !important;
  }

  &.percent-10 {
    background-position: -15px 0 !important;
  }

  &.percent-20 {
    background-position: -30px 0 !important;
  }

  &.percent-30 {
    background-position: -45px 0 !important;
  }

  &.percent-40 {
    background-position: -60px 0 !important;
  }

  &.percent-50 {
    background-position: -75px 0 !important;
  }

  &.percent-60 {
    background-position: -90px 0 !important;
  }

  &.percent-70 {
    background-position: -105px 0 !important;
  }

  &.percent-80 {
    background-position: -120px 0 !important;
  }
  // 80 & 90 use the same stop since we only have 10 of them.
  &.percent-90 {
    background-position: -120px 0 !important;
  }

  &.percent-100 {
    background-position: -135px 0 !important;
  }
}


.video-progress-small-featured {
  background: url('~img/components/video-progress/progress-small@2x-transperant.png') no-repeat;
  background-size: 200px 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 30px;
  height: 20px;
  width: 20px;

  &.percent-0 {
    background-position: 0px 0;
  }

  &.percent-10 {
    background-position: -20px 0;
  }

  &.percent-20 {
    background-position: -40px 0;
  }

  &.percent-30 {
    background-position: -60px 0;
  }

  &.percent-40 {
    background-position: -80px 0;
  }

  &.percent-50 {
    background-position: -100px 0;
  }

  &.percent-60 {
    background-position: -120px 0;
  }

  &.percent-70 {
    background-position: -140px 0;
  }

  &.percent-80 {
    background-position: -160px 0;
  }
  // 80 & 90 use the same stop since we only have 10 of them.
  &.percent-90 {
    background-position: -160px 0;
  }

  &.percent-100 {
    background-position: -180px 0;
  }
}


/**
  The circular progress bar on the desktop video player.
*/
 @-webkit-keyframes
  load { 0% {
    stroke-dashoffset: 310
  }
}
@-moz-keyframes
  load { 0% {
    stroke-dashoffset: 310
  }
}
@keyframes
  load { 0% {
    stroke-dashoffset: 310
  }
}



.circular-progress-bar {
  width: 115px;
  height: 115px;
  margin: 0 auto 20px;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  .icon-play-rotate {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -21px;
    margin-left: -17px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

    .icon-play {
      top: 0 !important;
      left: 0 !important;
      height: 38px !important;
      width: 38px !important;
    }
  }

  svg.circles {
    width: 110px;
    height: 110px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: rotate(-91deg);
    -moz-transform: rotate(-91deg);
    -ms-transform: rotate(-91deg);
    transform: rotate(-91deg);

    circle {
      fill: none;
      stroke-width: 4px;
      stroke: #4b4e4f;
    }

    circle:nth-child(2) {
      fill: none;
      stroke: #a81313;

      -webkit-animation: load 1.5s;
      -moz-animation: load 1.5s;
      -o-animation: load 1.5s;
      animation: load 1.5s;
    }
  }
}



/**
  The circular progress bar on mobile.
*/
.horizontal-progress-bar {
  background: #36393a;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0px;
  display: none;

  .progress-meter {
    background: #a81313;
    height: 3px;
    width: 0px;
  }
}



// Phones
@media (max-width:@widthMobileMax) {
  .circular-progress-bar {
    display: none;
  }

  .horizontal-progress-bar {
    display: block;
  }
}
