.flag-control {
  background: url('~img/components/icon/flag.png') no-repeat 5px 7px;
  background-size: 11px auto;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  width: 25px;
  top: -2px;
  position: relative;
  opacity: .9;

  &:hover {
    opacity: .8;
  }

  span {
    display: none;
  }

  &.flagged {
    background: none;
    width: auto;
    cursor: default;

    &:hover {
      opacity: .9;
    }

    span {
      display: block;
    }
  }
}


.edit-controls {
  .border-radius(4px);
  background: #272a2b;
  display: inline-block;
  list-style: none;
  margin: 0 0 0 15px;
  overflow: hidden;
  padding: 0;
  position: relative;

  li {
    margin: 0;
    float: left;
    padding: 0;

    &:last-child a,
    &:last-child .btn-delete, {
      border-right: none;
    }
  }

  li > a,
  .btn-delete {
    display: block;
    padding: 2px 3px;
    margin: 0;
    color: #ababab;
    border-right: 1px solid #52585A;
    overflow: hidden;

    &:hover {
      background: #3c4042;
    }
  }

  .icon {
    // both height and width must be set for ie10 yay
    display: block;
    float: left;
    height: 24px;
    text-indent: -9999px;
    width: 24;
  }

  .confirm {
    .transition(width, 0.2s, ease-in-out);
    cursor: default;
    display: block;
    float: left;
    height: 25px;
    line-height: 2em;
    overflow: hidden;
    text-indent: 0;
    width: 0;
    word-break: keep-all;

    &.active {
      width: 155px;
    }

    .confirm-inner {
      display: block;
      overflow: hidden;
      width: 155px;
      padding: 0;

      span {
        cursor: pointer;
        overflow: hidden;
        padding: 0 5px;
        text-indent: 0;
        width: auto;

        &:hover {
          color: #FFF;
        }
      }
    }
  }

}
