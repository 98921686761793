// .wmd-panel {
//     margin-left: 25%;
//     margin-right: 25%;
//     width: 50%;
//     min-width: 500px;
// }



.wmd-wrap {
  .inline-block-fix();
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;

  .drop_info {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    padding: 20px;
    text-align: center;
    width: 100%;

    .inner {
      border: 2px dashed #b2b9bb;
      padding: 20px;
    }
  }

  .editor-wrap {
    .border-radius(4px);
    background: #fff;
    border: 1px solid @colorBorder;
    margin-bottom: 20px;
    overflow: hidden;

    // Image drag over
    &.dragover {
      border-color: #999;

      textarea {
        background: url('~img/components/pagedown/drop_bg.png') no-repeat 50% 50%;
        background-size: 90%;
        color: #fff !important;
      }
    }
  }

  .wmd-panel {
    overflow: hidden;
    margin-bottom: 20px;
  }

  textarea {
    .box-shadow(none);
    border: none;
    display: block;
    font-size: 15px;
    font-weight: 300;
    height: 80px;
    line-height: 1.6;
    margin: 0;
    min-height: 80px;
    resize: none;
    padding: 0 2% 2%;
    width: 100%;
    word-wrap: break-word;
  }

  .wmd-button-bar {
      width: 100%;
      background-color: Silver;
  }

  .wmd-preview {
    // border-top: 1px solid @colorBorder;
    // border-bottom: 1px solid @colorBorder;
    padding: 10px 0 0;
    margin: 10px 0;

    .mention-href {
      background: #a5aeb2;
      color: #fff;
      font-weight: 500;
      padding: 3px;
      text-decoration: none;
      .border-radius(2px);

      &.pro {
        background-color: #b7a475;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .wmd-button-row {
      border-bottom: 1px solid #eee;
      position: relative;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      margin-top: 10px;
      padding: 0px 0 0 5px;
  }

  // .wmd-spacer {
  //     width: 1px;
  //     height: 20px;
  //     padding-right: 0px;
  //     margin-left: 5px;
  //     margin-right: 10px;
  //     background-color: Silver;
  //     display: inline-block;
  //     list-style: none;
  //     display: none;
  // }

  .wmd-button {
      width: 20px;
      height: 20px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 5px;
      display: inline-block;
      list-style: none;
      cursor: pointer;
      text-align: center;
  }

  .wmd-button > span {
      background: url('~img/components/pagedown/buttons.png') no-repeat top left;
      background-size: 102px 10px;
      width: 20px;
      height: 16px;
      display: inline-block;
      opacity: .8;
      // border: 1px solid red;
      //
      &:hover {
        opacity: 1;
      }
  }



  .wmd-bold-button {
    span {
      background-position: 5px 3px;
    }
  }

  .wmd-spacer {
    span {
      background-position: 4px 0;
    }
  }

  .wmd-italic-button {
    span {
      background-position: -17px 3px;
    }

  }

  .wmd-link-button {
    span {
      background-position: -59px 3px;
    }
  }

  .wmd-quote-button {
    span {
      background-position: -36px 3px;
    }
  }

  .wmd-image-button {
    span {
      background-position: -87px 3px;
    }
  }

}

// modal window that allows users to upload image for their comment.
.modal.imageupload {
  height: 200px;
  top: 35%;

  form {
    text-align: center;
    padding: 30px 15px 0;
  }

  .modal-dialog {
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 400px;
  }

  .modal-content {
    height: 100%;
  }

  section {
    overflow-y: scroll;
  }

  header {
    .clearfix();
    background: #fff;
    padding: 18px 25px 18px 30px;
    width: 100%;
  }

  h1 {
    color: #6a6f71;
    float: left;
    font-size: 15px;
    font-weight: normal;
    line-height: 1;
  }

  .btn {
    background: #fff;
  }

  .icon-close {
    cursor: pointer;
    float: right;
    height: 14px;
    outline: none;
    width: 14px;
  }
}


.wmd-prompt-background {
  background-color: black;
}

.wmd-prompt-dialog {
  .border-radius(5px);

  form {
    margin-bottom: 15px !important;
    padding: 20px 0 0;

    .btn {
      background: #fff;
      margin: 5px;
    }
  }
}

.wmd-prompt-dialog {
  padding: 0;
  border: 1px solid #999999;
  background-color: #F3F5F6;
  overflow: hidden;

  header {
    padding: 20px 30px;
    background: #fff;

    h1 {
      color: #6A6F71;
      font-size: 15px;
      font-weight: normal;
      line-height: 1;
    }
  }
}

.wmd-prompt-dialog > div {
    font-size: 0.8em;
    font-family: arial, helvetica, sans-serif;
}

.wmd-prompt-dialog > form > input[type="text"] {
    // border: 1px solid #999999;
    // color: black;
}


// Mentio selection menu styling goes in here
mentio-menu {
  background: #3b3e3f;
  opacity: 0.9;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  .box-shadow(0px 0px 5px 1px rgba(0,0,0,0.75));
  font-size: 14px;

  .dropdown-menu {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    overflow: hidden;

    padding: 0;
    list-style: none;

    li {
      padding: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  img {
    height: 30px;
    width: auto;
    vertical-align: middle;
    margin-left: 5px;
  }

  .active {
    background: #626262;
  }

  .menu-highlighted {
    color: #fff;
  }

  a.text-primary {
    color: #b1bac2;
    padding-left: 10px;

    &:hover {
      text-decoration: none;
    }
  }

  a.text-username  {
    color: #b1bac2;

    &:hover {
      text-decoration: none;
    }
  }
}
