.social-shares {
  display: inline-block;

  a.facebook {
    background-image: url(~img/partials/thread/facebook@2x_no_circle.png);
    background-size: 100% 100%;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
  }

  a.twitter {
    background-image: url(~img/partials/thread/twitter@2x_no_circle.png);
    background-size: 100% 100%;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
  }
}

// styles specific for when buttons (not on provideo)
.social-shares.detail.thread {
  margin-top: 15px;
}


// styles specific for when buttons (not on provideo)
.social-shares.feed.thread {
  margin-top: 15px;

  a.facebook {
    vertical-align: baseline;
  }

  a.twitter {
    vertical-align: baseline;
  }
}
