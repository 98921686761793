.raf {

  &__btn-dark {
    // dark backgrouns raf related are in banners
    color: #fff;
    background: #272a2b;
    border: none;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 7px 20px 5px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: nowrap;

    &:hover {
      background-color: #323536;
      color: #fff;
    }

    &.site-wide-banner-btn {
      font-size: 13px;
      padding: 10px 35px 10px 35px;
      .border-radius(5px);
      margin-bottom: 5px;
      line-height: 3;
    }
  }

  &__cta-btn {
    border: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 13px 30px 13px 30px;
    margin: 0px 15px 0px 15px;
    font-size: 16px;
    // remove safari mobile styles if we use the stylig on <input>
    -webkit-appearance: none;

    &.intro {
      margin: 10px 0 10px 0;
    }

    &.red {
      // red background button action
      background: #a81313;
    }

    &.facebook {
      position: relative;
      padding-right: 50px;

      &:after {
        content: '';
        width: 40px;
        height: 40px;
        position: absolute;
        top: 5px;
        right: 5px;
        background: url('~img/affiliates/icons/facebook-white.png') no-repeat;
        background-size: cover;
      }
    }

    &.twitter {
      position: relative;
      padding-right: 50px;

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        background: url('~img/affiliates/icons/twitter-white.png') no-repeat;
        background-size: cover;
      }

    }

  }

  &__h2 {
      color: #1d1d1d;
      font-family: @fontHeading;
      font-weight: 500;
      margin: 0 auto;
      line-height: 1.2;
      font-size: ~"calc(20px + 20 * ((100vw - 400px) / 1040))";

      &.one-line {
        max-width: 100%;
      }
    }

  &__h3 {
    color: #000;
    font-weight: 400;
    font-family: @fontHeading;
    font-size: ~"calc(12px + 12 * ((100vw - 400px) / 1040))";
  }

  &__p {
    font-size: 16px;
    color: #959595;
    font-family: @fontHeading;
    padding: 0;

    &.big {
      font-size: 18px;
    }

    &.extra-big {
      font-size: 24px;
      padding: 25px 0px 25px 0px;
    }

    &.disclaimer {
      // small info
      font-size: 12px;
    }

    &.disclaimer-small {
      font-size: 10px;
    }

    &.black {
      // black text
      color: #000;
    }
    &.white {
      color: #fff;
    }

    &.purplish {
      color: #d79292;
    }
    &.light {
      font-weight: 100;
      font-family: @fontDefault;
    }
    &.bold {
      font-weight: 700;
    }
  }

  &__faq {
    // raf __faq ul item
    display: block;
    list-style: none;
    width: 100%;
    padding: 0;
    max-width: 770px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;

    > li {
      margin: 15px 0 0 0;
      .border-radius(8px);
      background-color: #fff;
      border: 1px solid #e5eaed;
      margin-top: 20px;
    }

    &__accordion-btn {
      // button that when clicked trigger the next
      color: #030300;
      display: inline-block;
      border: none;
      background: none;
      text-align: left;;
      font-size: 18px;
      width: 100%;
      position: relative;
      padding: 20px;
      font-family: @fontHeading;

      &:after {
        .transform(scaleX(1.7) rotate(90deg));
        .transition(all, 0.2s, ease-in);
        color: #9ba2a4;
        content: '>';
        font-size: 10px;
        font-weight: 100;
        line-height: 17px;
        position: absolute;
        right: 0;
        top: 45%;
      }

      &:hover {
        cursor: pointer;

        &:after {
          color: #ccc;
        }
      }

    }

     &__accordion-text {
      // the text to the corrensponding button
      display: none;
      padding: 10px 45px 20px 22px;
      text-align: left;

      &.active {
        display: block;
        // that is shown when active
      }
    }
  }

  &__invites-form {
    &__options {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      > span {
        flex-grow: 1;
        font-size: 16px;
        color: #959595;
        font-weight: 500;
        font-family: @fontHeading;
        padding: 5px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          border-bottom: 1px solid #959595;
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 40px 0 20px 0;

      &__by-email,
      &__by-link,
      &__by-twitter,
      &__by-facebook {
        display: flex;
        justify-content: center;
        width: 100%;

        &.hidden {
          display: none;
        }
      }

      input[name=email],
      input[name=url] {
        width: 70%;
        max-width: 600px;
        margin-bottom: 0;
        font-size: 18px;
        padding-left: 45px;
        height: 50px;
      }

      &__by-email {
        .icon {
          height: 30px;
          width: 30px;
          position: relative;
          top: 10px;
          left: 40px;
          background: url('~img/affiliates/icons/email-grey.png') no-repeat;
          background-size: cover;
        }
      }

      &__by-link {
        .icon {
          height: 30px;
          width: 30px;
          position: relative;
          top: 10px;
          left: 40px;
          background: url('~img/affiliates/icons/link-grey.png') no-repeat;
          background-size: cover;
        }
      }
    }

    &__hinting-text {
      // text shown below the forms
      font-size: 18px;
      color: #959595;
      font-family: @fontHeading;
      padding: 0;
    }

    &__pop-from-top-message {
      position: fixed;
      height: 100px;
      left: 0;
      right: 0;
      line-height: 100px;
      top: 0px;
      z-index: 6400;
      display: none;
      background: white;

      > .message {
        font-size: 14px;
        letter-spacing: 1.2px;
        color: #fff;
        font-weight: 300;

        &:before {
          content: '';
          position: absolute;
          top: 35px;
          width: 30px;
          height: 30px;
        }

        > b {
          padding-left: 40px;
        }
      }

      &._200 {
        // success messages
        background: #1d9653;

        > .message {
          &:before {
            background: url('~img/affiliates/icons/checkmark-white.png');
            background-size: cover;
          }
        }
      }

      &._400,
      &._403,
      &._500, {
        // error messages
        background: #a81313;

        > .message {
          &:before {
            background: url('~img/affiliates/icons/cross-white.png');
            background-size: cover;
          }
        }
      }
    }

  }

  &__has-invites-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0 50px 0;

    &__icon-user {
      width: 90px;
      height: 90px;
      display: inline-block;
      position: relative;
      top: 10px;
      left: 10px;
      float: left;
    }

    &__dollar-sign {
      float: left;
      font-family: @fontHeading;
      font-size: 80px;
      line-height: 1;
      height: 90px;
      width: 80px;
      position: relative;
      top: 10px;
      left: 25px;
      font-weight: 400;
    }


    &__essentials,
    &__elites,
    &__credits {
      order: 0;
      flex-grow: 0;
      text-align: left;
      height: 110px;
      color: #fff;
      min-width: 210px;
    }

    &__essentials {
      background-color: #959595;
    }
    &__elites {
      background-color: #b7a475;
      margin-right: 20px;
      margin-left: 20px;
    }

    &__essentials,
    &__elites {

      &__numbers {
        float: right;
        margin-right: 30px;
        position: relative;
        top: 15px;
        padding-bottom: 15px;

        &__plan {
          text-transform: uppercase;
          font-family: @fontHeading;
          letter-spacing: 1.2px;
        }

        &__count {
          font-family: @fontHeading;
          font-weight: 700;
          font-size: 55px;
          text-align: right;
          line-height: 1;
        }
      }
    }

    &__credits {
      &__numbers {
        float: left;
        margin-left: 20px;
        position: relative;
        top: 15px;
        display: inline-block;

        &__plan {
          text-transform: uppercase;
          font-family: @fontHeading;
          letter-spacing: 1.2px;
        }

        &__count {
          font-family: @fontHeading;
          font-weight: 700;
          font-size: 55px;
          text-align: right;
          line-height: 1;
        }
      }

      &__text {
        display: inline-flex;
        align-items: center;
        height: 110px;

        > p.raf__p.disclaimer {
          font-size: 12px;
          padding: 0 25px 0 25px;
        }
      }

    }

    &__credits {
      background-color: #272a2b;
      min-width: 410px;
    }
  }

  &__site-wide-banner {
    // banner shown on top of the site
    .transition(top, .7s, ease-in-out);
    background: #a81313;
    height: 80px;
    color: #000;
    left: 0;
    padding: 15px 0;
    position: fixed;
    text-align: center;
    font-size: 16px;
    top: -100vh;
    width: 100%;
    z-index: 6400;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    &__left {
      text-align: left;
    }

    &__right {
      text-align: center;
    }

    &__close {
      .transform(translate(0,-56%));
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: calc(22px + 6 * ((100vw - 320px)/ 1330));
      line-height: 1;
      padding: 1%;
      position: absolute;
      right: 2%;
      top: 50%;
    }
  }

  &__plans-pricing-banner {
    // banner shown on the sign up plans and pricing page
    background: #a81313;
    text-align: center;
    margin: 0% 5%;
    padding: 25px 0 25px 0;
    line-height: 35px;

    &__href {
      color: #d2cd4c;
      text-decoration: none;

      &:hover {
        color: #d2cd4c;
        // text-decoration: none;
      }
    }
  }

  &__profile-sidebar {
    margin: -20px -15px;
    margin-bottom: 40px;
    background: #a81313;
    padding: 15px;

    &.has-credits {
      text-align: center;
    }

    &__progress,
    &__credits {
      padding: 20px;

      &__count {
        display: block;
        font-size: 50px;
        color: #fff;
      }

      &__plan {
        display: inline-block;
        width: 40%;

        &__count {
          font-size: 40px;
          color: #fff;
        }

        &__name {
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }

}

// Mobile support
@media (max-width: @widthMobileMax) {
  .raf {
    &__h2 {
      font-size: 24px;
    }

    &__h3 {
      font-size: 22px;
    }

    &__p {
      &.black {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;

        &.mobile--ignore--br {
          br {
            display: none;
          }
        }
      }

      &.extra-big {
        font-size: 20px;
      }
    }

    &__has-invites-form {
      flex-direction: row;
      flex-wrap: wrap;

      &__essentials,
      &__elites {
        width: 45%;
        margin: 0;

        &__numbers {
          min-width: 80px;
        }
      }

      &__credits {
        width: 90%;
        margin-top: 5px;
      }

      &__essentials,
      &__elites,
      &__credits {
        text-align: center;
        min-width: 0;
        max-width: 100%;
        height: auto;
      }
    }

    &__invites-form {
      &__body {
        &__by-email,
        &__by-link {
          flex-direction: column;

          input[name=email],
          input[name=url] {
            margin: 0 15px 5px 15px;
            width: auto;
            max-width: none;
          }

          .icon {
            left: 25px;
            top: 40px;
          }
        }
      }

      &__pop-from-top-message {
        position: fixed;
        height: auto;
        left: 0;
        right: 0;
        line-height: 35px;
        padding-top: 10px;
        padding-bottom: 10px;
        top: 0px;
        z-index: 6400;
        display: none;
        background: white;

        > .message {
          &:before {
            display: none;
          }
        }
      }
    }

    &__site-wide-banner {
      // banner shown on top of the site
      height: auto;
      padding: 15px 20px;
    }
  }
}
