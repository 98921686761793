.center-horizontally-relative {
  position: relative;
  margin: 0 auto;
}

.center-vertically-and-horizontally-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animation-delay(@time) {
  -ms-animation-delay: @time;
  -webkit-animation-delay: @time;
  animation-delay: @time;
}

.animation-timing-function(@type) {
  -ms-animation-timing-function: @type;
  -webkit-animation-timing-function: @type;
  animation-timing-function: @type;
}

.font(@family, @size, @color, @weight) {
  font-family: @family;
  font-size: @size;
  color: @color;
  font-weight: @weight;
  font-display: swap;
}


.payment-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  // hide by default
  display: none;
  color: #000;

  &--inline-form {
    display: inline-block;
  }

  &--hint-text,
  &--hint-link {
    .font(@fontOpenSans, 12px, #9a9aa5, 400);

    &.bolded {
      font-weight: 700;
    }
  }

  &--hint-link {
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      color: #9a9aa5;
    }

    &.no-text-decoration {
      text-decoration: none;
    }
  }

  &--button {
    border: none;
    padding: 15px 30px 15px 30px;
    .font(@fontOpenSans, 11px, #f9f9fa, 400);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    .border-radius(30px);
    .transition(all, 0.5s, ease-out);
    -webkit-appearance: button;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &.green {
      background: #14cb18;

      &:hover {
        background-color: #49da4c;
        .box-shadow(none);
      }
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(60, 60, 60, 0.65);
    width: 100%;
  }

  &__close {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 35px;
    left: 35px;
    color: #000;
    z-index: 5;  // above loading.

    &:hover {
      cursor: pointer;
      .icon-close {
        path {
          fill: #19191f;
        }
      }
    }
  }

  &__content-container {
    max-height: 80vh;
    .center-vertically-and-horizontally-absolute();
    overflow-y: auto;
  }

  &__loading,
  &__content {
    width: 575px;
    min-width: 575px;
    min-height: 360px;
  }

  &__loading {
    display: none;
    width: 575px;
    z-index: 2;
    // make background white and slight transparent
    background: rgba(213, 212, 212, 0.8);

    .loading-balls {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 30%;
      left: 45%;
      transform: translate(-50%, -50%);

      .ball {
        position: absolute;
        width: 74px;
        height: 74px;
        opacity: 0;
        .transform(rotate(225deg));
        animation: orbit 6.96s infinite;
        -ms-animation: orbit 6.96s infinite;
        -webkit-animation: orbit 6.96s infinite;

        &--1 {
          .animation-delay(1.52s);
        }

        &--2 {
          .animation-delay(0.3s);
        }

        &--3 {
          .animation-delay(0.6s);
        }

        &--4 {
          .animation-delay(0.9s);
        }

        &--5 {
          .animation-delay(1.2s);
        }
      }

      .inner-ball {
        position: absolute;
        width: 10px;
        height: 10px;
        background: rgb(0,0,0);
        left:0px;
        top:0px;
        margin-top: 40px;
        border-radius: 10px;
      }
    }

    @keyframes orbit {
      0% {
        opacity: 1;
        z-index:99;
        .transform(180deg);
        .animation-timing-function(ease-out);
      }

      7% {
        opacity: 1;
        .transform(rotate(300deg));
        .animation-timing-function(linear);
      }

      30% {
        opacity: 1;
        .transform(rotate(410deg));
        .animation-timing-function(ease-in-out);
      }

      39% {
        opacity: 1;
        .transform(rotate(645deg));
        .animation-timing-function(linear);
      }

      70% {
        opacity: 1;
        .transform(rotate(770deg));
        .animation-timing-function(ease-out);
      }

      75% {
        opacity: 1;
        .transform(rotate(900deg));
        .animation-timing-function(ease-out);
      }

      76% {
        opacity: 0;
        .transform(rotate(900deg));
      }

      100% {
        opacity: 0;
        .transform(rotate(900deg));
      }
    }

    &.show {
      // writing position: absolute and top/left as they will get
      // calculated in place on class addage
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__content {
    position: relative;
    background: #fff;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    align-items: center;
    .box-shadow(0px 0px 12px 10px #222);

    &--header_section {
      h3 {
        .font(@fontOpenSans, 23px, #19191f, 300);
      }

      h4 {
        .font(@fontOpenSans, 20px, #19191f, 700);

        &.payment-plan {
          padding-top: 20px;
          font-size: 16px;

          &.installment-selected {
            padding-top: 0;
            font-size: 15px;
          }
        }
      }

      > p {
        .font(@fontOpenSans, 12px, #9a9aa5, 100);
      }

      .amount {
        .font(@fontOpenSans, 35px, #14cb18, 700);
      }

      .amount-deducted {
        .font(@fontOpenSans, 20px, #000, 700);

        .base-price {
          text-decoration: line-through;
          padding-right: 2px;
        }

        .deducted-to {
          color: #00c728;
        }
      }


      .discounts-title {
        font-size: 14px;
      }

      .discounts {
        list-style: none;
        font-size: 14px;
        padding: 0;
        margin: 10px 0;

        &__discount {
          background: #00c729;
          border-radius: 5px;
          display: inline-block;
          margin: 2px 0;
          padding: 5px 40px;
          .font(@fontOpenSans, 15px, #fff, 400);
        }

      }
    }

    &__errors_section,
    &__major-error {
      padding: 10px 0 10px 0;
      border: 1px solid #bd2d37;

      .error {
        font-size: 15px;
        color: #bd2d37;
        font-weight: 600;
      }

      .contact-us {
        font-size: 12px;

        a {
          color: #18191a;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }

    &__major-error {
      .center-vertically-and-horizontally-absolute();
      padding: 10%;
      border: none;
      min-width: 575px;
      text-align: center;
    }

    &__payment_method_update {
      text-align: center;
      padding: 10%;
      min-width: 575px;
      // min-height: 600px;  // big min height bigger as dorpin loads later
      // .center-vertically-and-horizontally-absolute();

      .payment-option-form {
        padding: 20px;
        display: flex;
        flex-direction: column;

        &__payment-fields {
          &__row {
            display: flex;
            flex-direction: row;
            padding: 15px;

            .payment-type {
              font-size: 15px;
              width: 75px;
              font-weight: 700;
              margin-right: 10px;
            }

            .payment-form {
              width: 100%;
            }

            &.skrill {
              align-items: center;

              .payment-form {
                text-align: left;

                &.skrill {
                  display: flex;
                  align-items: center;
                }

                .skrill-label {
                  width: 115px;
                  height: 38px;
                  background-image: url("https://www.skrill.com/fileadmin/content/images/brand_centre/Pay_by_Skrill/skrill-payby-btn-grey_245x75.png");
                  background-size: 100%;
                  background-repeat: no-repeat;
                  margin: 0;
                  padding: 0;
                  display: inline-block;

                  &.active {
                    background-image: url("https://www.skrill.com/fileadmin/content/images/brand_centre/Pay_by_Skrill/skrill-payby-btn-purple_245x75.png")
                  }

                  &:hover {
                    cursor: pointer !important;
                  }
                }

                p.skrill-info {
                  padding: 0 0 0 10px;
                  display: none;

                  &.active {
                    display: inline-block;
                  }

                  label.pay-with-other {
                    font-size: 13px;
                    color: #3a3a3a;
                    text-decoration: underline;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }

                input#skrill_checkbox {
                  display: none;
                }
              }
            }

            &.braintree {
              &.deactivate {
                opacity: 0.2;
                pointer-events: none;
              }
            }
          }
        }
      }

      &__footer {
        margin: 10px 0px 10px 0px;
        padding: 0 20px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a[type="form-submit"] {
          padding: 20px;  // so to be more clickable on mobiles
        }
      }

    }

    &__confirm-purchase {
      text-align: center;
      padding: 10%;
      min-width: 575px;
      // .center-vertically-and-horizontally-absolute();

      &__payment_method_available,
      &__payment_method_missing {
        margin: 20px 0 20px 0;
      }

      &__payment_method_available {
        .card-info {
          // margin-top: 20px;
          border: 1px solid black;
          min-height: 70px;
          border-color: #14cb18;
          padding: 5%;
          .border-radius(4px);
          .box-shadow(0px 0px 15px rgba(12, 162, 15, 0.32));
          display: flex;
          justify-content: space-between;
          align-items: center;

          .card-type {
            height: 50px;
            min-width: 70px;
            margin-right: 20px;
            background: url('~img/redline/compressed/payment_cards_sprite.png');

            &.visa {
              background-position: 0px 0px;
            }

            &.mastercard {
              background-position: 0px -50px;
            }

            &.american-express {
              background-position: 0px -100px;
            }

            &.paypal {
              background-position: 0px -150px;
            }

            &.other {
              background-position: 0px -200px;
            }

            &.skrill {
              background-position: 0px -250px;
            }
          }

          .card-description {
            text-align: left;
            line-height: 1.2;

            p {
              padding: 0;
            }
            > p:first-child {
              .font(@fontOpenSans, 12px, #9a9aa5, 100);
            }

            > p:last-child {
              .font(@fontOpenSans, 14px, #000, 700);
            }
          }

          .payment-form {
            // ...
          }
        }

       .errors {
          .font(@fontOpenSans, 13px, #bd2d37, 100);
        }
      }
    }

    &__success {
      text-align: center;
      padding: 10%;
      min-width: 575px;

      &__text {
        padding: 60px 0;
        font-size: 16px;
      }
    }
  }

  .installment-options {
    max-width: 300px;
    margin: 0 auto;

    input[type="radio"] {
      display: none;
    }

    label {
      color: #000;

      &.choice {
        padding: 15px 30px 15px 30px;
        .font(@fontOpenSans, 11px, #f9f9fa, 600);
        letter-spacing: 1.5px;
        text-transform: uppercase;
        .border-radius(30px);
        border: 1px solid #14cb18;
        .transition(all, 0.5s, ease-out);
        -webkit-appearance: none;
        color: #14cb18;
        background: none;

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }

        &.selected {
          background: #14cb18;
          color: #fff;

          &:hover {
            background-color: #49da4c;
          }
        }
      }
    }
  }
}


// mobile
@media (max-width: @widthMobileMax) {
  .payment-modal {
    &__content-container {
      max-height: none;
      height: 70vh;
      width: 100%;
      background-color: #fff;
      display: flex;
    }

    &__loading {
      width: 100%;
      min-width: 0px;
    }

    &__close {
      top: 20px;
      left: 20px;
    }

    .payment-modal__content__confirm-purchase__payment_method_available {
      .card-info {
        flex-wrap: wrap;
      }

      .card-description {
        flex-grow: 2
      }

      .payment-form {
        margin-top: 20px;
        flex-grow: 3;
      }
    }

    &__content {
      width: 100%;
      height: auto;
      min-width: 100%;
      .box-shadow(0px 0px 0px 0px #222);

      &__confirm-purchase,
      &__payment_method_update,
      &__success {
        padding: 2%;
        min-width: 0px;
      }
    }
  }
}
