#create_edit.posts {
  #neck {
    background: #f7f8f8;

    h1 {
      font-size: 36px;
      color: #272a2b;
      font-weight: 700;
      line-height: 1em;
      padding: 48px 0;
    }
  }


  .wmd-wrap {
    margin-left: 0;
    margin-top: 20px;
  }

  div.body {
    margin-bottom: 20px;
  }
  .article_form,
  #hand_submit {
    #id_game_type, #id_game_stake, #id_hide_names {
      display: none;
    }
  }

  .error.hide {
    display: none;
  }


  ul.errorlist {
    clear: both;
    padding: 0 0 5px 0;
    margin-bottom: -25px;

    li {
      background: url(~img/rio/form_error.png) no-repeat scroll left 9px;
      color: #a70106;
      display: block;
      font-size: 12px;
      padding: 4px 0 6px 12px;
    }
  }

  .form_container {
    max-width: 1040px;

    label {
      color: #929ea3;
      font-family: @fontHeading;
      margin: 20px 0 0;
      padding-bottom: 4px;
    }

    .option_wrap {
      clear: both;
      overflow: hidden;

      .options.cats {
        display: none;
      }

      select {
        display: none;
      }

      .options {
        a {
          .border-radius(4px);
          .transition(background, 0.22s, ease-out);
          background: #eceeee;
          color: #6a6f71;
          display: inline-block;
          font-family: @fontDefault;
          font-weight: 500;
          line-height: 1;
          margin: 0 10px 10px 0;
          padding: 10px 14px;
          text-decoration: none;

          &:hover {
            background: #dfe1e1;
          }

          &.selected {
            background: #a81313;
            color: #fff;
          }
        }
      }
    }

    #stakes-input {
      display: none;
    }

    #pinned-input {
      > span {
        margin-right: 10px;
        font-size: 10px;

        > input[type="number"] {
          width: 50px;
        }

      }
    }

    input[type="text"] {
      color: #878282;
      font-size: 14px;
      font-weight: 300;
      padding: 14px 18px;
      width: 100%;
    }

    #hand-history textarea {
      height: 42px;
      min-height: auto;
      max-height: auto;
    }

    textarea {
      font-size: 14px;
      line-height: 1.5;
      width: 100%;
      resize: none;
      min-height: 215px;
      max-height: 800px;
      padding: 1% 2%;
    }

    h4 {
      color: #929ea3;
      font-family: @fontHeading;
      margin: 30px 0 0;
      padding-bottom: 4px;
      text-decoration: underline;
    }

    .image_upload_wrap {
      color: #abacac;
      float: left;
      font-family: @fontHeading;
      font-weight: 500;
      font-size: 13px;
      padding-left: 45px;
      padding-top: 7px;
      width: 100px;
    }

    .controls {
      float: right;
      margin-bottom: 90px;

      button.btn, input.btn[type="submit"], input.btn[type="button"] {
        margin-top: -3px;
        float: right;
      }
    }

    #stakes-input {
      margin-bottom: 20px;
    }
  }
}


// Tablet and mobile support
@media (max-width: @widthTabletMax) {
  #create_edit.posts #torso .row {
    background: #fff;
  }

  body.post-detail {
    #torso .row {
      background: #fff;
    }

    #header .span4 {
      position: relative;
      top: -12px;
    }

    #header .thread-buttons {
      margin-left: 20px;
    }

    #header #post_subscribe {
      margin-right: 20px;
      float: right;
      clear: both;
    }
  }

  #video_detail #torso #post_content.video-post .post .post_body {
    padding: 16px 180px 30px 30px;
  }
}

#hand_form {
  .toggle_style {
    .clearfix();
    margin-bottom: 40px;

    div {
      color: #979797;
      float: left;
      font-weight: 400;
      text-decoration: underline;

      &.toggle {
        background: transparent url(~img/hand/toggle_style.jpg) no-repeat left -15px;
        cursor: pointer;
        height: 16px;
        margin: 0 8px;
        margin-top: 2px;
        width: 37px;

        &.checked {
          background-position: left 3px;
          margin-top: 0;
        }
      }

      &.option {
        font-family: @fontHeading;
        color: #929ea3;
        cursor: pointer;

        &.active {
          color: #a81313;
        }
      }

      &.checkbox {
        display: none;
      }
    }
  }
}

#hand_submit {
  .section > .title-block,
  .section > .street {
    border-bottom: none;
    margin-bottom: 20px;
  }
}
