// This is just like a .component-dropdown except that has multiple columns. Currently in use for the
// Pro dropdown on the video listing page.
.component-dropdown-columns {

  position: static;

  h5 {
    color: #2b3134;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 15px;
    padding-left: 10px;
  }

  .options {
    .border-radius(5px);
    border: 1px solid @colorBorder;
    left: 0px;
    padding: 40px 10px 40px 40px;
    top: 38px;
    width: 900px;
    max-width: 90%;
    margin-top: 10px;

    ul {
      .clearfix();
    }

    li {
      float: left;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 140px;
    }
  }

  // this is purely the arrow of the PROS options listings
  .arrow {
    .transform(rotate(45deg));
    background: #fff;
    height: 15px;
    width: 15px;
    border: 1px solid @colorBorder;
    border-bottom: none;
    border-right: none;
    position: absolute;
    left: 530px;
    margin-left: -7px;
    top: -11px;
  }
}
