/*
  Any following feed specific stuff

  This isn't a place to dump anything for the feed into!
  This is specifically for overrides of components that are reused around the site and need to look different on
  the feed app.
*/

body.feeds-following {
  height: 100vh;
  // position: relative;

  #body_container {
    height: 100vh;
    // overflow-y: hidden;
    // position: absolute;
    // top: 0;
  }

  .global-nav {
    .flex_column(1);
    flex-direction: row;
    position: relative;
    min-height: @navHeight;
    max-height: @navHeight; // needed for the new nav (.global-nav)
    @media (max-width: @widthMobileMax) {
      position: fixed;
      z-index: 10000;
    }
    // -webkit-transform: translate3d(0,0,0);

    // disable with disable tag
    &.disabled {
      pointer-events: none;
      .branding,
      .menu-notifications,
      .menu-messages,
      .runitonce-poker,
      .search,
      .btn-user-nav,
      .main-content-container {
        filter: brightness(30%);
        &.highlighted {
          filter: none;
        }
      }
    }
  }

  // Because there is no sidebar, don't ever hide the author byline on a thread detail view.
  section.thread-detail > div > article header.not_stuck h3 {
    display: block !important;
  }

  section.thread-detail > div > article header.stuck {
     position: absolute;
  }

  section.thread-detail>.thread-detail-ui-view {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-width: 1000px;
    width: 90%;

    // Gross IE 10+ hack.
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      /* IE10+ specific styles go here */
      height: 100%;
    }
    @media (max-width: @widthMobileMax) {
      width: 100%;
    }
  }

  /* After the transition this will be the only class remaining */
  .thread-view-pane {
    opacity: 1; /* Default value but added for clarity */
    // .transition(all .5s);
  }

  /* Initial state when showing */
  .thread-view-pane.ng-hide-remove {
    opacity: 0;
    display: block !important;
  }

  /* Will transition towards this state */
  .thread-view-pane.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
  }

  // mobile menu effects on other areas
  &.user-menu-open {
    .nav-main {
      right: 0;
    }

    .nav-main .nav-main-user {
      left: 0;
    }
  }

  &.forum-menu-open {
    .nav-main {
      right: 0;
    }

    // .global-nav {
    //   right: 0;
    // }
  }

}
