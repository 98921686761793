body#meet_the_pros {

  &.not-logged-in #torso {
    padding-bottom: 110px;
  }

  #torso {
    h1 {
      font-family: @fontHeading;
      padding-top: 160px;
      text-align: center;
      color: #fff;
      font-size: 50px;
      text-transform: uppercase;
      line-height: 1em;
      letter-spacing: 1px;
      letter-spacing: .05em;

      span {
        font-size: 12px;
        color: #9ea3a6;
        display: block;
        line-height: 1em;
        padding-bottom: 10px;
        letter-spacing: .5em;
      }

      .line {
        padding: 0;
        margin: 20px auto 0;
        display: block;
        width: 85px;
        height: 2px;
        background: #77151b;
      }
    }

    .page-banner {
      .retina-background-image('~img/partials/meet-the-pros/banner','jpg', no-repeat, 1340px, auto, 0, 0);
      background-color: #0D0D0F;
      height: 384px;
    }

    h2 {
      display: inline-block;
      float: left;
      position: relative;
      top: 10px;
    }

    #content_filters {
      .clearfix();
      clear: none;
      display: block;
      float: right;
    }

    .filter_wrap {
      border-bottom: 1px solid @colorBorder;
      padding-top: 20px;
    }
  }
}


// Large pro listing as seen on the Meet The Pros page.
.pro-list-lrg {
  .flex_container(row);
  -webkit-box-pack: justify;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 !important;
  padding: 50px 8.4%;
  width: 100%;

  .pro {
    overflow: hidden;
    margin-bottom: 50px;
    // flexbox uses min and max, not exact
    min-width: 260px;
    max-width: 260px;

    .inner {
      background: #313536;
      margin: 0 auto;
      width: 235px;

      &:hover .photo img {
        .transform(scale(1.1) translateX(-12px));
      }
    }

    .photo {
      height: 205px;
      overflow: hidden;
      display: block;

      img {
        .transition(all, 0.22s, ease-in-out);
        .transform(translate3d(0, 0, 1px));
        width: 280px;
        position: relative;
        left: -22px;
        height: auto;

        &.not-active {
          filter: grayscale(1);
        }
      }
    }

    .info {
      -webkit-backface-visibility: hidden;
      position: relative;
      padding: 12px 10px;
    }

    .name {
      display: inline-block;

      a {
        color: #fff;
        display: inline-block;
      }
      span {
        color: #85858d;
        display: block;
      }
    }

    .creds {
      display: inline-block;
      font-size: 12px;
      position: relative;
      left: 2px;

      span {
        color: #aa985c;
        display: inline-block;
        font-family: @fontHeading;
        font-weight: 700;
      }

      .game_type {
        text-transform: uppercase;
        &.game_type-mixed {
          text-transform: capitalize;
        }
        &.game_type-mindset {
          text-transform: capitalize;
        }
      }
    }
  }


  @media (min-width: @widthDesktopMax) {
    .pro {
      min-width: 278px;
      max-width: 278px;
    }
  }
}


.follow_btn.slideout {
  display: block;
  line-height: 0em;

  // Not following, grey backgorund and a plus.
  .follow {
    background: #48494a;

    .icon {
      background: url('~img/components/icon/plus.svg') no-repeat 4px 4px;
      background-size: 12px auto;
    }
  }

  // You are folloiwng the user, red background with a check.
  .unfollow {
    background: #cb0f1a;

    .icon {
      background: url('~img/components/icon/check.svg') no-repeat 2px 2px;
      background-size: 15px auto;
    }
  }

  .btn {
    .border-radius(3px);
    .transition(all .15s);
    border: none;
    color: #fff;
    display: block;
    overflow: hidden;
    padding: 5px;
    position: relative;
    text-align: right;
    width: 30px;
  }

  .icon {
    display: inline-block;
    height: 20px;
    width: 20px;
  }

  .action {
    .transition(all, .25s);
    left: 8px;
    line-height: 1em;
    opacity: 0;
    position: absolute;
    top: 8px;
    transition-delay: 0;
  }

  // When you hover over the button, transition it's width and show the action.
  &:hover {
    .btn {
      width: 90px;
    }

    .action {
      .transition(all, .75s);
      opacity: 1;
      transition-delay: .25s;
    }
  }
}


//Specific Pro Overrides
.pro-list-lrg {
  .pro.vincent_van {
    .name a {
      font-size: 11px;
    }
  }
}
