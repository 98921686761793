body.affiliates-landing {

  #torso {
    background: #fff url('~img/affiliates/affiliates-background.jpg') center -@navHeight no-repeat;
    background-size: 100% auto;
    width: auto;
  }

  &.logged---in {
    #torso {
      background: #fff;
      padding: 0 50px;
    }
  }

  .btn-affiliate-link {
    background: #f5f6f8;
    border: 1px solid #e0e2e6;
    .border-radius(5px);
    font-family: @fontHeading;
    font-size: 14px;
    color: #45494f;
    position: relative;
    padding-left: 25px;
    padding-right: 45px;
    height: 60px;
    -webkit-appearance: none;

    &:after {
      content: '';
      width: 15px;
      height: 15px;
      display: block;
      position: absolute;
      top: 21px;
      right: 20px;
      background: url('~img/affiliates/icons/link.svg') center top no-repeat;
      background-size: 100% auto;
    }
  }

  .stats-header {
    h2 {
      line-height: 60px;
    }
  }

  .stats-header,
  .affiliates-graph,
  .media-downloads {
    max-width: 1130px;
    display: flex;
    justify-content: space-between;
    margin: 90px auto 0;
  }

  .media-downloads {
    align-items: center;

    &__left {
      width: 25%;
    }

    &__right {
      width: 65%;

      > img {
        width: 100%;
        height: auto;
      }
    }

  }


  .affiliates-graph {
    padding: 50px 40px;
    flex-direction: column;
    justify-content: flex-start;
    background: #f5f6f8;
    .border-radius(8px);

    &__options {
      font-family: @fontHeading;
      font-weight: 500;
      margin-bottom: 40px;

      div {
        font-size: 18px;
        line-height: 40px;
        display: inline-block;
      }

      #graph-period {
        font-size: 16px;
        height: 40px;
        background: #fff;
        border: 1px solid #e0e2e6;
        .border-radius(5px);
        margin-left: 15px;
        -webkit-appearance: none;
      }
    }

    &__body {
      background: #fff;
      border: 1px solid #d2d5da;
      .border-radius(8px);
    }

    &__legend {
      display: flex;
      justify-content: space-between;
      margin: 60px 100px 20px;
      text-align: center;

      &__total_earnings,
      &__total_signups,
      &__total_elite,
      &__total_essential,
      &__total_chargebacks {
        display: flex;
        flex-direction: column;
        font-family: @fontHeading;

        .number {
          font-size: 55px;
          line-height: 90%;
        }

        .meaning {
          font-size: 12px;
        }

      }
    }

  }

  h1 {
    font-size: 60px;
    font-family: @fontHeading;
    font-weight: 700;
    line-height: 1.3em;
    color: #16191e;
    margin-bottom: 50px;
    padding: 0;
  }

  .affiliates-description {
    margin: 120px auto 0;
    max-width: 1130px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      width: 60%;

      p {
        font-family: @fontOpenSans;
        font-size: 20px;
        color: #16191e;
        padding-bottom: 40px;
      }
    }

    &__right {
      width: 40%;

      > img {
        background-size: 100% auto;
        position: relative;
        width: 100%;
        height: auto;
        transform: skewX(-7deg);
        box-shadow: 40px 40px 100px 2px rgba(0, 0, 0, 0.2);
        left: 15%;
      }

    }

  }

  .affiliates-body-form {
    max-width: 1130px;
    width: 70%;
    margin: 80px auto 0;
    opacity: 1;
    .transition(opacity, 0.50s, ease-in-out);

    // disable buttons when "loading"
    &.loading {
      opacity: 0;

      input[type="submit"] {
        pointer-events: none;
      }

      .application-form {
        input, textarea {
          pointer-events: none;
        }
      }
    }

    input[type="submit"] {
      -webkit-appearance: none;
      max-width: 490px;
      width: 50%;
      height: 50px;
      line-height: 50px;
      background: #292b30;
      font-size: 16px;
      font-family: @fontHeading;
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      color: #fff;
      border: none;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 40px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .application-form {
    background: #f5f6f8;
    padding: 40px 4%;
    margin-bottom: 50px;

    input,
    textarea {
      -webkit-appearance: none;
      padding: 0; // reset
      outline: none;  // reset
      margin: 0;  // reset from _forms.less

      font-size: 14px;
      font-family: @fontOpenSans;
      font-weight: 400;
      color: #16191e;
    }

    .input-effect {
      // added so we can use aboslute position in inner labels
      position: relative;

      &.icon {
        > img {
          position: absolute;
          height: 12px;
          top: 19px;
          left: 15px;
        }

        // increase padding and label left offset cuz of icons
        input {
          padding-left: 40px;

          ~ label {
            padding-left: 40px;
          }
        }

      }

      textarea,
      input {
        height: 50px;
        line-height: 15px;  // line height + paddings to equalize height
        width: 100%;
        border: 0;
        box-sizing: border-box;
        margin-bottom: 28px;
        padding: 25px 0 10px 20px;

        ~ label {
          background-color: transparent;
          position: absolute;
          top: 0px;
          padding-left: 20px;
          height: 50px;
          line-height: 50px;
          width: 100%;
          font-family: @fontOpenSans;
          // font-size: 13px;
          font-weight: 400;
          color: #a4a4a5;

          transition: 0.3s;
          letter-spacing: 0.5px;
        }

        &.has-content,
        &:focus {
          outline: none;

          ~ label {
            top: -10px;
            font-size: 10px;
            color: #a4a4a5;
            transition: 0.3s;
          }
        }
      }

      &.textarea {
        > textarea {
          width: 48%;
          max-width: 100%;
          min-height: 50px;
          margin: 0;
          padding-top: 20px;

          &::placeholder {
            color: #a4a4a5;
          }
        }
      }
    }

    &__main-inputs {
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__left,
      &__right {
        width: 50%;
      }

      &__left {
        margin-right: 2%;
      }

      &__right {
        margin-left: 2%;
      }
    }
  }

  .accordion-faq {
    width: 60%;
    max-width: 850px;
    margin: 100px auto;

    &:before {
      content: '';
      width: 100%;
      background: #d2d5da;
      height: 1px;
      display: block;
      margin-bottom: 100px;
    }

    h2 {
      .font(@fontHeading, 36px, #101213, 300);
      text-align: center;
    }

    ul {
      list-style: none;
      margin-top: 50px;

      li {
        .border-radius(5px);
        background-color: #f5f6f8;
        margin-top: 15px;

        a.section-trigger {
          height: 50px;
          border: none;
          background: none;
          outline:none;
          position: relative;
          text-align: left;
          width: 100%;
          display: flex;
          align-items: center;

          h3 {
            font-size: 18px;
            color: #16191e;
            display: inline-block;
            max-width: 84%;
            padding-left: 20px;
          }

          &:after {
            transform: scaleX(1.4) rotateZ(-180deg);
            .transition(all, 0.2s, ease-in);
            color: #16191e;
            content: '^';
            font-size: 12px;
            font-weight: 100;
            line-height: 50px;
            position: absolute;
            right: 10px;
          }

          &:hover {
            text-decoration: none;
          }
        }

        div.section-info {
          display: none;

          p {
            padding: 10px 45px 20px 22px;
            text-align: left;
          }
        }


        &.active {
          a.section-trigger {
             &:after {
              .transform(scaleX(1.4), rotateZ(0deg));
            }
          }

          div.section-info {
            display: block;
          }
        }
      }
    }
  }

}


// Once the image at the top starts look funny, just adjust the flex layout
@media (max-width: 935px) {
  body.affiliates-landing {

    .affiliates-description {
      &__left {
        width: 100%;
      }

      // that's the image
      &__right {
        display: none;
      }
    }

  }
}


// Phone in landscape (less than 767px)
@media (max-width: @widthMobileMax) {
  body.affiliates-landing {

    h1 {
      font-size: 44px;
      line-height: 1.1em;
    }

    .stats-header,
    .affiliates-graph,
    .media-downloads {
      max-width: 90%;
    }

    .affiliates-description {
      margin-top: 50px;

      &__left {
        p {
          font-size: 18px;
        }
      }
    }

    // form inputs to be one below another
    .affiliates-body-form {
      width: 90%;

      .application-form {
        &__main-inputs {
          flex-direction: column;

          &__left,
          &__right {
            width: 100%;
            margin: 0;
          }

        }
      }

      // the input text area for questions and comments at bottom to be
      // full width by default now
      .input-effect.textarea {
        > textarea {
          width: 100%;
        }
      }

    }

    .accordion-faq {
      width: 90%;

      ul {
        li {
          a.section-trigger {
            h3 {
              font-size: 15px;
            }
          }
        }
      }

    }

  }
}

// Phone in portrait (less than 384px)
@media (max-width: @widthMobilePortrait) {
  body.affiliates-landing {
    .stats-header {
      flex-direction: column;
    }

    .affiliates-graph {
      &__legend {
        flex-direction: column;
        margin: 40px auto;

        > div {
          margin-top: 20px;
        }
      }
    }

    .media-downloads {
      flex-direction: column;

      &__left,
      &__right {
        width: 80%;
      }
    }
  }

}
