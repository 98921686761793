body.three-free-videos-sign-up {
    background-color: #06080E;

    // MTT version
    &.three-free-videos-mtt {
      .sib-form {
        padding: 20px 0;
      }
    }

    .flex-wrapper {
        display: flex;
        flex-direction: row;
        margin: 50px 0 50px 5%;
    }

    .content-container {
        width: 60%;
        padding-top: 50px;
        max-width: 600px;
        h1 {
            color: white;
            font-family: @fontNowayMedium;
            font-size: 44px;
            line-height: 1.3;
        }

        p {
            font-family: @fontOpenSans;
            color: #919aa1;
            line-height: 1.6;
            font-size: 22px;

            &.testimonial {
                font-size: 18px;
                color: #78828a;
                padding-top: 30px;

            }
            &.maria {
                font-size: 18px;
            }
        }

        #signup-form {
            padding: 20px 0;
            div {
                flex-direction: column;
            }
            label {
                border: 0;
                &.active {
                    background-color: transparent;
                }

                &.username {
                  .username-unavailable {
                    font-size: 13px;
                    color: #89171d;
                    position: absolute;
                    top: 45px;
                    left: 20px;
                    display: none;
                  }

                  &.available {
                    input {
                     background: url('~img/account/registration/form/status_icons.png') no-repeat 96% 15px !important;
                      background-size: 14px auto !important;
                      background-color: #101218 !important;
                    }
                  }

                  &.unavailable {
                    input {
                      background: url('~img/account/registration/form/status_icons.png') no-repeat 96% -88px !important;
                      background-size: 14px auto !important;
                      background-color: #101218 !important;
                      color: red;
                    }

                    .username-unavailable {
                      display: block;
                    }
                  }

                  &.working {
                    input {
                      background: url('~img/account/registration/form/ajax-loader.gif') no-repeat 96% 5px !important;
                    }
                  }
                }

            }
            input {
                background-color: #101218;
                color: white;
                padding: 15px 10px;
                &:hover {
                    cursor: text;
                }

                &.active {

                }
            }

            .error {
                color:white;
            }

            .submit {
                div {
                    width: 100%;
                    padding: 0;
                }

                .btn-primary {
                    width: 100%;
                    background-color: #b21a1f;
                    color: white;
                    font-family: @fontOpenSans;
                    font-size: 22px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .image-container {
        background-image: url(~img/three_free_videos/free-vids-sign-up-bg.jpg);
        background-repeat: no-repeat;
        margin-left: 8%;
        width: -webkit-fill-available;
        background-position: 0px 0;
        background-size: 100vw;
        .photo {
            padding-top: 70px;
            padding-left: 5%;
            width: 100%;
            max-width: 800px;

            &.mobile {
                display: none;
            }
        }
    }

    @media (max-width: 730px) {
        .flex-wrapper {
            flex-direction: column;
            margin: 50px 5%;
        }

        .content-container {
            width: 100%;
            order: 2;
            margin-top: -20%;
            padding: 0;
            //z-index: 1;

            h1 {
                font-size: ~"calc(28px + 8 * ((100vw - 400px) / 330))";
                padding-bottom: 0;
            }

            p {
                font-size: 14px;
                padding-bottom: 8px;

                &.testimonial {
                    font-size: 14px;
                    padding-top: 15px;
                }
                &.maria {
                    font-size: 14px;
                }
            }

            #signup-form {
                padding: 0;

                input {
                    margin-top: 0px;
                    padding: 12px;
                }
                label {
                    &.username {
                        &.available {
                            input {
                                background: url('~img/account/registration/form/status_icons.png') no-repeat 96% 14px !important;
                                background-size: 9px auto !important;
                                background-color: #101218 !important;
                            }
                        }
                        &.unavailable {
                            input {
                                background: url('~img/account/registration/form/status_icons.png') no-repeat 96% -53px !important;
                                background-size: 9px auto !important;
                                background-color: #101218 !important;
                            }
                        }
                    }
                }

                .submit {
                    margin-top: -20px;
                }
            }
        }

        .image-container {
            width:100%;
            order: 1;
            margin-left: 0;
            margin-top: -15%;
            .photo{
                padding: 0;
                filter: brightness(80%);
                &.mobile {
                    display: block;
                }
                &.not-mobile {
                    display: none;
                }
            }
        }
    }

}
