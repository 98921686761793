.sidebar .top_users {
  header {
    padding: 20px;
    .box-shadow(0px 2px 8px 0px rgba(0,0,0,0.1));


    h3 {
      color: @colorDarkText;
      font-size: 16px;
      display: inline-block;
      margin-right: 10px;
    }

    a {
      display: inline-block;
    }
  }
}

.sidebar .points-explanation {
  background: linear-gradient(to bottom, #ebecec 0%, #fff 35%);
  .border-radius(4px);
  border: 1px solid #d0d4d6;
  margin-top: 30px;
  max-width: 272px;

  > img {
    position: relative;
    top: -12px;
    width: 88%;
    display: block;
    margin: 0 auto;
  }

  header {
    padding: 20px;
    .box-shadow(0px 2px 8px 0px rgba(0,0,0,0.1));


    h3 {
      color: @colorDarkText;
      font-size: 16px;
      display: inline-block;
      margin-right: 10px;
    }

    a {
      display: inline-block;
    }
  }

  .instructions {
    margin: 20px 26px 0;

    p{
      font-size: 12px;
      color: #8b8f91;
      padding: 0 0 15px 0;
    }

    h2 {
      margin-bottom: 10px;
    }

    ul {
      clear: both;
      .clearfix();
      list-style: none;
      margin-right: 4px;

      li {
        height: 24px;

        .title{
          background-color: #fff;
          bottom: 9px;
          color: #333;
          float: left;
          padding-right: 10px;
          position: relative;
          text-transform: uppercase;
        }

        .points{
          background-color: #fff;
          bottom: 9px;
          color: @colorLink;
          float: right;
          padding-left: 10px;
          position: relative;
          text-transform: uppercase;
        }

        div.horizontal_bar{
          border-top: 1px solid #ebf0f0;
          top: 8px;
          position: relative;
          height: 1px;
          font-size: 11px;
          font-family: @fontHeading;
          font-weight: 700;
        }
      }
     }
  }
}
