.pro-videos-list-banner {
  .transition(top, .7s, ease-in-out);
  background: #b8a374;
  color: #000;
  font-family: @fontHeading;
  font-weight: 300;
  left: 0;
  line-height: 1.5;
  padding: 15px 0;
  position: fixed;
  text-align: center;
  top: -20vh;
  width: 100%;
  z-index: 6400;
  font-size: 16px;

  a {
    color: #000;
    text-decoration: underline;
  }

  &__close {
    .transform(translate(0,-56%));
    color: #000;
    cursor: pointer;
    display: block;
    font-size: calc(22px + 6 * ((100vw - 320px)/ 1330));
    line-height: 1;
    padding: 1%;
    position: absolute;
    right: 2%;
    top: 50%;
  }
}
