@import "../common.less";
@import "../mixins.less";

.nav-separator(@margin: 1.5vw) {
  display: inline-block;
  height: 30px;
  border-left: 1px solid #3f444d;
  position: relative;
  margin: 0 @margin;
  content: "";
  vertical-align: middle;

  @media (max-width: @widthMobileNavBreakpoint) {
    display: none;
  }
}

.plus-sign(@color: @colorLinkNav) {
  display: inline-block;
  content: "+";
  position: relative;
  margin: 0 1vw;
  color: @color;
}

.global-nav {
  &--mobile,
  &--desktop {
    height: @navHeight;
    position: fixed;
    z-index: 5000;
    top: 0;
    width: 100%;
    background-color: @navBackground;
    text-rendering: optimizeLegibility;
    display: flex;
  }
  &--mobile {
    display: none;
  }

  @media (max-width: @widthMobileNavBreakpoint) {
    &--mobile {
      display: flex !important;
    }
    &--desktop {
      display: none !important;
    }
  }

  &.dropdown-active {
    .global-nav__section > span:not(.top-level):not(.more-link):not(.game_type),
    .global-nav__section > ul > li > a:not(.top-level):not(.more-link):not(.game_type) {
      color: @colorLinkNavUnFocused !important;
    }
  }

  .main-content-container {
    position: absolute;
    left: 0;
    right: 0;
    height: @navHeight;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: -1;  // makes logo, and notification icons clickable

    @media (max-width: @widthLaptopMax) {
      justify-content: flex-start;;
      padding-left: 40px;
    }

    @media (max-width: @widthMobileNavBreakpoint) {
      padding-left: 5vw;
    }

    &.logged_in {
      @media (max-width: @widthLaptopMax) {
        padding-left: 230px;
      }
    }

    // on mobile, the main content (provideo / forum / more menus)
    // come from left-side and reside out of screen unless toggled
    @media (max-width: @widthMobileNavBreakpoint) {
      position: absolute;
      display: block;
      left: -100vw;
      .transition(left, 0.2s, ease-out);
      width: 100vw;
      min-height: 100vh;
      height: 100%;
      background: @navBackground;
      flex-direction: column;
      z-index: 4;
      overflow-y: scroll;
      padding-bottom: 150px; // safari mobile bottom navigation/action takes from the page

      &.open-section-mobile {
        overflow-y: hidden;
      }
    }
  }

  &__section {
    display: flex;
    align-items: center;

    @media (max-width: @widthMobileNavBreakpoint) {
      flex-direction: column;
      align-items: flex-start;
    }

    &.branding {
      margin-right: 2.5vw;
      padding-left: 2vw;

      @media (max-width: @widthLaptopMax) {
        padding-left: 0;
      }

      > a {
        padding: 0;
        height: 100%;
      }

      .logo {
        transition: opacity .2s ease-out;
        background: url("~img/rio/menu/red_&_white.svg") no-repeat 50% center;
        background-size: 128px auto;
        min-width: 128px;
        position: relative;

        @media(max-width: @widthLaptopMax) and (min-width: @widthMobileNavBreakpoint) {
          background: url("~img/rio/menu/rio-logo.png") no-repeat 50% center;
          background-size: 16px auto;
          min-width: 50px;
        }

        &.on-visions-page {
          &:before {
            // hacky way to reuse same vision logo file both logged in/out users
            content: '';
            background: @navBackground;
            background-image: url("~img/rio/menu/vision-avatar-red.png");
            background-size: cover;
            width: 30px;
            height: 30px;
            position: absolute;
            left: -8px;
          }
        }

        &:hover {
          opacity: .8;
        }
      }

      &.logged_in {
        margin-right: 0;

        .logo {
          background: url("~img/rio/menu/rio-logo.png") no-repeat 50% center;
          background-size: 16px auto;
          min-width: 50px;

          &.on-visions-page {
            //vision-avatar-red.png
            background: @navBackground;
            background-image: url("~img/rio/menu/vision-avatar-red.png");
            background-size: 34px 34px;
            background-repeat: no-repeat;
            background-position: center;

            &:before {
              display: none;
            }
          }
        }

        &:after {
          .nav-separator(2.5vw);
        }

        @media (max-width: @widthMobileNavBreakpoint) {
          margin: 0 auto;
          transform: translateX(-52px);
          min-width: 100vw;
          z-index: -1;
        }
      }

      &:not(.logged_in) {
        @media (max-width: @widthMobileNavBreakpoint) {
          margin-right: auto;
          margin-left: 10px;
        }
      }

    }

    &.provideos {
      @media (max-width: @widthMobileNavBreakpoint) {
        padding-top: 30px;
      }

    }

    &.more {
      // relative so the related dropdown can use
      // position: absolute with top and left offsets
      position: relative;
      background: url("~img/rio/menu/three_dots.svg") no-repeat 50% center;

      @media (max-width: @widthMobileNavBreakpoint) {
        background: none;
      }

      background-size: 24px auto;

      .trigger-nav-dropdown {
        color: transparent !important;
      }
    }

    &.logins {
      position: absolute;
      right: 0;
      height: @navHeight;
      flex-direction: row;
      align-items: center;

      a.login,
      a.signup {
        text-transform: uppercase;
        font-family: @fontOpenSans;
        font-size: 12px;
        color: @colorLinkNav;
        font-weight: 600;
        white-space: nowrap;

        @media (max-width: @widthMobileNavBreakpoint) {
          padding: 0 7px;
        }
      }

      a.signup {
        padding: 5px 20px;
        background: #cf0f1b;
        color: white;
        border-radius: 20px;
        height: auto;
        margin-right: 35px;
        margin-left: 10px;

        @media (max-width: @widthMobileNavBreakpoint) {
          padding: 5px 10px;
          margin: auto 5px;
        }
      }
    }

    &.hamburger {
      align-items: center;
      padding: 5px 10px;
      justify-content: center;

      .icon {
        display: block;
        height: 32px;
        width: 32px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .section-label {
    font-family: @fontOpenSans;
    font-size: 11px;
    color: @colorLinkNav;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;

    @media (max-width: @widthLaptopMax) {
      display: none;
    }
  }

  .section-label-mobile {
    font-family: @fontOpenSans;
    font-size: 13px;
    color: @colorLinkNav;
    font-weight: 400;
    padding: 30px 7vw 7px;
  }

  .dropdown-label {
    font-size: 11px;
    font-family: @fontOpenSans;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #a7b0bc;
    padding: 7px 7vw;
  }

  .icon-downarrow {
    width: 11px;
    height: 11px;
    margin-left: 6px;

    @media (max-width: @widthMobileNavBreakpoint) {
      display: none;
    }
  }

  .icon-close {
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  button.search-toggle {
    position: absolute;
    right: 0;
    height: @navHeight;
    width: @navHeight;
    cursor: pointer;
    background-color: @navBackground;
    border: none;
    box-sizing: border-box;
    .transition(all, 0.5s, ease-out);

    > svg {
      display: inline-block;
      position: relative;
      width: 18px;

      path {
        .transition(fill, .2s, ease-out);
      }
    }

    &:hover {
      path {
        fill: #fff;
      }
    }
  }

  .more-link.search {
    .icon.icon-search {
      height: 16px;
      width: 16px;
      top: 2px;
      fill: #fff;
    }
  }

  section.search-dropdown {
    position: fixed;
    top: @navHeight;
    max-height: 200px;
    width: 100%;
    background: #fff;
    left: 0;
    right: 0;
    padding: 40px 30px;
    z-index: 3010;
    .transition(all, 0.5s, ease-out);
    box-shadow: 0 16px 35px 0 rgba(0, 0, 0, 0.3);
    display: none;

    input {
      background: none;
      border: none;
      box-shadow: none;
      display: block;
      .font(@fontNowayMedium, 45px, #abadb0, 700);
      height: 90px;
      line-height: 1.2;
      margin: 0 auto;
      outline: none;
      padding: 0;
      text-align: left;
      width: 92%;

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      display: block;
    }
  }

  .backwards-and-close {
    position: absolute;
    top: 0;
    width: 100vw;
    left: -100vw;
    .transition(left, 0.2s, ease-out);
    z-index: 10;
    height: @navHeight;
    background: @navBackground;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 10vw;

    &.show {
      left: 0;
    }

    .backwards {
      background: url('~img/partials/learning-paths/back-arrow.png');
      filter: brightness(0) invert(1);
      width: 40px;
      height: 15px;
      background-size: 100% 100%;
      visibility: hidden;

      &:hover {
        cursor: pointer;
      }
    }

    &.section-open {
      .backwards {
        visibility: visible;
      }
    }
  }

  // exclude .user-menu
  &__section:not(.user_menu) {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      height: 100%;

      @media (max-width: @widthMobileNavBreakpoint) {
        flex-direction: column;
      }
    }

    .trigger-nav-dropdown, a {
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 100%;
      width: 100%;
      padding: 0 25px;
      font-family: @fontOpenSans;
      font-size: 14px;
      color: @colorLinkNav;
      font-weight: 400;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        color: @colorLinkHoverNav !important;

        &.game_type {
          background: #28303e;
          border-radius: 10px;
        }
      }

      @media (max-width: @widthLaptopMax) {
        padding: 0 15px;
      }

      @media (max-width: @widthMobileNavBreakpoint) {
        padding: 7px 7vw;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }
    }

    .nav-dropdown {
      display: none;
      font-family: @fontOpenSans;

      &.active,
      &:hover {
        display: flex;
      }

      &--horizontal {
        position: absolute;
        justify-content: center;
        flex-direction: row;
        align-items: stretch;
        top: @navHeight;
        left: 50%;
        transform: translateX(-50%);
        //left: 0;
        //right: 0;
        height: @navDropdownHorizontalHeight;
        background-color: @navDropdownHorizontalBackground;
        border-radius: 10px;

        > li {
          height: @navDropdownHorizontalBackground;
        }

        // Center the vision dropdown on the nav item
        &.vision {
          left: auto !important;
          transform: translateX(-48px) !important;
        }

        // Course subnav dropdown.
        &.courses {
          justify-content: flex-start;
          max-width: 1050px;
          width: 100vw;
          margin: 0 auto;
          //display: block;

          .courses-nav--inner {
            display: flex;
            padding: 40px ~"min(2vw, 40px)";
            flex-grow: 1;
          }

          .courses-nav--categories-list {
            ul {
              display: block;
              //margin: 0 20px 0 0;
              padding: 0;
            }

            li {
              display: block;
              font-size: 25px;
              padding: 10px 15px;
              color: #3d4552;
            }

          .courses-nav--category-title {

            &.active {
              color: #fff;
            }
          }
          }

          .courses-nav--categories {
            border-left: 1px solid #262c35;
            padding-left: ~"min(4vw, 60px)";
            display: flex;
            flex-grow: 4;
            align-items: center;
          }

          .courses-nav--category {
            display: none;
            gap: 10px;
            flex-grow: 1;

            &.active {
              display: flex;
            }
          }

          .courses-nav--course {
            display: flex;
            flex-direction: column;
            height: 240px;
            width: 190px;
            text-align: center;
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: auto 100%;

            &:hover {
              transform: scale(1.05);
            }

            .course-title {
              display: none;
            }

            .course-video-count {
              position: relative;
              background: #fff;
              display: inline-block;
              margin: 0 auto;
              color: #000;
              font-size: 12px;
              border-radius: 12px;
              padding: 2px 15px;
              top: 185px;
            }

            &.course-from-the-ground-up {
              background-image: url('~img/partials/nav-main/course-from-the-ground-up.jpg');
            }

            &.course-from-the-ground-up-mtt {
              background-image: url('~img/partials/nav-main/course-from-the-ground-up-mtt.jpg');
            }

            &.course-from-the-ground-up-plo {
              background-image: url('~img/partials/nav-main/course-from-the-ground-up-plo.jpg');
            }

            &.course-from-the-ground-up-sng {
              background-image: url('~img/partials/nav-main/course-from-the-ground-up-sng.jpg');
            }

            &.course-plo-puzzle {
              background-image: url('~img/partials/nav-main/course-plo-puzzle.jpg');
            }

            &.course-a-game-poker-masterclass {
              background-image: url('~img/partials/nav-main/course-a-game-poker-masterclass.jpg');
              background-position-y: -1px;
            }

            &.course-this-is-plo {
              background-image: url('~img/partials/nav-main/course-this-is-plo.jpg');
            }

            &.course-pads-on-pads {
              background-image: url('~img/partials/nav-main/course-pads-on-pads.jpg');
            }

            &.course-knockout-tournament-mastery {
              background-image: url('~img/partials/nav-main/course-knockout.jpg');
            }

            &.course-from-the-ground-up-5card-plo {
              background-image: url('~img/partials/nav-main/from-the-ground-up-5card-plo.jpg');
              background-size: contain;
            }

            &.course-dominate-with-data {
              background-image: url('~img/partials/nav-main/course-dominate-with-data.jpg');
              background-size: contain;
            }


            &.course-the-game-plan {
              position: relative;
              &:after {
                content: "Learn to Study Like the Best";
                font-family: @fontPoppins;
                font-size: 12px;
                color: #fff;
                border-image: url("~img/courses/the-game-plan/dotted-border.png") 9/4px 4px 4px 4px/0 0 0 0 round;
                position: absolute;
                top: 50%;
                left: 5%;
                padding: 7px;
              }
              background-image: url('~img/partials/nav-main/course-the-game-plan.jpg');
            }

            &.course-foundations {
              background-image: url('~img/partials/nav-main/course-foundations.jpg');
            }
          }

        }

        &.forums {
          justify-content: center;
        }

        a {
          color: @navDropdownHrefColor;

          @media (max-width: @widthMobileNavBreakpoint) {
            color: #fff;
          }

          &:hover {
            color: @colorLinkHoverNav
          }
        }
      }

      &--vertical {
        position: absolute;
        flex-direction: column;
        //right: 100px;

        > li {
          width: 245px;
          padding: 0 15px;
          height: @navHeight;

          a {
            justify-content: space-between;
            background: @navDropdownVerticalBackground;
            padding: 8px;

            .stakes-name {
              white-space: nowrap;
              padding-left: 15px;
            }
          }
        }
      }

      &--vertical {
        position: absolute;
        flex-direction: column;
        top: @navHeight;

        @media (max-width: @widthMobileNavBreakpoint) {
          padding-top: 0;
          margin-bottom: 100px;
        }

        &:not(.more) {
          > li {
            @media (pointer: coarse) and (hover: none) {
              &:first-child {
                padding-top: 10px;
              }
            }
            @media not (pointer: coarse) {
              &:nth-child(2) {
                padding-top: 10px;
              }
            }
            &:last-child {
              padding-bottom: 10px;
            }
          }
        }

        &.more {
          > li {
            &:first-child {
              padding-top: 10px;
            }
            &:last-child {
              padding-bottom: 10px;
            }
          }
        }

        > li {
          width: auto;
          min-width: 165px;
          background-color: @navBackground;
          opacity: 0.95;
          white-space: nowrap;

          a {
            padding: 12px 25px;
            background: transparent;

            &:hover {
              background: #28303e;
              border-radius: 10px;

              @media (max-width: @widthMobileNavBreakpoint) {
                background: transparent;
              }
            }

            @media (max-width: @widthMobileNavBreakpoint) {
              padding: 7px 7vw;
            }
          }

          @media (min-width: @widthMobileNavBreakpoint) {
            &:first-child {
              padding-top: 10px;
              border-radius: 7px 7px 0 0;

              &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-bottom: 10px solid @navBackground;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                position: absolute;
                top: 2px;
                left: 25px;
              }
            }

            &:last-child {
              padding-bottom: 10px;
              border-radius: 0 0 7px 7px;
            }
          }
        }

      }

      @media (max-width: @widthMobileNavBreakpoint) {
        &--vertical,
        &--horizontal,
        &--vertical {
          flex-direction: column;
          position: static;
          display: block;
          background: transparent;
          height: 100%;
          transform: none;
        }

        &--vertical {
          margin-bottom: 50px;

          > li {
            padding: 0;
          }
        }
      }
    }

    .trigger-nav-dropdown {

      @media (max-width: @widthMobileNavBreakpoint) {
        &.center-inner-dropdown-at-850 {
          ~ .nav-dropdown {
            right: 50%;
            transform: translateX(50%);
          }
        }
      }

      @media (max-width: @widthLaptopMax) {
        &.align-inner-dropdown-right-at-960px {
          ~ .nav-dropdown {
            right: 0;
          }
        }
      }

      @media (max-width: @widthLaptopMax) {
        &.align-inner-dropdown-right-at-1154px {
          ~ .nav-dropdown {
            right: 0;
          }
        }
      }

      &.active,
      &:hover {
        ~ .nav-dropdown {
          display: flex;
        }
      }

      @media (pointer: coarse) and (hover: none) {
        &:not(.active) {
          ~ .nav-dropdown {
            display: none;
          }
        }
      }
      @media (max-width: @widthMobileNavBreakpoint) {
        // 0. after all, but the already unfolded for mobile
        &:not(.unfold-mobile) {
          // 1. add a .plus-sign() :after element
          &:after {
            .plus-sign();
          }

          // 2. make all corresponding nav-dropdowns position
          // fixed, and hide them on the left side
          ~ .nav-dropdown {
            &--horizontal,
            &--vertical {
              left: -100vw;
              position: absolute;
              overflow-y: scroll;
              z-index: 5;
              top: 0;
              right: auto;
              height: 100%;
              min-height: 100vh;
              .transition(left, 0.2s, ease-out);
              width: 100vw;
              background: @navBackground;
              display: block;
              padding-top: calc(@navHeight + 0.4 * @navHeight);
              padding-bottom: 150px;

              > li {
                width: auto;

                a {
                  height: 100%;
                  background: transparent;
                  display: block;
                  font-size: 16px;
                  font-family: @fontOpenSans;
                  color: #fff;
                  font-weight: 600;

                  .stakes-name {
                    padding: 7px 0 7px 7vw;
                    display: inline-block;
                  }

                  .stakes-count {
                    color: transparent;
                    background: transparent;
                    display: inline-block;
                    width: auto;

                    > span {
                      color: #b5b9be;
                      font-size: 1.1em;
                    }
                  }
                }

                &.add-thread {
                  max-width: 80vw;
                  height: 60px;
                  margin: 25px 10vw;

                  a {
                    display: flex;
                    .border-radius(4px);
                    padding-left: 0;
                    overflow: hidden;

                    .stakes-name {
                      background: #626c78;
                      white-space: normal;
                      padding: 5px 15px;
                    }

                    .stakes-count {
                      background: #727b86;
                      align-self: center;
                      justify-content: center;
                      display: flex;

                      color: #fff;
                      flex-grow: 1;
                    }
                  }
                }
              }
            }
          }

          &.active {
            ~ .nav-dropdown {
              &--horizontal,
              &--vertical {
                left: 0;
              }
            }
          }
        }

        // show the related .nav-dropdown when unfold-mobile
        &.unfold-mobile {
          ~ .nav-dropdown {
            display: flex;
          }
        }
      }
    }
  }

  .user_menu-logo_name {
    .transition(left, 0.40s, linear);
    height: 45px;
    display: table;
    position: relative;
    list-style: none;

    > .menu-user-toggle {

      .fullscreen-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2000;
        background: rgba(14, 18, 23, 0.9);
        cursor: pointer;
        display: none;
      }

      .btn-user-nav {
        height: @navHeight;
        padding-right: 20px;
        position: relative;
        min-width: 65px;

        > svg {
          .transition(fill, .2s, ease-out);
        }

        .avatar img {
          .border-radius(3px);
          border: none;
          overflow: hidden;
          width: 27px;
          height: 27px;
          display: inline-block;
          position: relative;
          top: 12px;
        }

        .icon-user_rightarrow {
          height: 10px;
          width: 10px;
          top: 3px;
          z-index: 2;
          .transition(all, 0.2s, ease-in-out);
          .transform(translateX(0));

          line {
            .transition(all, .2s, ease-in-out);
          }

          .top-right {
            opacity: 0;
            .transform(translateX(-12px) translateY(13px));
          }

          .bottom-right {
            opacity: 0;
            .transform(translateX(-12px) translateY(-13px));
          }
        }

        &:hover {
          cursor: pointer;

          line {
            fill: #aaa;
            stroke: #aaa;
          }

          .icon-user_rightarrow {
            left: 2px;
          }
        }
      }
    }

    @media (max-width: @widthMobileNavBreakpoint) {
      position: absolute;
      right: 0;

      > .menu-user-toggle {
        .btn-user-nav {
          padding-left: 20px;
          padding-right: 3vw;

          .icon-user_rightarrow {
            .transform(rotate(180deg) translateX(45px));
            top: 12px;
            left: 6px;
          }
        }
      }
    }
  }

  &.nav-open {
    @media (max-width: @widthMobileNavBreakpoint) {
      .main-content-container {
        left: 0;
      }
    }
  }

  &.user-menu-open {
    .user_menu-logo_name {
      > .menu-user-toggle {

        .fullscreen-mask {
          @media (max-width: @widthMobileNavBreakpoint) {
            display: block;
          }
        }

        .icon-user_rightarrow {
          left: -2px;
          .transform(translateX(6px));

          .top-right {
            opacity: 1;
            .transform(translateX(0) translateY(0));
          }

          .bottom-right {
            opacity: 1;
            .transform(translateX(0) translateY(0));
          }

          line {
            fill: #aaa;
            stroke: #aaa;
          }
        }

        .nav-drawer {
          left: 0;
          opacity: 1;

          @media (max-width: @widthMobileNavBreakpoint) {
            left: auto;
            right: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .mobile {
    &--only {
      display: none;
    }
  }

  @media (max-width: @widthMobileNavBreakpoint) {
    .mobile {
      &--only {
        display: flex;
      }

      &--hide {
        display: none !important;
      }
    }
  }

  .touchscreen {
    &--only {
      display: none;
    }
  }

  @media (pointer: coarse) and (hover: none) {
    .touchscreen {
      &--only {
        display: inline-block !important;
      }
    }
  }

  @media (max-width: 375px) {
    .global-nav__section.logins a.signup {
      margin: auto 5px;
      padding: 5px 10px;
      font-size: 11px;
    }

  }

}
