.dominate-with-data-course-banner {
  .transition("top background", .7s, ease-in-out);
  height: 70px;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 6400;
  justify-content: center;
  align-content: center;
  top: -20vh;
  background-color: #000;
  background-image: url("~img/courses/dominate-with-data/banner/freenachos.png"), url("~img/courses/dominate-with-data/banner/bg.jpg");
  background-position: 30px top, center top;
  background-size: auto 70px, cover;
  background-repeat: no-repeat;
  color: #fff;

  &:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    .dominate-with-data-course-banner__content {
      span.out-now {
        span {
          transform: translateX(5px);
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    gap: min(40px, 2vw);

    span {
      img {
        display: block;
        height: 35px
      }

      &.out-now {
        font-family: @fontOpenSans;
        color: #00FFA3;
        font-size: 26px;
        text-transform: uppercase;
        margin-left: 5px;

        span {
          display: inline-block;
        }
      }
    }
  }

  &__close {
    color: #fff;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: calc(22px + 6 * ((100vw - 320px) / 1330));
    position: absolute;
    right: 2%;
    top: 12px;
    z-index: 6500;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@media (max-width: 1285px) {
  .dominate-with-data-course-banner {
    background-image: url("~img/courses/dominate-with-data/banner/freenachos.png"), url("~img/courses/dominate-with-data/banner/bg.jpg");
    background-position: -15px top, center top;

    &__content {
      margin-left: 50px;
      span {
        img {
          display: block;
          height: 20px
        }

        &.out-now {
          font-size: 20px;
        }
      }
    }
  }
}


@media (max-width: 1030px) {
  .dominate-with-data-course-banner {
    &__content {
      span {
        &.by {
          display: none;
        }
      }
    }
  }
}


// Tablet support
@media (max-width: 845px) {
  .dominate-with-data-course-banner {
    &__content {
      flex-direction: column;
      gap: 0;

      span {
        &.build-your {
          img {
            height: 25px;
          }
        }
        &.title {
          img {
            height: 20px;
          }
        }

        &.out-now {
          display: none;
        }
      }
    }
  }
}


// Mobile support
@media (max-width: 670px) {
  .dominate-with-data-course-banner {
    &__content {
        span.title {
          img {
            height: 10px;
          }
        }
    }
  }
}
