@import "../common.less";


.social-auth {
  width: 100%;
  margin: 0 auto;
  max-width: 300px;

  &--align-left {
    margin: 0;
  }

  &--no-max-width {
    max-width: none;
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &__line {
      flex-grow: 1;
      height: 1px;
      background-color: #dee2e5;
    }

    &__text {
      color: #a39f9f;
      padding: 0 15px;
      text-transform: uppercase;
      font-family: @fontOpenSans;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }

  &__choices {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    li {
      margin-bottom: 20px;
    }
  }

  &__button {
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 8px;

    background-color: #fff;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #dee2e6;
    color: #404040FF;
    outline: none;
    font-family: @fontOpenSans;
    font-weight: 500;

    font-size: 16px;
    transition: all 0.2s ease;

    &:hover {
      cursor: pointer;
      background-color: #eaedef;
    }

    &__icon {
      height: 18px;
      width: 18px;
      display: inline-block;

      &--google {
        background-image: url("~img/social-auth/google-color.svg");
        background-size: cover;
      }

      &--facebook {
        background-image: url("~img/social-auth/facebook-color.svg");
        background-size: cover;
      }
    }

    &--facebook {
      background-color: #1877F2;
      border-color: #1877F2;
      color: #fff;

      &:hover {
        background-color: #1961c3;
        border-color: #1961c3;
      }
    }
  }

  &__connected_accounts {
    p {
      margin: 12px 0px;
    }
  }

  &__detach-form {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button[type="submit"] {
      color: #fff;
    }

    label {
      padding-left: 25px;
      background-size: auto 14px;
      background-position: 0 50%;
      background-repeat: no-repeat;
      margin: 0;
    }

    &--google {
      label {
        background-image: url("~img/social-auth/google-color.svg");
      }
    }

    &--facebook {
      label {
        background-image: url("~img/social-auth/facebook-color.svg");
      }
    }
  }
}
