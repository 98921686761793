.animated-background-color-mixin--popup() {
  background: linear-gradient(130deg, #81ffe9, #ff7f7e, #b062fe);
  background-size: 300% auto;
  animation: animated-gradient-background-keyframes 7s ease-in-out infinite;
}

@keyframes animated-gradient-background-keyframes {
  0% {
    background-position: 0 57%
  }
  50% {
    background-position: 100% 44%
  }
  100% {
    background-position: 0 57%
  }
}


body#plans_pricing {
  #body_container {
    overflow-x: hidden;
  }

  &.banner-at-top {
    .video-subscription-plans {
      margin-top: 75px;
    }
  }

  .free-trial-available-plans-selection {
    font-size: 22px;
    color: @colorRed;
    font-family: @fontHeading;
    font-weight: bold;
    padding: 10px;
  }

  .subscription-expires {
    margin-top: 10px;
    text-decoration: underline;
    font-size: 12px;

    &.black {
      color: #000;
    }

    &.white {
      color: #fff;
    }

    &.unpause {
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .visions-container {
    p.vision-expires {
      font-size: 11px;
      color: #fff;
      padding: 0;
    }
  }

  .order-summary {
    background: #fff;

    &.loading {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .cart-summary {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: @fontNowayMedium;
    color: #000;
    max-width: 1340px;
    margin: 100px auto;

    &__details {
      display: flex;
      flex-direction: column;
      margin: 0 2%;
      width: 70%;

      .product {
        &.elite {
          background-image: url(~img/subscribe/plans_pricing/elite_card_background.png);
          background-size: 100% auto;
          background-repeat: no-repeat;
        }

        &.essential {
          overflow: hidden;

          &.essential_free_period {
            .corner-banner {
              color: #fff;
              font-family: @fontOpenSans;
              font-size: 14px;
              font-weight: 700;
              padding: 10px 0;
              text-align: center;
              text-transform: uppercase;
              background: #941c12;
              position: absolute;
              right: -45px;
              top: 19px;
              transform: rotate(45deg);
              width: 180px;
              height: 53px;

              > span {
                position: relative;
                top: 5px;
              }

              &.pt {
                font-size: 14px;
              }
            }
          }
        }

        &.visions {
          background-image: url(~img/visions/plo/plo_BG.jpg);
          background-size: 140% auto;
          background-repeat: no-repeat;
        }

        &.from-the-ground-up {
        }

        &.a-game-poker-masterclass {
        }

        border: 1px solid black;
        background: #1b1b1b;
        display: flex;
        justify-content: space-between;
        padding: 30px;
        // add relative so the remove button can be absolute
        position: relative;
        margin-top: 10px;
        border-radius: 3px;

        .information {
          display: flex;
          flex-direction: column;
        }

        .pricing {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        .remove {
          color: #6b6c6e;
          font-family: @fontNowayRegular;
          font-size: 14px;
          position: absolute;
          top: 10px;
          right: 10px;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
            text-decoration: none;
          }
        }

        .price,
        .discounted_to {
          font-family: @fontNowayMedium;
          font-size: 22px;
          color: #fff;
          padding-left: 10px;
        }

        .discounted_to {
          display: none;
        }

        .pricing.discounted {
          .price {
            text-decoration: line-through;
            text-decoration-color: @colorLink;
            text-decoration-thickness: 3px;
          }

          .discounted_to {
            display: inline-block;
          }
        }

        .title {
          font-family: @fontNowayMedium;
          font-size: 42px;
          color: #fff;
        }

        .description {
          font-family: @fontNowayMedium;
          font-size: 16px;
          color: #fff;
        }
      }

    }

    &__receipt {
      display: flex;
      flex-direction: column;
      padding: 40px 50px;
      justify-content: space-between;
      background: #f5f5f6;
      border-radius: 5px;
      font-size: 18px;
      width: 35%;
      min-width: 300px;
      font-family: @fontNowayMedium;

      ul {
        list-style: none;
        margin-top: 50px;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          margin-bottom: 40px;

          .description {
            width: 90%;
            display: flex;
            flex-direction: column;

            .discounted {
              color: #db2a13;
              font-size: 16px;
              display: none;
            }

            &.discounted {
              .discounted {
                display: inline-block;
              }
            }
          }

          .price {
            display: flex;
            flex-direction: column;
            align-items: center;

            .discounted {
              color: #db2a13;
              font-size: 18px;
              display: none;
            }

            &.discounted {
              .price {
                text-decoration: line-through;
                text-decoration-color: @colorLink;
                text-decoration-thickness: 3px;
              }

              .discounted {
                display: inline-block;
              }
            }
          }
        }
      }

      .due-today {
        font-size: 25px;
        border-bottom: 3px solid black;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }

      .receipt-total {
        border-top: 3px solid black;
        margin-top: 20px;
        padding-top: 30px;
        font-size: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .total,
        .blackfriday-savings {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .blackfriday-savings {
          color: #db2a13;
          font-size: 16px;
        }

        .checkout {
          width: 100%;
          font-size: 16px;
          font-family: @fontOpenSans;
          color: #fff;
          background-color: #16191e;
          padding: 15px;
          text-align: center;
          margin-top: 45px;
          transition: background 0.3s ease-in-out;

          &:hover {
            background-color: #282f3a;
            text-decoration: none;
          }
        }
      }

    }

    h3 {
      font-family: @fontNowayMedium;
      font-size: 55px;
    }

    p.subscriptions,
    p.one-time-purchases {
      font-family: @fontNowayMedium;
      font-size: 23px;
      margin-top: 30px;
    }

  }

  .body_container {
    height: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
  }

  .page-navigator {
    background: white;
    margin-top: 50px;
    padding-top: 15px;
    height: 50px;
    position: fixed;
    width: 100vw;
    text-align: center;
    z-index: 1000;
    border-bottom: 1px solid #85858C38;


    a {
      font-size: 14px;
      font-family: @fontOpenSans;
      font-weight: 900;

      &.navigator-btn {
        color: #cbcbcb;
        margin: 0 10px;

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }

        &.active {
          color: black;
        }

      }

      &.cart-counter {
        padding: 5px 15px;
        border-radius: 30px;
        background-color: black;
        color: white;
        position: absolute;
        right: 10px;
        top: 8px;

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }

        .cart-items {
          color: #fe0000;
          margin-left: 30px;
        }

      }
    }

    p {
      color: black;
      float: right;
      padding-bottom: 0;
      margin-right: 15px;
    }
  }

  .black-friday-sale-banner {
    background: #f1f3f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    z-index: 1000;
    margin-top: 100px; // nav + page-navigator height
    height: auto;
    left: 0;
    right: 0;
    padding: 0 5%;

    &__intro-text {
      font-family: @fontOpenSans;
      font-size: 16px;
      color: #000;
      text-transform: uppercase;
      font-weight: 900;
      padding: 0;
      letter-spacing: 1.3px;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 20px;
      }

      @media (max-width: @widthMobileMax) {
        font-size: 11px;
      }
    }

    &__bonus-code-text {
      font-family: @fontOpenSans;
      font-size: 14px;
      color: #000;
      font-weight: 900;
      padding: 0;
      letter-spacing: 1.1px;

      @media (max-width: @widthMobileMax) {
        font-size: 10px;
      }
    }

    &__countdown {
      margin: 20px 0;
    }
  }

  .flags {
    margin: 30px auto 80px;
    left: 0;
    right: 0;
    width: 125px;
    border: 1px solid #acb2bc;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow: hidden;
    padding: 0;

    &:hover {
      border-radius: 15px;
      margin-bottom: 15px;

      li {
        display: flex !important;
        order: 2;

        &:hover {
          background-color: #f3f3f3;
        }
      }

      .dropdown_arrow {
        visibility: hidden;
      }
    }

    li {
      justify-content: center;
      display: none;
    }

    a {
      color: #000;
      font-family: @fontOpenSans;
      font-weight: 700;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 125px;
      text-decoration: none;
      padding: 6px 10px;
    }

    .dropdown_arrow {
      height: 6px;
      visibility: visible;
      display: inline-block;
    }

    .flag {
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center;
    }

    li {
      order: 2;

      &.english {
        .flag {
          background-image: url("~img/flags/usa.png");
        }
      }

      &.russia {
        .flag {
          background-image: url("~img/flags/russia.png")
        }
      }

      &.brazil {
        .flag {
          background-image: url("~img/flags/brazil.png");
        }
      }
    }

    &.en-us {
      li.english {
        display: flex;
        order: 1;
      }
    }

    &.ru {
      li.russia {
        display: flex;
        order: 1;
      }
    }

    &.pt {
      li.brazil {
        display: flex;
        order: 1;
      }
    }

  }

  .video-subscription-plans {
    background-color: white;

    .inner-wrapper {
      background-color: white;
      margin: 150px auto 0;
      max-width: 1340px;
      text-align: center;
      position: relative;
    }

    &.black-friday-sale {
      margin-top: 75px;
    }

    .title-container {
      h2 {
        color: black;
        font-size: 30px;
        font-family: @fontNowayMedium;
      }

      h1 {
        color: #515557;
        font-size: 18px;
        font-family: @fontOpenSans;
      }

      position: relative;
    }
  }

  .flex-container {
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 640px;

    &.offset-a-bit {
      position: relative;
      top: 50px;
    }

    .col-container {
      width: 32%;
      text-align: center;
      transition: all .2s ease-in-out;
      overflow: hidden;
      margin: 0 2px;
      padding: 30px 38px;
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0), 0 0px 0px 0 rgba(0, 0, 0, 0.0);

      &.elite {
        background-image: url(~img/subscribe/plans_pricing/elite_card_background.png);
        background-size: cover;

        p.price-increase,
        a.price-increase {
          color: #fff;
          font-family: @fontOpenSans;
          font-size: 14px;
          line-height: 21px;
          display: block;
          font-weight: 700;
          text-decoration: underline;
          padding: 4px 0;
          background: rgba(160, 20,20, 0.4);
          border: 2px solid #ff1800;
          border-radius: 8px;

          &:hover {
            font-size: 15px;
            .transition(all, 0.2s, ease-in-out);
          }
        }
      }

      &.basic {
        border: 2px solid #a2a6b1;
        background-color: white;

        .price {
          color: black;
        }
      }

      &.essential {
        border: 2px solid #16191e;
        background-color: #16191e;

        &.essential_free_period {
          position: relative !important;
          border: none !important;

          .corner-banner {
            color: #fff;
            font-family: @fontOpenSans;
            font-size: 14px;
            font-weight: 700;
            padding: 10px 0;
            text-align: center;
            text-transform: uppercase;
            background: #941c12;
            position: absolute;
            right: -45px;
            top: 19px;
            transform: rotate(45deg);
            width: 180px;
            height: 53px;

            > span {
              position: relative;
              top: 5px;
            }
          }
        }
      }

      .header.annual {
        display: none;
      }

      &.annual {
        position: relative;
        overflow: initial;

        .header.annual {
          position: absolute;
          left: 0;
          right: 0;
          top: -30px;
          height: 50px;
          font-family: @fontNowayMedium;
          font-size: 18px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(130deg, #61dc7a, #fe8efb, #5342ff);
        }

        .price {
          display: flex;
          justify-content: space-around;
          align-items: center;

          @media (max-width: @widthMobileMax) {
            flex-direction: column;
          }

          .monthly,
          .annual__price {
            position: relative;
          }

          .annual__or {
            font-family: @fontOpenSans;
            font-size: 17px;
            font-weight: 600;
            margin-left: 40px;
            position: relative;
            top: 15px;
            @media (max-width: @widthMobileMax) {
              margin-left: 0;
              margin-top: 40px;
              top: -10px;
            }
          }

          .annual__price {
            position: relative;

            .original-price {
              position: absolute;
              top: -10px;
              left: 50px;
              color: #ff003a;
              font-size: 20px;
              text-decoration: line-through;

              @media (max-width: @widthMobileMax) {
                top: -5px;
                left: 30px;
              }
            }

            .dollar-sign,
            .price-number,
            .cents,
            .per-month {
              background: linear-gradient(130deg, #61dc7a, #fe8efb, #5342ff);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .per-month {
              position: absolute;
              left: 35%;
              bottom: -15px;
            }
          }
        }

        .annual__purchase-buttons {
          display: flex;
          justify-content: space-between;

          .cart-addition {
            width: 165px;
            padding: 0 25px;

            @media (max-width: @widthMobileMax) {
              width: auto;
              padding: 0;
            }

            .add-product {
              line-height: 20px;
            }

            &[data-product="annual"] {
              background: linear-gradient(130deg, #5342ff, #fe8efb, #5342ff) 0 57% / 300% auto;

              &.selected {
                background: #A70106;
              }
            }
          }
        }
      }

      &:hover {
        transform: scale(1.03);
        z-index: 10;
        //cursor: pointer;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.3), 0 10px 20px 0 rgba(0, 0, 0, 0.3);
      }

      .header {
        h2 {
          font-family: @fontNowayMedium;
          font-size: 26px;
          color: white;
        }

        p {
          font-family: @fontOpenSans;
          font-size: 14px;
          color: white;

        }

        &.basic {
          h2 {
            color: black;
          }

          p {
            color: #7d8696;
          }
        }

        &.essential {
          p {
            color: #7d8696;
          }
        }
      }

      hr {
        margin-top: 20px;
      }

      .price {
        font-family: 'Oswald', sans-serif;
        font-weight: 100;
        font-size: 74px;
        margin: 20px 0 40px;
        color: white;
        position: relative;
      }

      &.free {
        color: black;
      }

      .dollar-sign {
        position: absolute;
        font-size: 36px;
        top: 15px;
      }

      .price-number {
        padding-left: 18px;
      }

      .cents {
        position: absolute;
        font-size: 36px;
        top: 15px;
      }

      .per-month {
        font-family: @fontOpenSans;
        font-weight: 700;
        font-size: 12px;
        position: absolute;
        bottom: -15px;
        left: 43%;
      }

      .benefit-list {

        text-align: left;
        list-style-type: none;
        margin: 20px 0;

        li {
          margin: 10px 0 10px 24px;
          font-family: @fontOpenSans;
          font-weight: 700;
          font-size: 14px;
          position: relative;
          color: #555a62;

          &.included {
            color: white;
          }

          img {
            position: absolute;
            height: 12px;
            width: auto;
            left: -24px;
            top: 5px;
          }
        }

        &.basic {
          li {
            color: #c2c6cb;

            &.included {
              color: black;
            }
          }
        }

      }

      .money-back, .grandfather-info {
        color: white;
        font-family: @fontNowayMedium;
        font-size: 12px;
        margin-top: 10px;
        padding-bottom: 0px;
      }

      .money-back {
        font-style: italic;
      }

      &.basic {
        .money-back, .grandfather-info {
          color: black;
        }
      }
    }
  }

  .pro-container {
    background-color: white;

    .button-container {
      text-align: center;
      margin-bottom: 30px;

      .button {
        color: grey;
        font-size: 20px;
        font-family: @fontNowayMedium;
        font-weight: 500;
        padding: 5px 10px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          color: black;
          text-decoration: underline;
        }
      }
    }

    .pro-images {
      display: none;
      padding-bottom: 50px;
      //width: 100vw;

      &.active {
        display: flex;
        flex-direction: row;
      }

      .images-container {
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 275px;
        position: relative;

        @media (max-width: @widthTabletMax) {
          flex-wrap: wrap;
          height: auto;
          min-height: 40vh;
        }

        &:after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          //box-shadow: 7vw 0 60px -10px rgba(2, 5, 8, 0.75) inset, -7vw 0 60px -10px rgba(2, 5, 8, 0.75) inset;
        }

        .pro-image {
          width: 28%;
          max-width: 275px;
          margin-left: 0.5%;
          display: flex;
          justify-content: start;
          align-items: flex-end;
          flex-shrink: 0;
          background-color: #020508;
          box-shadow: 0 -120px 50px 0px rgba(2, 5, 8, 0.75) inset;

          @media (max-width: 1400px) {
            box-shadow: 0 -20px 30px 0 rgba(2, 5, 8, .75) inset;
          }

          &:first-child {
            margin-left: 0;
          }

          p {
            color: #fff;
            font-size: 22px;
            font-family: @fontOpenSans;
            font-weight: 500;
            text-align: left;
            margin: 30px;

            @media (max-width: @widthTabletMax) {
              font-size: calc(0.85 * 27px);
            }
            @media (max-width: @widthMobileMax) {
              font-size: calc(0.5 * 27px);
              margin: 10px;
            }
          }

          &.phil {
            background-image: url("https://media.runitonce.com/pro-avatars/phil-galfond-Elite-PLO-instructor.jpg");
            background-size: cover;
          }

          &.dan-smith {
            background-image: url("https://media.runitonce.com/pro-avatars/dan-smith.jpg");
            background-size: cover;
          }

          &.justin-bonomo {
            background-image: url("https://media.runitonce.com/pro-avatars/justin-bonomo.jpg");
            background-size: cover;
          }

          &.jeremy-ausmus {
            background-image: url("https://media.runitonce.com/pro-avatars/jeremy-ausmus--runitonce-poker-coach.jpg");
            background-size: cover;
          }

          &.koon {
            background-image: url("https://media.runitonce.com/pro-avatars/jason-koon-elite-MTT-instructor.jpg");
            background-size: cover;
          }

          &.peter-clarke {
            background-image: url("https://media.runitonce.com/pro-avatars/peter_clarke-runitonce-poker-coach.jpg");
            background-size: cover;
          }

          &.alexandra-fagaras {
            background-image: url("https://media.runitonce.com/pro-avatars/alexandra-fagaras-runitonce-poker-coach-nlhe.png");
            background-size: cover;
          }

          &.kevin-martin {
            background-image: url("https://media.runitonce.com/pro-avatars/kevin-martin-poker-coach-no-limit-holdem.jpg");
            background-size: cover;
          }

          &.paul-gough {
            background-image: url("https://media.runitonce.com/pro-avatars/paul-gough-nlhe-poker-coach.jpg");
            background-size: cover;
          }

          &.thomas-emter {
            background-image: url("https://media.runitonce.com/pro-avatars/thomas-emter-pot-limit-omaha-poker-coach.jpg");
            background-size: cover;
          }
        }
      }
    }
  }

  .lifetime-access {
    font-family: @fontNowayRegular;
    font-size: 12px;
    color: #b8bac3;
    font-style: italic;
    margin: 10px 5px;
    display: block;
  }

  .cart-addition {
    /*
    shut down all inner element pointer events
    reasoning: elements with class cart-additions will have data-*
    attributes and we'd like to access those through the javascript
    event handlers that will be attached
    (otherwise in event.target we may find the inner elements "clicked")
     */

    * {
      pointer-events: none !important;
    }

    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #515561;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    font-family: @fontOpenSans;
    font-weight: 600;
    text-align: center;
    width: 280px;
    height: 50px;
    transition: all .2s ease-out;

    &.this-is-plo-discounted {
      width: 300px;
    }

    &.current {
      // for video plan that the user already owns
      background-color: #A70106;
      pointer-events: none;

      &:hover {
        background-color: #A70106;
      }
    }

    &.cancel-subscription {
      background-color: black;
    }

    &.check-out-course {
      // for the course that the user already owns
      background-color: #A70106;

      &:hover {
        background-color: #A70106;
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #777F8C;
      text-decoration: none;
    }

    .add-product,
    .remove-product {
      display: inline-block;
    }

    .add-product {
      align-items: center;
      justify-content: flex-start;
      line-height: 50px;

      &.paused {
        line-height: 16px;
        display: flex;
        flex-direction: column;

        > span {
          font-size: 0.7em;
        }
      }

      .old-price {
        text-decoration: line-through;
        opacity: 0.7;
        margin-right: 10px;
      }

      .price-product {
        height: 50px;
        border-right: 1px solid #787d89;
        margin-right: 20px;
        padding-right: 20px;
        display: inline-block;

        &.discounted {
          font-weight: bold;
          font-style: italic;
          padding-right: 10px;
          margin-right: 10px;
        }
      }
    }

    // inner .remove element is to be shown if product already
    // in cart, and now clicking the button should remove it
    .remove-product {
      display: none;
    }

    &.selected {
      flex-direction: column;

      // inner .add element is to be hidden if product is in cart
      // and clicking on cart-addition will remove it from there
      .add-product {
        display: none;
      }

      .remove-product {
        display: inline-block;
      }

      ~ .cart-skip {
        display: none;
      }

      background-color: #A70106;
    }

    &.pt {
      .price-product {
        margin-right: 10px;
        padding-right: 10px;
      }
    }

  }

  .cart-skip {
    color: #fff;
    font-family: @fontOpenSans;
    font-weight: 600;
    font-size: 16px;
    text-decoration: underline;
    margin-left: 12px;

    &.black {
      color: #16191e
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  .visions-container {
    background-color: #fcfcfc;
    background-image: url("~img/subscribe/plans_pricing/vector-visions-background-top-right.jpg");
    background-size: 1680px auto;
    background-repeat: no-repeat;
    background-position: top right;

    .title-container {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 60px;

      img {
        width: 250px;
        height: auto;
        margin: 20px auto;
        filter: invert(100%);
      }

      p {
        color: #676d78;
        font-size: 18px;
        font-family: @fontOpenSans;
      }
    }

    /*
    Layout related stuff for the visions section in here
     */

    &__content {
      display: flex;
      flex-direction: row;
      padding: 3% 5%;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      font-weight: 100;
      max-width: 1340px;
      margin: 0 auto;

      @media (max-width: @widthMobileMax) {
        flex-direction: column;
      }

      .vision-plans__text {
        order: 1;
        width: 38%;

        ul {
          padding-left: 15px;
        }

        li {
          font-size: 16px;
        }

        @media (max-width: @widthDesktopMin) {
          width: 100%;
          order: 2;
        }
      }

      .visions-video {
        order: 2;
        width: 56%;
        display: flex;
        justify-content: center;
        align-items: center;

        > iframe {
          min-height: 400px;
          min-width: 640px;
          box-shadow: 0px 15px 75px 25px rgba(0, 0, 0, 0.49);
        }

        @media (max-width: @widthDesktopMin) {
          width: 100%;
          order: 1;
          margin-bottom: 15%;

          > iframe {
            min-width: 90vw;
            min-height: calc(.625 * 90vw); // keep the 400px x 640px ratio
          }
        }
      }

      .extra {
        width: 100%;
        order: 3;
        margin: 80px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .fast,
      .intuitive,
      .powerful {
        width: 30%;

        @media (max-width: @widthMobileMax) {
          width: 100%;
          order: 3;
        }
      }

      .rulaz-vision-box {
        background-image: url("~img/visions/rulaz-vision-bg.jpg");
        background-size: 100% auto;
        width: 60%;
        margin: 0 auto;
        order: 4;
        text-align: center;
        padding: 40px;
        background-repeat: no-repeat;
        min-width: 550px;

        h2 {
          font-size: 40px;
          color: black;
          font-family: @fontNowayMedium;
        }

        p {
          font-size: 20px;
          padding: 20px 0px 30px;
          font-family: @fontOpenSans;
        }

        a {
          color: white;
          background-color: #972230;
          padding: 10px 60px;
          font-family: @fontNowayMedium;
          font-size: 16px;
          display: inline-block;
          margin-bottom: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .vision-plans {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        order: 4;
      }
    }

    /*
    End of Layout-related stuff for the visions section
     */

    .vision-plans {

      padding: 20px 20px 30px;
      background: #181b21;
      border-radius: 13px;

      &__monthly-yearly-switch {
        position: relative;
        margin: 40px auto;
      }


      .vision-plans-term-form {
        display: block;

        &.blackfriday {
          margin-bottom: 20px;
        }

        .vision-plans-term-form-inner {
          margin: 0;
          padding: 0;
          border: 2px solid #2b2e38;
          background: #1e2128;
          position: relative;
          display: inline-flex;
          border-radius: 25px;
        }

        .plan-option-wrap {
          display: inline-block;
        }

        input[name="vision-plans-term"] {
          display: none;
        }

        label {
          margin: 0;
          padding: 0;
          width: 165px;
          height: 46px;
          display: inline-block;
          position: relative;
          z-index: 2;
          font-size: 16px;
          text-align: center;

          .description {
            font-family: @fontOpenSans;
            display: inline-block;
            padding: 15px 10px 0;
            cursor: pointer;
            line-height: 1em;
            color: #fff;
            font-weight: 700;
            transition: color .25s;
            transition-delay: .0s;
          }

          .savings {
            display: inline-block;
            font-size: 13px;
            color: #f33446;
          }

          // 2 line plans
          &.vision-plans-3-month-label,
          &.vision-plans-12-month-label {
            .description {
              padding: 5px 10px 0;
            }
          }

          &.vision-plans-3-month-label.blackfriday {
            .description {
              padding: 15px 10px 0;
            }
          }

          &.vision-plans-12-month-label.blackfriday {
            position: relative;
          }
        }

        #vision-plans-1-month[name="vision-plans-term"]:checked ~ .vision-plans-1-month-label,
        #vision-plans-3-month[name="vision-plans-term"]:checked ~ .vision-plans-3-month-label,
        #vision-plans-12-month[name="vision-plans-term"]:checked ~ .vision-plans-12-month-label {
          .description {
            color: #0e1015;
            transition: color .25s;
            transition-delay: .05s;
          }

        }

        .selected-term-slider {
          position: absolute;
          left: 0;
          top: -2px;
          width: 165px;
          height: 50px;
          padding: 0;
          margin: 0;
          background: #fff;
          z-index: 1;
          border-radius: 25px;
          animation-fill-mode: forwards;
          transition: 0.2s all .05s ease-in-out;
        }

        #vision-plans-1-month:checked ~ .selected-term-slider {
          left: -2px;
        }

        #vision-plans-3-month:checked ~ .selected-term-slider {
          left: 33.3%;

          &.hide-monthly {
            left: -2px;
          }
        }

        #vision-plans-12-month:checked ~ .selected-term-slider {
          left: 67.2%;
          &.hide-monthly {
            left: 50%;
          }
          &.hide-quarterly {
            left: -2px;
            &:not(.hide-monthly) {
              left: 50%;
            }
          }
        }
      }


      &__products {
        list-style: none;
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        justify-content: space-around;

        li {
          width: 32%;

          &.disabled {
            opacity: 0.8;
            pointer-events: none;

            .price-discount {
              visibility: hidden;
            }

            .price {
              span {
                display: none;
              }
            }

            &:hover {
              cursor: none;
            }
          }

          .product-description {
            border: 2px solid #262935;
            padding: 20px;
            color: white;
            background-color: #1e2128;
            min-height: 220px;
            font-family: @fontOpenSans;
            font-weight: bold;
            position: relative;

            .black-friday-sale-sticker {
              height: 90px;
              width: 90px;
              position: absolute;
              top: -55px;
              left: 0;
              background: url(~img/subscribe/plans_pricing/black-friday-sale-sticker.png) no-repeat;
              background-size: 90px auto;

              @media (max-width: 1285px) {
                left: 0;

                @media (max-width: 700px) {
                  top: -15px;
                  height: 75px;
                  width: 75px;
                  background-size: 75px auto;
                }
              }
            }

            .price-discount {
              color: #f33446;
              text-decoration: line-through;
              text-decoration-thickness: 3px;
              font-size: 16px;

              // So the spacing doesn't change when there is no discount.
              .no-discount {
                text-decoration: none;
                opacity: 0;
              }
            }

            .price.blackfriday {
              font-size: 16px;
              text-decoration: line-through;
              color: #d0d8d9;
            }

            .price {
              font-size: 30px;

              span {
                font-size: 12px;
              }
            }

            .description-plan {
              font-size: 18px;
              margin-bottom: 35px;
            }

            a {
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              text-align: center;
              font-family: @fontOpenSans;
              border-radius: 30px;
              padding: 10px 30px;
              cursor: pointer;
              background-color: #515561;
              width: 90%;
              max-width: 300px;
              white-space: nowrap;
              line-height: 16px;

              &.selected {
                background-color: #A70106;
              }

              &:hover {
                background-color: #787c8e;
              }
            }

            &:hover {
              transform: scale(1.03);
              border: 3px solid #f33446;
              box-shadow: 0 0 10px 10px rgba(255, 56, 78, 0.2);
            }
          }

          margin: 10px;
        }

        &.show-monthly {
          li.monthly {
            display: block;
          }

          li.quarterly {
            display: none;
          }

          li.yearly {
            display: none;
          }
        }

        &.show-quarterly {
          li.monthly {
            display: none;
          }

          li.quarterly {
            display: block;
          }

          li.yearly {
            display: none;
          }
        }


        &.show-yearly {
          li.yearly {
            display: block;
          }

          li.quarterly {
            display: none;
          }


          li.monthly {
            display: none;
          }
        }
      }

      .cart-addition {
        width: 240px;
      }

      .cart-skip {
        color: #fff;
      }

    }


    h3 {
      color: #16191e;
      font-family: @fontNowayMedium;
      font-size: 30px;
      margin-bottom: 40px;
    }

    h4 {
      color: #16191e;
      font-family: @fontNowayMedium;
      font-size: 21px;
      margin-bottom: 20px;
    }

    p {
      font-family: @fontOpenSans;
      font-size: 18px;
      color: #676d78;
    }

  }

  .course-purchase-containter {
    background-image: url(~img/subscribe/plans_pricing/course-section-bg.jpg);
    background-size: cover;

    .inner-wrapper {
      max-width: 1340px;
      margin: 100px auto;

      .header-container {
        text-align: center;

        h2 {
          font-family: @fontNowayMedium;
          font-size: 30px;
          color: white;
        }

        p {
          font-family: @fontOpenSans;
          font-size: 18px;
          color: #676d78;

        }
      }

      #wsop-sale-sticker {
        height: 90px;
        width: 90px;
        position: absolute;
        top: -55px;
        left: 0px;
        background: url(~img/subscribe/plans_pricing/sale-sticker.png) no-repeat;
        background-size: 90px auto;

        @media (max-width: 1285px) {
          left: 0px;

          @media (max-width: 700px) {
            top: -65px;
          }
        }
      }

      .course-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 100px;

        .image-container {
          width: 60%;
          position: relative;

          .black-friday-sale-sticker {
            height: 90px;
            width: 90px;
            position: absolute;
            top: -55px;
            left: 0;
            background: url(~img/subscribe/plans_pricing/black-friday-sale-sticker.png) no-repeat;
            background-size: 90px auto;

            @media (max-width: 1285px) {
              left: 0;

              @media (max-width: 700px) {
                top: -65px;
              }
            }
          }

          // The big image, author info and title.
          .plaque {
            width: 700px;
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
            padding: 60px 140px 60px 60px;
            margin-left: 30px;
            position: relative;
            height: 375px;
            display: block;

            &.foundations {
              background: url("~img/courses/foundations/hero-mini.jpg") no-repeat;
              background-size: 100% auto;

              .category,
              .title,
              p {
                opacity: 0;
              }
            }

            &.from-the-ground-up {
              background: url('~img/courses/home/course-ftgu-bg.jpg') no-repeat;
              color: #242a3a;

              p {
                color: #616674;
              }
            }

            &.from-the-ground-up-plo {
              background: url("~img/courses/ftgu-plo-bg.jpg") no-repeat;
              background-size: 100% auto;
              color: #fff;

              p {
                color: #fb2796;
              }

              &.card-5 {
                background: url("~img/courses/from-the-ground-up-5card-plo/bg.jpg") no-repeat;
                background-size: cover;

                p {
                  color: #b2a8fc;
                }
              }
            }

            &.from-the-ground-up-sng {
              background: url("~img/courses/ftgu-sng-bg.jpg") no-repeat;
              background-size: 100% auto;
              color: #fff;

              p {
                color: #008daf;
              }

              .instructor {
                img {
                  transform: scale(1.3);
                }
              }
            }

            &.from-the-ground-up-mtt {
              background: url("~img/courses/ftgu-mtt-bg.jpg") no-repeat;
              color: #fff;

              p {
                color: #b7a9c2;
              }
            }

            &.knockout-tournament-mastery {
              background: url("~img/courses/knockout/hero-mini.jpg") no-repeat;
              background-size: 100% auto;
              color: #fff;

              .title {
                font-size: 65px;
                line-height: 1em;
              }

              p {
                color: #fff;
                max-width: 410px;
              }

              .instructor {
                img {
                  transform: scale(1.1);
                }
              }
            }

            &.this-is-plo {
              background: url("~img/courses/this-is-plo/hero-title-mobile.png"), url("~img/courses/this-is-plo/course-background-pp-listing.jpg");
              background-repeat: no-repeat, no-repeat;
              background-size: 60% auto, cover;
              background-position: 15%, 0;

              .category,
              .title {
                color: #fff;
              }

              p {
                color: #9e8a8f;
              }
            }

            &.the-game-plan {
              background: #000 url('~img/courses/the-game-plan/bg-1.jpg') top left;
              color: #fff;

              h4 {
                font-size: 40px;
                color: transparent;
                background: linear-gradient(to right, #e8229c, #632fe5);
                -webkit-background-clip: text;
                background-clip: text;
              }

              h3 {
                color: #fff;
                text-decoration: underline;
                text-decoration-color: #818181;
                text-decoration-thickness: 3px;
              }

              p {
                color: #818181;
              }
            }

            &.pads-on-pads {
              background: url("~img/courses/pads-on-pads/background-min.jpg") no-repeat;
              color: #fff;

              p {
                color: #07f160;
              }
            }

            &.a-game-poker-masterclass {
              padding-right: 100px;
              background: url('~img/courses/home/course-elliot-roe-bg.jpg') no-repeat;
              color: #FFF;

              p {
                color: #7bfe7a;
              }
            }

            &.plo-puzzle {
              background: url('~img/courses/plo-puzzle/page-bg.jpg') no-repeat;
              background-size: 100% auto;
              color: #fff;

              p {
                color: #9e8a8f;
              }

              .instructor {
                width: 250px;
                height: 177px;

                img {
                  width: 252px;
                  transform: translate(-1px, -1px);
                }

                .instructor-meta {
                  display: none;
                }
              }
            }

            &.dominate-with-data {
              background: url("~img/courses/dominate-with-data/course-card.jpg") no-repeat;
              background-size: 100% auto;
              color: #fff;

              .title, .category, p {
                opacity: 0;
              }
            }


            &:hover {
              text-decoration: none;
            }

            .category {
              font-family: @fontNowayMedium;
              font-size: 20px;
              text-transform: uppercase;
              font-weight: 700;
            }

            .title {
              font-family: @fontNowayMedium;
              font-weight: 700;
              font-size: 75px;
              line-height: 1.2em;
              padding-bottom: 20px;
            }

            p {
              font-family: @fontNowayMedium;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: .2em;
              text-transform: uppercase;
            }

            .instructor {
              .box-shadow(1px 2px 50px rgba(0, 0, 0, 0.55));
              // margin-bottom: 50px;
              position: absolute;
              bottom: -30px;
              right: -30px;
              text-align: center;
              width: 230px;
              height: 220px;
              background: #000;
              border-radius: 10px;
              overflow: hidden;

              img {
                width: 230px;
                display: block;
              }
            }

            .instructor-meta {
              position: absolute;
              width: 100%;
              bottom: 0;
            }

            .instructor-name {
              font-size: 15px;
              color: #fff;
              display: block;
            }

            .instructor-title {
              font-size: 12px;
              color: #80828e;
              text-transform: uppercase;
              display: block;
              padding-bottom: 20px;
            }

            @media (max-width: 1160px) {
              height: auto;
              padding: 15% 40% 10% 5%;
              background-size: auto 100%;
              margin-left: 0;

              &.this-is-plo {
                min-height: 300px;
                background-size: 60% auto, cover;
              }

              &.a-game-poker-masterclass {
                padding-right: 40%;
              }

              .instructor {
                top: 20%;
                right: 10px;
              }
            }

            @media (max-width: 1100px) {
              width: 100%;
              height: auto;

              .title {
                font-size: 60px;
              }

              .instructor {
                right: -100px;
                top: 20%;
              }
            }

            @media (max-width: @widthMobileMax) {
              padding: 15% 40% 10% 5%;

              .title {
                font-size: 35px;
              }

              .instructor {
                width: 150px;
                height: 145px;
                top: 25%;
                right: -40px;

                .instructor-meta {
                  bottom: -15px;
                }

                img {
                  width: 150px;
                }

              }

              &.a-game-poker-masterclass {
                .instructor {
                  height: 190px;
                }
              }

              &.plo-puzzle {
                .instructor {
                  width: 200px;
                  height: 143px;

                  img {
                    width: 205px;
                  }
                }
              }
            }

          }

          //img {
          //  height: auto;
          //  width: 100%;
          //}
        }

        .info-container {
          width: 35%;
          min-width: 400px;

          .description {
            h1 {
              font-family: @fontNowayMedium;
              font-size: 30px;
              color: white;
              margin: 10px 0;
            }

            p {
              color: #b8bac3;
              font-family: @fontOpenSans;
              font-size: 18px;
              padding-bottom: 35px;
              margin-top: 20px;
            }

          }

          .button-container {

          }
        }

        &.from-the-ground-up {
          position: relative;
        }

        #wsop-sale-sticker {
          height: 90px;
          width: 90px;
          position: absolute;
          top: 60px;
          left: -30px;
          background: url(~img/subscribe/plans_pricing/sale-sticker.png) no-repeat;
          background-size: 90px auto;

          @media (max-width: 1400px) {
            left: 20px;

            @media (max-width: 1100px) {
              left: 15%;
            }

            @media (max-width: 900px) {
              top: 50px;
            }

            @media (max-width: 700px) {
              background-size: 75px auto;
              left: 10%;
            }
          }
        }

        .free-essential-sticker {
          background: url(~img/courses/free-essential-banner.png) no-repeat;
          background-size: 200px auto;
          height: 50px;
          width: 200px;
          position: absolute;
          top: 95px;
          left: 230px;
          @media (max-width: 1100px) {
            left: 32%;
          }
          @media (max-width: 700px) {
            left: 27%;
            background-size: 150px auto;
          }
        }
      }
    }
  }

  .mobile-cart-container {
    display: block;
    position: fixed;
    z-index: 200;
    bottom: 0;
    width: 100vw;
    background: #16191e;
    text-align: center;
    padding: 15px 0;

    @media (min-width: @widthMobileMax + 1) {
      display: none;
    }

    &:hover {
      cursor: pointer;
      background: #282f3a;

      a {
        text-decoration: none;
      }

    }

    &.hide {
      display: none;
    }

    .cart {
      color: #fff;
      font-size: 16px;
      font-family: @fontOpenSans;
      font-weight: bold;
    }
  }

  .a-game-poker-masterclass-options {
    display: flex;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    font-family: @fontOpenSans;
    font-weight: 600;
    text-align: center;
    width: 280px;
    height: 50px;
    transition: all .2s ease-out;
    list-style: none;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 0;

    &:hover {
      cursor: pointer;
    }

    &.active {
      height: 100px;
      border-radius: 20px;
      text-decoration: none;

      li {
        svg {
          display: none !important;
        }
      }
    }

    &.a-game-poker-masterclass {
      li:first-child {
        order: 1;
      }

      li:last-child {
        order: 2;
      }
    }

    &:not(.a-game-poker-masterclass) {
      li:first-child {
        order: 2;
      }

      li:last-child {
        order: 1;
      }
    }

    li {
      order: 1;
      display: flex;
      justify-content: center;
      padding: 0 12px;
      margin: 0;
      min-height: 50px;
      align-items: center;
      background-color: #515561;

      svg {
        width: 12px;
        height: 12px;
        margin-left: 12px;

        path {
          fill: #fff;
        }
      }

      &:hover {
        color: #ccc;
      }

      .add-product {
        height: 50px;
        display: flex;
        justify-content: center;
        align-content: center;

        .old-price {
          text-decoration: line-through;
          opacity: 0.7;
          margin-right: 5px;
          line-height: 50px;
        }

        .price-product {
          height: 50px;
          line-height: 50px;
          //border-right: 1px solid #787d89;
          margin-right: 10px;
          //padding-right: 20px;
          display: inline-block;

          &.discounted {
            font-weight: bold;
            font-style: italic;
          }
        }
      }
    }

  }

  .annual-elite-promo-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12001;
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
    display: none;

    &.show {
      display: block;
    }

    &__content {
      max-height: 100vh;
      min-height: 360px;
      width: 80vw;
      max-width: 760px;
      .center-vertically-and-horizontally-absolute();
      overflow-y: auto;
      background: #020508 url("~img/annual-elite-offer/BG-min.jpg") no-repeat;
      background-position-x: center;
      background-position-y: -25%;
      background-size: 100% auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 5% 5%;

      h1 {
        width: 100%;
        text-align: center;

        font-family: @fontNowayRegular;
        font-size: 80px;
        line-height: 0.8;
        padding-bottom: 15%;
        .animated-background-color-mixin--popup();
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: @widthTabletMax) {
          line-height: 1.1;
          font-size: calc(0.65 * 80px);
        }

        @media (max-width: @widthMobileMax) {
          font-size: calc(0.5 * 80);
        }
      }

      p {
        font-family: @fontOpenSans;
        font-size: 18px;
        color: #fff;
        margin-bottom: 2%;

        @media (max-width: @widthMobileMax) {
          font-size: 14px;
        }
      }

      ul {
        font-family: @fontOpenSans;
        font-size: 15px;
        font-weight: 600;
        padding-left: 20px;

        @media (max-width: @widthMobileMax) {
          font-size: 13px;
        }

        li.bonus-riop {
          color: #ff7f7e;
        }

        li.bonus-course {
          color: #81ffe9;
        }

        li.bonus-vision {
          color: #b062fe;
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-family: @fontOpenSans;
        font-weight: 700;
        margin-top: 5%;
        width: 100%;

        @media (max-width: @widthMobileMax) {
          flex-direction: column;
        }

        .annual {
          width: auto;
          height: auto;
          padding: 10px 25px;
          font-weight: 700;

          @media (max-width: @widthMobileMax) {
            padding: 10px 15px;
          }
        }

        .elite {
          width: auto;
          height: auto;
          font-weight: 700;
          padding: 10px 25px;
          text-decoration: underline;
          background: none;
          border: none;

          @media (max-width: @widthMobileMax) {
            margin-top: 10px;
            padding: 10px 15px;
          }

          &:hover {
            text-decoration: none;
            background: none;
          }
        }
      }

    }
  }

  // when the plans section transforms to mobile
  @media (max-width: 1100px) {
    .flex-container {
      flex-direction: column-reverse;
      height: auto;

      .col-container {
        width: ~"calc(280px + 270 * ((100vw - 300px) / 800))";
        margin: 25px 0;

        &.elite {
          order: 1;
        }

        a {
          width: 100%;
        }

        .header {
          h2 {
            font-size: ~"calc(20px + 6 * ((100vw - 300px) / 800))";
          }
        }
      }

    }

    .course-purchase-containter {
      .inner-wrapper {
        .course-container {
          flex-direction: column;
          align-items: center;

          .image-container, .info-container {
            width: ~"calc(340px +  360 * ((100vw - 400px) / 700))";
            min-width: 0;
          }
        }
      }
    }

    .order-summary {
      .cart-summary {
        flex-direction: column;
        min-width: 300px;
        margin: 2%;

        &__details {
          width: 100%;
          margin: 0;

          h3 {
            text-align: center;
            font-size: ~"calc(50px +  4 * ((100vw - 400px) / 700))";
          }
        }

        &__receipt {
          width: 100%;
        }

      }
    }
  }

  @media (max-width: 1000px) {
    .vision-plans {
      &__products {
        flex-direction: column !important;
        height: auto !important;
        align-items: center;

        li {
          min-width: 280px;
          max-width: 500px;
          width: 100% !important;
        }
      }
    }
  }


  @media (max-width: @widthMobileMax) {
    .page-navigator {
      display: none;
    }

    .black-friday-sale-banner {
      margin-top: 50px;
    }

    .video-subscription-plans {
      .inner-wrapper {
        margin-top: 100px;
      }
    }

    .visions-container {
      .title-container {
        margin-top: 25px;
      }

      .extra {
        flex-direction: column;

        .fast,
        .intuitive,
        .powerful {
          max-width: 500px;
          margin: 15px auto 0;
        }
      }

      .vision-plans {
        .vision-plans-term-form {

          .vision-plans-term-form-inner {
            flex-direction: column;
          }

          label {
            margin: 0 auto;
            width: 300px;
            display: block;
            height: 60px;

            .description {
              padding-top: 21px !important;
            }
          }

          .selected-term-slider {
            left: 0;
            width: 300px;
            height: 60px;
          }

          #vision-plans-1-month:checked ~ .selected-term-slider {
            top: -2px;
            left: 0;
          }

          #vision-plans-3-month:checked ~ .selected-term-slider {
            top: 60px;
            left: 0;

            &.hide-monthly {
              top: -2px;
              left: 0;
            }
          }

          #vision-plans-12-month:checked ~ .selected-term-slider {
            top: 120px;
            left: 0;

            &.hide-monthly {
              top: 60px;
              left: 0;
            }
            &.hide-quarterly {
              top: -2px;
              left: 0;

              &:not(.hide-monthly) {
                top: 60px;
                left: 0;
              }
            }
          }
        }
      }

      .rulaz-vision-box {
        padding: 40px 10px;
        min-width: 0;
        width: 100%;

        h2 {
          font-size: 26px;
        }

        p {
          font-size: 18px;
          padding: 10px 0 15px;
        }
      }

    }

    .add-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cart-skip {
        margin-top: 10px;
      }
    }

    .order-summary {
      .cart-summary {
        align-items: center;
        margin: 100px 2% 0;

        &__details {
          .products {
            .product {
              .information {
                .title {
                  font-size: ~"calc(20px + 22 * ((100vw - 400px) / 370))";
                }
              }
            }
          }
        }

        &__receipt {
          width: 100%;
          padding: 20px 15px;
        }
      }
    }
  }

  #swal2-content {
    p {
      padding: 20px 0;
    }
  }

  #swal2-html-container {
    margin-left: 0;
    margin-right: 0;
  }

  .swal2-container.pause-membership {

    h2 {
      font-family: @fontNowayMedium;
      font-size: 25px;
      color: #16191e;
    }

    .swal2-popup {
      padding: 2em 1em;
      box-shadow: 0 11px 101px 0 rgba(0, 0, 0, 0.48);
    }

    .swal2-content {
      font-family: @fontOpenSans;
      color: #656c77;
      font-size: 16px;
      font-weight: 300;
      margin: 10px 0 20px;

      b {
        color: #16191e;
        font-weight: 700;
      }
    }

    .swal2-close {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      font-size: 0;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #515561;

      &:hover {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.48);
      }

      &:after {
        position: relative;
        content: "";
        height: 13px;
        width: 13px;
        background: url(~img/components/icon/close_hover.svg);
        background-size: cover;
      }
    }

    .swal2-styled.swal2-confirm {
      background: #515561;
      font-family: @fontOpenSans;
      font-weight: 600;
      color: #fff;
      font-size: 16px;
      width: 100%;
      border-radius: 30px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.48);
      }
    }

    .swal2-styled.swal2-deny {
      background: none;
      color: #515561;
      font-family: @fontOpenSans;
      font-weight: 700;
      font-size: 14px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
