/*
/* Resets and rendering of default elements
/* -------------------------------------------------------------------------- */

// Reset padding and margin for all major elements
body, h1, h2, h3, h4, h5, h6, ul, li, div, form, fieldset, textarea, hr, button {
  margin: 0px;
  padding: 0px;
}

// Render HTML 5 elements as block in IE
header, section, aside, article {
  display: block;
}

html, body {
  height: 100%;
  min-height: 100%;
}

body {
  background: #f1f3f5;
  background-size: 74px 74px;
  color: @colorDefault;
  font-family: @fontDefault;
  font-size: 13px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;

  // fonts look thin in Safari without this
  -webkit-font-smoothing: subpixel-antialiased;
}

h1, h2, h3, h4, h5, h6, button {
  font-family: @fontHeading;
  font-weight: 400;
}

#body_container {
  .transition(all, 0.20s, ease-in-out);

  // for flexbox
  .flex_container(column);
  min-height: 100%;
  width: 100%;

  // for transition when sliding out for menu on tablet/mobile
  position: relative;
  right: 0;
  z-index: 510;
}

#torso {
  .flex_column(50);
  -ms-flex-preferred-size: auto;
  margin-top: @navHeight;
}

@media (max-width: @widthMobileMax) {
  body {
    background: #f1f3f5;
    overflow-x: hidden;
  }

  body #torso {
    .transition(all, 0.10s, ease-in-out);
    -webkit-transition: -webkit-filter 0.10s linear;
    transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s; /* Safari */
    border-right: none;
    margin-top: 45px;
  }
}

fieldset {
  border: none;
}

textarea {
  font-family: @fontDefault;
}

img {
  border: none;
}

a {
  color: @colorLink;
  text-decoration: none;

  &:hover {
    color: #b61e22;
    text-decoration: underline;
  }
}

p, ul, ol {
  margin: 0;
  padding: 0 0 16px 0;
}

blockquote {
  margin-left: 3em;
  font-style: italic;
  color: #777;
}

// selecting text
::-moz-selection {
  background: #1b1e1f;
  color: #fff;
}

::selection {
  background: #1b1e1f;
  color: #fff;
}

// icon default layout
[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  position: relative;
}

// use box sizing for everything within this layout
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.hidden {
  display: none;
  visibility: hidden;
}

// Angular ng-hide/show
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide {
  display: none !important;
}
