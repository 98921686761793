body.elite-extras {
  background: #07090B;

  #torso {
    background: #07090B;
    border: none;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    margin-bottom: 120px;

    h1 {
      font-family: @fontNowayMedium;
      color: #fff;
      font-size: 110px;
      line-height: 110px;

      @media (max-width: @widthTabletPortrait) {
        font-size: 90px;
        line-height: 70px;
      }
    }

    h2 {
      font-family: @fontNowayMedium;
      color: #fff;
      font-size: 90px;
      line-height: 90px;

      @media (max-width: @widthTabletPortrait) {
        font-size: 70px;
        line-height: 70px;
      }
    }

    h4 {
      font-size: 24px;
    }

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;

      aspect-ratio: 3360/880;
      background-image: url("~img/views/elite-extras/header.jpg");
      //filter: brightness(120%);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;

      @media (max-width: @widthTabletPortrait) {
        background-size: 150% auto;
        min-height: 300px;
      }
    }

    section {
      width: 95vw;
      max-width: 1400px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      gap: 55px;

      @media (max-width: @widthTabletPortrait) {
        flex-direction: column;
        gap: 20px;
      }

      .plaque {
        aspect-ratio: 1740/925;
        max-width: 840px;
        width: 95vw;
        background-size: cover;
        filter: brightness(180%);

        &:hover {
          opacity: .9;
        }

        &.live-seminars {
          background-image: url("~img/views/elite-extras/live-seminars.jpg");
        }

        &.monthly-elite-calls {
          background-image: url("~img/views/elite-extras/monthly-elite-calls.jpg");
        }

        &.annual-elite-hangouts {
          background-image: url("~img/views/elite-extras/annual-elite-hangouts.jpg");
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        max-width: 470px;
        gap: 25px;
        align-items: flex-start;

        h3 {
          font-family: @fontNowayMedium;
          font-size: 36px;
          color: #fff;
        }

        p {
          font-family: @fontNowayLight;
          font-size: 20px;
          line-height: 1.3em;
          color: #fff;
        }

        @media (max-width: @widthTabletPortrait) {
          text-align: center;
          align-items: center;
          gap: 10px;

          h3 {
            font-size: 30px
          }
          p {
            font-size: 16px;
          }
        }

      }
    }

    .mini-courses {
      flex-direction: column;

      &__list {
        display: flex;

        .mini-course {
          display: block;
          color: #fff;
          max-width: 85vw;

          &:hover {
            text-decoration: none;

            .thumbnail {
              background-size: 105% auto !important;
              background-position: center;
              background-repeat: no-repeat;
            }

            .thumbnail > .play-btn {
              transform: scale(1.2);
            }
          }

          .thumbnail {
            aspect-ratio: 425/305;
            background: #393a3c;
            border-radius: 12px;
            border: .5px solid #fff;
            max-width: 425px;
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            .play-btn {
              aspect-ratio: 1/1;
              width: 80px;
            }
          }

          &.the-red-line-mastery {
            .thumbnail {
              background-size: cover;
              background-image: url("~img/courses/mini-courses/the-red-line-mastery/thumbnail.jpg");

              .play-btn {
                position: relative;
                top: 25px;
              }
            }
          }

          .information {
            margin: 25px 0;
            display: flex;
            gap: 10px;
            flex-direction: column;

            .title, .description, .professional {
              line-height: 1;
              text-align: left;
            }

            .professional {
              font-size: 16px;
            }

            .description {
              font-size: 16px;
              text-transform: uppercase;
              font-family: @fontNowayMedium;
              font-weight: 400;
              letter-spacing: .4ex;
              color: #fb2796;
            }
          }
        }

      }
    }
  }
}
