// Detail view of a thread type instance (hand history, topic, video)
// This will be extended per thread type

// feed only contain the fixed element
// on the thread detail view by itself we don't want this
.layout-two_column-left_small > section.thread-detail {
  // this transform has a z-index of 0, so any child 3d elements will need to be great to stay in this viewport
  .transform(translate3d(0,0,0));
}

.layout-two_column-right_small > section.thread-detail {
  header.stuck {
    top: @navHeight;
  }
}

section.thread-detail {
  background: #ffffff;
  color: #444;
  .box-shadow(0px 0px 8px 0px rgba(0,0,0,0.1));
  .flex_container(column);


  // Header once you've scrolled and the title shouldn't be showing any longer, stick to top
  // Note that it is fixed relative to the container using transform3D of container and this element
  header.stuck {
    // make this transform higher in order to be on a different plane, allow for fixed positioning
    .transform(translate3d(0,0,0));
    // .transition(opacity, .2s, linear);
    visibility: hidden;
    opacity: 0;
    background: #fff;
    background-size: 100% auto;
    left: 0;
    padding: 14px 0;
    position: fixed;
    top: 0;
    text-align: center;
    width: 100%;
    z-index: 1020;

    &.active {
      position: sticky;
      visibility: visible;
      opacity: 1;
    }

    .bottom-shadow {
      background: transparent url(~img/partials/thread/header-stuck-shadow.png) no-repeat center top;
      background-size: 100% auto;
      width: 100%;
      position: absolute;
      bottom: -20px;
      left: 0;
      height: 20px;
    }

    h1, h3, .posted_by, .author {
      display: inline-block;
      vertical-align: middle;
    }

    h1 {
      color: #1e1f21;
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 60%;

      a {
        color: #1e1f21;
        text-decoration: none;
      }
    }

    .posted_by {
      font-weight: 300;
      font-size: 13px;
    }

    .author {
      font-size: 13px;
      color: #b61e22;
      font-weight: 700;

      &.is_pro {
        color: #b7a475;
      }
    }

    .in, .topic-game_stake {
      display: none;
    }
  }

  > .thread-detail-ui-view {
    background: #f7f8f8;
  }

  .notice-locked-thread {
    text-align: center;
    margin: 100px 0 80px;

    h3 {
      font-size: 18px;
    }
  }

  .video-footer-ad {
    width: 100%;
    padding-bottom: calc(380 / 2086 * 100%);

    &.vision {
      padding-bottom: 0;
    }

    &.hover {
      cursor: pointer;
    }

    @media (max-width: 895px) {
      display: none !important;
    }

    img {
      display: block;
      border: none;
      width: 100%;
      height: auto;
      outline: none;
    }
  }
}

// used to show over the watermark
section.thread-detail > div > article {
  background: #fff;
}

section.thread-detail > div > article.topic-detail,
section.thread-detail > div > article.handhistory-detail {
  // TODO frontend of actions
  .thread-actions {
    display: none;
  }


  // Header of the thread type when the page initially opens
  header.not_stuck {
    margin: 0 15%;
    position: relative;
    padding-top: 30px;

    .thread-pub_date {
      font-size: 12px;
      color: #707575;
      float: right;
      font-weight: 300;
      margin-top: 5px;
      padding: 0 10px 0 0;
    }

    time {
      display: inline;
    }

    h1 {
      color: #333738;
      clear: both;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2em;
      padding: 15px 0 30px;

      a {
        color: #1e1f21;
      }
    }

    .controls.edit {
      float: right;
    }

    .posted_by {
      display: none;
    }

    .date-controls {
      float: right;
      font-size: 12px;
      margin-top: 5px;
      width: 40%;
    }

    h3 {
      width: 60%;
      float: left;
      font-size: 13px;
      font-weight: normal;
      position: relative;
      font-family: @fontDefault;
    }

    .author, .in, .topic-game_stake, .avatar {
      display: inline;
    }

    .display_name {
      font-weight: 700;
      font-size: 14px;
    }

    .in {
      color: #8f9598;
    }

    .in,
    .topic-game_stake {
      font-size: 12px;
    }

    .topic-game_stake {
      color: #1e1f21;
    }

    .avatar {
      display: inline-block;
      margin-right: 5px;
      top: 8px;

      img {
        height: 24px;
        width: auto;
      }
    }

    // image of the author is also a, thus different underlining logic on underline
    .author:hover {
      text-decoration: none;
      .display_name {
        text-decoration: underline;
      }
    }
  }

  .topic-content,
  .hand-content,
  .thread-footer {
    // margin: 0 10% 5%;
    margin: 0px 15% 30px;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;


    a {
      color: @colorLink;
      text-decoration: underline;

      &:hover {
        color: @colorLink;
      }
    }

    a.mention-href {
      background-color: #a5aeb2;
      color: #fff;
      font-weight: 500;
      text-decoration: none;
      padding: 3px;
      .border-radius(2px);

      &.pro {
        background-color: #b7a475;
      }
    }

    a.mention-href:hover {
      text-decoration: none;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .controls.edit {
    text-align: right;

    .edit-controls {
      opacity: 1 !important;
      margin-left: 0;
    }
  }

  > .controls {
    margin: 20px 15% 20px;
  }

  .footer {
    .clearfix();
    margin: 40px 15% 0;
    text-align: center;


    .thread-tags {
      clear: both;
      float: left;
      margin-top: 14px;
      text-align: left;
      width: 45%;

      h5 {
        color: @colorLink;
        display: inline-block;
        font-size: 13.5px;
        font-weight: 500;
        line-height: 1;
        padding: 0;
      }

      ul {
        display: inline-block;
        list-style: none;
        width: 88%;

        li {
          display: inline;
        }

        a {
          color: @colorDefault;
          text-decoration: underline;
        }
      }
    }

    .btn-share {
      float: right;

      .icon-share {
        top: 6px;
        width: 30px;
      }

      path {
        .transition(all, .1s, ease-in-out);
      }

      .border {
        .transform(translateX(1px));
      }

      &:hover path {
        fill: #666B6B;

        &.border {
          .transform(translateX(0px));
        }

        &.arrow {
          .transform(translateX(4px));
        }
      }
    }
  }

  // Topic + hand history footer.
  .thread-footer {
     .clearfix();


    &:before {
      border-top: 1px solid @colorBorder;
      content: '';
      display: block;
      width: 150px;
      padding-bottom: 15px;
    }

    #post_subscribe {
      float: left
    }

    .controls.flagging {
      float: right;
      position: relative;
      top: 28px;
      padding-right: 15px;
    }

    .controls.edit {
      float: right;
      position: relative;
      top: 25px;
    }
  }
}


// Thread delete page
body.thread-delete {
  .thread-detail {
    form {
      .controls {
        margin: 30px 0;
        text-align: right;

        a {
          margin: 0 20px;
        }
      }
    }
  }
}


// Hide the sidebar on a tablet or smaller
@media (max-width: @widthTabletMax) {
  section.thread-detail > div > article.topic-detail,
  section.thread-detail > div > article.handhistory-detail {

    header.not_stuck {
      h3 {
        display: block;
      }
    }
  }

}

// Mobile
@media (max-width: @widthMobileMax) {
  section.thread-detail > div > article.topic-detail,
  section.thread-detail > div > article.handhistory-detail {

    header.not_stuck {
      margin: 0 5%;

      .thread-pub_date {
        float: none;
        display: block;
        padding: 2px 0 10px;
        font-size: 11px;
      }

      h1 {
        font-size: 22px;
        padding: 10px 0 0;
        text-align: left;
        width: 80%;
      }

      h3 {
        float: none;
        text-align: left;
        width: 100%;
        margin-top: 10px;
      }

      .avatar {
        margin-right: 4px;
        top: 8px;
      }

      .date-controls {
        font-size: 12px;
        margin-top: 5px;
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }

    header.stuck {
      display: none;
    }

    .topic-content,
    .hand-content,
    .thread-footer {
      font-size: 13.5px;
      margin: 20px 5%;
      overflow: hidden;
    }

    .hand-content .street-text {
      font-size: 14px;
      padding: 6px 14px;
    }

    > .controls {
      margin: 10px 5%;
    }
  }
}



// Thread delete page.
body.thread-delete {
  .thread-content {
    .delete.controls {
      a.btn {
        text-decoration: none;
      }

      input {
        border: none;
        color: #a81313;
        padding: 0;
        margin: 12px 10px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
