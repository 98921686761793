.pagination-infinite {
  .load_more {
    margin: 0 auto;
    padding: 20px 0;

    .inner {
      .border-radius(5px);
      background: #fafafa;
      border: 1px solid #eee;
      color: #5d5b5b;
      cursor: pointer;
      line-height: 2.5;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      width: 152px;

      &.loading_now .inner,
      &.loading_now .inner:hover {
         background: #fff url(~img/rio/loading.gif) no-repeat center center;
         background-size: 22px 22px;
         text-indent: -9999px;
      }

      &:hover {
        background: #fff;
      }

      &.done {
        cursor: auto;
      }
    }

    &.loading_now .inner,
    &.loading_now .inner:hover {
      background: #fff url(~img/rio/loading.gif) no-repeat center center;
      background-size: 22px 22px;
      text-indent: -9999px;
    }
  }
}

.pagination-processing {
  background: #fff url(~img/rio/loading.gif) no-repeat center center;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1080;
}
