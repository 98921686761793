body#poker-training_videos #content_filters {
  ul#seen-filters {
    li:first-child {
      margin-left: 0px;
    }
  }
}

.component-dropdown {
  display: block;
  float: left;
  margin: 8px 15px 12px 0;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  // required for all global pieces of the site that span both stylesheets
  *, *:before, *:after {
    // use box sizing for everything within this layout
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    // set to the new font
    font-family: @fontOpenSans;
    font-weight: 500;
  }

  &:hover {
    > a,
    > .options,
    .arrow,
    select {
      border-color: #999;
    }
  }

  &.value-selected {
    a.btn {
      background-color: #f8f9fa;
    }
  }

  // all anchors
  a,
  select {
    color: #666b6b;
    cursor: pointer;
    font-size: 13px;

    &:hover {
      text-decoration: none;
      color: #333;
    }
  }

  // The selected anchor.
  > a,
  select {
    .border-radius(5px);
    background: #fff url('~img/components/icon/downarrow.svg') no-repeat 95% 50%;
    background-size: 10px auto;
    border: 1px solid @colorBorder;
    padding: 6px 30px 6px 12px;
    display: inline-block;
    min-width: 138px;

    // universalize all
    width: 195px;
  }

  select {
    display: none;
    -webkit-appearance: none;
    height: 33px;
    margin: 0px;
    padding: 5px 5px 5px 15px;
    position: relative;
    width: 138px;
  }

  .icon {
    display: block;
    padding-left: 25px;
  }

  .options {
    border: 1px solid @colorBorder;
    border-top: none;
    .lower-border-radius(5px);
    background: #fff;
    color: #666b6b;
    display: none;
    margin: 0;
    padding: 0px;
    position: absolute;
    right: 0;
    top: 28px;
    width: 100%;

    ul#seen-filters {
      li {
        label {
          padding: 5px 0px 5px 35px;
          margin-bottom: 0px;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          position: relative;

          input {
            margin-right: 10px;
            width: 15px;
            height: 15px;
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkmark {
            position: absolute;
            top: 5px;
            left: 8px;
            height: 18px;
            width: 18px;
            background-color: #fff;
            border: 1px solid #aeb3b3;
            .border-radius(4px);
          }

          .checkmark:after {
            content: "";
            position: absolute;
            display: none;

            left: 10px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            .transform(rotate(45deg));
          }

          &:hover {
            cursor: pointer;
          }

          input:checked ~ .checkmark {
            background-color: #a81313;;
            border: 1px solid #a81313;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }
        }
      }

    }

  }

  // Selection options container
  ul {
    .lower-border-radius(5px);
    list-style: none;
    margin: 0;
    width: 100%;
    overflow: hidden;
    padding-bottom: 5px;
  }

  // Selection options
  li {
    display: block;
    float: none;
    margin: 0;
    padding: 0;

    a {
      display: block;
      padding: 6px 10px 5px;

      &:hover {
        background: #eee;
      }
    }

    a.leads-to-empty {
      color: #BAB2B2;
    }
  }

  &.seen {
    a.btn {
      height: 33px;
    }
  }

  &.stakes .icon {
    background: url(~img/post/table_sprite.png) no-repeat 0 -120px;
  }

  &.user_group .icon {
    background: url(~img/post/table_sprite.png) no-repeat 0 -156px;
    font-size: 13px;
  }

  &.order_by .icon {
    background: url(~img/components/icon/bookmark.svg) no-repeat 0 1px;
    background-size: 16px auto;
    font-size: 13px;
  }

  &.seen .icon {
    background: url(~img/components/icon/viewing_history.svg) no-repeat;
    background-size: 17px auto;
  }

  &.post_action .icon {
    background: url(~img/post/table_sprite.png) no-repeat 0 -40px;
  }

  &.dates .icon,
  &.top_users .icon {
    background: url(~img/components/icon/clock.svg) no-repeat;
    background-size: 17px auto;
  }

  &.pro_game_type .icon,
  &.game_type .icon,
  &.game_types .icon,
  &.thread_type .icon,
  &.dashboard_post_type .icon {
    background: url(~img/components/icon/handhistory.svg) no-repeat;
    background-size: 17px auto;
  }

  &.pro_level .icon {
    background: url(~img/components/icon/following.svg) no-repeat;
    background-size: 15px auto;
  }

  &.pro_users .icon,
  &.pro_user .icon {
    background: url(~img/components/icon/crown_gray.svg) no-repeat 0 1px;
    background-size: 16px auto;
    font-size: 13px;
  }

  &.pro_user .btn {
    // width: 180px;
  }

  &.video_formats .icon {
    background: url(~img/components/icon-non-sprite/video_formats.svg) no-repeat;
    background-size: 21px 17px;
  }

  &.time_span .icon {
    background: url(~img/components/icon/time_span.svg) no-repeat;
    background-size: 17px auto;
  }

  &.player_formats .icon {
    background: url(~img/components/icon-non-sprite/player_formats.svg) no-repeat;
    background-size: 19px 19px;
  }

  &.languages .icon,
  &.russian_only .icon,
  &.portuguese_only .icon {
    background: url(~img/components/icon/microphone.svg) no-repeat;
    background-size: 17px auto;
  }

  &.languages,
  &.russian_only,
  &.portuguese_only {
    > a {
      width: 180px;
    }
  }

  //  Tooltips (only applies to the language one at the moment)
  .tooltip {
    border-radius: 5px;
    background: #66696d;
    color: #fff;
    width: 310px;
    position: absolute;
    bottom: 45px;
    right: -15px;
    opacity: .9;

    .tooltip-content {
      padding: 15px 30px 0 15px
    }

    .tooltip-close {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 13px;
      display: block;
      height: 25px;
      width: 25px;
      cursor: pointer;
      background: url(~img/components/icon/close.svg) no-repeat 6px center;
      background-color: #2e3134;
      background-size: 12px 12px;

      &:active {
        background-color: #454a4d;
      }
    }

    &:after {
      position: absolute;
      width: 20px;
      height: 15px;
      background: #66696d;
      content: '';
      display: block;
      bottom: -4px;
      right: 20px;
      .transform(rotate(45deg));
    }
  }


  &.video_formats .btn {
    // width: 230px;
    // min-width: 230px;
  }

  &.player_formats .btn {
    // width: 185px;
    // min-wdith: 185px;
  }

  &.thread_type .btn {
    // width: 175px;
    // min-width: 175px;
  }

  // Active state for dropdown options.
  &.active {
    > a {
      z-index: 100;
    }

    ul,
    .options {
      display: block;
      z-index: 100;
    }
  }
}

// Dropdown component subtypes
.component-dropdown {
  &.thread_type .btn {
    // width: 140px;
  }

  &.first {
    margin-left: 21px;
  }

  &.label {
    padding: 12px 0 0 30px;
    margin: 3px 10px 0 0;

    &.filter_by {
      border-left: 1px solid #E4E3E3;
      top: 0;
    }

    &.search {
      .clearfix();
      padding-top: 3px;

      .btn {
        background: url(~img/pros/list_search.png) no-repeat center center;
        float: left;
        height: 18px;
        padding: 5px;
        width: 18px;
        text-indent: -9999px;
      }

      span {
        display: block;
        float: left;
        padding-left: 10px;
        padding-top: 7px;
      }
    }
  }
}


#content_filters {
  clear: both;
  margin-top: 0;
  position: relative;
  z-index: 1080;

  li.main-filters,
  li.more-filters {
    width: 100%;
    display: block;
  }

  li.main-filters {
    li.trigger-more-filters {
      float: right;

      a {
        width: auto;
        border: none;

        > .icon {
          background: url(~img/components/icon/more_filters.svg) no-repeat;
        }
      }

    }
  }

  li.more-filters {
    margin-left: 2px;
    display: none;

    &.opened {
      display: block;
    }

    li.hide-seen-trigger {
      float: right;

      // this is due to .slider being positioned absolute
      position: relative;
      left: -45px;

      label {
        display: inline-block;
        margin: 0;
        height: 10px;
        margin-left: 7px;
        line-height: 53px;

        &:hover {
          cursor: pointer;
        }
      }

      .switch {
        position: relative;
        display: inline-block;
      }

      /* Hide default HTML checkbox */

      .switch input {
        display: none;
      }

      /* The slider */

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        width: 45px;
        height: 12px;
        .transition(all, .4s);

        &:before {
          position: absolute;
          content: "";
          height: 18px;
          width: 18px;
          left: -2px;
          bottom: -3px;
          background-color: #838787;
          .transition(all, .4s);
          .border-radius(50%);
        }
      }

      .slider.round {
        .border-radius(34px);

        &:before {
          .border-radius(50%);
        }
      }

      input:checked + .slider {
        background-color: #ffcccc;
      }

      input:focus + .slider {
        box-shadow: 2px 2px 1px #000;
      }

      input:checked + .slider:before {
        background-color: rgb(168, 19, 19);
        box-shadow: 0px 4px 21px 0px rgba(140, 8, 8, 0.35);
        .transform(translateX(30px));
      }

    }
  }

}


// Tablets and smaller. Show the selects and hide the psuedo selects.
@media (max-width: @widthTabletMax) {
  .component-dropdown {
    .btn {
      display: none;
    }

    select {
      display: inline-block;
      // the selects will go either way on 2 rows so make them wider
      // width: 200px;
    }
  }

  // show the trigger-more-filter btn trigger
  #content_filters {
    li.main-filters {
      // center it below the main filters
      li.component-dropdown.trigger-more-filters {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 100%;

        .btn {
          display: block;
        }
      }
    }
  }

  // show Viewing History filter
  .component-dropdown.seen {
    .btn {
      width: auto;
      display: block;

      .icon {
        background: none;
        padding-left: 0px;
      }
    }
  }
}

@media (max-width: @widthMobileMax) {
  .component-dropdown {
    width: 100%;

    select {
      width: 100%;
      display: block;
    }
  }
}
