.animated-background-color-mixin() {
  background: linear-gradient(130deg, #61dc7a, #fe8efb, #5342ff);
  background-size: 300% auto;
  animation: animated-gradient-background-keyframes 10s ease-in-out infinite;
}

.gradient-border-container-mixin() {
  position: relative;
  padding: 20px;
  background: #1C202F;
  background: linear-gradient(180deg, #1C202F 0%, #080C13 100%);
  //backdrop-filter: blur(10px);
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 24px;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(150deg, #DFDFE0, #553963, #553963, #0E1013);
  }
}

.gradient-border-container-mixin-alt() {
  position: relative;
  background: #1C202F;
  background: linear-gradient(180deg, #1c202f 10%, #080B11 70%, #674850 91%, #ba96f1 105%);
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 24px;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(150deg, #FCA7DA, #553963, #FB8B01, #BCFF00);
  }
}

@keyframes animated-gradient-background-keyframes {
  0% {
    background-position: 0 57%
  }
  50% {
    background-position: 100% 44%
  }
  100% {
    background-position: 0 57%
  }
}


body.elite-sales-page {
  background: #020508;

  #body_container {
    overflow-x: hidden;
    flex-direction: column;
    justify-content: center;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1660px;
    width: 95%;
    padding: 0 5%;
    margin: @navHeight auto;
    //background-size: 1680px auto;
    //background: url("~img/annual-elite-offer/BG-min.jpg") no-repeat center -100px;

    @media (max-width: @widthTabletMax) {
      br {
        display: none;
      }
    }

    h1 {
      color: #A8B0B9;
      font-family: @fontOpenSans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 9.6px;
      text-transform: uppercase;
      padding: 0;

      @media (max-width: @widthTabletMax) {
        line-height: 2em;
        font-size: calc(0.65 * 18px);
      }
    }

    h2 {
      font-family: @fontOpenSans;
      color: #fff;
      font-weight: 400;
      font-size: 75px;
      text-align: left;
      line-height: 1.5;

      @media (max-width: @widthTabletMax) {
        font-size: calc(0.65 * 75px);
      }

      @media (max-width: @widthMobileMax) {
        font-size: calc(0.5 * 75px);
      }
    }

    h3 {
      font-family: @fontOpenSans;
      color: #fff;
      font-size: 130px;
      font-weight: 600;
      line-height: 1;

      @media (max-width: 1440px) {
        font-size: calc(0.7 * 130px);
      }

      @media (max-width: @widthTabletMax) {
        font-size: calc(0.65 * 130px);
      }
    }

    h4 {
      font-family: @fontOpenSans;
      color: #fff;
      font-size: 50px;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 40px;

      @media (max-width: @widthTabletMax) {
        font-size: calc(0.65 * 50px);
      }
    }

    p {
      padding: 0;
    }

    button,
    a.purchase {
      padding: 12px 70px;
      border-radius: 30px;
      background: #525c6a;
      color: #fff;
      font-family: @fontOpenSans;
      font-size: 20px;
      font-weight: 700;
      border: none;
      appearance: none;
      display: inline-block;

      @media (max-width: @widthTabletMax) {
        font-size: 19px;
      }

      &.gradient-background {
        background: linear-gradient(130deg, #61dc7a, #fe8efb, #5342ff) 0 57% / 300% auto;
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        .animated-background-color-mixin();
      }
    }

    .animated-gradient-background {
      .animated-background-color-mixin();

      &__text {
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 80px 20px 20px;
      width: 100%;

      @media (max-width: @widthTabletMax) {
        margin-top: 50px;
      }

      &.header {
        .subheading {
          font-size: 20px;
          font-family: @fontOpenSans;
          color: #A8B0B9;
          font-weight: 500;
          letter-spacing: 9.6px;
          text-transform: uppercase;
        }

        .top-heading {
          font-family: @fontNowayMedium;
          font-size: 90px;
          color: #fff;
          margin: 40px 0;
          line-height: 1;
          text-align: center;

          @media (max-width: @widthTabletMax) {
            margin-top: 35px;
            font-size: calc(0.8 * 90px);
          }

          @media (max-width: @widthMobileMax) {
            margin-top: 35px;
            font-size: calc(0.6 * 90px);
          }
        }

        p.bottom-content {
          width: 90%;
          font-size: 36px;
          color: #fff;
          margin: 100px auto 80px;
          line-height: 1.3;

          @media (max-width: @widthTabletMax) {
            width: auto;
            margin-top: 60px;
            font-size: calc(0.6 * 36px);
          }
        }

        .video-overview {
          margin: 70px auto 0;

          .video {
            align-self: flex-start;
            position: relative;

            margin: 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: auto;
              width: 100%;
              max-width: 980px;
            }

            .play-circle {
              border: 5px solid #fff;
              width: 90px;
              height: 90px;
              .border-radius(50%);
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;

              @media (max-width: 800px) {
                width: 45px;
                height: 45px;
                border: 2px solid #fff;
              }

              .arrow-right {
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 28px solid #fff;
                position: relative;
                top: 25px;
                left: 30px;

                @media (max-width: 800px) {
                  border-top: 7px solid transparent;
                  border-bottom: 7px solid transparent;
                  border-left: 14px solid #fff;
                  top: 12px;
                  left: 15px;
                }
              }
            }

            &:hover {
              cursor: pointer;

              .play-circle {
                // border-color: @colorLink;
                .arrow-right {
                  border-left-color: @colorLink;
                }
              }
            }
          }
        }


        p.normal {
          color: #aab3bb;
          font-family: @fontOpenSans;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 100px;

          @media (max-width: @widthTabletMax) {
            margin-bottom: 35px;
            font-size: calc(0.8 * 24px);
          }

          @media (max-width: @widthMobileMax) {
            margin-top: 35px;
            font-size: calc(0.6 * 24px);
          }
        }

        p.annual-elite-over {
          font-size: 22px;
          color: #eee;
          margin: 50px;
          background: #000;
        }
      }

      &.section {
        p {
          font-family: @fontOpenSans;
          font-weight: 400;
          font-size: 24px;
          color: #aab3bb;
          text-align: left;
          margin: 50px 0;

          @media (max-width: @widthTabletMax) {
            margin-top: 20px;
            font-size: calc(0.8 * 24px);
          }
        }
      }

      &.whats-new {
      }

      &.exclusive-elite-benefits {
        display: block;
        width: auto;
        margin: 80px auto 20px;

        .section-content {
          display: block;

          h2 {
            text-align: left;
          }

          ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5%;
            padding: 40px 0 0;
            flex-wrap: wrap;

            @media (max-width: @widthTabletMax) {
              justify-content: center;
              align-items: center;
              padding-top: 50px;
            }

            li {
              max-width: 260px;
              margin-bottom: 60px;

              @media (max-width: @widthTabletMax) {
                margin-bottom: 20px;
              }

              img {
                width: auto;
                height: 100px;
              }

              p {
                padding-top: 10px;
                font-family: @fontNowayMedium;
                font-size: 24px;
                color: #fff;

                @media (max-width: @widthTabletMax) {
                  font-size: calc(0.8 * 24px);
                }
              }
            }
          }
        }
      }


      //&.do-you-want {
      //  .section-content {
      //    display: block;
      //  }
      //
      //  p {
      //    color: #FFF;
      //    font-size: 28px;
      //    line-height: 150%;
      //
      //    @media (max-width: @widthTabletMax) {
      //      font-size: calc(0.65 * 28px);
      //    }
      //  }
      //}


      &.reasons-to-go-elite {
        ul {
          list-style: none;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          text-align: left;

          @media (max-width: @widthTabletMax) {
            flex-direction: column;
          }

          li {
            width: 27%;

            @media (max-width: @widthTabletMax) {
              min-width: 100%;
              margin-top: 40px;
            }

            h3 {
              font-family: @fontOpenSans;
              font-size: 20px;
              margin-bottom: 40px;
              color: #A8B0B9;
              letter-spacing: 9.2px;
              text-transform: uppercase;
              font-weight: 700;
            }

            p {
              font-family: @fontOpenSans;
              font-size: 18px;
              line-height: 140%;
              color: #b7b7b9;
              margin-bottom: 2em;
            }
          }
        }
      }

      &.pros-images {
        display: block;

        .slide-outer {
          display: block;
          margin-left: 40px;
          margin-right: 40px;
          margin-top: 30px;
          position: relative;
        }

        #pros-images-slider {
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          scrollbar-width: none;

          .arrow-container-left, .arrow-container-right {
            height: 280px;
            width: 40px;
            top: 0;
            position: absolute;

            &:hover {
              background-color: rgba(255, 255, 255, 0.5);
              cursor: pointer;
            }
          }

          .arrow-container-left {
            left: -40px;

            .left-button {
              height: 37px;
              width: auto;
              position: absolute;
              left: 7px;
              top: 120px;
            }
          }

          .arrow-container-right {
            right: -40px;

            .right-button {
              height: 37px;
              width: auto;
              position: absolute;
              right: 7px;
              top: 120px;
            }
          }

          .left-button, .right-button {
            &:hover {
              cursor: pointer;
              height: 40px;
              width: auto;
            }
          }

          .individual-scroll-item {
            flex-shrink: 0;
            margin-right: 30px;
            width: 275px;
          }

          .pro-image {
            max-width: 275px;
            height: 280px;
            display: flex;
            justify-content: start;
            align-items: flex-end;
            flex-shrink: 0;
            background-color: #020508;
            box-shadow: 0 -120px 50px 0px rgba(2, 5, 8, 0.75) inset;

            @media (max-width: 1400px) {
              box-shadow: 0 -20px 30px 0 rgba(2, 5, 8, .75) inset;
            }

            &:first-child {
              margin-left: 0;
            }

            p {
              color: #fff;
              font-size: 22px;
              font-family: @fontOpenSans;
              font-weight: 500;
              text-align: left;
              margin: 30px;

              @media (max-width: @widthTabletMax) {
                font-size: calc(0.85 * 27px);
              }

              @media (max-width: @widthMobileMax) {
                font-size: calc(0.5 * 27px);
                margin: 10px;
              }

            }

            &.phil {
              background-image: url("https://media.runitonce.com/pro-avatars/phil-galfond-Elite-PLO-instructor.jpg");
              background-size: cover;
            }

            &.dan-smith {
              background-image: url("https://media.runitonce.com/pro-avatars/dan-smith.jpg");
              background-size: cover;
            }

            &.justin-bonomo {
              background-image: url("https://media.runitonce.com/pro-avatars/justin-bonomo.jpg");
              background-size: cover;
            }

            &.jeremy-ausmus {
              background-image: url("https://media.runitonce.com/pro-avatars/jeremy-ausmus--runitonce-poker-coach.jpg");
              background-size: cover;
            }

            &.koon {
              background-image: url("https://media.runitonce.com/pro-avatars/jason-koon-elite-MTT-instructor.jpg");
              background-size: cover;
            }

            &.greenwood {
              background-image: url("https://media.runitonce.com/pro-avatars/sam-greenwood-elite-poker-coach.jpg");
              background-size: cover;
            }

            &.davies {
              background-image: url("https://media.runitonce.com/pro-avatars/seth-davies-elite-poker-coach.jpg");
              background-size: cover;
            }

            &.hastings {
              background-image: url("https://media.runitonce.com/pro-avatars/brianhastings-elite-mtt-poker-coach.jpg");
              background-size: cover;
            }

            &.gamble {
              background-image: url("https://media.runitonce.com/pro-avatars/nathan-gamble-elite-mixed-poker-coach.jpg");
              background-size: cover;
            }

            &.jungleman {
              background-image: url("https://media.runitonce.com/pro-avatars/jungleman-elite-nlhe-poker-coach.jpg");
              background-size: cover;
            }

            &.forrester {
              background-image: url("https://media.runitonce.com/pro-avatars/tyler-forrester-Elite-NLHE-instructor.jpg");
              background-size: cover;
            }

            &.horseofhell {
              background-image: url("https://media.runitonce.com/pro-avatars/Horseofhell-elite-PLO-poker-coach.jpg");
              background-size: cover;
            }

            &.johnson {
              background-image: url("https://media.runitonce.com/pro-avatars/luke-johnson-elite-nlhe-poker-coach.jpg");
              background-size: cover;
            }

            &.theologis {
              background-image: url("https://media.runitonce.com/pro-avatars/alex_theologis-elite-mtt-poker-coach.jpg");
              background-size: cover;
            }

            &.leng {
              background-image: url("https://media.runitonce.com/pro-avatars/ryan-leng-elite-poker-coach-mtt.png");
              background-size: cover;
            }

            &.gryko {
              background-image: url("https://media.runitonce.com/pro-avatars/richard_gryko-elite-poker-coach.jpg");
              background-size: cover;
            }

            &.meet-coaches {
              background: #1A1E1F;

              p a {
                color: #fff;
              }
            }
          }
        }
      }

      &.pros-stats {
        margin-top: 20px;

        p {
          font-family: @fontOpenSans;
          color: #A8B0B9;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          text-align: left;
          margin-bottom: 50px;

          @media (max-width: 1440px) {
            font-size: calc(0.8 * 20px);
          }

          @media (max-width: @widthTabletMax) {
            margin-bottom: 20px;
            font-size: calc(0.85 * 20px);
            text-align: center;
          }
        }
      }

      &.testimonials {
        display: block;
        text-align: left;

        h2 {
          padding-bottom: 40px;
        }

        .quotes {
          display: flex;
          gap: 30px;
          font-size: 12px;
        }

        .quote-container {
          .gradient-border-container-mixin();
          padding: 20px 20px 100px 20px;
        }


        p {
          text-align: left;

          &.quote {
            text-align: center;
            font-size: 20px;
            color: #fff;
            font-family: @fontOpenSans;
            font-weight: 400;
            margin-top: 50px;

            &:before {
              content: '';
              position: absolute;
              top: 20px;
              right: 20px;
              background: url('~img/views/elite-sales/quote.png') no-repeat top right;
              background-size: 75px auto;
              filter: blur(3px);
              width: 75px;
              height: 75px;
              opacity: .15;
            }

            @media (max-width: @widthTabletMax) {
              margin-top: 0;
              font-size: calc(0.85 * 18px);
            }
          }

          &.quote-author {
            position: absolute;
            font-size: 18px;
            padding-right: 20px;
            color: #fff;
            left: 20px;
            bottom: 20px;

            @media (max-width: @widthTabletMax) {
              font-size: calc(0.85 * 22px);
            }

          }
        }

        @media (max-width: @widthTabletMax) {
          .quotes {
            display: block;
          }

          .quote-container {
            margin-bottom: 20px;
          }
        }
      }

      &.testimonials.pros {
        .quote-container {
          padding-bottom: 160px;
        }

        .quote-author {
          left: 20px;
          right: 20px;
          display: flex;
          background: #141F30;
          border-radius: 10px;
          padding: 8px 18px;
          gap: 12px;
          align-items: center;

          .author-image {
            img {
              width: 80px;
              height: 80px;
              display: block;
            }
          }

          .copy {
            .name {
              color: #FFF;
              font-size: 22px;
              font-weight: 600;
              display: block;
            }

            .title {
              color: #A9A7B8;
              font-size: 16px;
              display: block;
            }
          }
        }
      }

      &.people {
        .section-content {
          position: relative;
          display: block;
        }

        .quotes {
          display: block;
          position: relative;
          margin-left: 40px;
          margin-right: 40px;
          margin-top: 30px;
        }

        #quotes-slider {
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .quote-container {
          width: 300px;
          flex-shrink: 0;
          margin-right: 30px;

          @media (max-width: @widthMobileMax) {
            width: 235px;
          }
        }


        .arrow-container-left, .arrow-container-right {
          height: 200px;
          width: 40px;
          top: 140px;
          position: absolute;

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
          }
        }

        .arrow-container-left {
          left: -40px;

          .left-button {
            height: 37px;
            width: auto;
            position: absolute;
            left: 7px;
            top: 83px;
          }
        }

        .arrow-container-right {
          right: -40px;

          .right-button {
            height: 37px;
            width: auto;
            position: absolute;
            right: 7px;
            top: 83px;
          }
        }

        .left-button, .right-button {
          &:hover {
            cursor: pointer;
            height: 40px;
            width: auto;
          }
        }
      }

      /**
      &.why-go-annual {
        text-align: left;
        justify-content: left;
        display: block;

        h2 {
          padding-bottom: 40px;
        }

        .section-content {
          display: block;
        }

        .content-container {
          .gradient-border-container-mixin();
          padding: 60px 40px;
        }

        .content-container-inner {
          display: flex;
          gap: 20px;
        }

        .list-block {
          text-align: center;
        }

        ul {
          padding: 0 0 30px 20px;
          text-align: left;

          li {
            color: #FFF;
            font-family: @fontOpenSans;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 157.583%;
            letter-spacing: 3.08px;
            text-transform: capitalize;
          }
        }

        .plan-block {
          border-radius: 24px;
          background: linear-gradient(215deg, rgba(6, 8, 13, 0.70) 0%, rgba(4, 8, 14, 0.70) 121.08%);
          max-width: 345px;
          text-align: center;
          padding: 40px 20px;

          .plan-name {
            color: #6D6884;
            font-family: @fontOpenSans;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 3.92px;
            text-transform: uppercase;
          }

          .plan-price {
            color: #FC4949;
            text-align: center;
            font-family: @fontNowayMedium;
            font-size: 64px;
            font-style: normal;
            font-weight: 500;
          }
        }

      }
      */

      &.plan-selector {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: stretch;
        position: relative;

        @media (max-width: @widthTabletMax) {
          flex-direction: column;
        }

        .plan-container {
          .gradient-border-container-mixin();
          padding: 50px 20px 20px;
          //max-width: 384px;
          max-width: 450px;
          position: relative;

          @media (max-width: @widthTabletMax) {
            margin: 0 auto;
          }

          &.monthly-elite {
            .plan-price-container {
              .compare-price {
                text-decoration: none;
              }
            }
          }

          .black-friday-sale-sticker {
            height: 90px;
            width: 90px;
            position: absolute;
            top: -15px;
            right: -20px;
            background: url(~img/subscribe/plans_pricing/black-friday-sale-sticker.png) no-repeat;
            background-size: 90px auto;

            @media (max-width: 700px) {
              top: -65px;
            }
          }


          &.annual-elite {
            .gradient-border-container-mixin-alt();

            .purchase {
              padding-left: 30px;
              padding-right: 30px;
            }
          }

          .container-label {
            background: #282e44;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            padding: 10px 20px;
            line-height: 1em;
            border: 1px solid #FCA8DA;
            border-radius: 30px;
          }

          .plan-title {
            color: #A9A7B8;
            text-align: center;
            font-family: @fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 7.5px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 25px;
          }

          .plan-price-container {
            display: block;
            margin-bottom: 40px;

            span {
              display: inline-block;
              float: none;
              font-family: @fontOpenSans;
              color: #FFF;
            }

            .compare-price {
              display: block;
              color: #A9A7B8;
              text-align: center;
              font-size: 26px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              text-decoration: line-through;
              padding-bottom: 10px;
            }

            .currency {
              font-size: 27px;
              line-height: 100%;
              vertical-align: top;
            }

            .plan-price {
              text-align: center;
              font-size: 62px;
              line-height: 1em;
            }

            .plan-timespan {
              font-family: @fontNowayLight;
              font-size: 27px;
              text-transform: uppercase;
            }

            .monthly-price {
              padding-top: 10px;
              display: block;
              color: #FFF;
              font-family: @fontOpenSans;
              font-size: 24px;
              font-weight: 400;
              line-height: 150%;
            }
          }

          .button-container {
            margin-bottom: 40px;

            .manage-plan {
              display: block;
              padding: 10px;
              font-size: 14px;
              text-align: center;
              margin: 0;

              a {
                color: #fff;
              }
            }

            .purchase:not(.gradient-background) {
              color: #010312;
              background: #fff;

              &:hover {
                opacity: .95;
              }
            }

            .purchase.disabled {
              opacity: 1 !important;
              background: #b3b3b3 !important;
              cursor: default;
            }
          }


          .features-container {
            ul {
              list-style: none;
              text-align: left;
            }

            li {
              color: #FFF;
              font-family: @fontOpenSans;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              background: url('~img/views/elite-sales/check.png') no-repeat 0 1px;
              background-size: 18px auto;
              padding: 0 0 15px 30px;
            }
          }

        }

        .anual-elite-benefits {
          padding-top: 130px;
          max-width: 470px;

          @media (max-width: @widthTabletMax) {
            margin: 0 auto;

            .header {
              text-align: center !important;
            }
          }

          .header {
            color: #A9A7B8;
            font-family: @fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 3.5px;
            text-transform: uppercase;
            margin-bottom: 20px;
            text-align: left;

            strong {
              font-weight: 700;
              color: #fff;
            }
          }

          .benefit {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            align-items: stretch;

            &.beyond .copy {
              padding-bottom: 10px;
            }
          }

          .copy {
            text-align: left;
            border: 3px solid #181C2C;
            padding: 20px;
            border-radius: 8px;
            flex-grow: 2;
            display: flex;
            align-items: center;
            position: relative;

            .new-tag {
              position: absolute;
              top: -5px;
              right: -10px;
              display: inline-block;
              background: #fff;
              border-radius: 3px;
              color: #000;
              padding: 1px 3px 2px;
              font-weight: 600;
            }

            em {
              font-weight: 300;
              color: #C1A9DC;
              font-style: normal;
            }

            .main {
              font-size: 24px;
              color: #FFF;
              font-style: normal;
              line-height: 1.2em;
              display: block;
            }

            .extra {
              color: #A9A7B8;
              display: block;
              font-size: 12px;
            }

            strong {
              font-weight: 700;
              color: #fff;
            }
          }

          .image {
            flex-grow: 0;

            img {
              width: 120px;
              height: 120px;
              display: block;
            }
          }
        }
      }


      &.faq-section {
        ul.rio--accordion {
          list-style: none;
          text-align: left;
          width: 840px;
          margin-top: 45px;
          margin-bottom: 20px;

          @media (max-width: @widthTabletMax) {
            width: 100%;
          }

          p {
            padding-bottom: 1em;
          }

          li {
            border-bottom: 1px solid #2b323a;
            width: 100%;

            .expand-trigger {
              font-family: @fontOpenSans;
              font-size: 22px;
              color: #fff;
              margin-top: 30px;
              margin-bottom: 35px;
              padding-right: 30px;
              position: relative;

              @media (max-width: @widthTabletMax) {
                font-size: calc(0.8 * 22px);
              }

              &:after {
                color: #fff;
                content: "+";
                font-family: @fontDefault;
                font-size: 30px;
                position: absolute;
                right: 0;
                font-weight: 700;
                top: -4px;

                @media (max-width: @widthTabletMax) {
                  font-size: calc(0.8 * 30px);
                  top: calc(0.8 * -4px)
                }
              }
            }

            .section {
              font-family: @fontOpenSans;
              color: #b7b7b9;
              margin-bottom: 35px;
              font-size: 18px;

              @media (max-width: @widthTabletMax) {
                font-size: calc(0.8 * 18px);
              }
            }

            &.expanded {
              .expand-trigger {
                &:after {
                  content: "-";
                  transform: scale(1.6, 0.8);
                  right: 4.1px;
                  top: -3.2px;

                  @media (max-width: @widthTabletMax) {
                    font-size: calc(0.8 * -3.2px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .section-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      @media (max-width: @widthTabletMax) {
        flex-direction: column;
      }

      .forty-percent {
        width: 40%;

        @media (max-width: @widthTabletMax) {
          width: 100%;
        }
      }

      .sixty-percent {
        width: 60%;

        p {
          font-family: @fontOpenSans;
          font-weight: 400;
          font-size: 24px;
          color: #a6b0ba;
          text-align: left;
          margin: 40px 0 70px 0;

          @media (max-width: 1535px) {
            br {
              display: none;
            }
          }

          @media (max-width: @widthTabletMax) {
            font-size: calc(0.8 * 24px);
            margin-bottom: 25px;
          }
        }

        @media (max-width: @widthTabletMax) {
          width: 100%;
        }
      }
    }

    .section-content {
      .ad-brag {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 550px;

        @media (max-width: @widthTabletMax) {
          margin-top: 40px;
        }

        p {
          text-align: center;
          color: #fff;
          font-size: 13px;
          font-weight: 700;
          font-family: @fontOpenSans;
          margin: 0;

          @media (max-width: @widthTabletMax) {
            font-size: calc(0.85 * 19px);
          }

          &.title {
            font-size: 42px;
            font-weight: 900;

            @media (max-width: @widthTabletMax) {
              font-size: calc(0.85 * 60px);
            }
          }
        }

        .k5-videos,
        .videos-daily,
        .pros {
          margin-left: 10px;
          min-height: 230px;
          min-width: 20%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 30px;

          @media (max-width: @widthTabletMax) {
            min-width: 30%;
          }
        }

        .videos-daily,
        .pros {
          background: #2D272F;
        }

        .k5-videos {
          min-height: 230px;
          min-width: 60%;
          transform: perspective(40px) rotateY(-1deg);
          box-shadow: 0 0 65px 0 #020508;

          @media (max-width: @widthTabletMax) {
            min-width: 50%;
          }

          p.title {
            font-size: 92px;
            @media (max-width: @widthTabletMax) {
              font-size: calc(0.62 * 80px);
            }
          }

          p {
            font-size: 18px;
            @media (max-width: @widthTabletMax) {
              font-size: calc(0.85 * 26px);
            }
          }
        }
      }
    }

    .play-circle {
      position: relative;
      border: 3px solid #fff;
      width: 80px;
      height: 80px;
      border-radius: 80px;

      .arrow-right {
        position: absolute;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 26px solid #fff;
        top: 25px;
        left: 28px;
      }

      &:hover {
        cursor: pointer;
        .box-shadow(1px 2px 75px rgba(0, 0, 0, 0.55));

        .arrow-right {
          border-left-color: @colorLink;
        }
      }
    }
  }

  // for the vimeo video (the testimonial)
  .rio-modal {
    .content {
      // that video is with 1:1 ratio;
      height: 80vh;
      width: 80vh;
      max-width: 1340px;
      max-height: 1340px;

      @media (orientation: portrait) {
        height: 80vw;
        width: 80vw;
      }
    }
  }
}
