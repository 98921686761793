body.intro-walkthrough-body {
  .backdrop {
    background: rgba(27, 30, 31, 0.5);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 101%;

    // default to 4k then up the z-index if needed per slide
    z-index: 4600;
  }

  .layout-two_column-left_small > .col_1 {
    border-right: none;
  }

  .feed-events {
    > .backdrop {
      .transition(none);
      position: fixed;
      z-index: 6000;

      &.walking-through {
        left: 100%;
        position: absolute;
      }
    }
  }

  .nav-drawer,
  .nav-drawer .nav-notifications,
  .layout {
    .transition-delay(0.1s);
    .transition(all, 0.6s, ease-in-out);
  }

  .intro-walkthrough {
    .border-radius(6px);
    .transform(translateX(-50%));
    background: #202223;
    display: none;
    min-height: 294px;
    left: 50%;
    overflow: hidden;
    position: fixed;
    top: 20%;
    width: 468px;
    z-index: 6010;

    .icon-close {
      .transition(opacity, 0.2s, ease-out);
      cursor: pointer;
      height: 34px;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0px;
      top: 2px;
      width: 34px;
      z-index: 6020;

      path {
        .transition(fill, 0.1s, ease-out);
        fill: #343536;
      }

      &:hover path {
        fill: #636567;
      }
    }

    form {
      display: none;
    }

    .paginate-slides {
      display: none;
      position: absolute;
      right: 48px;
      bottom: 40px;

      span {
        display: inline-block;
      }

      .current, .total {
        font-size: 12px;
        font-family: @fontHeading;
        color: #c5c9cc;
        white-space: nowrap;
      }

      .next {
        color: #202223;
        font-size: 16px;
        position: relative;
        left: 8px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        text-align: center;
        line-height: 35px;

        &:after {
          background-clip: padding-box;
          border-radius: 50%;
          border: 17px solid #fff;
          content: " ";
          height: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
          width: 0;
          z-index: -1;
        }
      }
    }

    .sections-slider {
      .clearfix();
      position: relative;
      left: 0;
      top: 0;
      width: 2500px;
    }

    section {
      float: left;
      padding: 40px 40px 18px;
      text-align: center;
      vertical-align: top;
      width: 468px;
    }

    h2 {
      color: #fff;
      font-size: 26px;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 22px;
    }

    p {
      color: #c5c9cb;
      font-size: 16px;
      font-weight: 300;
      padding-bottom: 32px;
    }

    .take-the-tour,
    .get-started,
    .skip {
      cursor: pointer;
      display: block;
      margin: 0 auto;
    }

    .take-the-tour,
    .get-started {
      .transition(all, 0.2s, ease-out);
      .border-radius(22px);
      background: #fff;
      border: none;
      font-family: @fontHeading;
      font-size: 14px;
      font-weight: 500;
      color: #666b6b;
      padding: 13px 22px;

      &:hover {
        .border-radius(18px);
        color: #4B4E4E;
      }
    }

    .skip {
      background: transparent;
      border: none;
      color: #808686;
      font-family: @fontHeading;
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0;
      padding: 8px 22px;

      &:hover {
        color: #717676;
        text-decoration: underline;
      }
    }
  }

  .backdrop, .intro-walkthrough {
    .transition(all, 0.2s, ease-out);
    opacity: 1;

    &.hide {
      opacity: 0;
    }
  }
}


// Tablets
@media (max-width: @widthTabletMax) {
  body.intro-walkthrough-body {
    .intro-walkthrough {
      display: none !important;
    }

    .backdrop {
      display: none !important;
    }
  }
}
