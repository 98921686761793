@colorGreen: #6AC87F;
@colorBlue: #2e6fea;
@colorDarkGrey: #313236;
@colorMedGrey: #6d747c;
@colorRed: #;


body.visions-interface {
  .visions-user-tour {
    @media (max-width: @widthMobileMax) {
      display: none;
    }

    &.vision-5c-tour {

    }

    .tour-container {
      position: absolute;
      color: black;
      //left: ~"calc(50% - 250px)";
      z-index: 100;
      display: none;
      &.active {
        display: block;
        &.pointer-flex {
          display: flex;
          flex-direction: row;
        }
      }


      .text-box {
        padding: 20px;
        font-size: 14px;
        width: 300px;
        background-color: white;
        box-shadow: 0 0 20px rgb(0, 0, 0);
        font-weight: normal;

        &.black-border {
          border: 1px solid black;
        }

        h2 {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .button-container {
          height: 20px;
          width: 80%;
          margin: auto;

          &.full {
            width: 100%;
          }
      }

      .next-box, .skip {
          font-weight: 700;

          &:hover {
              cursor: pointer;
          }
      }

      .next-box {
          float: left;
          font-weight: 700;
          color: black;

          &.right {
            float: right;
          }
      }

      .skip {
          float: right;
          color: #a5a6a6;
      }

      .top-pointer, .bottom-pointer {

        &.twenty {
          padding-left:20%;
        }

        &.forty {
          padding-left: 39%;
        }

        &.fifty {
          padding-left:50%;
        }

        &.sixty {
          padding-left:60%;
        }

        &.eighty {
          padding-left: 80%;
        }
      }

      &#vision-box-11 {
        .top-pointer, .bottom-pointer {
          padding-left: 62%;
        }
      }

      &#vision-box-14 {
        .left-pointer {
          padding-top: 25px;
        }
      }

      .left-pointer, .right-pointer {
        display: flex;
        flex-direction: row;
        padding-top: 60px;
        &.lower {
          padding-top:200px;
        }

        &.high {
          padding-top: 25px;
        }
      }

      .dot {
          height: 10px;
          width: 10px;
          background: #b3b3b3;
          border-radius: 50%;
          &.verticle {
              margin-left: -5px;
          }
          &.horizontal {
              margin-top: -5px;
          }
      }

      .verticle-line {
          height: 40px;
          width: 1px;
          background: #b3b3b3;
      }

      .horizontal-line {
          width: 40px;
          height: 1px;
          background: #b3b3b3;
      }

      &.dark {
        color: white;
        .text-box {
          background-color: black;
        }
      }
    }
  }

  .vision-streaks-intro-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;

    &__content {
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("~img/visions/phd/modal-content-background.jpg");
      background-size: cover;
      width: 85%;
      max-width: 620px;
      padding: 60px 35px;
      border: 3px solid #b3a272;
      // center on screen (+50px to take top nav into consideration)
      position: absolute;
      top: calc(50% + 50px);
      left: 50%;
      transform: translate(-50%, calc(-50% + 50px));

      @media (max-width: @widthMobileMax) {
        top: calc(2% + 50px);
        transform: translate(-50%, calc(-2% + 50px));
      }

      &__close {
        position: absolute;
        background-image: url("~img/visions/phd/modal-close.png");
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
        top: -20px;
        right: -15px;
        width: 30px;
        height: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      h3 {
        font-family: @fontNowayMedium;
        font-size: 26px;
        color: #fff;
        margin: 0;
      }

      p {
        font-family: @fontOpenSans;
        color: #c5cad0;
        text-align: center;
        font-size: 16px;
        padding: 40px 0;
        white-space: pre-wrap;
        margin: 0;
      }

      input {
        padding: 10px 50px;
        font-family: @fontNowayMedium;
        font-size: 16px;
        background: #fff;
        border-radius: 3px;
        outline: none;
        border: none;
        -webkit-appearance: none;
        appearance: none;

        &:hover {
          cursor: pointer;
          box-shadow: inset 0 0 40px 2px rgba(169, 169, 169, 0.6);
        }
      }
    }
  }
}
