.transition(@property: all, @duration: 2s) {
  transition: @property @duration ease-in-out;
}

.rio--carousel {
  height: auto;
  position: relative;
  overflow: hidden;

  > ul:first-child {
    list-style: none;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 30px;
    max-width: 100vw;

    li {
      width: 100%;
      flex-shrink: 0;
      order: 2;
      opacity: 0;
      padding: 0 2.5vw;
      will-change: opacity;
      .transition(opacity, 0.65s);

      &.active {
        order: 1;
        opacity: 1;
      }
    }
  }

  &--prev,
  &--next {
    background: transparent;
    background: url('~img/visions/plo/testimonial-right-arrow.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 45px;
    width: 45px;
    cursor: pointer;
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    will-change: transform;
    .transition;

    &:hover {
      transform: translateY(-50%) scale(1.2);
    }
  }

  &--prev {
    transform: translateY(-50%) rotate(180deg);

    &:hover {
      transform: translateY(-50%) rotate(180deg) scale(1.2);
    }
  }

  &--prev {
    left: 2%;
  }

  &--next {
    right: 2%;
  }

  &--dots {
    list-style: none;
    height: 1.2em;
    margin: 0;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    display: table;

    li {
      background: #7c8490;
      border-radius: 1.25em;
      cursor: pointer;
      display: inline-block;
      height: 0.6em;
      width: 0.6em;
      margin: 0 0.7em;
      padding: 0.2em;
      will-change: background, padding;
      .transition(all, 0.65s);

      &.active {
        background: #fff;
        padding: 0.2em 1.5em;
      }
    }

  }
}
