body.jungleman-promo {
  background-color: #000003;
  color: #fff;
  font-size: 62.5%;

  .content {
    display: flex;
    font-family: @fontOpenSans;
    height: 100vh;
  }

  .col-1 {
    background: url("~img/pages/jungleman-promo/sidebar.jpg") #15181e no-repeat;
    background-size: 100% auto;
    flex-grow: 0;
    width: 620px;
    margin: 15px 0 15px 15px;
    height: 96vh;
    min-height: 800px;

    .logo {
      margin: 0;
      padding: 0;

      a {
        background: url("~img/pages/jungleman-promo/logo.png") no-repeat;
        background-size: 235px auto;
        display: block;
        height: 35px;
        width: 235px;
      }

      span {
        visibility: hidden;
      }
    }

    h2 {
      font-size: 7.5em;
      font-weight: 700;
      line-height: 1.2em;
      margin: 1em 0 .2em;
    }

    .join {
      font-size: 3.0em;
      color: #bcbabb;
      margin: 0 0 1.75em;
      padding: 0;
    }

    .benefits {
      font-size: 2em;
      color: #ffffff;
      list-style: none;
      margin: 0 0 2em;

      li {
        padding-left: 25px;
        background: url("~img/pages/jungleman-promo/check.png") no-repeat;
        background-size: 14px auto;
        background-position: 0 .5em;
        padding-bottom: .5em;
      }
    }

    .free-btn {
      background: #d8341c;
      padding: .8em;
      text-align: center;
      color: #fff;
      font-size: 2em;
      display: block;
      margin-bottom: 1em;
      font-weight: 700;

      &:hover {
        text-decoration: none;
        opacity: .8;
      }
    }

    .promo-content {
      padding: 40px 60px 60px;
    }
  }

  .col-2 {
    flex-grow: 2;
    height: 100vh;
    background: url("~img/pages/jungleman-promo/jungleman-bg.jpg") no-repeat;
    // background-position: 620px 0px;
    background-size: contain;
    min-height: 800px;
    max-height: 1700px;
  }


  // Tablets
  @media (max-width: 1400px) {
    font-size: 40%;

    .col-1 {
      width: 350px;

      .promo-content {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }



  //  Phones
  @media (max-width: 760px) {
    .content {
      flex-direction: column;
      height: auto;
    }

    .col-1 {
      flex-grow: 0;
      order: 2;
      min-height: 0;
      height: auto;
      margin: 0 3vw;
      width: auto;

      .logo {
        display: none;
      }

      .promo-content {
        padding: 20px 20px 60px;
      }

      h2 {
        margin-top: 0px;
      }
    }

    .col-2 {
      background: url("~img/pages/jungleman-promo/jungleman-bg-mobile.jpg") no-repeat top center;
      flex-grow: 0;
      background-size: contain;
      order: 1;
      height: 62vw;
      min-height: 0;
      max-height: none;
    }
  }
}
