// Pro Training Video list page
body#poker-training_videos {

  #neck {
    position: relative;

    h1 {
      font-size: 36px;
      color: #fff;
      font-weight: 500;
      line-height: 1em;
    }

    h2 {
      font-family: @fontDefault;
      font-weight: 500;
      font-size: 21px;
      color: #838990;
      padding-bottom: 20px;
    }

    background: #2b3134;
    padding: 40px 0;

    .meet-the-pros {
      background: #656b72;
      border: none;
      position: absolute;
      right: 0;
      top: 16px;

      &:hover {
        background: #5b6167;
      }
    }
  }

  // Tell this flex item to not auto-grow with whatever is inside of it. This way we can have horizontal
  // scrolling on the tags list.
  .layout-one_column > .col_1 {
    min-width: 0
  }

  .filter_wrap {
    background: transparent url(~img/partials/thread/header-stuck-shadow.png) no-repeat center bottom;
    background-size: 100% auto;
    padding: 0 0 16px;
    margin-bottom: 36px;
  }

  #content_filters {
    margin-top: 8px;

    li:first-child {
      margin-left: 2px;
    }

    ul {
      .clearfix();
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }


  // 2 new video highlight section
  .highlighting_new {
    .clearfix();

    .featured_videos {
      .flex_container(row);
    }

    .featured_video {
      .clearfix();
      .flex_column(1);
      background: #1a1e1f;
      color: #687275;
      display: block;
      float: left;
      min-height: 200px;
      padding: 20px 15px;
      position: relative;

      .author-avatar,
      .author1-avatar,
      .author2-avatar {
        background: transparent no-repeat left top;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 90;
      }

      .author-avatar {
        background-size: 225px auto;
        width: 44%;
      }

      .author1-avatar,
      .author2-avatar {
        background-position: top center;
        background-size: 180% auto;
        width: 20%;
        max-width: 116px;
      }

      .author2-avatar {
        left: 20%;
      }

      &.index1 {
        margin-right: 4%;
      }

      &:hover {
        text-decoration: none;
      }

      h3 {
        font-family: @fontDefault;
        font-size: 17px;
        color: #fff;
      }

      .duration {
        display: block;
        font-size: 12px;
        padding-bottom: 15px;
      }

      p {
        font-size: 14px;
      }

      .description {
        padding-left: 230px;
      }

      .info {
        position: absolute;
        float: left;
        bottom: 15px;
        left: 15px;
        z-index: 100;

        .player_name {
          font-size: 14px;
          color: #fff;
        }

        .nickname {
          font-size: 11px;
          color: #85858d
        }

        &.author2 {
          left: 21%;
        }

        &.author1 {
          left: 5px;
        }
      }

      .video_link {
        color: #fff;
        background: url('~img/components/icon/playLrg.svg') no-repeat;
        background-size: 21px auto;
        display: inline-block;
        padding: 1px 0 1px 30px;
      }

      &:hover .video_link {
        text-decoration: underline;
      }
    }

    .meet-the-pros {
      position: absolute;
      right: 0;
      top: 30px;
    }

    &.unauthenticated {

      > h2 {
        font-size: 16px;
        color: #fff;
        padding-bottom: 16px;
      }

      .pro-videos-text {
        h1 {
          padding-bottom: 25px;
        }

        a {
          color: #fff;
          text-decoration: underline;
        }

        p {
          color: #888;
        }

        strong {
          color: #fff;
        }

        .meetthepros {
          background: #656b72;
          border: none;
          display: inline-block;
          margin-top: 30px;
          text-decoration: none;
        }
      }

      .featured_videos {
        .flex_container(column);
        width: 50;
        float: left;

        .featured_video {
          &.index1 {
            margin-right: 15%;
            margin-bottom: 5%;
          }

          &.index2 {
            margin-right: 15%;
          }
        }
      }
    }
  }


  .tags_container {
    display: flex;
    align-items: baseline;
    gap: 10px;
    padding: 30px 0 10px;
    border-bottom: 1px solid #ddd;
    min-height: 85px;

    .search_wrapper {
      input {
        border: 1px solid #c0c7ca;
        margin: 0;
        width: 155px;
        padding: 8px 10px 8px 30px;
        box-shadow: none;
        border-radius: 18px;
        background: url(~img/components/icon-non-sprite/search.svg) no-repeat 6px 5px;
        background-size: 20px auto;
        font-size: 13px;

        &:hover,
        &:active,
        &:focus {
          border-color: #999;
        }
      }
    }

    .tags_wrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    .arrow-left,
    .arrow-right {
      width: 50px;
      position: absolute;
      top: 3px;
      height: 44px;
      z-index: 5;

      &.hidden {
        display: none !important;
      }

      .arrow {
        height: 32px;
        width: 32px;
        text-align: center;
        border: 1px solid #ccc;
        background: #efefef;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
          background: #ddd;
        }

        &:active {
          background: #fff;
        }
      }

      .symbol {
        display: block;
        background: url(~img/components/icon-non-sprite/right-arrow.svg) no-repeat;
        background-size: 13px auto;
        width: 12px;
        height: 12px;

      }
    }

    .arrow-left {
      left: 0;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 25%);

      .symbol {
        transform: rotate(180deg);
      }
    }

    .arrow-right {
      right: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 25%);

      .arrow {
        position: absolute;
        right: 0;
      }
    }

    .tags_list {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      padding: 0 20px;
      margin: 0;

      // Hide scrollbars.
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
    }

    .tag-list-tag {
      // Stupid high default order so we can push any number of them to the front of the list.
      order: 1000;
      display: inline-block;
      list-style: none;
      margin: 0px 5px 0 0;
      line-height: 3em;
      flex-shrink: 0;
      position: relative;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .close {
        display: none;
      }

      &:hover {
        cursor: pointer;

        .tag-title {
          background: #eee;
          text-decoration: none;
        }
      }

      &.active {
        order: 1;
        display: inline-block !important;

        .close {
          color: #fff;
          display: block;
          position: absolute;
          left: 14px;
          top: -1px;
          content: "x";
          height: 10px;
          width: 10px;
        }

        .tag-title {
          border: 1px solid #2b3134;
          background: #2b3134;
          color: #fff;
          padding-left: 30px;

          &:hover {
            background: #444c51;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }

      &.hidden {
        display: none;
      }

      .tag-title {
        word-break: keep-all;
        padding: 8px 20px;
        border: 1px solid #c0c7ca;
        border-radius: 20px;
        background: #fff;
        color: #666b6b;
      }
    }
  }


  @media (min-width: @widthTabletMin) and (max-width: @widthTabletMax) {
    // Tablets
    .highlighting_new .featured_video {
      width: auto;
      max-width: 600px;
      float: none;
      margin: 10px 0;

      &.index2 {
        display: none;
      }
    }

    .highlighting_new.unauthenticated .featured_video {
      display: none;
    }
  }


  //  Phones
  @media (max-width: @widthMobileMax) {
    #neck {
      padding: 20px 0 10px;

      .meet-the-pros {
        display: none;
      }
    }

    .highlighting_new .featured_video {
      display: none;
    }

    .highlighting_new {
      h2 {
        display: none;
      }
    }

    .tags_container {
      flex-direction: column;

      .tags_list {
        padding-left: 0;
      }

      .search_wrapper {
        width: 100%;

        input {
          width: 100%;
        }
      }

    }
  }


}

