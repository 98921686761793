// Styles for modal found in newsletter-signup-modal.js
.rio-modal.rio-modal-newsletter-signup {
  overflow: auto;

  .content {
    width: 720px !important;
    height: auto !important;
  }

  .close-message {
    display: block !important;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .newsletter-signup-modal {
    background: #12171f url('~img/partials/newsletter-signup-modal/newsletter-signup-modal-bg.jpg') no-repeat;
    background-size: 100%;
    border-radius: 30px;

    .content-wrap {
      color: #fff;
      min-height: 440px;
      padding: 235px 0 5px;
      margin: 0 auto;

      .modal-title {
        color: #fff;
        font-size: 64px;
        font-weight: 700;
        line-height: 1em;
      }

      p {
        margin: 10px auto 25px;
        padding: 0;
        width: 430px;
        color: #9d9faa;
        font-size: 18px;
        line-height: 1.7em;
      }

      form {
        margin: 20px 10%;
        position: relative;

        .input-email {
          color: #7d868e;
          background: #343a3f;
          border: 1px solid  #343a3f;
          border-radius: 40px;
          font-size: 17px;
          padding: 20px;
          width: 100%;
        }

        .input-submit {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          display: block;
          cursor: pointer;
          border: none;
          outline: none;
          background: #12171f;
          color: white;
          position: absolute;
          top: 10px;
          font-size: 17px;
          right: 10px;
          padding: 12px 20px;
          border-radius: 31px;
          .transition(transform, 0.2s, ease-out);

          &:hover {
            transform: scale(1.1);
          }


          &:active {
            background: #333;
          }
        }
      }
    }
  }
}

// Start shrinking down the modal here.
@media (max-width: 915px) {
  .rio-modal-newsletter-signup.active {

    .content {
      overflow: hidden;
      width: 90% !important;
      max-width: 480px;
      height: auto !important;
    }

    .newsletter-signup-modal {
      .content-wrap {
        padding-top: 125px;
        min-height: auto;
        width: auto;

        .modal-title {
          font-size: 42px;
        }

        p {
          padding-left: 40px;
          padding-right: 40px;
          font-size: 14px;
          width: auto;
        }

        form {
          margin: 20px 5% 0;

          .input-submit {
            font-size: 14px;
          }

          .input-email {
            font-size: 14px;
          }

          .form-errors p {
            margin-bottom: 0;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}
