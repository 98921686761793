/**
  menu-dropdowns are the notifications and messages dropdown feed things in the main nav.
 */
.menu-dropdown {
  float: left;
  position: relative;
  padding: 16px 5px;
  cursor: pointer;

  &:hover {
    path {
      fill: #aaa !important;
    }

    // keep icon pro always white
    svg.icon-pro path {
      fill: #fff !important;
    }
  }

  &.active {
    path {
      fill: #FFF;
    }
  }

  .notification-list.menu-dropdown--dropdown {
    left: -10px;
  }

  // The list of messages that gets shown/hidden
  .dropdown,
  .menu-dropdown--dropdown {
    .border-radius(0 0 5px 5px);
    .box-shadow(0 0 20px rgba(0, 0, 0, .4));
    z-index: 3020;
    cursor: default;
    background: #fff;
    position: absolute;
    width: 410px;
    top: 45px;
    left: -5px;
    border: 1px solid #abafb1;
    opacity: 1;

    &.ng-hide {
      .transition(all, 0.1s, linear);
      opacity: 0;
      top: 30px;
    }

    &.ng-hide-add, &.ng-hide-remove {
      display: block !important;
    }

    // Triangle corner thingie.
    .tab {
      .transform(rotate(45deg));
      height: 10px;
      width: 10px;
      background: #E5E5E5;
      position: absolute;
      top: -6px;
      left: 20px;
    }

    // List header
    .mast {
      background: rgba(230, 230, 230, 0.85);
      border-bottom: 1px solid #D4D6D5;
      padding: 10px 0 10px 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;

      header {
        padding: 0 15px 0 15px;
        overflow: hidden;
        font-family: @fontDefault;
        font-size: 13px;
      }

      .count {
        float: left;
        font-weight: 500;
        position: relative;
        top: 5px;
        font-size: 14px;

        .message-count {
          display: inline-block;
          background: #c11018;
          color: #fff;
          margin-top: -2px;
          padding: 1px 6px;
          .border-radius(2px);
          font-size: 11px;
          font-weight: normal;
          margin-right: 3px;
        }
      }

      a {
        float: right;
      }
    }
  }

  // the list of notifications
  .list {
    height: 265px;
    overflow: auto;
    position: relative;

    // A single notification.
    article {
      a {
        color: #5d5c5c;
        display: block;
        border-bottom: 1px solid #D7D7D7;
        padding: 15px 15px;
        position: relative;

        &:hover {
          background: #fbfbfb;
          text-decoration: none;
        }
      }

      header {
        .clearfix();
      }

      h3 {
        font-family: @fontDefault;
        font-weight: 700;
        padding-bottom: 0px;
        font-size: 13px;
        letter-spacing: normal;
      }

      .avatar {
        float: left;
        margin-right: 15px;
      }

      .preview {
        font-size: 12px;
        line-height: 1.2em;
        font-weight: 400;
      }

      .unseen_indicator {
        display: block;
        top: 46%;
        left: 5px;
      }

      &.seen {
        opacity: .5;

        .unseen_indicator {
          display: none;
        }
      }

      .action {
        color: #515555;
      }

      .target {
        font-weight: 700;
      }

      .timestamp {
        display: block;
        font-size: 11px;
        padding-top: 10px;
      }
    }
  }

  // Red dot on a list item.
  .unseen_indicator {
    position: absolute;
    background: #c11018;
    width: 5px;
    height: 5px;
    .border-radius(50%);
    top: 15px;
    left: 5px;
  }

  // List footer
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .border-radius(0 0 5px 5px);
    background: rgba(230, 230, 230, 0.85);
    border-top: 1px solid #D4D6D5;
    overflow: hidden;
    padding: 5px 0 5px 0;

    .controls {
      padding: 0 15px 0 15px;
      .clearfix();
    }

    .btn {
      display: inline-block;
    }
  }

}
